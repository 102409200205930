import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LayoutService } from '../../../layout/service/app.layout.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import tippy from 'tippy.js';
import { createPdf } from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions, Content, StyleDictionary } from 'pdfmake/interfaces';
import { MaturityFrameworkAssessmentComponent } from './assessment/maturity-framework-assessment';
import { AssessmentMaturity, CategoryQuestionMaturity, SubCategoryQuestionMaturity } from '../../api/maturity.api';
import { DbService } from '../../service/db.service';
import { AuthService } from '../../service/auth.service';
import { firstValueFrom } from 'rxjs';

@Component({
	templateUrl: './maturity-framework.html',
	styleUrls: ['./maturity-framework.scss'],
	providers: [ConfirmationService, MessageService],
})
export class MaturityFrameworkComponent implements OnInit {
	constructor(
		private http: HttpClient,
		public layoutService: LayoutService,
		public dialogService: DialogService,
		public dbService: DbService,
		public authService: AuthService,
		public messageService: MessageService,
		public confirmationService: ConfirmationService,
	) {}

	pillars_components = [
		{
			title: 'Strategic Alignment',
			description: 'Strategic alignment on the Enterprise AI strategy across the leadership, Board and Investors',
		},
		{
			title: 'AI Strategic Plan',
			description: 'A roadmap outlining specific actions and initiatives to implement the AI strategy',
		},
		{
			title: 'Value Realization',
			description:
				'Demonstrating the impact of AI initiatives on driving business outcomes, such as improved decision-making, increased agility, or competitive advantage',
		},
		{
			title: 'Enterprise AI Policy',
			description:
				'It refers to a set of guidelines to govern the development, deployment, and use of AI technologies across the enterprise',
		},
		{
			title: 'Accountability & Transparency',
			description:
				'Mechanisms and processes put in place to ensure that decision-making processes involving AI systems are understandable, traceable, and subject to scrutiny',
		},
		{
			title: 'Risk Management',
			description:
				'Risk Management involves identifying, assessing, and mitigating potential risks associated with the development, deployment, and use of AI technologies',
		},
		{
			title: 'Governance',
			description:
				'The set of processes, policies, and structures established to ensure that AI initiatives align with organizational goals, regulatory requirements, and ethical standards',
		},
		{
			title: 'Culture',
			description:
				'An AI-ready culture embraces innovation, continuous learning, collaboration, and diversity, fostering an environment where AI adoption is embraced',
		},
		{
			title: 'Talent Strategy',
			description:
				'Talent strategy involves the systematic approach to acquiring, developing, and retaining the skilled workforce needed to drive AI initiatives forward',
		},
		{
			title: 'Cybersecurity Risk Management',
			description:
				'The process of identifying, assessing, and mitigating risks related to the security of digital assets, including data, systems, and networks',
		},
		{
			title: 'IP Protection',
			description:
				'Safeguarding intellectual property assets, including patents, copyrights, trademarks, and trade secrets, from unauthorized use, reproduction, or exploitation',
		},
		{
			title: 'Embedded Security',
			description:
				'Integration of security measures directly into hardware, software, or firmware components to protect against cyber threats and vulnerabilities',
		},
		{
			title: 'Cloud Infrastructure',
			description:
				'Virtualized resources, including computing power, storage, and networking, that are available on-demand via the internet from cloud service providers',
		},
		{
			title: 'AI Application Development Platforms',
			description:
				'Software tools and frameworks that streamline the process of building, deploying, and managing AI-powered applications',
		},
		{
			title: 'Interoperability',
			description:
				'It ensures that AI systems can communicate, integrate, and collaborate effectively with other systems and technologies within an organization ecosystem',
		},
		{
			title: 'Data Governance',
			description:
				'Management of the availability, usability, integrity, and security of dataManagement of the availability, usability, integrity, and security of data',
		},
		{
			title: 'Data Platforms',
			description:
				'Technologies and systems designed to support the storage, management, processing, and analysis of large volumes of data',
		},
		{
			title: 'Data Security & Privacy',
			description:
				'Measures and practices implemented to protect sensitive information from unauthorized access, disclosure, alteration, or destruction',
		},
		{
			title: 'AI Marketplace & Accessibility',
			description:
				'Platform or ecosystem where AI tools, models, algorithms, and solutions are made available for purchase, licensing, or free usage',
		},
		{
			title: 'Models / Solutions Management',
			description:
				'The AI models and solutions that are built and deployed need to be managed for drift, effectiveness, and cost',
		},
		{
			title: 'AI Integrated Operations',
			description:
				"Seamless integration of AI technologies into various aspects of an organization's operations, workflows, and decision-making processes",
		},
	];

	categories: CategoryQuestionMaturity[] = [];

	assessment: AssessmentMaturity;

	stageValue: string = '';

	async ngOnInit() {
		this.layoutService.updateTitle('AI Maturity Framework');

		await this.load();

		const elements = document.querySelectorAll<HTMLElement>('circle.cls-5');

		const subcategories: SubCategoryQuestionMaturity[] = [];

		for (const category of this.categories) {
			subcategories.push(
				...(await this.dbService.data_sub_category_question_maturity
					.where('categoryId')
					.equals(category.id as string)
					.sortBy('sort')),
			);
		}

		const parentTooltip = document.querySelector<HTMLElement>('.maturity-chart');

		if (parentTooltip) {
			for (let i = 0; i < elements.length; i++) {
				const element = elements[i];
				const subcategory = subcategories[i];
				const htmlTemplate = `
								<div class="flex gap-2 align-items-center m-1" style="font-size:10px">
									<span><b>${this.pillars_components[i].title}</b></span>
									<span>${this.pillars_components[i].description}</span>
								</div>
								`;
				tippy(element, {
					content: htmlTemplate,
					allowHTML: true,
					arrow: false,
					appendTo: parentTooltip,
					offset: [10, 0],
					placement: 'right-start',
					zIndex: 100,
					duration: 10,
				});
			}
		}

		document.querySelectorAll<HTMLElement>('text.cls-7').forEach((text, i) => {
			text.addEventListener('click', () => {
				this.modal(i);
			});
		});
	}

	async load() {
		this.categories = await this.dbService.data_category_question_maturity.toCollection().sortBy('sort');

		const assessments = await this.dbService.data_assessment_maturity
			.where('organizationId')
			.equals(await this.authService.getCurrentOrganizationId())
			.toArray();

		if (assessments.length) {
			this.assessment = assessments[0];
		} else {
			this.assessment = new AssessmentMaturity('', {}, {}, [], await this.authService.getCurrentOrganizationId());
			await this.dbService.data_assessment_maturity.add(this.assessment);
		}

		this.stageValue = this.getStage(
			this.categories.map((c) => this.assessment.formData[c.id as string] || 0).reduce((a, b) => a + b, 0) /
				this.categories.length,
		);
	}

	modal(current_index: number) {
		const ref = this.dialogService.open(MaturityFrameworkAssessmentComponent, {
			data: { current_index },
			header: 'Assessments',
			width: '95%',
			height: '90%',
			styleClass: 'maturity-framework-assessments',
			contentStyle: { overflow: 'auto' },
			showHeader: false,
			baseZIndex: 100,
			maximizable: true,
		});

		ref.onClose.subscribe(() => {
			this.load().then(() => {});
		});
	}

	getCategoryStage(category: CategoryQuestionMaturity) {
		return this.getStage(this.getStageValue(category));
	}

	getStageValue(category: CategoryQuestionMaturity) {
		return this.assessment ? this.assessment.formData[category.id as string] || 0 : 0;
	}

	getStage(value: number): string {
		if (value < 0 || value > 100) {
			value = 0;
		}

		const nearestStageValue = Math.round(value / 25) * 25;

		switch (nearestStageValue) {
			case 0:
				return '--';
			case 25:
				return 'Stage 1';
			case 50:
				return 'Stage 2';
			case 75:
				return 'Stage 3';
			case 100:
				return 'Stage 4';
			default:
				return '--';
		}
	}

	exportPDF() {
		this.confirmationService.confirm({
			header: 'Export PDF report',
			message: 'Generate a PDF report of the assessment',
			icon: 'pi pi-file-pdf',
			accept: () => {
				this.createPDF().then(() => {});
			},
			reject: (type: ConfirmEventType) => {
				switch (type) {
					case ConfirmEventType.REJECT:
						break;
					case ConfirmEventType.CANCEL:
						break;
				}
			},
		});
	}

	async createPDF() {
		const documentStyle = getComputedStyle(document.documentElement);

		const styles: StyleDictionary = {
			h1: {
				fontSize: 22,
				bold: true,
				lineHeight: 1.5,
			},
			h2: {
				fontSize: 18,
				bold: true,
				lineHeight: 1.5,
			},
			textPrimary: {
				color: documentStyle.getPropertyValue('--primary-color'),
			},
		};

		const graphSVG = await firstValueFrom(this.http.get('assets/report/graph.svg', { responseType: 'text' }));
		const barSVG = await firstValueFrom(this.http.get('assets/report/bar.svg', { responseType: 'text' }));
		const stage1SVG = await firstValueFrom(this.http.get('assets/report/stage1.svg', { responseType: 'text' }));
		const stage2SVG = await firstValueFrom(this.http.get('assets/report/stage2.svg', { responseType: 'text' }));
		const stage3SVG = await firstValueFrom(this.http.get('assets/report/stage3.svg', { responseType: 'text' }));
		const stage4SVG = await firstValueFrom(this.http.get('assets/report/stage4.svg', { responseType: 'text' }));

		const getCategoryStage = (category: CategoryQuestionMaturity) => {
			return getStage(this.getStageValue(category));
		};

		const getStage = (value: number): string => {
			if (value < 0 || value > 100) {
				value = 0;
			}

			const nearestStageValue = Math.round(value / 25) * 25;

			switch (nearestStageValue) {
				case 0:
					return '';
				case 25:
					return stage1SVG;
				case 50:
					return stage2SVG;
				case 75:
					return stage3SVG;
				case 100:
					return stage4SVG;
				default:
					return '';
			}
		};

		const stageSVG = getStage(
			this.categories.map((c) => this.assessment.formData[c.id as string] || 0).reduce((a, b) => a + b, 0) /
				this.categories.length,
		);

		const content: Content = [
			{
				columns: [
					{ width: '80%', text: 'Assessment Overview', style: ['h1', 'textPrimary'] },
					{
						width: '20%',
						stack: stageSVG ? [{ svg: stageSVG }] : [],
						alignment: 'right',
					},
				],
				marginBottom: 15,
			},
			{ text: 'Hackett’s AI Framework is based on seven Foundational Pillars', style: 'h2' },
			{
				svg: graphSVG,
				width: 250,
				height: 250,
				alignment: 'center',
				margin: [0, 10, 0, 15],
			},
			{ text: 'AI Foundational Pillars', style: 'h2' },
		];

		this.categories.map((category) => {
			const stageValue = this.getStageValue(category);
			const stageSVG = getCategoryStage(category);
			content.push({
				columns: [
					{
						width: '75%',
						text: category.name,
						style: 'textPrimary',
					},
					{
						width: '15%',
						stack: stageSVG ? [{ svg: stageSVG }] : [],
						alignment: 'right',
					},
					{
						width: '10%',
						text: '',
					},
				],
				margin: [0, 6, 0, 2.5],
			});
			content.push({
				columns: [
					{
						width: '90%',
						stack: [
							{
								svg: barSVG.replace('width="100"', `width="${(stageValue * 550) / 100}"`),
								width: 465,
							},
						],
					},
					{
						width: '10%',
						text: stageValue + ' %',
						style: 'textPrimary',
						alignment: 'left',
						marginLeft: 10,
						marginTop: 4,
					},
				],
				margin: [0, 2.5, 0, 6],
			});
		});

		content.push({ text: '', pageBreak: 'after' });

		content.push({ text: 'Hola' });

		const docDefinition: TDocumentDefinitions = {
			pageSize: 'A4',
			content,
			styles,
		};

		createPdf(docDefinition).download('report');
	}
}
