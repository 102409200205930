<p-toast position="top-center"></p-toast>

<div class="sign-in row">
	<div class="welcome col-12 md:col-6 lg:col-7 xl:col-8 grid">
		<div class="welcome-background col-12 md:col-8 lg:col-8 xl:col-8"></div>
		<div class="welcome-color col-12 md:col-8 lg:col-8 xl:col-8"></div>
		<div
			class="col-12 md:col-10 lg:col-9 xl:col-8 welcome-text flex justify-content-around align-content-center flex-wrap"
		>
			<div></div>
			<div class="flex justify-content-between flex-wrap gap-6">
				<div>
					<p class="text-white font-bold text-6xl">AI XPLR</p>
					<p class="text-white text-4xl">Platform</p>
				</div>
				<div class="welcome-bar w-full border-round"></div>
			</div>
			<div></div>
			<div></div>
		</div>
	</div>
	<div class="col-12 md:col-6 lg:col-5 xl:col-4 h-screen form">
		<div class="flex flex-column justify-content-center align-items-center h-full">
			<div class="flex flex-column justify-content-center flex-wrap gap-3 form-content">
				<img
					ngSrc="../../../../assets/rtp/img/hackett-logo.png"
					alt="The Hackett Group"
					height="35"
					width="260"
					class="ml-3"
				/>
				<div class="grid ml-3 gap-3">
					<form [formGroup]="form" class="form-inputs">
						<div class="col-12">
							<p class="text-color-secondary">Sign in using your credentials</p>
						</div>
						<div class="col-12">
							<input
								type="text"
								pInputText
								id="username"
								formControlName="username"
								placeholder="Username"
								class="form-input-text p-3 w-full"
								(keyup.enter)="signIn()"
							/>
						</div>
						<div class="col-12">
							<p-password
								inputId="password"
								formControlName="password"
								placeholder="Password"
								styleClass="w-full"
								inputStyleClass="form-input-text p-3 w-full"
								[feedback]="false"
								[toggleMask]="true"
								(keyup.enter)="signIn()"
							></p-password>
						</div>
					</form>
					<div class="col-12">
						<div class="flex justify-content-between align-items-center">
							<p-button
								id="submit"
								label="Login"
								styleClass="p-button-lg"
								[loading]="loading"
								(onClick)="signIn()"
							></p-button>
							<p-button
								label="Forgot Password?"
								styleClass="p-button-sm p-button-text"
								[disabled]="loading"
								(onClick)="showResetPassword()"
							></p-button>
						</div>
					</div>
				</div>
				<p class="text-color-secondary ml-5 text-sm">
					Using the AI XPLR Platform, is subject to the end user terms and conditions.
				</p>
			</div>
		</div>
	</div>
</div>

<p-dialog
	header="Forgot your password?"
	[(visible)]="reset_password_visible"
	[style]="{ 'width': '35vw' }"
	[modal]="true"
	[draggable]="false"
	[resizable]="false">
	<form (ngSubmit)="resetPassword()" class="px-3">
		<div class="flex flex-column justify-content-between text-gray-800 gap-3" style="max-width: 80%">
			<span>Enter your Email and we will sent you a link to reset your password.</span>
			<input id="usernameOrEmail" type="text" [(ngModel)]="username_email" name="usernameOrEmail"
				   class="p-inputtext p-component" required placeholder="youremail@example.com"/>
			<button type="submit" pButton label="Send recovery email" [loading]="reset_password_loading"
					[disabled]="!username_email" class="text-sm"  style="max-width: 50%"></button>
		</div>
	</form>
</p-dialog>
