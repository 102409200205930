import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DbService } from '../../../service/db.service';
import { AuthService } from '../../../service/auth.service';
import { Options as OptionsSlider } from 'ngx-slider-v2/options';

@Component({
	templateUrl: './benefits-assessment.html',
})
export class BenefitsAssessmentComponent implements OnInit {
	constructor(
		public ref: DynamicDialogRef,
		public dbService: DbService,
		public authService: AuthService,
		public config: DynamicDialogConfig<{
			current_index?: number;
		}>,
	) {}

	current_index = 0;

	max_index = 7;

	labels: string[] = [];
	phases: string[] = [];

	items: MenuItem[] = [];

	data: {
		label: string;
		questions: {
			id: string;
			text: string;
			description: string;
			value: number;
			res1: string;
			res2: string;
			res3: string;
		}[];
	}[] = [
		{
			label: 'FTEs',
			questions: [
				{
					id: '1',
					text: 'FTE Actual',
					description: 'Our GL and fixed asset depts.',
					value: 0,
					res1: '',
					res2: '',
					res3: '',
				},
			],
		},
		{
			label: 'Productivity',
			questions: [
				{
					id: '1',
					text: 'Volume (Units)',
					description: 'Journal entries',
					value: 0,
					res1: '',
					res2: '',
					res3: '',
				},
			],
		},
		{
			label: 'Costs',
			questions: [
				{ id: '1', text: 'Labor Cost', description: '', value: 0, res1: '', res2: '', res3: '' },
				{ id: '2', text: 'Outsourcing Cost', description: '', value: 0, res1: '', res2: '', res3: '' },
			],
		},
		{
			label: 'Speed',
			questions: [
				{
					id: '1',
					text: 'Cycle Time (in Hours)',
					description: 'For us to close the books',
					value: 0,
					res1: '',
					res2: '',
					res3: '',
				},
			],
		},
		{
			label: 'Automation',
			questions: [
				{
					id: '1',
					text: 'Automation Percentage',
					description: 'Oracle Journalizing',
					value: 0,
					res1: '',
					res2: '',
					res3: '',
				},
			],
		},
		{
			label: 'Accuracy',
			questions: [
				{
					id: '1',
					text: 'Accuracy %',
					description: '',
					value: 0,
					res1: '',
					res2: '',
					res3: '',
				},
			],
		},
	];

	questions: {
		id: string;
		text: string;
		description: string;
		value: number;
	}[] = [
		{
			id: '1',
			text: 'Readiness to maintain AI solutions efficiently over time, including updates, improvements, and troubleshooting.',
			description: '',
			value: 0,
		},
		{
			id: '2',
			text: "Organization's capacity for collaboration across different functions and teams to support Cognitive Automation-driven processes.",
			description: '',
			value: 0,
		},
		{
			id: '3',
			text: "Alignment of Virtual Assistants with the organization's strategic objectives and executive leadership support.",
			description: '',
			value: 0,
		},
		{
			id: '4',
			text: "Alignment of Cognitive Automation with the organization's strategic objectives and executive leadership support.",
			description: '',
			value: 0,
		},
		{
			id: '5',
			text: "Organization's ability to maintain and update Intelligent Data Capture solutions efficiently.",
			description: '',
			value: 0,
		},
		{
			id: '6',
			text: 'Maturity level of the existing sub-processes and their readiness for AI integration.',
			description: '',
			value: 0,
		},
		{
			id: '7',
			text: "Alignment of Intelligent Data Capture with the organization's strategic objectives and executive leadership support.",
			description: '',
			value: 0,
		},
		{
			id: '8',
			text: "Alignment of Task Automation with the organization's strategic objectives and executive leadership support.",
			description: '',
			value: 0,
		},
		{
			id: '9',
			text: 'Risk level related to Generative AI adoption and mitigation planning.',
			description: '',
			value: 0,
		},
		{
			id: '10',
			text: 'Availability of budget resources for AI implementation.',
			description: '',
			value: 0,
		},
		{
			id: '11',
			text: 'Risk level related to Virtual Assistant adoption and mitigation planning.',
			description: '',
			value: 0,
		},
		{
			id: '12',
			text: "Organization's ability to maintain and update Cognitive Automation solutions efficiently.",
			description: '',
			value: 0,
		},
		{
			id: '13',
			text: 'Risk level related to Intelligent Data Capture adoption and mitigation planning.',
			description: '',
			value: 0,
		},
		{
			id: '14',
			text: 'Extent to which AI adoption aligns with regulatory requirements in the industry.',
			description: '',
			value: 0,
		},
	];

	loading = true;

	async ngOnInit() {
		['Benefits', 'Governance', 'Data', 'Talent'].forEach((c) => {
			this.labels.push(c);
			let label = c.split(' ')[0];
			if (label.length < 3) {
				label = c;
			}
			this.items.push({
				label,
			});
		});

		this.phases = [...this.labels];

		this.max_index = this.items.length - 1;

		this.loading = false;
	}

	close() {
		this.ref.close();
	}

	previousStep() {
		if (this.current_index > 0) {
			this.current_index--;
		}
	}

	nextStep() {
		if (this.current_index < this.max_index) {
			this.current_index++;
		}
	}

	changeStep(index: number) {
		this.current_index = index;
	}

	getFormSliderOptions(question: { id: string; value: number }): OptionsSlider {
		const getTextClass = (value: number): string => {
			if (value === 4) {
				return 'text-4';
			} else if (value === 3) {
				return 'text-3';
			} else if (value === 2) {
				return 'text-2';
			} else if (value === 1) {
				return 'text-1';
			} else {
				return 'text-na';
			}
		};
		return {
			floor: 0,
			ceil: 4,
			step: 1,
			showTicks: true,
			showTicksValues: true,
			getPointerColor: (value: number): string => {
				if (value === 4) {
					return '#383890';
				} else if (value === 3) {
					return '#6B9E00';
				} else if (value === 2) {
					return '#FF7D00';
				} else if (value === 1) {
					return '#5B6770';
				} else {
					return '#C1C6C8';
				}
			},
			translate: (value: number): string => {
				return '';
			},
			getLegend: (value: number): string => {
				const label = value ? value : 'N/A';
				let textClass = question.value === value ? getTextClass(question.value) : 'text-na';
				return `<span class="${textClass}">${label}</span>`;
			},
		};
	}
}
