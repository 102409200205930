import Quill from 'quill';
import DeltaType from 'quill-delta';

const List: any = Quill.import('formats/list');
const ListItem: any = Quill.import('formats/list/item');
const Parchment: any = Quill.import('parchment');
const Module: any = Quill.import('core/module');
const Delta: typeof DeltaType = Quill.import('delta');
const icons: any = Quill.import('ui/icons');

class CheckBoxListItem extends ListItem {
	static blotName = 'check-box-list-item';
	static tagName = 'li';
	static className = 'check-box-list-item';

	static create(value: any) {
		const node = super.create(value);
		node.setAttribute('class', 'check-box-list-item');
		node.addEventListener('click', () => {
			node.setAttribute('data-checked', !(node.getAttribute('data-checked') === 'true'));
		});
		return node;
	}

	static value(domNode: any) {
		return domNode.getAttribute('data-checked') === 'true';
	}

	static formats(domNode: any) {
		const format: any = { 'check-box-list-item': true };
		if (domNode.hasAttribute('data-checked')) {
			format['data-checked'] = domNode.getAttribute('data-checked') === 'true';
		}
		return format;
	}

	format(name: any, value: any) {
		if (name === CheckBoxListItem.blotName && !value) {
			(this as any).replaceWith(Parchment.create((this as any).statics.scope));
		} else if (name === 'data-checked') {
			(this as any).domNode.setAttribute(name, value ? 'true' : 'false');
		} else {
			super.format(name, value);
		}
	}

	clone() {
		const clone = super.clone();
		clone.domNode.setAttribute('data-checked', 'false');
		return clone;
	}
}

class CheckBoxList extends List {
	static blotName = 'check-box-list';
	static tagName = 'ul';
	static className = 'check-box-list';
	static defaultChild = 'check-box-list-item';
	static allowedChildren = [CheckBoxListItem];

	static create(value: any) {
		const node = super.create('');
		node.setAttribute('class', 'check-box-list');
		node.setAttribute('data-checked', false);
		return node;
	}
}

class CheckBoxListModule extends Module {
	public quill: Quill;

	constructor(quill: Quill, options: any) {
		super(quill, options);
		this.quill = quill;

		this.quill.clipboard.addMatcher('ul.check-box-list', (node, delta) => {
			delta.ops.forEach((op) => {
				op.attributes = { ...(op.attributes || {}), 'check-box-list': 'unchecked' };
				if (!!op.attributes['check-box-list-item']) {
					const options = op.attributes['check-box-list-item'];
					op.attributes = { ...(op.attributes || {}), ...options };
					delete op.attributes['check-box-list-item'];
				}
			});
			return delta;
		});
	}
}

Quill.register({
	'formats/check-box-list': CheckBoxList,
	'formats/check-box-list-item': CheckBoxListItem,
	'modules/check-box-list': CheckBoxListModule,
});

icons['check-box-list'] = `
  <svg class="" viewbox="0 0 18 18">
    <line class="ql-stroke" x1="9" x2="15" y1="4" y2="4"></line>
    <polyline class="ql-stroke" points="3 4 4 5 6 3"></polyline>
    <line class="ql-stroke" x1="9" x2="15" y1="14" y2="14"></line>
    <polyline class="ql-stroke" points="3 14 4 15 6 13"></polyline>
    <line class="ql-stroke" x1="9" x2="15" y1="9" y2="9"></line>
    <polyline class="ql-stroke" points="3 9 4 10 6 8"></polyline>
  </svg>
`;
