import { Component, OnInit } from '@angular/core';
import { SimulationService } from '../simulation.service';

@Component({
	selector: 'app-simulation-enterprise-context',
	templateUrl: './enterprise-context.component.html',
	styleUrls: ['./enterprise-context.component.scss'],
})
export class EnterpriseContextComponent implements OnInit {
	constructor(public simulationService: SimulationService) {}

	async ngOnInit() {}
}
