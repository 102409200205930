<div class="mt-5 pt-3 px-2">
	<div class="row pr-3">
		<div class="col-5 p-0"><label>Questions</label></div>
		<div class="col-3 p-0"><label>Score</label></div>
		<div class="col-4 p-0"><label>Comments</label></div>
	</div>

	<form *ngIf="form" [formGroup]="form" class="card-content-form ml-3 mt-1">
		<ng-template ngFor let-question [ngForOf]="questions">
			<p *ngIf="ifSubCategory(question)" class="mb-0 mt-2 text-primary font-bold">
				{{ subCategoryLabel(question) }}
			</p>
			<div *ngIf="question.id" class="grid text-stepper border-bottom-1 border-gray-300">
				<div class="col-5 flex align-content-end" style="padding-top: 0; padding-bottom: 0">
					<span class="flex align-items-center justify-content-center">
						{{ question.text }}
					</span>
				</div>
				<div class="col-3" style="padding-top: 0; padding-bottom: 0">
					<ngx-slider
						[id]="question.id"
						[formControlName]="question.id + '-value'"
						[options]="service.getFormSliderOptions(question.id + '-value', form)"
						(userChangeEnd)="getFormData()"
					>
					</ngx-slider>
				</div>
				<div class="col-4 flex align-content-end" style="padding-right: 0; padding-top: 0; padding-bottom: 0">
					<div class="flex align-items-center justify-content-center" style="width: 100%">
						<input
							placeholder="add notes"
							class="w-full comments"
							[formControlName]="question.id + '-note'"
							(blur)="saveNote(question.id)"
						/>
					</div>
				</div>
			</div>
		</ng-template>
	</form>

	<div *ngIf="!form" class="card-content-form ml-3 mt-1 pt-3">
		<div class="grid text-stepper mt-2 mb-2">
			<div class="col-5" style="padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
			<div class="col-3" style="padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
			<div class="col-4" style="padding-right: 0; padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
		</div>
		<div class="grid text-stepper mt-2 mb-2">
			<div class="col-5" style="padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
			<div class="col-3" style="padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
			<div class="col-4" style="padding-right: 0; padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
		</div>
		<div class="grid text-stepper mt-2 mb-2">
			<div class="col-5" style="padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
			<div class="col-3" style="padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
			<div class="col-4" style="padding-right: 0; padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
		</div>
		<div class="grid text-stepper mt-2 mb-2">
			<div class="col-5" style="padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
			<div class="col-3" style="padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
			<div class="col-4" style="padding-right: 0; padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
		</div>
		<div class="grid text-stepper mt-2 mb-2">
			<div class="col-5" style="padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
			<div class="col-3" style="padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
			<div class="col-4" style="padding-right: 0; padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
		</div>
		<div class="grid text-stepper mt-2 mb-2">
			<div class="col-5" style="padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
			<div class="col-3" style="padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
			<div class="col-4" style="padding-right: 0; padding-top: 0; padding-bottom: 0">
				<p-skeleton styleClass="mb-5"></p-skeleton>
			</div>
		</div>
	</div>
</div>
