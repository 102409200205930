<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>

<div *ngIf="currentM1Prep" class="flex flex-wrap">
	<div class="col-4">
		<div class="flex flex-column justify-content-start align-items-start gap-2">
			<label for="company" class="font-bold label-filter-details">Company (*)</label>
			<!-- <input
				type="text"
				pInputText
				placeholder="Enter Company Name"
				*ngIf="!activeSalesforce"
				id="company"
				class="w-full"
				[(ngModel)]="companyName"
			/> -->
			<p-autoComplete
				[(ngModel)]="currentM1Prep.context.info"
				[suggestions]="suggestions"
				(completeMethod)="search($event)"
				(onSelect)="onCompanySelect($event)"
				field="name"
				placeholder="Enter Company Name"
				class="w-full"
				[style]="{ width: '100%' }"
				[inputStyle]="{ width: '100%' }"
			>
			</p-autoComplete>
		</div>
	</div>
	<div class="col-4">
		<div class="flex flex-column justify-content-start align-items-start gap-2">
			<label for="industry" class="font-bold label-filter-details">Industry (*)</label>
			<p-autoComplete
				class="w-full"
				styleClass="filter-content min-token"
				panelStyleClass="w-full"
				inputStyleClass="w-full"
				[(ngModel)]="currentM1Prep.context.industryTreeSelected"
				[suggestions]="simulationService.suggestionsIndustry"
				(completeMethod)="simulationService.searchIndustry($event)"
				field="label"
				placeholder="Specify industry"
				appendTo="body"
				id="industry"
				inputId="industry"
				[multiple]="true"
			></p-autoComplete>
		</div>
	</div>
	<div class="col-4">
		<div class="flex flex-column justify-content-start align-items-start gap-2">
			<label class="font-bold label-filter-details">Taxonomy (*)</label>
			<p-treeSelect
				class="w-full"
				containerStyleClass="w-full"
				[(ngModel)]="currentM1Prep.context.taxonomyTreeSelected"
				[options]="simulationService.addProcessTaxonomyTreeSelect"
				placeholder="All"
				display="chip"
				[metaKeySelection]="false"
				[propagateSelectionUp]="false"
				selectionMode="checkbox"
				appendTo="body"
			></p-treeSelect>
		</div>
	</div>
	<div class="col-12 py-0">
		<div class="col-12 p-0" style="display: grid; grid-template-columns: auto 1fr; align-items: center">
			<div class="d-flex align-items-center">
				<!-- <p-checkbox
					[binary]="true"
					[(ngModel)]="activeSalesforce"
					inputId="incremental"
				></p-checkbox>
				<label for="incremental" class="pl-2" style="cursor: pointer;">Hackett Company (Generate Salesforce Insights)</label> -->
			</div>
			<div class="d-flex align-items-center gap-3" style="justify-self: end">
				<p-button
					label="Clear"
					icon="pi pi-eraser"
					styleClass="button-header"
					[disabled]="loadingA || loadingB || loadingC || loadingE"
					(onClick)="clearM1Prep()"
					class="pr-3"
				></p-button>
				<p-button
					*ngIf="currentM1Prep.context.info.name && currentM1Prep.hasAnyData"
					label="Save"
					class="pl-3 pr-3"
					icon="pi pi-save"
					styleClass="button-header"
					[disabled]="loadingA || loadingB || loadingC || loadingE"
					(click)="saveM1Prep()"
				></p-button>
				<p-button
					label="Generate Insights"
					icon="pi pi-play-circle"
					[disabled]="loadingA || loadingB || loadingC || loadingE"
					[loading]="loadingA || loadingB || loadingC || loadingE"
					(onClick)="runAssistant()"
				></p-button>
			</div>
		</div>
	</div>
	<div class="col-12">
		<div class="flex flex-column justify-content-start align-items-start gap-2">
			<label for="add-information" class="font-bold label-filter-details"> Additional Instructions </label>
			<textarea
				rows="2"
				pInputTextarea
				placeholder="Enter additional instructions"
				id="add-information"
				class="w-full"
				[(ngModel)]="currentM1Prep.context.additionalInformation"
			></textarea>
		</div>
	</div>
	<div *ngIf="!hasRun && !loadingA && !loadingB && !loadingC && !loadingE" class="col-12">
		<div class="box-simulation-item mt-3 py-3">
			<div class="flex flex-column justify-content-center align-items-center h-15rem text-color-normal">
				<div>
					<img src="assets/images/drawNew.svg" alt="Generate Data" class="w-10rem h-10rem" />
				</div>
				<div class="pt-3">
					<span class="text-empty"
						>Fill the form and click into
						<span class="text-primary font-bold">Generate Insights</span> button to generate the necessary
						data and inputs for this M1 Preparation.</span
					>
				</div>
			</div>
		</div>
	</div>

	<div class="col-12 ml-3 pb-0" *ngIf="currentM1Prep.hasCompanyAnalysis || loadingA">
		<div
			class="grid w-full pt-3 box-simulation-item-content"
			style="background: linear-gradient(to bottom, #f5f5f6 0%, #ffffff 100%)"
		>
			<div class="flex justify-content-between align-items-center w-full py-3" style="height: 100%">
				<div class="flex align-items-start" (click)="op.toggle($event)" style="cursor: pointer">
					<i class="pi pi-building text-primary" style="font-size: 1.5rem"></i>
					<span class="text-md lg:text-lg xl:text-xl font-bold pl-3">{{
						currentM1Prep.context.info.name
					}}</span>
				</div>

				<!-- <div>
					<p-button
						label="Export"
						icon="pi pi-file"
						styleClass="p-button-primary mr-2"
						[disabled]="loadingA || loadingB || loadingC || loadingE"
						[loading]="loadingA || loadingB || loadingC || loadingE"
						(click)="exportData()"
					></p-button>
				</div> -->
			</div>
		</div>
	</div>

	<div class="col-6 pt-0" *ngIf="currentM1Prep.hasCompanyAnalysis || loadingA">
		<div class="box-simulation-item-content">
			<p-scrollPanel [style]="{ width: '100%', height: '330px' }">
				<!-- <div class="copy-paste-icons">
					<p-button icon="pi pi-file" styleClass="p-button-text"></p-button>
					<p-button icon="pi pi-pencil" styleClass="p-button-text"></p-button>
				</div> -->
				<div class="content">
					<div class="flex flex-column gap-2">
						<i class="pi pi-megaphone text-primary pb-2" style="font-size: 1.2rem"></i>
						<span class="font-bold">Recent News</span>
						<span *ngIf="currentM1Prep.companyAnalysis">{{
							currentM1Prep.companyAnalysis.recent_news
						}}</span>
						<div *ngIf="loadingA">
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
						</div>
						<ul *ngIf="currentM1Prep.companyAnalysis">
							<ng-template ngFor let-link [ngForOf]="currentM1Prep.companyAnalysis.links">
								<li class="pt-1">
									<p>
										<a target="_blank" [href]="link">{{ link }}</a>
									</p>
								</li>
							</ng-template>
						</ul>
					</div>
				</div>
			</p-scrollPanel>
		</div>
	</div>

	<div class="col-6 pt-0" *ngIf="currentM1Prep.hasCompanyAnalysis || loadingA">
		<div class="box-simulation-item-content">
			<p-scrollPanel [style]="{ width: '100%', height: '330px' }">
				<!-- <div class="copy-paste-icons">
					<p-button icon="pi pi-file" styleClass="p-button-text"></p-button>
					<p-button icon="pi pi-pencil" styleClass="p-button-text"></p-button>
				</div> -->
				<div class="content">
					<div class="flex flex-column gap-2">
						<i class="pi pi-dollar text-primary pb-2" style="font-size: 1.2rem"></i>
						<span class="font-bold">Earnings Call</span>
						<span *ngIf="currentM1Prep.companyAnalysis">{{
							currentM1Prep.companyAnalysis.earnings_call
						}}</span>
						<div *ngIf="loadingA">
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
							<p class="pt-2">
								<p-skeleton></p-skeleton>
							</p>
						</div>
					</div>
				</div>
			</p-scrollPanel>
		</div>
	</div>

	<div class="col-12" *ngIf="currentM1Prep.hasCompanyAnalysis || loadingA">
		<div class="grid">
			<div class="col-4">
				<div
					class="box-simulation-item-content d-flex flex-column justify-content-between"
					style="background: linear-gradient(to bottom, #fdefe3 0%, #ffffff 100%)"
				>
					<i class="pi pi-eye text-primary pb-2" style="font-size: 1.2rem"></i>
					<div *ngIf="loadingA">
						<p class="pt-2"><p-skeleton></p-skeleton></p>
						<p class="pt-2"><p-skeleton></p-skeleton></p>
						<p class="pt-2"><p-skeleton></p-skeleton></p>
						<p class="pt-2"><p-skeleton></p-skeleton></p>
						<p class="pt-2"><p-skeleton></p-skeleton></p>
					</div>
					<div *ngIf="!loadingA">
						<span class="font-bold">Focus</span>
						<p class="pt-2">Edge-to-cloud platform-as-a-service solutions and digital transformation</p>
					</div>
				</div>
			</div>
			<div class="col-4">
				<div
					class="box-simulation-item-content d-flex flex-column justify-content-between"
					style="background: linear-gradient(to bottom, #f9f5ff 0%, #ffffff 100%)"
				>
					<i class="pi pi-chart-scatter text-primary pb-2" style="font-size: 1.2rem"></i>
					<div *ngIf="loadingA">
						<p class="pt-2"><p-skeleton></p-skeleton></p>
						<p class="pt-2"><p-skeleton></p-skeleton></p>
						<p class="pt-2"><p-skeleton></p-skeleton></p>
						<p class="pt-2"><p-skeleton></p-skeleton></p>
						<p class="pt-2"><p-skeleton></p-skeleton></p>
					</div>
					<div *ngIf="!loadingA">
						<span class="font-bold">2023 Highlights</span>
						<p class="pt-2">IT consulting and support services.</p>
					</div>
				</div>
			</div>
			<div class="col-4">
				<div
					class="box-simulation-item-content d-flex flex-column justify-content-between"
					style="background: linear-gradient(to bottom, #e7f5f4 0%, #ffffff 100%)"
				>
					<i class="pi pi-chart-line text-primary pb-2" style="font-size: 1.2rem"></i>
					<div *ngIf="loadingA">
						<p class="pt-2"><p-skeleton></p-skeleton></p>
						<p class="pt-2"><p-skeleton></p-skeleton></p>
						<p class="pt-2"><p-skeleton></p-skeleton></p>
						<p class="pt-2"><p-skeleton></p-skeleton></p>
						<p class="pt-2"><p-skeleton></p-skeleton></p>
					</div>
					<div *ngIf="!loadingA">
						<span class="font-bold">Key Growth Areas</span>
						<p class="pt-2">Intelligent Edge, High Performance Computing & AI.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		class="col-6 d-flex justify-content-center align-items-center"
		*ngIf="!loadingB && currentM1Prep._hasData && !currentM1Prep.hasProducts"
	>
		<p class="text-lg m-0 font-bold text-center">No products found.</p>
	</div>

	<div class="col-6" *ngIf="currentM1Prep.hasProducts || loadingB">
		<span class="text-lg m-0 font-bold text-primary">Top </span>
		<span class="text-lg m-0 font-bold">Products Offering</span>

		<div class="pt-2 h-full">
			<div class="h-full" *ngIf="loadingB">
				<div class="flex h-full">
					<div class="col-4 pl-0">
						<div class="box-simulation-item h-full">
							<ul class="product-tabs">
								<li *ngFor="let _ of [1, 2, 3, 4, 5]" class="product-tab">
									<p><p-skeleton></p-skeleton></p>
								</li>
							</ul>
						</div>
					</div>

					<div class="col-8 pr-0" *ngIf="loadingB">
						<div class="box-simulation-item h-full">
							<div class="pb-2">
								<p-skeleton shape="circle" styleClass="mr-2" width="2rem" height="2rem"></p-skeleton>
							</div>
							<p-skeleton width="80%" height="1.5rem"></p-skeleton>
							<p-skeleton width="100%" height="2rem" styleClass="my-2"></p-skeleton>

							<div class="flex">
								<div class="col-6">
									<div class="pb-1">
										<p-skeleton shape="circle" width="1.5rem" height="1.5rem"></p-skeleton>
									</div>
									<p-skeleton width="50%" height="1.2rem" styleClass="my-1"></p-skeleton>
									<ul>
										<li *ngFor="let _ of [1, 2, 3]">
											<p-skeleton width="100%" height="1rem"></p-skeleton>
										</li>
									</ul>
								</div>

								<div class="col-6">
									<div class="pb-1">
										<p-skeleton shape="circle" width="1.5rem" height="1.5rem"></p-skeleton>
									</div>
									<p-skeleton width="50%" height="1.2rem" styleClass="my-1"></p-skeleton>
									<ul>
										<li *ngFor="let _ of [1, 2, 3]">
											<p-skeleton width="100%" height="1rem"></p-skeleton>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="h-full" *ngIf="!loadingB && currentM1Prep.products?.products?.length">
				<div class="flex h-full">
					<div class="col-4 pl-0">
						<div class="box-simulation-item h-full">
							<ul class="product-tabs">
								<li
									*ngFor="let product of currentM1Prep.products?.products; let i = index"
									[class.active]="selectedProductIndex === i"
									(click)="selectedProductIndex = i"
									class="product-tab"
								>
									{{ product.name }}
								</li>
							</ul>
						</div>
					</div>

					<div class="col-8 pr-0" *ngIf="currentM1Prep.products?.products?.[selectedProductIndex]">
						<div class="box-simulation-item h-full">
							<div *ngIf="currentM1Prep.products?.products?.[selectedProductIndex] as selectedProduct">
								<div class="pb-2">
									<i class="pi pi-box text-primary py-2" style="font-size: 1.2rem"></i>
								</div>
								<span class="font-bold text-blue-800">{{ selectedProduct.name }}</span>
								<p>{{ selectedProduct.description }}</p>

								<div class="flex">
									<div class="col-6">
										<div class="pb-1">
											<i class="pi pi-share-alt text-gray-400" style="font-size: 1.2rem"></i>
										</div>
										<span class="font-bold text-blue-800 pt-1">Channels:</span>
										<ul>
											<li *ngFor="let channel of selectedProduct.channels">
												{{ channel }}
											</li>
										</ul>
									</div>

									<div class="col-6">
										<div class="pb-1">
											<i class="pi pi-users text-gray-400" style="font-size: 1.2rem"></i>
										</div>
										<span class="font-bold text-blue-800 pt-1">Segments:</span>
										<ul>
											<li *ngFor="let segment of selectedProduct.segments">
												{{ segment }}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		class="col-6 d-flex justify-content-center align-items-center"
		*ngIf="!loadingB && currentM1Prep._hasData && !currentM1Prep.hasServices"
	>
		<p class="text-lg m-0 font-bold text-center">No services found.</p>
	</div>

	<div class="col-6" *ngIf="currentM1Prep.hasServices || loadingB">
		<span class="text-lg m-0 font-bold text-primary">Top </span>
		<span class="text-lg m-0 font-bold">Services</span>

		<div class="pt-2 h-full">
			<div class="h-full" *ngIf="loadingB">
				<div class="flex h-full">
					<div class="col-4 pl-0">
						<div class="box-simulation-item h-full">
							<ul class="product-tabs">
								<li *ngFor="let _ of [1, 2, 3, 4, 5]" class="product-tab">
									<p><p-skeleton></p-skeleton></p>
								</li>
							</ul>
						</div>
					</div>

					<div class="col-8 pr-0" *ngIf="loadingB">
						<div class="box-simulation-item h-full">
							<div class="pb-2">
								<p-skeleton shape="circle" styleClass="mr-2" width="2rem" height="2rem"></p-skeleton>
							</div>
							<p-skeleton width="80%" height="1.5rem"></p-skeleton>
							<p-skeleton width="100%" height="2rem" styleClass="my-2"></p-skeleton>

							<div class="flex">
								<div class="col-6">
									<div class="pb-1">
										<p-skeleton shape="circle" width="1.5rem" height="1.5rem"></p-skeleton>
									</div>
									<p-skeleton width="50%" height="1.2rem" styleClass="my-1"></p-skeleton>
									<ul>
										<li *ngFor="let _ of [1, 2, 3]">
											<p-skeleton width="100%" height="1rem"></p-skeleton>
										</li>
									</ul>
								</div>

								<div class="col-6">
									<div class="pb-1">
										<p-skeleton shape="circle" width="1.5rem" height="1.5rem"></p-skeleton>
									</div>
									<p-skeleton width="50%" height="1.2rem" styleClass="my-1"></p-skeleton>
									<ul>
										<li *ngFor="let _ of [1, 2, 3]">
											<p-skeleton width="100%" height="1rem"></p-skeleton>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="h-full" *ngIf="!loadingB && currentM1Prep.products?.services?.length">
				<div class="flex h-full">
					<div class="col-4 pl-0">
						<div class="box-simulation-item h-full">
							<ul class="product-tabs">
								<li
									*ngFor="let product of currentM1Prep.products?.services; let i = index"
									[class.active]="selectedServiceIndex === i"
									(click)="selectedServiceIndex = i"
									class="product-tab"
								>
									{{ product.name }}
								</li>
							</ul>
						</div>
					</div>

					<div class="col-8 pr-0" *ngIf="currentM1Prep.products?.services?.[selectedServiceIndex]">
						<div class="box-simulation-item h-full">
							<div *ngIf="currentM1Prep.products?.services?.[selectedServiceIndex] as selectedService">
								<div class="pb-2">
									<i class="pi pi-box text-primary py-2" style="font-size: 1.2rem"></i>
								</div>
								<span class="font-bold">{{ selectedService.name }}</span>
								<p>{{ selectedService.description }}</p>

								<div class="flex">
									<div class="col-6">
										<div class="pb-1">
											<i class="pi pi-share-alt text-gray-400" style="font-size: 1.2rem"></i>
										</div>
										<span class="font-bold text-blue-800 pt-1">Channels:</span>
										<ul>
											<li *ngFor="let channel of selectedService.channels">
												{{ channel }}
											</li>
										</ul>
									</div>

									<div class="col-6">
										<div class="pb-1">
											<i class="pi pi-users text-gray-400" style="font-size: 1.2rem"></i>
										</div>
										<span class="font-bold text-blue-800 pt-1">Segments:</span>
										<ul>
											<li *ngFor="let segment of selectedService.segments">
												{{ segment }}
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="col-12" *ngIf="currentM1Prep.hasCompetitiveLandscape || loadingC">
		<span class="text-lg m-0 font-bold">Competitive Landscape</span>
	</div>
	<div class="col-12 ml-3" *ngIf="currentM1Prep.hasCompetitiveLandscape || loadingC">
		<div class="grid w-full pt-3 box-simulation-item-content">
			<div class="col-4">
				<i class="pi pi-briefcase text-primary pb-2" style="font-size: 1.2rem"></i>
				<p-scrollPanel [style]="{ width: '100%', height: '330px' }">
					<!-- <div class="copy-paste-icons">
						<p-button icon="pi pi-file" styleClass="p-button-text"></p-button>
						<p-button icon="pi pi-pencil" styleClass="p-button-text"></p-button>
					</div> -->
					<div class="content">
						<div class="flex flex-column gap-2">
							<span class="font-bold">Summary</span>
							<div *ngIf="loadingC">
								<p class="pt-2">
									<p-skeleton></p-skeleton>
								</p>
								<p class="pt-2">
									<p-skeleton></p-skeleton>
								</p>
								<p class="pt-2">
									<p-skeleton></p-skeleton>
								</p>
								<p class="pt-2">
									<p-skeleton></p-skeleton>
								</p>
								<p class="pt-2">
									<p-skeleton></p-skeleton>
								</p>
								<p class="pt-2">
									<p-skeleton></p-skeleton>
								</p>
								<p class="pt-2">
									<p-skeleton></p-skeleton>
								</p>
								<p class="pt-2">
									<p-skeleton></p-skeleton>
								</p>
							</div>
							<span *ngIf="currentM1Prep.competitiveLandscape">{{
								currentM1Prep.competitiveLandscape.summary
							}}</span>
						</div>
					</div>
				</p-scrollPanel>
			</div>
			<div class="col-4">
				<i class="pi pi-crown text-primary pb-2" style="font-size: 1.2rem"></i>
				<p-scrollPanel class="pt-1" [style]="{ width: '100%', height: '330px' }">
					<!-- <div class="copy-paste-icons">
						<p-button icon="pi pi-file" styleClass="p-button-text"></p-button>
						<p-button icon="pi pi-pencil" styleClass="p-button-text"></p-button>
					</div> -->
					<div class="content">
						<div class="flex flex-column gap-2">
							<span class="font-bold">Key Competitors</span>
							<ul *ngIf="loadingC">
								<li>
									<p>
										<p-skeleton></p-skeleton>
									</p>
								</li>
								<li>
									<p>
										<p-skeleton></p-skeleton>
									</p>
								</li>
								<li>
									<p>
										<p-skeleton></p-skeleton>
									</p>
								</li>
								<li>
									<p>
										<p-skeleton></p-skeleton>
									</p>
								</li>
								<li>
									<p>
										<p-skeleton></p-skeleton>
									</p>
								</li>
								<li>
									<p>
										<p-skeleton></p-skeleton>
									</p>
								</li>
								<li>
									<p>
										<p-skeleton></p-skeleton>
									</p>
								</li>
							</ul>
							<ul *ngIf="currentM1Prep.competitiveLandscape">
								<ng-template
									ngFor
									let-competitors
									[ngForOf]="currentM1Prep.competitiveLandscape.key_competitors"
								>
									<li>
										{{ competitors }}
									</li>
								</ng-template>
							</ul>
						</div>
					</div>
				</p-scrollPanel>
			</div>
			<div class="col-4">
				<i class="pi pi-link text-primary pb-2" style="font-size: 1.2rem"></i>
				<p-scrollPanel [style]="{ width: '100%', height: '330px' }">
					<!-- <div class="copy-paste-icons">
						<p-button icon="pi pi-file" styleClass="p-button-text"></p-button>
						<p-button icon="pi pi-pencil" styleClass="p-button-text"></p-button>
					</div> -->
					<div class="content">
						<div class="flex flex-column gap-2">
							<div class="flex flex-column gap-2">
								<span class="font-bold">Links</span>
								<ul *ngIf="loadingC">
									<li>
										<p>
											<p-skeleton></p-skeleton>
										</p>
									</li>
									<li>
										<p>
											<p-skeleton></p-skeleton>
										</p>
									</li>
									<li>
										<p>
											<p-skeleton></p-skeleton>
										</p>
									</li>
									<li>
										<p>
											<p-skeleton></p-skeleton>
										</p>
									</li>
									<li>
										<p>
											<p-skeleton></p-skeleton>
										</p>
									</li>
									<li>
										<p>
											<p-skeleton></p-skeleton>
										</p>
									</li>
									<li>
										<p>
											<p-skeleton></p-skeleton>
										</p>
									</li>
								</ul>
								<ul *ngIf="currentM1Prep.competitiveLandscape">
									<ng-template ngFor let-link [ngForOf]="currentM1Prep.competitiveLandscape.links">
										<li class="pt-1">
											<p>
												<a target="_blank" [href]="link">{{ link }}</a>
											</p>
										</li>
									</ng-template>
								</ul>
							</div>
						</div>
					</div>
				</p-scrollPanel>
			</div>
		</div>
	</div>

	<div class="col-12 ml-3" *ngIf="currentM1Prep.hasServices || loadingD">
		<div class="grid w-full pt-3 box-simulation-item-content">
			<span class="text-lg m-0 font-bold pb-3">Salesforce</span>
			<div class="col-12 pb-0" *ngIf="!currentM1Prep.hasSalesforce && loadingD">
				No Salesforce data available
			</div>
			<div class="chart-container" [ngStyle]="{ display: !currentM1Prep.hasSalesforce ? 'none' : 'block' }">
				<canvas
					#salesforceChart
					id="salesforceChart"
				></canvas>
			</div>
			<div class="col-12 pb-0" *ngIf="currentM1Prep.hasSalesforce">
				<label for="contactFilter">Opportunity Owner: </label>
				<select [(ngModel)]="selectedContact" (change)="applyFilter()">
					<option value="">All Contacts</option>
					<option *ngFor="let contact of contacts.slice().sort()" [value]="contact">
						{{ contact }}
					</option>
				</select>
			</div>
			<div class="col-12 pb-0" *ngIf="currentM1Prep.hasSalesforce">
				<label for="yearFilter">Year:</label>
				<select [(ngModel)]="selectedYear" (change)="applyFilter()">
					<option value="">All Years</option>
					<option *ngFor="let year of availableYears" [value]="year">
						{{ year }}
					</option>
				</select>
			</div>
			<div class="col-12">
				<div [ngStyle]="{ display: !currentM1Prep.hasSalesforce ? 'none' : 'block' }">
					<table #tableUseCases class="display w-full" style="width: 100%"></table>
				</div>
			</div>
		</div>
	</div>

	<div class="col-12 ml-3" *ngIf="currentM1Prep.hasConversation || loadingE">
		<div class="grid w-full pt-3 box-simulation-item-content">
			<div class="col-12">
				<span class="text-lg m-0 font-bold pb-3">Onboarding Talk Track</span>
			</div>

			<div class="col-12 bg-blue-50 rounded-md">
				<span class="text-lg m-0 font-bold text-blue-800"
					>Powering the Future: Unleashing Innovations and Strategic Edge in a Competitive Landscape</span
				>
			</div>
			<div class="col-12">
				<div class="font-bold">Introduction</div>
				<div *ngIf="!loadingE">
					<p class="pt-3" *ngIf="currentM1Prep.conversation">
						{{ currentM1Prep.conversation.introduction }}
					</p>
				</div>
				<div *ngIf="loadingE">
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
				</div>
			</div>

			<div class="col-12">
				<div class="font-bold">1. Financial Highlights:</div>
				<div *ngIf="!loadingE">
					<p class="pt-3" *ngIf="currentM1Prep.conversation">
						{{ currentM1Prep.conversation.financial_highlights }}
					</p>
				</div>
				<div *ngIf="loadingE">
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
				</div>
			</div>

			<div class="col-12">
				<div class="font-bold">2. Strategic Priorities:</div>
				<div *ngIf="!loadingE">
					<p class="pt-3" *ngIf="currentM1Prep.conversation">
						{{ currentM1Prep.conversation.strategic_priorities }}
					</p>
				</div>
				<div *ngIf="loadingE">
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
				</div>
			</div>

			<div class="col-12">
				<div class="font-bold">3. Key Initiatives and Focus Areas:</div>
				<div *ngIf="!loadingE">
					<p class="pt-3" *ngIf="currentM1Prep.conversation">
						{{ currentM1Prep.conversation.key_initiatives_and_focus_areas }}
					</p>
				</div>
				<div *ngIf="loadingE">
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
				</div>
			</div>

			<div class="col-12">
				<div class="font-bold">4. Product Portfolio Overview:</div>
				<div *ngIf="!loadingE">
					<p class="pt-3" *ngIf="currentM1Prep.conversation">
						{{ currentM1Prep.conversation.product_portfolio_overview }}
					</p>
				</div>
				<div *ngIf="loadingE">
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
				</div>
			</div>

			<div class="col-12">
				<div class="font-bold">5. Market Positioning and Competitors:</div>
				<div *ngIf="!loadingE">
					<p class="pt-3" *ngIf="currentM1Prep.conversation">
						{{ currentM1Prep.conversation.market_positioning_and_competitors }}
					</p>
				</div>
				<div *ngIf="loadingE">
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
				</div>
			</div>

			<div class="col-12">
				<div class="font-bold">6. Sales Performance and Growth Opportunities:</div>
				<div *ngIf="!loadingE">
					<p class="pt-3" *ngIf="currentM1Prep.conversation">
						{{ currentM1Prep.conversation.sales_performance_and_growth_opportunities }}
					</p>
				</div>
				<div *ngIf="loadingE">
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
				</div>
			</div>

			<div class="col-12">
				<div class="font-bold">7. KPIs to Monitor:</div>
				<div *ngIf="!loadingE">
					<p class="pt-3" *ngIf="currentM1Prep.conversation">
						{{ currentM1Prep.conversation.kpis_to_monitor }}
					</p>
				</div>
				<div *ngIf="loadingE">
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
				</div>
			</div>

			<div class="col-12">
				<div class="font-bold">8. Industry Trends to Watch:</div>
				<div *ngIf="!loadingE">
					<p class="pt-3" *ngIf="currentM1Prep.conversation">
						{{ currentM1Prep.conversation.industry_trends_to_watch }}
					</p>
				</div>
				<div *ngIf="loadingE">
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
					<p class="pt-2"><p-skeleton></p-skeleton></p>
				</div>
			</div>
		</div>
	</div>
</div>

<p-overlayPanel #op>
	<div *ngIf="currentM1Prep" class="flex flex-column gap-3 w-25rem">
		<div>
			<div *ngIf="loadingA">
				<p>
					<p-skeleton></p-skeleton>
				</p>
				<p>
					<p-skeleton></p-skeleton>
				</p>
				<p>
					<p-skeleton></p-skeleton>
				</p>
				<p>
					<p-skeleton></p-skeleton>
				</p>
			</div>

			<span class="block mb-2" *ngIf="currentM1Prep.hasCompanyAnalysis && !loadingA">{{
				currentM1Prep.companyAnalysis.summary
			}}</span>
		</div>
	</div>
</p-overlayPanel>
