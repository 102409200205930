import { Component, Input, OnInit } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { AppFilterService } from './app.filter.service';

@Component({
	selector: 'app-filter',
	templateUrl: './app.filter.component.html',
	styleUrls: ['./app.filter.component.scss'],
})
export class AppFilterComponent implements OnInit {
	@Input() styleClass: string;

	constructor(public layoutService: LayoutService, public appFilterService: AppFilterService) {}

	ngOnInit() {
		window.addEventListener('resize', () => this.sizeSide());

		this.sizeSide();
	}

	sizeSide() {
		const container: HTMLDivElement | null = document.querySelector('app-filter');
		if (container) {
			const containerWidth = container.offsetWidth;

			if (containerWidth !== this.appFilterService.containerWidth) {
				this.appFilterService.containerWidth = containerWidth;
				if (containerWidth < 990 && this.layoutService.isDesktop() && this.appFilterService.openMenu) {
					this.layoutService.onMenuClose();
					this.appFilterService.openMenu = true;
				}
				if (containerWidth < 880 && this.appFilterService.showSide) {
					this.appFilterService.showSide = false;
					this.appFilterService.showSideExtend = false;
				}

				/*if (containerWidth >= 990 && this.layoutService.isDesktop() && !this.openMenu && this.showSide) {
					this.layoutService.onMenuOpen();
					this.openMenu = false;
				}*/
				if (containerWidth >= 880 && !this.appFilterService.showSideExtend) {
					this.appFilterService.showSide = true;
					this.appFilterService.showSideExtend = true;
				}
			}
		}
	}
}
