import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

/**
 * Class representing an API Interceptor.
 *
 * @@Injectable()
 * @class
 * @implements {HttpInterceptor}
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
	/**
	 * Intercepts HTTP requests and adds necessary headers before forwarding them to the next handler.
	 *
	 * @param {HttpRequest<any>} req - The HTTP request to be intercepted.
	 * @param {HttpHandler} next - The next handler in the HTTP request chain.
	 * @returns {Observable<HttpEvent<any>>} - An observable of the HTTP response events.
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let requestUrl = req.url;
		const token = localStorage.getItem('auth-token') || '';
		let setHeaders: { [key: string]: string } = {};
		if (requestUrl.indexOf('@api') >= 0) {
			requestUrl = requestUrl.replace('@api', `${environment.url}/api/v1`);
			setHeaders = { Authorization: 'Bearer ' + token, 'Cache-Control': 'no-cache' };
		} else if (requestUrl.indexOf('graphql') >= 0) {
			setHeaders = { Authorization: 'JWT ' + token };
		}
		const apiReq = req.clone({
			url: requestUrl,
			setHeaders,
		});
		return next.handle(apiReq);
	}
}
