import { Component, OnInit, Input } from '@angular/core';
import { Simulation, UseCaseSimulation } from '../../../api/simulation.api';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SimulationService } from '../simulation.service';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { UseCaseService } from '../../../service/use-case.service';
import { AuthService } from '../../../service/auth.service';
import PptxGenJS from 'pptxgenjs';
import { Organization } from 'src/app/rtp/api/common';

@Component({
	selector: 'app-use-case-simulation-item',
	templateUrl: './use-case-simulation-item.component.html',
	styleUrls: ['./use-case-simulation-item.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class UseCaseSimulationItemComponent implements OnInit {
	@Input() useCase?: UseCaseSimulation;
	@Input() useCasesSaved: boolean;

	constructor(
		private http: HttpClient,
		public simulationService: SimulationService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public useCaseService: UseCaseService,
		public authService: AuthService,
	) {}

	valuesDriversScores: { title: string; label: string; className: string }[] = [];

	generatePresentation(useCase: UseCaseSimulation) {
		let shorts = this.useCase?.context.aiEnablers.map((value: string) => {
			const e = this.simulationService.aiEnablers.find((d) => d.id === value);
			if (e) {
				return e.short;
			} else {
				return value;
			}
		}) as string[];

		const pptx = new PptxGenJS();

		const slideWidth = pptx.presLayout.width;
		const slideHeight = pptx.presLayout.height;

		const currentYear = new Date().getFullYear().toString();

		// Slide 1
		let slide1 = pptx.addSlide();
		slide1.addImage({
			path: 'assets/images/usecase-slide/Slide1.png',
			x: 0,
			y: 0,
			w: slideWidth,
			h: slideHeight,
		});

		slide1.addText(new Date().toLocaleDateString(), {
			x: 4.4,
			y: 4.5,
			fontSize: 12,
			color: '000000',
			align: 'left',
		});

		// Slide 2
		let slide2 = pptx.addSlide();
		slide2.addImage({
			path: 'assets/images/usecase-slide/Slide2.png',
			x: 0,
			y: 0,
			w: slideWidth,
			h: slideHeight,
		});

		slide2.addText(
			'Process: ' + this.currentUseCase?.data?.process ? this.currentUseCase?.data.process : ('---' as any),
			{
				x: 0.5,
				y: 3.5,
				fontSize: 16,
				color: 'FFFFFF',
				align: 'left',
			},
		);

		slide2.addText(
			'Subprocess: ' + this.currentUseCase?.data?.subprocess
				? this.currentUseCase?.data.subprocess
				: ('---' as any),
			{
				x: 0.5,
				y: 4,
				fontSize: 16,
				color: 'FFFFFF',
				align: 'left',
			},
		);

		// Slide 3
		let slide3 = pptx.addSlide();
		slide3.addImage({
			path: 'assets/images/usecase-slide/Slide3.png',
			x: 0,
			y: 0,
			w: slideWidth,
			h: slideHeight,
		});

		slide3.addText(this.currentUseCase?.name ? this.currentUseCase.name : '---', {
			x: 0.25,
			y: 0.65,
			fontSize: 12,
			color: '000000',
			align: 'left',
		});

		slide3.addText(this.currentUseCase?.description ? this.currentUseCase.description : '---', {
			x: 0.25,
			y: 1.1,
			w: 5.5,
			fontSize: 9,
			color: '000000',
			align: 'left',
		});

		slide3.addImage({
			path: shorts.includes('AA')
				? 'assets/images/usecase-slide/AA-selected.png'
				: 'assets/images/usecase-slide/AA.png',
			x: 5.95,
			y: 1.1,
			w: 24 / 96,
			h: 24 / 96,
		});

		slide3.addText('AA', {
			x: 5.85,
			y: 1.45,
			w: 0.5,
			fontSize: 9,
			color: shorts.includes('AA') ? '000000' : 'C8D3EB',
			align: 'center',
		});

		slide3.addImage({
			path: shorts.includes('CA')
				? 'assets/images/usecase-slide/CA-selected.png'
				: 'assets/images/usecase-slide/CA.png',
			x: 6.6,
			y: 1.1,
			w: 24 / 96,
			h: 24 / 96,
		});

		slide3.addText('CA', {
			x: 6.5,
			y: 1.45,
			w: 0.5,
			fontSize: 9,
			color: shorts.includes('CA') ? '000000' : 'C8D3EB',
			align: 'center',
		});

		slide3.addImage({
			path: shorts.includes('IDC')
				? 'assets/images/usecase-slide/IDC-selected.png'
				: 'assets/images/usecase-slide/IDC.png',
			x: 7.25,
			y: 1.1,
			w: 24 / 96,
			h: 24 / 96,
		});

		slide3.addText('IDC', {
			x: 7.15,
			y: 1.45,
			w: 0.5,
			fontSize: 9,
			color: shorts.includes('IDC') ? '000000' : 'C8D3EB',
			align: 'center',
		});

		slide3.addImage({
			path: shorts.includes('LLM')
				? 'assets/images/usecase-slide/LLM-selected.png'
				: 'assets/images/usecase-slide/LLM.png',
			x: 7.9,
			y: 1.1,
			w: 24 / 96,
			h: 24 / 96,
		});

		slide3.addText('LLM', {
			x: 7.8,
			y: 1.45,
			w: 0.5,
			fontSize: 9,
			color: shorts.includes('LLM') ? '000000' : 'C8D3EB',
			align: 'center',
		});

		slide3.addImage({
			path: shorts.includes('PA')
				? 'assets/images/usecase-slide/PA-selected.png'
				: 'assets/images/usecase-slide/PA.png',
			x: 8.55,
			y: 1.1,
			w: 24 / 96,
			h: 24 / 96,
		});

		slide3.addText('PA', {
			x: 8.45,
			y: 1.45,
			w: 0.5,
			fontSize: 9,
			color: shorts.includes('PA') ? '000000' : 'C8D3EB',
			align: 'center',
		});

		slide3.addImage({
			path: shorts.includes('WA')
				? 'assets/images/usecase-slide/WA-selected.png'
				: 'assets/images/usecase-slide/WA.png',
			x: 9.2,
			y: 1.1,
			w: 24 / 96,
			h: 24 / 96,
		});

		slide3.addText('WA', {
			x: 9.1,
			y: 1.45,
			w: 0.5,
			fontSize: 9,
			color: shorts.includes('WA') ? '000000' : 'C8D3EB',
			align: 'center',
		});

		slide3.addText(
			this.currentUseCase?.data?.data_requirements ? this.currentUseCase.data?.data_requirements : '---',
			{
				x: 2.1,
				y: 2.13,
				w: 3.5,
				fontSize: 9,
				color: '000000',
				align: 'left',
			},
		);

		slide3.addText(
			this.currentUseCase?.data?.potential_challenges ? this.currentUseCase.data?.potential_challenges : '---',
			{
				x: 5.8,
				y: 2.13,
				w: 3.8,
				fontSize: 9,
				color: '000000',
				align: 'left',
			},
		);

		slide3.addText(this.currentUseCase?.data?.llm_explanation ? this.currentUseCase.data?.llm_explanation : '---', {
			x: 0.35,
			y: 3.4,
			w: 9.3,
			fontSize: 7.5,
			color: '000000',
			align: 'left',
		});

		slide3.addText(
			this.currentUseCase?.context?.['impact']?.length ? this.currentUseCase.context['impact'].join(', ') : '---',
			{
				x: 0.5,
				y: 2.1,
				w: 2.5,
				fontSize: 14,
				color: '154EF9',
				align: 'left',
			},
		);

		slide3.addText(this.valuesDriversScoresLabel(this.currentUseCase?.data?.valuesDriversScores?.revenue_growth), {
			x: 0.35,
			y: 4.8,
			w: 1.8,
			fontSize: 8,
			color: this.getColorFromClass(
				this.valuesDriversScoresClassName(this.currentUseCase?.data?.valuesDriversScores?.revenue_growth),
			),
			align: 'center',
			highlight: this.getBackgroundColorFromClass(
				this.valuesDriversScoresClassName(this.currentUseCase?.data?.valuesDriversScores?.revenue_growth),
			),
		});

		slide3.addText(
			this.valuesDriversScoresLabel(this.currentUseCase?.data?.valuesDriversScores?.customer_experience),
			{
				x: 2.2,
				y: 4.8,
				w: 1.8,
				fontSize: 8,
				color: this.getColorFromClass(
					this.valuesDriversScoresClassName(
						this.currentUseCase?.data?.valuesDriversScores?.customer_experience,
					),
				),
				align: 'center',
				highlight: this.getBackgroundColorFromClass(
					this.valuesDriversScoresClassName(
						this.currentUseCase?.data?.valuesDriversScores?.customer_experience,
					),
				),
			},
		);

		slide3.addText(
			this.valuesDriversScoresLabel(this.currentUseCase?.data?.valuesDriversScores?.process_productivity),
			{
				x: 4.1,
				y: 4.8,
				w: 1.8,
				fontSize: 8,
				color: this.getColorFromClass(
					this.valuesDriversScoresClassName(
						this.currentUseCase?.data?.valuesDriversScores?.process_productivity,
					),
				),
				align: 'center',
				highlight: this.getBackgroundColorFromClass(
					this.valuesDriversScoresClassName(
						this.currentUseCase?.data?.valuesDriversScores?.process_productivity,
					),
				),
			},
		);

		slide3.addText(
			this.valuesDriversScoresLabel(this.currentUseCase?.data?.valuesDriversScores?.employee_productivity),
			{
				x: 6,
				y: 4.8,
				w: 1.8,
				fontSize: 8,
				color: this.getColorFromClass(
					this.valuesDriversScoresClassName(
						this.currentUseCase?.data?.valuesDriversScores?.employee_productivity,
					),
				),
				align: 'center',
				highlight: this.getBackgroundColorFromClass(
					this.valuesDriversScoresClassName(
						this.currentUseCase?.data?.valuesDriversScores?.employee_productivity,
					),
				),
			},
		);

		slide3.addText(this.valuesDriversScoresLabel(this.currentUseCase?.data?.valuesDriversScores?.cost_savings), {
			x: 7.9,
			y: 4.8,
			w: 1.8,
			fontSize: 8,
			color: this.getColorFromClass(
				this.valuesDriversScoresClassName(this.currentUseCase?.data?.valuesDriversScores?.cost_savings),
			),
			align: 'center',
			highlight: this.getBackgroundColorFromClass(
				this.valuesDriversScoresClassName(this.currentUseCase?.data?.valuesDriversScores?.cost_savings),
			),
		});

		slide3.addText(currentYear, {
			x: 4.35,
			y: 5.44,
			w: 0.5,
			fontSize: 5,
			color: '7A878F',
			align: 'left',
		});

		let slide4 = pptx.addSlide();
		slide4.addImage({
			path: 'assets/images/usecase-slide/Slide4.png',
			x: 0,
			y: 0,
			w: slideWidth,
			h: slideHeight,
		});

		slide4.addText(currentYear, {
			x: 4.35,
			y: 5.44,
			w: 0.5,
			fontSize: 5,
			color: '7A878F',
			align: 'left',
		});

		pptx.writeFile({ fileName: 'UseCaseSimulationPresentation.pptx' });
	}

	getColorFromClass(className: string): string {
		switch (className) {
			case 'background-low':
				return '#9f5252';
			case 'background-medium':
				return '#8d744e';
			case 'background-high':
				return '#579043';
			default:
				return '#000000';
		}
	}

	getBackgroundColorFromClass(className: string): string {
		switch (className) {
			case 'background-none':
				return '#f9fafc';
			case 'background-low':
				return '#fbeded';
			case 'background-medium':
				return '#fcf2d7';
			case 'background-high':
				return '#dbf3de';
			default:
				return '#FFFFFF';
		}
	}

	valuesDriversScoresLabel(value: number = 0) {
		let label = '';
		if (value === 0) {
			label = '---';
		} else if (value < 4) {
			label = 'Low';
		} else if (value >= 4 && value <= 7) {
			label = 'Medium';
		} else {
			label = 'High';
		}
		return label;
	}

	valuesDriversScoresClassName(value: number = 0) {
		let className = '';
		if (value === 0) {
			className = 'background-none';
		} else if (value < 4) {
			className = 'background-low';
		} else if (value >= 4 && value <= 7) {
			className = 'background-medium';
		} else {
			className = 'background-high';
		}
		return className;
	}

	aiEnablers: string[] = [];
	organization: Organization | undefined = undefined;
	embeddedAIData: {
		platform: string;
		vendors: { vendor: string; functionalities: { name: string; description_and_implementation: string }[] }[];
	}[] = [];

	async ngOnInit() {
		this.organization = await this.authService.getCurrentOrganization();

		if (this.useCase) {
			this.simulationService.getUseCaseSimulation(this.useCase.id as string).then((u) => {
				if (u) {
					this.currentUseCase = u;
				} else {
					this.currentUseCase = this.useCase;
				}
				if (this.currentUseCase) {
					this.aiEnablers = this.currentUseCase.context.aiEnablers.map((value: string) => {
						const e = this.simulationService.aiEnablers.find((d) => d.name === value);
						if (e) {
							return e.id;
						} else {
							return value;
						}
					}) as string[];
					this.valuesDriversScores = [
						{
							title: 'Revenue Growth',
							label: this.valuesDriversScoresLabel(
								this.currentUseCase.data?.valuesDriversScores?.revenue_growth,
							),
							className: this.valuesDriversScoresClassName(
								this.currentUseCase.data?.valuesDriversScores?.revenue_growth,
							),
						},
						{
							title: 'Customer Experience',
							label: this.valuesDriversScoresLabel(
								this.currentUseCase.data?.valuesDriversScores?.customer_experience,
							),
							className: this.valuesDriversScoresClassName(
								this.currentUseCase.data?.valuesDriversScores?.customer_experience,
							),
						},
						{
							title: 'Process Productivity',
							label: this.valuesDriversScoresLabel(
								this.currentUseCase.data?.valuesDriversScores?.process_productivity,
							),
							className: this.valuesDriversScoresClassName(
								this.currentUseCase.data?.valuesDriversScores?.process_productivity,
							),
						},
						{
							title: 'Employee Productivity',
							label: this.valuesDriversScoresLabel(
								this.currentUseCase.data?.valuesDriversScores?.employee_productivity,
							),
							className: this.valuesDriversScoresClassName(
								this.currentUseCase.data?.valuesDriversScores?.employee_productivity,
							),
						},
						{
							title: 'Cost Savings',
							label: this.valuesDriversScoresLabel(
								this.currentUseCase.data?.valuesDriversScores?.cost_savings,
							),
							className: this.valuesDriversScoresClassName(
								this.currentUseCase.data?.valuesDriversScores?.cost_savings,
							),
						},
					];

					this.simulationService.getSimulation(this.currentUseCase.simulationId).then((s) => {
						if (s) {
							this.simulation = s;
						}
					});

					if (this.organization && this.currentUseCase.data['embeddedAI'] && this.currentUseCase.data['embeddedAI'][this.organization.id as string]) {
						this.embeddedAIData =
							this.currentUseCase.data['embeddedAI'][this.organization.id as string] || [];
					}
				}
			});
		}
	}

	addToMyUseCase() {
		if (this.useCasesSaved) {
			this.confirmationService.confirm({
				header: 'Add to your use cases?',
				message: 'This will add the use case and an AI-generated diagram to your use cases.',
				icon: 'pi pi-sparkles',
				acceptLabel: 'With diagram',
				acceptIcon: 'pi pi-sitemap',
				accept: () => {
					this._addToMyUseCase(true);
				},
				rejectLabel: 'Without diagram',
				rejectIcon: 'pi pi-check',
				reject: (a: any) => {
					console.log(a);
					if (a === 1) {
						this._addToMyUseCase(false);
					}
				},
			});
		} else {
			this.messageService.add({
				severity: 'warn',
				summary: 'Simulation without saving',
				detail: 'The simulation must be saved before sending the UseCases',
			});
		}
	}

	addToMyUseCaseLoading: boolean = false;

	currentUseCase: UseCaseSimulation | undefined = undefined;
	simulation: Simulation | undefined = undefined;

	async _addToMyUseCase(diagram: boolean) {
		this.addToMyUseCaseLoading = true;
		if (this.currentUseCase) {
			const params: { [key: string]: string } = {};
			if (this.simulation?.taxonomyId && this.simulation?.taxonomyRel === '3') {
				params['level2Id'] = this.simulation.taxonomyId;
			}
			await this.useCaseService.setCurrentUseCase('', params).then(async () => {
				if (this.useCaseService.currentUseCase && this.currentUseCase) {
					this.useCaseService.currentUseCase.name = this.currentUseCase.name;
					this.useCaseService.currentUseCase.description = this.currentUseCase.description;
					this.useCaseService.currentUseCase.diagram = this.useCaseService._newDiagramEmpty(
						this.useCaseService.currentUseCase,
					);
					await this.authService.getCurrentOrganization().then((organization) => {
						if (organization && this.useCaseService.currentUseCase?.diagram) {
							this.useCaseService.currentUseCase.diagram.organization = organization;
							this.useCaseService.currentUseCase.diagram.organizationId = organization.id as string;
							this.useCaseService.currentDiagram = this.useCaseService.currentUseCase.diagram;
						}
					});
				}
			});
			if (diagram) {
				this.http
					.post<{ xml: string }>(environment.url + '/api/ai/diagram/', {
						description: JSON.stringify({
							name: this.currentUseCase.name,
							description: this.currentUseCase.description,
							keyFeatures: this.currentUseCase.keyFeatures,
							context: this.currentUseCase.context,
						}),
						format: 'json',
					})
					.subscribe({
						error: (err) => {
							console.log(err);
						},
						next: (res) => {
							if (res && res.xml) {
								this.useCaseService.saveCurrentUseCase().then(async (error) => {
									if (error) {
										this.messageSaveUseCase(error);
									} else {
										if (this.useCaseService.currentUseCase?.diagram) {
											this.useCaseService
												.saveFilesDiagram(
													this.useCaseService.currentUseCase.diagram,
													res.xml,
													'',
												)
												.then(async (error) => {
													if (error) {
														this.messageSaveUseCase(error);
													} else {
														if (this.useCaseService.currentUseCase?.diagram) {
															this.useCaseService
																.saveDiagram(this.useCaseService.currentUseCase.diagram)
																.then(async () => {
																	this.messageSaveUseCase();
																	if (
																		this.currentUseCase &&
																		this.useCaseService.currentUseCase
																	) {
																		this.currentUseCase.deployedId = this
																			.useCaseService.currentUseCase.id as string;
																		this.addToMyUseCaseLoading = false;
																		this.simulationService
																			.saveUseCase(this.currentUseCase)
																			.then(() => {
																				if (this.simulation) {
																					this.simulation.use_cases =
																						this.simulation.use_cases.map(
																							(u) => {
																								if (
																									this
																										.currentUseCase &&
																									u.id ===
																										this
																											.currentUseCase
																											.id
																								) {
																									return this
																										.currentUseCase;
																								} else {
																									return u;
																								}
																							},
																						);
																				}
																			});
																	}
																});
														}
													}
												});
										}
									}
								});
							} else {
								this.addToMyUseCaseLoading = false;
							}
						},
					});
			} else {
				this.useCaseService.saveCurrentUseCase().then(async (error) => {
					if (error) {
						this.messageSaveUseCase(error);
					} else {
						this.messageSaveUseCase();
						if (this.currentUseCase && this.useCaseService.currentUseCase) {
							this.currentUseCase.deployedId = this.useCaseService.currentUseCase.id as string;
							this.addToMyUseCaseLoading = false;
							this.simulationService.saveUseCase(this.currentUseCase).then(() => {
								if (this.simulation) {
									this.simulation.use_cases = this.simulation.use_cases.map((u) => {
										if (this.currentUseCase && u.id === this.currentUseCase.id) {
											return this.currentUseCase;
										} else {
											return u;
										}
									});
								}
							});
						}
					}
				});
			}
		}
	}

	messageSaveUseCase(error?: string) {
		if (error) {
			if (error === 'no-name') {
				this.messageService.add({
					severity: 'warn',
					summary: 'No name',
					detail: 'Provide a name for the new Use Case',
				});
			} else if (error === 'no-description') {
				this.messageService.add({
					severity: 'warn',
					summary: 'No description',
					detail: 'Provide a description for the new Use Case',
				});
			} else if (error === 'no-updated') {
				this.messageService.add({ severity: 'warn', summary: 'No changes', detail: 'Nothing to save' });
			} else {
				this.messageService.add({ severity: 'error', summary: 'Error', detail: error });
			}
		} else {
			this.messageService.add({
				severity: 'success',
				summary: 'Confirmed',
				detail: 'Use Case saved on My Use Cases',
			});
		}
	}

	async goToMyUseCase() {
		if (this.currentUseCase && this.currentUseCase.deployedId) {
			await this.useCaseService.goToUseCase(this.currentUseCase.deployedId, true);
		}
	}

	embeddedAILoading: boolean = false;

	async generateEmbeddedAI() {
		if (this.currentUseCase) {
			this.embeddedAILoading = true;
			this.embeddedAIData = [];

			if (!this.currentUseCase.data['embeddedAI'] || Array.isArray(this.currentUseCase.data['embeddedAI'])) {
				this.currentUseCase.data['embeddedAI'] = {};
			}

			if (this.simulationService.enterpriseContext === undefined) {
				await this.simulationService.getEnterpriseContext();
			}

			this.missingFields = [];

			if (!this.organization) {
				this.missingFields.push('Organization');
			}

			if (!this.simulationService?.enterpriseContext?.enterpriseContext?.description?.trim()) {
				this.missingFields.push('Description');
			}
	
			if (!this.simulationService?.enterpriseContext?.enterpriseContext?.industry?.length) {
				this.missingFields.push('Industry');
			}
	
			// if (!this.simulationService?.enterpriseContext?.enterpriseContext?.revenue) {
			// 	this.missingFields.push('Revenue');
			// }
	
			// if (!this.simulationService?.enterpriseContext?.enterpriseContext?.information?.length) {
			// 	this.missingFields.push('Information');
			// }
	
			// if (!this.simulationService?.enterpriseContext?.enterpriseContext?.employee) {
			// 	this.missingFields.push('Employee');
			// }
	
			if (!this.simulationService?.enterpriseContext?.enterpriseContext?.data_landscape?.length) {
				this.missingFields.push('Data Landscape');
			}
	
			if (this.missingFields.length > 0) {
				const detailMessage = `Please fill ${this.missingFields.join(', ')} field${this.missingFields.length > 1 ? 's' : ''} inside Enterprise Context.`;
				this.messageService.add({
					severity: 'error',
					summary: 'Validation Error - Enterprise Context',
					detail: detailMessage,
				});
				this.embeddedAILoading = false;
				this.embeddedAIData = [];
				return;
			}

			const input = {
				name: this.organization?.name || '',
				description: this.simulationService?.enterpriseContext?.enterpriseContext?.description || '',
				additionalNotes: 'Additional Notes',
				scope: ['1'],
				search: false,
				generate: true,
				enterpriseContext: {
					companyName: this.organization?.name || '',
					industry: this.simulationService.enterpriseContext?.enterpriseContext?.industry || [''],
					revenue: this.simulationService.enterpriseContext?.enterpriseContext?.revenue || '',
					information: this.simulationService.enterpriseContext?.enterpriseContext?.information || [],
					employee: this.simulationService.enterpriseContext?.enterpriseContext?.employee || '',
					data_landscape: this.simulationService.enterpriseContext?.enterpriseContext?.data_landscape?.map(
						(d) => {
							const dn: { [key: string]: any } = {};
							dn[d.name] = {
								applicable: d.applicable,
								tech_vendor: d.tech_vendor,
								description: d.description,
							};
							return dn;
						},
					),
				},
				usesCasesContext: {
					aiEnablers: [],
					technologies: [],
					benefits: [],
					impact: {},
					taxonomy: [],
				},
				use_case: this.currentUseCase,
			};

			console.log('Aqui');
			console.log(input);

			this.http
				.post<{ use_case: any[] }>(environment.url + '/api/ai/use_case_embedded/', JSON.stringify(input))
				.subscribe({
					error: (err) => {
						console.log(err);
						this.embeddedAILoading = false;
					},
					next: (data) => {
						console.log(data);

						if (
							this.currentUseCase &&
							this.organization &&
							data?.use_case &&
							data?.use_case[0] &&
							data?.use_case[0].platforms
						) {
							this.embeddedAIData = data?.use_case[0].platforms || [];
							this.currentUseCase.data['embeddedAI'][this.organization.id as string] =
								this.embeddedAIData;
							this.simulationService.saveUseCase(this.currentUseCase);
						}

						this.embeddedAILoading = false;
					},
				});
		}
	}

	embeddedAIRegenerate = false;
	missingFields: string[] = [];

	regenerateEmbeddedAI() {
		this.missingFields = [];

		if (!this.organization) {
			this.missingFields.push('Organization');
		}

		if (!this.simulationService?.enterpriseContext?.enterpriseContext?.description?.trim()) {
			this.missingFields.push('Description');
		}

		if (!this.simulationService?.enterpriseContext?.enterpriseContext?.industry?.length) {
			this.missingFields.push('Industry');
		}

		// if (!this.simulationService?.enterpriseContext?.enterpriseContext?.revenue) {
		// 	this.missingFields.push('Revenue');
		// }

		// if (!this.simulationService?.enterpriseContext?.enterpriseContext?.information?.length) {
		// 	this.missingFields.push('Information');
		// }

		// if (!this.simulationService?.enterpriseContext?.enterpriseContext?.employee) {
		// 	this.missingFields.push('Employee');
		// }

		if (!this.simulationService?.enterpriseContext?.enterpriseContext?.data_landscape?.length) {
			this.missingFields.push('Data Landscape');
		}

		if (this.missingFields.length > 0) {
			const detailMessage = `Please fill ${this.missingFields.join(', ')} field${this.missingFields.length > 1 ? 's' : ''} inside Enterprise Context.`;
			this.messageService.add({
				severity: 'error',
				summary: 'Validation Error - Enterprise Context',
				detail: detailMessage,
			});
			this.embeddedAIRegenerate = false;
			return;
		}

		this.confirmationService.confirm({
			header: 'Regenerate Embedded AI?',
			message: 'This will regenerate the Embedded AI. Are you sure?',
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: 'Confirm',
			acceptIcon: 'pi pi-check',
			accept: () => {
				this.embeddedAIRegenerate = true;
				this.generateEmbeddedAI().then(() => {
					this.embeddedAIRegenerate = false;
				})
			},
		});
	}
}
