import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService, TreeNode } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { AuthService } from '../rtp/service/auth.service';
import { Router } from '@angular/router';
import { Organization } from '../rtp/api/common';
import { DbService } from '../rtp/service/db.service';
import { Menu } from 'primeng/menu';
import { SimulationService } from '../rtp/components/simulation/simulation.service';

@Component({
	selector: 'app-topbar',
	templateUrl: './app.topbar.component.html',
	providers: [ConfirmationService, MessageService],
})
export class AppTopBarComponent implements OnInit {
	organizationSelected: Organization | undefined;
	organizationItems: Organization[] = [];
	menuItems1: MenuItem[] = [];
	menuItems2: MenuItem[] = [];
	technologyVendorOptions: any[] = [];
	tableRows: any[] = [];
	checked: boolean = false;
	public title: string = '';
	loadOrganization = true;
	visible_video = false;
	suggestions: any[] = [];

	arr = [
		{
        	name: "GenAI Development Platform",
            default: true,
            applicable: true,
            dataSource: false,
            embeddedAI: false,
            description: "Generative AI development platforms are tools that facilitate the creation, training, and deployment of AI models capable of generating new content such as text, images, audio, or code. These platforms require the ability to ingest a wide variety of data types—including textual data (like books and code), visual data (images and videos), audio data (speech and music), and structured data (databases and spreadsheets)—to train their models effectively. They must offer functionalities for data preprocessing, model training, evaluation, and deployment while addressing requirements like data privacy, ethical considerations, and security to ensure responsible AI development.",
            tech_vendor: ["ZBrain.ai", "Azure OpenAI", "Google Vertex AI", "AWS Bedrock", "Relevance AI"]
        }, {
            name: "Enterprise Resource Planning (ERP)",
            default: true,
            applicable: true,
            dataSource: true,
            embeddedAI: true,
            description: "An ERP system manages diverse data descriptors and requirements across various business functions: financial data (general ledger entries, transactions), human resources data (employee records, payroll, attendance), inventory and supply chain data (stock levels, supplier information, purchase orders), manufacturing data (production schedules, bills of materials), sales and customer data (customer profiles, sales orders), and compliance data (audit trails, regulatory filings). These systems require data to be accurate, consistent, real-time, standardized, secure, and validated, with scalability and integration capabilities to support efficient operations and informed decision-making across the enterprise.",
            tech_vendor: ["SAP", "Oracle ERP", "Microsoft Dynamics", "Infor ERP", "Epicor"]
        }, {
            name: "Customer Relationship Management (CRM)",
            default: true,
            applicable: true,
            dataSource: true,
            embeddedAI: true,
            description: "A CRM system manages various data descriptors and requirements centered around customer interactions and relationships: customer data (contact information, demographics, purchase history), sales data (leads, opportunities, sales pipeline), marketing data (campaign responses, segmentation, preferences), service and support data (customer inquiries, support tickets, resolutions), and social media interactions. These systems require data to be accurate, up-to-date, consistent, and securely managed, with real-time integration across all customer touchpoints. They must offer scalability, data analytics, and accessibility to enable personalized customer experiences, enhance sales and marketing strategies, and support informed decision-making for business growth.",
            tech_vendor: ["Salesforce", "Microsoft Dynamics CRM", "HubSpot", "Oracle CX", "Zoho CRM"]
        }, {
            name: "Human Capital Management (HCM)",
            default: true,
            applicable: true,
            dataSource: true,
            embeddedAI: true,
            description: "A Human Capital Management (HCM) system handles various data descriptors and requirements related to workforce management: employee data (personal details, job roles, qualifications), payroll and compensation data (salaries, benefits, bonuses), talent management data (performance reviews, training records, career development plans), time and attendance data (timesheets, leave requests, overtime), recruitment data (job postings, applications, candidate assessments), and compliance data (certifications, legal compliance, diversity metrics). These systems require data to be accurate, up-to-date, consistent, and securely managed, with real-time integration across all HR functions. They must offer scalability, analytics, and user accessibility to enhance HR processes, support employee engagement, ensure regulatory compliance, and enable informed decision-making for strategic workforce planning.",
            tech_vendor: ["Workday", "SAP SuccessFactors", "Oracle HCM", "ADP", "BambooHR"]
        }, {
            name: "Supply Chain Management (SCM)",
            default: true,
            applicable: true,
            dataSource: true,
            embeddedAI: true,
            description: "A Supply Chain Management (SCM) system manages various data descriptors and requirements related to the flow of goods and services: procurement data (purchase orders, supplier information, contracts), inventory data (stock levels, warehouse locations, reorder points), production data (manufacturing schedules, capacity planning, work orders), logistics data (shipping details, tracking information, delivery schedules), demand forecasting data (sales trends, market analysis), and compliance data (regulatory requirements, quality certifications). These systems require data to be accurate, real-time, consistent, and securely managed, with seamless integration across all supply chain activities. They must offer scalability, advanced analytics, and user accessibility to optimize operations, reduce costs, enhance collaboration with suppliers and partners, and support informed decision-making for strategic supply chain planning.",
            tech_vendor: ["Oracle SCM", "Blue Yonder (JDA)", "SAP SCM", "Infor SCM", "Manhattan Associates"]
        }, {
            name: "Business Intelligence (BI) & Analytics",
            default: true,
            applicable: true,
            dataSource: false,
            embeddedAI: true,
            description: "A Business Intelligence (BI) and Analytics platform manages various data descriptors and requirements to transform raw data into actionable insights: data from multiple sources (databases, spreadsheets, cloud services), transactional data (sales records, financial transactions, customer interactions), operational data (supply chain metrics, HR statistics, production data), external data (market trends, social media feeds, competitor analysis), and historical data (archived records, time-series data). These platforms require data to be accurate, timely, consistent, and securely managed, with robust data integration and processing capabilities. They must offer scalability, advanced analytics (data mining, predictive modeling, machine learning), and user-friendly interfaces for data visualization and reporting to support informed decision-making, strategic planning, and performance management across the organization.",
            tech_vendor: ["Tableau", "Microsoft Power BI", "Qlik", "Looker", "SAP BusinessObjects"]
        }, {
            name: "Collaboration & Communication",
            default: true,
            applicable: true,
            dataSource: false,
            embeddedAI: true,
            description: "A collaboration and communication platform manages various data descriptors and requirements to facilitate effective teamwork and information sharing: user data (profiles, contact information, status updates), messaging data (chat logs, emails, instant messages), document data (shared files, collaborative documents, version histories), scheduling data (calendars, meeting invites, task assignments), and multimedia data (audio/video calls, recordings, screen sharing sessions). These platforms require data to be accessible, real-time, consistent, and securely managed, with robust integration capabilities across devices and other applications. They must offer scalability, user-friendly interfaces, and features like presence indicators, notifications, and search functions to enhance collaboration, improve communication efficiency, and support team productivity across the organization.",
            tech_vendor: ["Microsoft 365", "Google Workspace", "Slack", "Zoom", "Cisco Webex"]
        }, {
            name: "Cloud Infrastructure",
            default: true,
            applicable: true,
            dataSource: false,
            embeddedAI: false,
            description: "A cloud infrastructure platform manages various data descriptors and requirements related to virtualized computing resources and services: infrastructure data (compute instances, storage volumes, network configurations), user data (accounts, permissions, access keys), monitoring data (logs, metrics, performance alerts), configuration data (deployment scripts, templates, settings), and billing data (usage metrics, cost reports, invoices). These platforms require data to be secure, consistent, highly available, and compliant with regulations, with robust integration capabilities across services and applications. They must offer scalability, advanced management tools, and user accessibility to optimize resource utilization, ensure operational efficiency, and support informed decision-making for IT infrastructure management.",
            tech_vendor: ["Amazon Web Services (AWS)", "Microsoft Azure", "Google Cloud Platform", "IBM Cloud", "Oracle Cloud"]
        }, {
            name: "Service Management Platforms",
            default: true,
            applicable: true,
            dataSource: true,
            embeddedAI: true,
            description: "A service management platform manages various data descriptors and requirements related to the delivery and support of services within an organization: service request data (incidents, service tickets, change requests), asset and configuration data (hardware and software inventories, configuration items, dependencies), knowledge base data (articles, FAQs, troubleshooting guides), user data (contact information, access levels, service histories), and performance metrics (service level agreements, response times, resolution rates). These platforms require data to be accurate, real-time, consistent, and securely managed, with integration across all service management processes. They must offer scalability, automation of workflows, and user-friendly interfaces to enhance service efficiency, improve customer satisfaction, and support informed decision-making for continuous service improvement.",
            tech_vendor: ["ServiceNow", "BMC Remedy", "Freshservice", "Ivanti", "Cherwell"]
        }, {
            name: "Project and Portfolio Management (PPM)",
            default: true,
            applicable: true,
            dataSource: true,
            embeddedAI: true,
            description: "A Project and Portfolio Management (PPM) system handles various data descriptors and requirements related to the planning, execution, and oversight of projects and portfolios: project data (objectives, timelines, milestones, deliverables), resource data (personnel assignments, equipment usage, budget allocations), task data (schedules, dependencies, progress updates), portfolio data (project prioritization, risk assessments, ROI analysis), and performance metrics (KPIs, status reports, variance analyses). These systems require data to be accurate, real-time, consistent, and securely managed, with integration across all projects and organizational levels. They must offer scalability, advanced analytics, and user-friendly interfaces to enhance project visibility, optimize resource utilization, support strategic decision-making, and ensure alignment with business objectives.",
            tech_vendor: ["Microsoft Project", "Jira", "Asana", "Smartsheet", "Monday.com"]
        }, {
            name: "Marketing Automation",
            default: true,
            applicable: true,
            dataSource: true,
            embeddedAI: true,
            description: "A marketing automation platform manages various data descriptors and requirements related to marketing activities and customer engagement: customer and prospect data (contact information, demographics, behavior profiles), campaign data (emails, social media posts, advertisements, scheduling), engagement data (click-through rates, open rates, website visits, conversions), segmentation data (audience lists, preferences, purchase history), and analytics data (performance metrics, A/B testing results, ROI analysis). These platforms require data to be accurate, timely, consistent, and securely managed, with integration across multiple channels and customer touchpoints. They must offer scalability, automation capabilities, and user-friendly interfaces to personalize marketing efforts, enhance customer engagement, optimize campaign effectiveness, and support informed decision-making for strategic marketing initiatives.",
            tech_vendor: ["Adobe Marketo", "HubSpot", "Pardot (Salesforce)", "ActiveCampaign", "Eloqua (Oracle)"]
        }, {
            name: "E-Commerce",
            default: true,
            applicable: true,
            dataSource: true,
            embeddedAI: true,
            description: "An e-commerce platform manages various data descriptors and requirements related to online retail operations: product data (descriptions, prices, images, inventory levels), customer data (contact information, preferences, purchase history), transaction data (orders, payments, shipping details), marketing data (promotions, discounts, advertising campaigns), and analytics data (website traffic, conversion rates, sales trends). These platforms require data to be accurate, real-time, consistent, and securely managed, with integration across multiple channels and customer touchpoints (websites, mobile apps, social media). They must offer scalability, user-friendly interfaces, and robust features like search functionality, personalized recommendations, and secure payment processing to enhance customer experience, optimize sales performance, and support informed decision-making for business growth.",
            tech_vendor: ["SAP Commerce Cloud", "Shopify", "Magento", "BigCommerce", "WooCommerce"]
        }, {
            name: "Corporate Performance Management",
            default: true,
            applicable: true,
            dataSource: true,
            embeddedAI: true,
            description: "A Corporate Performance Management (CPM) system handles various data descriptors and requirements related to strategic planning and performance evaluation: financial data (budgets, forecasts, consolidated financial statements), operational data (key performance indicators, balanced scorecards, departmental metrics), planning data (strategic objectives, initiative tracking, resource allocation), consolidation data (intercompany transactions, eliminations, currency translations), and compliance data (regulatory filings, audit trails, risk assessments). These systems require data to be accurate, timely, consistent, and securely managed, with integration across all organizational levels and data sources. They must offer scalability, advanced analytics, and user-friendly interfaces to facilitate strategic planning, enhance performance monitoring, support informed decision-making, and ensure alignment with corporate goals and regulatory requirements.",
            tech_vendor: ["OneStream", "OracleEPM", "Workday", "Anaplan", "Board"]
        }, {
            name: "Procurement Management Platform",
            default: true,
            applicable: true,
            dataSource: true,
            embeddedAI: true,
            description: "A Procurement Management Platform manages various data descriptors and requirements related to the purchasing process and supplier relationships: procurement data (purchase requisitions, purchase orders, approvals), supplier data (contact information, performance metrics, contracts), inventory data (stock levels, demand forecasts, reorder points), financial data (budget allocations, cost analyses, payment terms), compliance data (regulatory requirements, audit trails, policy adherence), and analytics data (spend analysis, supplier risk assessments, procurement KPIs). These platforms require data to be accurate, real-time, consistent, and securely managed, with integration across supply chain, finance, and inventory systems. They must offer scalability, automation capabilities, and user-friendly interfaces to streamline procurement processes, enhance supplier collaboration, optimize spending, ensure compliance, and support informed decision-making for strategic sourcing and procurement operations. ",
            tech_vendor: ["Coupa", "SAP Ariba", "Jaggaer", "Oracle Procurement Cloud", "GEP SMART"]
        }
	];

	@ViewChild('menubutton') menuButton!: ElementRef;

	constructor(
		public messageService: MessageService,
		public confirmationService: ConfirmationService,
		public cdr: ChangeDetectorRef,
		public layoutService: LayoutService,
		public authService: AuthService,
		public dbService: DbService,
		public simulationService: SimulationService,
		public router: Router,
	) {
		this.title = this.layoutService.title;

		this.layoutService.titleText$.subscribe((title) => {
			this.title = title;
			this.cdr.detectChanges();
		});

		this.authService.updateSource$.subscribe(async () => {
			this.menuItems1 = [
				{
					label: this.authService.profile,
					icon: 'pi pi-user',
				},
			];
			this.organizationItems = await this.authService.getOrganizations();
			this.organizationSelected = await this.authService.getCurrentOrganization();
			this.cdr.detectChanges();
		});

		this.menuItems2 = [
			{
				label: 'Sign Out',
				icon: 'pi pi-sign-out',
				command: () => {
					this.confirmationService.confirm({
						header: 'Sign Out',
						message: 'Are you sure you want to sign out?',
						icon: 'pi pi-exclamation-triangle',
						accept: async () => {
							this.layoutService.updateTitle('');
							this.messageService.add({ severity: 'warn', summary: 'Sign Out' });
							await this.authService.signOut();
							await this.router.navigateByUrl('/sign-in');
						},
					});
				},
			},
		];
	}

	async ngOnInit() {
		await this.loadOrganizations();
		this.initEnterpriseContext();
	}

	private async initEnterpriseContext() {
		await this.simulationService.getEnterpriseContext();
		this.simulationService.loadPlatformCategories();
		this.initializeDefaultRow();
		this.tableRows = JSON.parse(localStorage.getItem('platformCategories') || '[]');
		this.tableRows = this.simulationService.data_landscape.length ? [...this.simulationService.data_landscape] : [];
	}

	private initializeDefaultRow() {
		if (this.tableRows.length === 0) {
			this.addTableRow();
		}
	}

	private async loadOrganizations() {
		const loadOrganizations = () =>
			setTimeout(async () => {
				if (this.loadOrganization && this.authService.loadOrganizations) {
					this.organizationItems = await this.authService.getOrganizations();
					this.organizationSelected = await this.authService.getCurrentOrganization();
					this.loadOrganization = false;
				} else {
					loadOrganizations();
				}
			}, 150);
		loadOrganizations();
	}

	async onChangeOrganization() {
		if (this.organizationSelected) {
			// this.simulationService.enterpriseContext = undefined;
			await this.authService.setCurrentOrganization(this.organizationSelected, true);
			// this.dbService.dbOrganizationInitLoad = true;
			// this.dbService.organizationSync(this.organizationSelected?.id || '', false);
			window.location.reload();
		}
	}

	onChipAdd(event: any, index: number) {
		const value = event.value.trim();

		if (value) {
			this.tableRows[index].tech_vendor.push({ label: value });
		}

		this.tableRows[index].tech_vendor = this.tableRows[index].tech_vendor.filter(
			(vendor: { label: string }) => vendor.label && vendor.label.trim(),
		);

		this.cdr.detectChanges();
	}

	showDialogEnterpriseContext: boolean = false;
	revenueTreeSelections: TreeNode | undefined = undefined;
	employeesTreeSelections: TreeNode | undefined = undefined;
	countriesTreeSelections: TreeNode | undefined = undefined;
	industryTreeSelections: TreeNode[] = [];
	additionalInformationTreeSelections: TreeNode[] = [];
	topProductsTreeSelections: TreeNode[] = [];
	topServicesTreeSelections: TreeNode[] = [];
	descriptionSelection: string = '';

	openEnterpriseContext() {
		if (!this.simulationService.data_landscape || !Array.isArray(this.simulationService.data_landscape)
			 || this.simulationService.data_landscape.length === 0 || this.simulationService.data_landscape === undefined) {
			this.simulationService.data_landscape = this.arr;
		}
		this.tableRows = this.simulationService.data_landscape.length
			? this.simulationService.data_landscape.map((row) => ({
					...row,
					tech_vendor: (row.tech_vendor || []).map((vendor: any) => {
						const matchedOption = this.technologyVendorOptions.find((option) => option.label === vendor);
						return matchedOption
							? { label: matchedOption.label, key: matchedOption.key }
							: { label: vendor, key: vendor };
					}),
			  }))
			: [];
		this.revenueTreeSelections = { ...this.simulationService.revenueTreeSelections };
		this.employeesTreeSelections = { ...this.simulationService.employeesTreeSelections };
		this.countriesTreeSelections = { ...this.simulationService.countriesTreeSelections };
		this.industryTreeSelections = [...this.simulationService.industryTreeSelections];
		this.additionalInformationTreeSelections = [...this.simulationService.additionalInformationTreeSelections];
		this.topProductsTreeSelections = [...this.simulationService.topProductsTreeSelections];
		this.topServicesTreeSelections = [...this.simulationService.topServicesTreeSelections];
		this.descriptionSelection = this.simulationService.descriptionSelection;
		this.showDialogEnterpriseContext = true;
	}

	clearEnterpriseContext() {
		// Reset all the form fields
		this.industryTreeSelections = [];
		this.descriptionSelection = '';
		this.revenueTreeSelections = undefined;
		this.employeesTreeSelections = undefined;
		this.countriesTreeSelections = undefined;
		this.topProductsTreeSelections = [];
		this.topServicesTreeSelections = [];
	}

	updateEnterpriseContext() {
		const modifiedTableRows = this.tableRows.map((row) => ({
			...row,
			tech_vendor: Array.isArray(row.tech_vendor)
				? row.tech_vendor.map((vendor: { label: string }) => vendor?.label || '')
				: [],
		}));
		this.simulationService.data_landscape = modifiedTableRows;
		this.simulationService.revenueTreeSelections = { ...this.revenueTreeSelections };
		this.simulationService.employeesTreeSelections = { ...this.employeesTreeSelections };
		this.simulationService.countriesTreeSelections = { ...this.countriesTreeSelections };
		this.simulationService.industryTreeSelections = [...this.industryTreeSelections];
		// this.simulationService.additionalInformationTreeSelections = [...this.additionalInformationTreeSelections];
		this.simulationService.topProductsTreeSelections = [...this.topProductsTreeSelections];
		this.simulationService.topServicesTreeSelections = [...this.topServicesTreeSelections];
		this.simulationService.descriptionSelection = this.descriptionSelection;
		this.simulationService
			.updateEnterpriseContext()
			.then(() => {
				this.messageService.add({
					severity: 'success',
					summary: 'Confirmed',
					detail: 'Enterprise Context Saved',
				});
			})
			.catch((error) => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Failed to save Enterprise Context.',
				});
				console.error('Error saving Enterprise Context:', error);
			});
	}

	onSelect(event: any, rowData: any) {
		const selectedItem = this.arr.find((item) => item.name === event);

		if (selectedItem) {
			rowData.name = selectedItem.name;
			rowData.description = selectedItem.description || '';
			rowData.tech_vendor = rowData.tech_vendor || [];
			rowData.applicable = rowData.applicable || false;
		} else {
			rowData.name = event;
			rowData.description = 'no desc yet to apply';
		}
		this.cdr.detectChanges();
	}

	filterNames(event: any) {
		let query = event.query.toLowerCase();
		this.suggestions = this.arr
			.filter((item) => item.name.toLowerCase().startsWith(query))
			.map((item) => item.name);
	}

	addTableRow() {
		this.tableRows.push({
			name: '',
			applicable: true,
			dataSource: false,
			embeddAI: false,
			tech_vendor: [],
			description: '',
			default: false,
		});
	}
	removeTableRow(index: number) {
		this.tableRows.splice(index, 1);
		this.simulationService.data_landscape = [...this.tableRows];
	}
	
}
