import PptxGenJS from 'pptxgenjs';

import Slide = PptxGenJS.Slide;
import BackgroundProps = PptxGenJS.BackgroundProps;

/**
 * Represents an input for cursor positioning and alignment.
 *
 * @interface
 */
export interface CursorInput {
	y?: number | string;
	h?: number | string;
	x?: number | string;
	w?: number | string;
	align?: 'left' | 'right' | 'center';
	yS?: number;
	xS?: number;
	ignoreNewSlide?: boolean;
	maxX?: number;
	maxY?: number;
	footerInverted?: boolean;
}

/**
 * Represents the Cursor Output.
 *
 * @interface
 */
export interface CursorOutput {
	y: number;
	h?: number;
	x: number;
	w?: number;
	valign: 'top' | 'middle' | 'bottom';
}

/**
 * Represents the options for a cursor.
 *
 * @interface
 */
interface CursorOptions {
	y?: number;
	yS?: number;
	x?: number;
	xS?: number;
	horizontal?: boolean;
}

/**
 * Represents a cursor object used for positioning elements on a slide.
 */
export class Cursor {
	public y: number;
	public readonly yS: number;
	public x: number;
	public readonly xS: number;
	public vertical;

	public readonly initY: number = 0;
	public readonly initX: number = 0;
	public slide: Slide;
	public slideNum: number = 1;

	// @ts-ignore
	/**
	 * Constructs a new Cursor object.
	 * @param {PptxGenJS} pres - The PptxGenJS instance on which to operate.
	 * @param {CursorOptions} options - The options for configuring the Cursor object.
	 * @param {number=} options.y - The initial y-coordinate of the cursor. Default is 0.
	 * @param {number=} options.yS - The step size for moving the cursor vertically. Default is 0.15.
	 * @param {number=} options.x - The initial x-coordinate of the cursor. Default is 0.
	 * @param {number=} options.xS - The step size for moving the cursor horizontally. Default is 0.15.
	 * @param {boolean=} options.horizontal - Determines whether the cursor moves horizontally. If false, the cursor moves vertically. Default is true.
	 * @constructor
	 */
	constructor(public pres: PptxGenJS, options: CursorOptions) {
		this.y = options.y || 0;
		this.yS = options.yS || 0.15;
		this.x = options.x || 0;
		this.xS = options.xS || 0.15;
		this.vertical = !options.horizontal;

		this.initX = this.x;
		this.initY = this.y;
		this.slide = this.pres.addSlide();
	}

	/**
	 * Toggles the orientation of the object.
	 * If the object is currently vertical, it sets the x-coordinate to the initial x-coordinate.
	 * If the object is currently horizontal, it sets the object to vertical orientation.
	 *
	 * @return {void}
	 */
	toggleOrientation(): void {
		this.vertical = !this.vertical;
		if (this.vertical) {
			this.x = this.initX;
			if (this.maxH) {
				this.y = this.y + this.maxH;
				this.maxH = 0;
			}
		}
	}

	/**
	 * Retrieves the orientation of the object.
	 *
	 * @returns {string} The orientation of the object. Returns 'y' if the object is in vertical orientation,
	 *                   or 'x' if the object is in horizontal orientation.
	 */
	getOrientation(): 'y' | 'x' {
		return this.vertical ? 'y' : 'x';
	}

	public readonly maxX: number = 10;
	public readonly maxY: number = 4.95; //5.63
	public maxH: number = 0;

	/**
	 * Parses the value and returns a numeric representation.
	 * If the value is a number, it is returned as is.
	 * If the value is a string, it is converted to a number by parsing the percentage and multiplying it with the maximum value.
	 *
	 * @param {number|string} v - The value to be parsed. Can be either a number or a string.
	 * @param {number} maxV - The maximum value to be used in case the value is a percentage. Only applicable if the value is a string.
	 * @returns {number} - The numeric representation of the value.
	 */
	public parseValue(v: number | string, maxV: number): number {
		if (typeof v === 'number') {
			return v;
		} else {
			return maxV * (parseInt(v.replace('%', '')) / 100);
		}
	}

	/**
	 * Returns the position of the cursor based on the provided input value.
	 * Calls the provided callback function with the position and the current slide.
	 *
	 * @param value - The input value for the cursor position. Can contain x and y coordinates.
	 * @param callback - The callback function to be called with the position and slide.
	 * @returns {void}
	 */
	newPosition(value: CursorInput, callback: (position: CursorOutput, slide: Slide) => void): void {
		const maxX = value.maxX || this.maxX;
		const maxY = value.maxY || this.maxY;
		const p: CursorOutput = {
			y: (value.y ? this.parseValue(value.y, maxY) : this.y) + (value.yS === undefined ? this.yS : value.yS),
			x: (value.x ? this.parseValue(value.x, maxX) : this.x) + (value.xS === undefined ? this.xS : value.xS),
			valign: 'top',
		};
		if (value.h) {
			p.h = this.parseValue(value.h, maxY - p.y);
		}
		if (value.align) {
			if (value.align === 'center') {
				p.x = p.x + (maxX - p.x) / 2;
			}
		}
		if (value.w) {
			p.w = this.parseValue(value.w, maxX - p.x);
			if (value.align) {
				if (value.align === 'center') {
					p.w = this.parseValue(value.w, (maxX - p.x) * 2);
					p.x = p.x - p.w / 2 - (value.xS === undefined ? this.xS : value.xS) * 2;
				}
			}
		}
		if (!this.vertical && p.w) {
			this.x = p.x + p.w + (value.xS === undefined ? this.xS : value.xS);
			if (p.h && p.h > this.maxH) {
				this.maxH = p.h;
			}
		}
		if (this.vertical && p.h) {
			this.y = p.y + p.h;
		}
		if ((this.x >= maxX || this.x + (value.xS === undefined ? this.xS : value.xS) >= maxX) && this.maxH) {
			this.y = this.y + this.maxH;
			this.maxH = 0;
		}
		if (
			!value.ignoreNewSlide &&
			(this.y >= maxY || this.y + (value.yS === undefined ? this.yS : value.yS) >= maxY)
		) {
			// this.newSlide();
			p.y = this.y + (value.yS === undefined ? this.yS : value.yS);
		}
		callback(p, this.slide);
	}

	/**
	 * Add a new slide to the presentation.
	 *
	 * @return {void}
	 */
	newSlide(options: { footer?: boolean; background?: BackgroundProps | null } = { footer: true }): void {
		this.slide = this.pres.addSlide();
		this.vertical = true;
		this.y = this.initY;
		this.x = this.initX;
		this.maxH = 0;

		this.addBackground({ background: options.background });

		if (options.footer === undefined) {
			options.footer = true;
		}

		if (options.footer) {
			this.addFooter();
		}
	}

	addBackground(options?: { slide?: Slide; background?: BackgroundProps | null }) {
		if (!options) {
			options = {};
		}
		if (!options.slide) {
			options.slide = this.slide;
		}
		if (!options.background && options.background !== null) {
			options.background = { path: '../../../../../assets/rtp/img/report-background-slide.png' };
		}
		if (options.background) {
			options.slide.background = options.background;
		}
	}

	addFooter(slide?: Slide): void {
		if (!slide) {
			slide = this.slide;
		}
		this.slideNum++;

		const documentStyle = getComputedStyle(document.documentElement);

		slide.addImage({
			path: '../../../../../assets/rtp/img/report-rectround-footer.png',
			w: '90%',
			h: '9%',
			y: this.maxY,
			x: '5%',
		});

		slide.addImage({
			path: '../../../../../assets/rtp/img/report-footer.png',
			w: 1.15,
			h: 0.2,
			y: this.maxY + 0.18,
			x: 0.75,
		});

		slide.addText(
			'© 2024 The Hackett Group, Inc. All rights reserved. Reproduction of this document or any portion thereof without prior written consent is prohibited.',
			{
				y: this.maxY + 0.25,
				x: 2.75,
				color: documentStyle.getPropertyValue('--primary-color'),
				fontSize: 6,
			},
		);

		slide.addText(this.slideNum.toString(), {
			y: this.maxY + 0.25,
			x: '90%',
			color: '#A9B7CB',
			fontSize: 11,
		});
	}

	/**
	 * Sets the position of the object.
	 *
	 * @param {object} position - The new position values.
	 * @param {number} [position.x] - The new x-coordinate.
	 * @param {number} [position.y] - The new y-coordinate.
	 * @param {number} [position.xS] - The amount to add to the current x-coordinate.
	 * @param {number} [position.yS] - The amount to add to the current y-coordinate.
	 *
	 * @return {void}
	 */
	setPosition(position: { x?: number; y?: number; xS?: number; yS?: number }): void {
		if (position.x) {
			this.x = position.x;
		}
		if (position.xS) {
			this.x = this.x + position.xS;
		}
		if (position.y) {
			this.y = position.y;
		}
		if (position.yS) {
			this.y = this.y + position.yS;
		}
	}

	/**
	 * Retrieves the position.
	 *
	 * @returns {object} The position object.
	 * @property {number} x - The X coordinate.
	 * @property {number} y - The Y coordinate.
	 */
	getPosition(): { x: number; y: number; xS: number; yS: number } {
		return {
			y: this.y,
			x: this.x,
			yS: this.yS,
			xS: this.xS,
		};
	}
}
