<div class="card grid" [ngClass]="styleClass">
	<div class="side-element filter-sidebar"
		 [ngClass]="{
		'active': appFilterService.showSide,
		'extend': !appFilterService.showSideExtend,
		}"
		 (click)="appFilterService.openSide()">
		<ng-template [ngIf]="appFilterService.showSide">
			<ng-content select="[filter-sidebar]"></ng-content>
		</ng-template>
		<p-button *ngIf="appFilterService.showSideExtend" click-stop-propagation
				  class="filter-sidebar-button"
				  styleClass="p-button-text text-primary hover:text-white"
				  [icon]="'pi ' + (appFilterService.showSide ? 'pi-chevron-circle-left' : 'pi-chevron-circle-right')"
				  (onClick)="appFilterService.toggleSide()"></p-button>
	</div>
	<div *ngIf="appFilterService.showSideExtend || (!appFilterService.showSideExtend && !appFilterService.showSide)" class="col side-element filter-content"
		 [ngClass]="{
		'active': appFilterService.showSide,
		'extend': !appFilterService.showSideExtend,
		}">
		<ng-content select="[filter-content]"></ng-content>
	</div>
</div>
