import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DbService } from '../../service/db.service';
import {
	EnterpriseContext,
	IEnterpriseContext,
	ISimulationContext,
	M1Prep,
	Simulation,
	UseCaseSimulation,
} from '../../api/simulation.api';
import { E2ETaxonomyService } from '../../service/e2e-taxonomy.service';
import { MenuItem, TreeNode } from 'primeng/api';
import { toCapitalize } from '../../service/services.data/utils.data';
import { Router } from '@angular/router';
import { Level1, Level2, Function, CategoryFunction, Technology } from '../../api/e2e-taxonomy.api';
import { DomSanitizer } from '@angular/platform-browser';
import { Options as OptionsSlider } from 'ngx-slider-v2/options';
import { AuthService } from '../../service/auth.service';
import { firstValueFrom, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface QuerySolutions {
	technologySelection: string[];
	functionsSelection: string[];
	toolsSelection: string[];
}

@Injectable({
	providedIn: 'root',
})
export class SimulationService {
	constructor(
		private sanitizer: DomSanitizer,
		private router: Router,
		private http: HttpClient,
		public dbService: DbService,
		private authService: AuthService,
		private e2eTaxonomyService: E2ETaxonomyService,
	) {
		this.authService.organizationSource$.subscribe(() => {
			setTimeout(() => {
				this.onLoad();
			}, 150);
		});

		this.updateSimulationsObservable.subscribe(() => {
			setTimeout(() => {
				this.loadSimulations();
			}, 150);
		});

		if (localStorage.getItem('industryTreeSelections')) {
			this.industryTreeOptions = JSON.parse(localStorage.getItem('industryTreeSelections') || '[]');
		}
		if (localStorage.getItem('topProductsTreeSelections')) {
			this.topProductsTreeOptions = JSON.parse(localStorage.getItem('topProductsTreeSelections') || '[]');
		}
		if (localStorage.getItem('topServicesTreeSelections')) {
			this.topServicesTreeOptions = JSON.parse(localStorage.getItem('topServicesTreeSelections') || '[]');
		}
	}

	confirmationService: ConfirmationService;

	loaded: boolean = false;
	addProcessLoaded: boolean = false;

	simulations: Simulation[] = [];
	simulationsUsesCases: UseCaseSimulation[] = [];

	m1Preps: M1Prep[] = [];

	technologiesTree: TreeNode[] = [];
	technologiesTreeSelection: TreeNode[] = [];
	processTree: TreeNode[] = [];
	processTreeSelection: TreeNode[] = [];

	aiUseCases: any[] = [];
	counters: any = {};

	searchText: string = '';
	filteredUseCases: any[] = [];
	pagedSolutions: any[] = [];

	rows: number = 15;
	currentPage: number = 0;
	counterGenAiSubject: Subject<number> = new Subject();
	counterGenAiObservable = this.counterGenAiSubject.asObservable();

	simulationsMenu: { [key: string]: MenuItem[] } = {};
	m1PrepMenu: { [key: string]: MenuItem[] } = {};

	enterpriseContext: EnterpriseContext | undefined = undefined;

	//default prompt studio
	defaultSimulationContext: ISimulationContext = {
		aiEnablers: [],
		technologies: [],
		business_values: [],
		impact: {
			breakthrough: 5,
			transformative: 5,
			incremental: 5,
		},
		taxonomy: [],
		scope: [],
	};

	taxonomy: CategoryFunction[] = [];

	simulationSubject: Subject<boolean> = new Subject();
	simulationObservable = this.simulationSubject.asObservable();

	selectedSimulationSubject: Subject<Simulation> = new Subject();
	selectedSimulationObservable = this.selectedSimulationSubject.asObservable();

	selectedSimulationStarSubject: Subject<Simulation> = new Subject();
	selectedSimulationStarObservable = this.selectedSimulationStarSubject.asObservable();

	updateSimulationsSubject: Subject<string> = new Subject();
	updateSimulationsObservable = this.updateSimulationsSubject.asObservable();

	updateUseCaseValidSubject: Subject<string> = new Subject();
	updateUseCaseValidObservable = this.updateUseCaseValidSubject.asObservable();

	selectedM1PrepSubject: Subject<M1Prep> = new Subject();
	selectedM1PrepObservable = this.selectedM1PrepSubject.asObservable();

	async onLoad() {
		this.addProcessTaxonomyTreeSelect = await this.e2eTaxonomyService.getCategoriesFunction(
			{ extend: 'function.level1.level2' },
			true,
		);

		this.taxonomy = await this.e2eTaxonomyService.getCategoriesFunction({ extend: 'function.level1.level2' });

		this.taxonomy.map((cf) => {
			cf.functions = cf.functions.map((f) => {
				f.category = cf;
				f.levels1 = f.levels1.map((l1) => {
					l1.function = f;
					l1.levels2 = l1.levels2.map((l2) => {
						l2.level1 = l1;
						return l2;
					});
					return l1;
				});
				return f;
			});
			return cf;
		});

		this.addProcessLoaded = true;

		this.aiEnablersTreeOptions = await this.e2eTaxonomyService.getTechnologies({}, true);

		await this.e2eTaxonomyService.getTechnologies().then((aiEnablers) => {
			this.aiEnablers = aiEnablers.map((t) => {
				t.svg = this.safeHTML(t.svg) as any;
				return t;
			});
		});

		const organizationId: string = await this.authService.getCurrentOrganizationId();

		await this.getEnterpriseContext();

		await this.loadSimulations(organizationId);

		this.loaded = true;

		this.simulationSubject.next(true);
	}

	updatePagedSolutions() {
		const start = this.currentPage * this.rows;
		const end = start + this.rows;
		this.pagedSolutions = this.filteredUseCases.slice(start, end);
	}

	paginate(event: any) {
		this.currentPage = event.page;
		this.updatePagedSolutions();
	}

	changeSelection() {
		this.filteredUseCases = [...this.aiUseCases];
		// console.log('this.filteredUseCases',this.filteredUseCases);
		if (this.searchText) {
			this.filteredUseCases = this.aiUseCases.filter((useCase) =>
				useCase.name.toLowerCase().includes(this.searchText.toLowerCase()),
			);
		}

		if (this.technologiesTreeSelection.length > 0) {
			const selectedLabels = this.technologiesTreeSelection.map((selection) => selection.label);
			this.filteredUseCases = this.filteredUseCases.filter((useCase) =>
				useCase.data.aiEnablers.some((enabler: string) => selectedLabels.includes(enabler)),
			);
		}

		if (this.functionsTreeSelection.length > 0) {
			const selectedLabels = this.functionsTreeSelection.map((selection) => selection.label);

			this.filteredUseCases = this.filteredUseCases.filter((useCase) => {
				let categoryFunctionName = null;

				if (
					useCase.level2 &&
					useCase.level2.level1 &&
					useCase.level2.level1.function &&
					useCase.level2.level1.function.categoryFunction
				) {
					categoryFunctionName = useCase.level2.level1.function.categoryFunction.name;
				}

				if (
					!categoryFunctionName &&
					useCase.level1 &&
					useCase.level1.function &&
					useCase.level1.function.categoryFunction
				) {
					categoryFunctionName = useCase.level1.function.categoryFunction.name;
				}
				return categoryFunctionName && selectedLabels.includes(categoryFunctionName);
			});
		}

		if (this.impactTreeSelection.length > 0) {
			const selectedImpactAssessments = this.impactTreeSelection.map((selection) => selection.label);
			this.filteredUseCases = this.filteredUseCases.filter((useCase) =>
				selectedImpactAssessments.includes(useCase.data.impact_assessment),
			);
		}

		this.updatePagedSolutions();
		// console.log('this.searchText', this.searchText)
		// console.log('filtered', this.filteredUseCases);
		// console.log('paged', this.pagedSolutions);
	}

	async loadSimulations(organizationId?: string) {
		this.loaded = false;

		if (!organizationId) {
			organizationId = await this.authService.getCurrentOrganizationId();
		}

		const countAiOpportunities = await firstValueFrom(
			this.http.get<{
				count: number;
			}>('@api/e2e_taxonomy/ai_opportunity?count=1'),
		).then((data) => {
			return data.count;
		});

		this.technologiesTree = await this.e2eTaxonomyService.getTechnologies({}, true);

		const data_use_case_library_e2e = await this.dbService.data_use_case_library
			.where('sourceRel')
			.equals('1')
			.toArray();
		const data_use_case_library_process = await this.dbService.data_use_case_library
			.where('sourceRel')
			.equals('2')
			.toArray();
		const data_level1 = await this.dbService.data_level1.toArray();
		const data_function = await this.dbService.data_function.toArray();
		const data_category_function = await this.dbService.data_category_function.toArray();
		const data_use_case_library_subprocess = await this.dbService.data_use_case_library
			.where('sourceRel')
			.equals('3')
			.toArray();
		const data_level2 = await this.dbService.data_level2.toArray();

		const level1Map: Map<string, Level1> = new Map(data_level1.map((level1) => [level1.id as string, level1]));
		const level2Map: Map<string, Level2> = new Map(data_level2.map((level2) => [level2.id as string, level2]));
		const functionMap: Map<string, Function> = new Map(data_function.map((func) => [func.id as string, func]));
		const categoryFunctionMap: Map<string, CategoryFunction> = new Map(
			data_category_function.map((catFunc) => [catFunc.id as string, catFunc]),
		);

		const countByImpactAndCategory: { [impact: string]: { [category: string]: number } } = {};

		const combinedDataE2E = data_use_case_library_e2e.map((useCase) => {
			const level1 = level1Map.get(useCase.sourceId);
			const fn = level1 ? functionMap.get(level1.functionId) : undefined;
			const categoryFunction = fn ? categoryFunctionMap.get(fn.categoryId) : undefined;

			const aiEnablersLabels = useCase.data.aiEnablers.map((id: string) => {
				const found = this.aiEnablersTreeOptions.find((enabler) => enabler.key === id);
				return found ? found.label : id;
			});

			return {
				...useCase,
				data: {
					...useCase.data,
					aiEnablers: aiEnablersLabels,
				},
				level1: level1
					? {
							...level1,
							function: fn
								? {
										...fn,
										categoryFunction: categoryFunction || null,
								  }
								: null,
					  }
					: null,
				source: 'E2E Process',
			};
		});

		combinedDataE2E.forEach((item) => {
			const impactAssessment = item.data.impact_assessment;

			const categoryName = 'E2E';

			if (impactAssessment && categoryName) {
				if (!countByImpactAndCategory[impactAssessment]) {
					countByImpactAndCategory[impactAssessment] = {};
				}
				if (!countByImpactAndCategory[impactAssessment][categoryName]) {
					countByImpactAndCategory[impactAssessment][categoryName] = 0;
				}
				countByImpactAndCategory[impactAssessment][categoryName] += 1;
			}
		});

		const combinedDataProcess = data_use_case_library_process.map((useCase) => {
			const level1 = level1Map.get(useCase.sourceId);
			const fn = level1 ? functionMap.get(level1.functionId) : undefined;
			const categoryFunction = fn ? categoryFunctionMap.get(fn.categoryId) : undefined;
			const aiEnablersLabels = useCase.data.aiEnablers.map((id: string) => {
				const found = this.aiEnablersTreeOptions.find((enabler) => enabler.key === id);
				return found ? found.label : id;
			});

			return {
				...useCase,
				data: {
					...useCase.data,
					aiEnablers: aiEnablersLabels,
				},
				level1: level1
					? {
							...level1,
							function: fn
								? {
										...fn,
										categoryFunction: categoryFunction || null,
								  }
								: null,
					  }
					: null,
				source: 'Process',
			};
		});

		combinedDataProcess.forEach((item) => {
			const impactAssessment = item.data.impact_assessment;
			const categoryName = item.level1?.function?.categoryFunction?.name;

			if (impactAssessment && categoryName) {
				if (!countByImpactAndCategory[impactAssessment]) {
					countByImpactAndCategory[impactAssessment] = {};
				}
				if (!countByImpactAndCategory[impactAssessment][categoryName]) {
					countByImpactAndCategory[impactAssessment][categoryName] = 0;
				}
				countByImpactAndCategory[impactAssessment][categoryName] += 1;
			}
		});

		const combinedDataSubProcess = data_use_case_library_subprocess.map((useCase) => {
			const level2 = level2Map.get(useCase.sourceId);
			const level1 = level2 ? level1Map.get(level2.level1Id) : undefined;
			const fn = level1 ? functionMap.get(level1.functionId) : undefined;
			const categoryFunction = fn ? categoryFunctionMap.get(fn.categoryId) : undefined;

			const aiEnablersLabels = useCase.data.aiEnablers.map((id: string) => {
				const found = this.aiEnablersTreeOptions.find((enabler) => enabler.key === id);
				return found ? found.label : id;
			});

			return {
				...useCase,
				data: {
					...useCase.data,
					aiEnablers: aiEnablersLabels,
				},
				level2: level2
					? {
							level1: level1
								? {
										...level1,
										function: fn
											? {
													...fn,
													categoryFunction: categoryFunction || null,
											  }
											: null,
								  }
								: null,
					  }
					: null,
				source: 'Subprocess',
			};
		});

		combinedDataSubProcess.forEach((item) => {
			const impactAssessment = item.data.impact_assessment;
			const categoryName = item.level2?.level1?.function?.categoryFunction?.name;

			if (impactAssessment && categoryName) {
				if (!countByImpactAndCategory[impactAssessment]) {
					countByImpactAndCategory[impactAssessment] = {};
				}
				if (!countByImpactAndCategory[impactAssessment][categoryName]) {
					countByImpactAndCategory[impactAssessment][categoryName] = 0;
				}
				countByImpactAndCategory[impactAssessment][categoryName] += 1;
			}
		});
		this.aiUseCases = [...combinedDataE2E, ...combinedDataProcess, ...combinedDataSubProcess];

		const data_use_case_library = await this.dbService.data_use_case_library.toArray();
		let e2eSourceRelCount = 0;
		let processSourceRelCount = 0;
		let subProcessSourceRelCount = 0;

		data_use_case_library.forEach((useCase) => {
			if (useCase.sourceRel === '1') {
				e2eSourceRelCount += 1;
			} else if (useCase.sourceRel === '2') {
				processSourceRelCount += 1;
			} else if (useCase.sourceRel === '3') {
				subProcessSourceRelCount += 1;
			}
		});

		this.filteredUseCases = this.aiUseCases;
		this.updatePagedSolutions();
		// console.log('this.aiUseCases', this.aiUseCases);

		function getCount(impact: string, category: string): number {
			return countByImpactAndCategory[impact]?.[category] || 0;
		}

		this.counters = {
			genAiUseCases: e2eSourceRelCount + processSourceRelCount + subProcessSourceRelCount,
			e2eSourceRel: e2eSourceRelCount,
			processSourceRel: processSourceRelCount,
			subProcessSourceRel: subProcessSourceRelCount,
			process: await this.dbService.data_level1.count(),
			subProcess: await this.dbService.data_level2.count(),
			e2e: await this.dbService.data_function.count(),
			steps: 4066,
			aiOpportunities: countAiOpportunities,
			aiUseCases: await this.dbService.data_use_case_library.count(),
			breakthrough: {
				total: 
					countByImpactAndCategory['Breakthrough']
						? Object.values(countByImpactAndCategory['Breakthrough']).reduce((a, b) => a + b, 0)
						: 0,
				sales: getCount('Breakthrough', 'Sales and Customer Service'),
				marketing: getCount('Breakthrough', 'Marketing'),
				services: getCount('Breakthrough', 'Operations'),
				procurement: getCount('Breakthrough', 'Procurement'),
				finance: getCount('Breakthrough', 'Finance'),
				hr: getCount('Breakthrough', 'HR'),
				it: getCount('Breakthrough', 'IT'),
			},
			transformative: {
				total: 
					countByImpactAndCategory['Transformative']
						? Object.values(countByImpactAndCategory['Transformative']).reduce((a, b) => a + b, 0)
						: 0,
				sales: getCount('Transformative', 'Sales and Customer Service'),
				marketing: getCount('Transformative', 'Marketing'),
				services: getCount('Transformative', 'Operations'),
				procurement: getCount('Transformative', 'Procurement'),
				finance: getCount('Transformative', 'Finance'),
				hr: getCount('Transformative', 'HR'),
				it: getCount('Transformative', 'IT'),
			},
			incremental: {
				total: 
					countByImpactAndCategory['Incremental']
						? Object.values(countByImpactAndCategory['Incremental']).reduce((a, b) => a + b, 0)
						: 0,
				
				sales: getCount('Incremental', 'Sales and Customer Service'),
				marketing: getCount('Incremental', 'Marketing'),
				services: getCount('Incremental', 'Operations'),
				procurement: getCount('Incremental', 'Procurement'),
				finance: getCount('Incremental', 'Finance'),
				hr: getCount('Incremental', 'HR'),
				it: getCount('Incremental', 'IT'),
			},
		};

		this.simulationsUsesCases = (await this.dbService.data_use_case_simulation.toArray()).filter((d) => {
			if (organizationId) {
				return d.organizationId === organizationId;
			} else {
				return true;
			}
		});

		await this._loadSimulations(organizationId);

		this.loaded = true;
	}

	async _loadSimulations(organizationId?: string) {
		if (!organizationId) {
			organizationId = await this.authService.getCurrentOrganizationId();
		}

		const simulations = (await this.dbService.data_simulation.toArray()).filter((d) => {
			if (organizationId) {
				return d.organizationId === organizationId;
			} else {
				return true;
			}
		});

		this.simulations = [];

		const domParser = new DOMParser();

		for (const simulation of simulations) {
			if (simulation.taxonomyId && simulation.taxonomyRel) {
				switch (simulation.taxonomyRel) {
					case '1':
						const fun = await this.e2eTaxonomyService.getFunction(simulation.taxonomyId, true);
						if (fun) {
							simulation.label = fun.name;
							simulation.parent_label = fun.category.name;
						}
						break;
					case '2':
						const l1 = await this.e2eTaxonomyService.getLevel1(simulation.taxonomyId, true);
						if (l1) {
							simulation.label = l1.name;
							simulation.parent_label = l1.function.path;
						}
						break;
					case '3':
						const l2 = await this.e2eTaxonomyService.getLevel2(simulation.taxonomyId, true);
						if (l2) {
							simulation.label = l2.name;
							simulation.parent_label = l2.level1.path;
						}
						break;
				}
			} else {
				simulation.label = domParser.parseFromString(simulation.name, 'text/html').body.textContent || '';
			}
			simulation.use_cases = await this.dbService.data_use_case_simulation
				.where('simulationId')
				.equals(simulation.id as string)
				.toArray();
			this.simulations.push(simulation);
			this.setSimulationMenuItem(simulation);
		}
	}

	async _loadM1Preps(organizationId?: string) {
		if (!organizationId) {
			organizationId = await this.authService.getCurrentOrganizationId();
		}

		this.m1Preps = (await this.dbService.data_m1_prep.toArray()).filter((d) => {
			if (organizationId) {
				return d.organizationId === organizationId;
			} else {
				return true;
			}
		});
		this.m1Preps.map((m1Prep) => {
			this.setM1PrepMenuItem(m1Prep);
		});
	}

	safeHTML(html: string) {
		const parser = new DOMParser();
		const document = parser.parseFromString(html, 'text/html');
		return this.sanitizer.bypassSecurityTrustHtml(document.body.outerHTML);
	}

	sleep(ms: number) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	industryTreeSelections: TreeNode[] = [];
	industryTreeOptions: TreeNode[] = [];

	topServicesTreeSelections: TreeNode[] = [];
	topServicesTreeOptions: TreeNode[] = [];

	topProductsTreeSelections: TreeNode[] = [];
	topProductsTreeOptions: TreeNode[] = [];

	aiEnablers: Technology[] = [];
	aiEnablersTreeOptions: TreeNode[] = [];

	toolsTree: TreeNode[] = [];
	toolsTreeSelection: TreeNode[] = [];

	functionsTree: TreeNode[] = [];
	functionsTreeSelection: TreeNode[] = [];

	impactTree: TreeNode[] = [
		{ label: 'Breakthrough', data: 'Breakthrough' },
		{ label: 'Transformative', data: 'Transformative' },
		{ label: 'Incremental', data: 'Incremental' },
	];
	impactTreeSelection: TreeNode[] = [];

	suggestionsIndustry: any[] = [];
	suggestionsTopServices: any[] = [];
	suggestionsTopProducts: any[] = [];
	data_landscape: any[] = [];

	addPlatformCategory(category: any) {
		this.data_landscape.push(category);
	}

	loadPlatformCategories() {
		const savedData = localStorage.getItem('platformCategories');
		if (savedData) {
			this.data_landscape = JSON.parse(savedData);
		}
	}

	savePlatformCategories() {
		localStorage.setItem('platformCategories', JSON.stringify(this.data_landscape));
	}

	searchIndustry(event: { query: string }) {
		const search = new RegExp(event.query, 'i');
		const tags = this.industryTreeOptions.filter((t) => t.label && t.label.toLowerCase().search(search) >= 0);
		if (!tags.length) {
			tags.push({
				label: toCapitalize(event.query),
				key: event.query,
			});
		}
		this.suggestionsIndustry = tags;
	}

	searchProduct(event: { query: string }) {
		const search = new RegExp(event.query, 'i');
		const tags = this.topProductsTreeOptions.filter((t) => t.label && t.label.toLowerCase().search(search) >= 0);
		if (!tags.length) {
			tags.push({
				label: toCapitalize(event.query),
				key: event.query,
			});
		}
		this.suggestionsTopProducts = tags;
	}

	searchService(event: { query: string }) {
		const search = new RegExp(event.query, 'i');
		const tags = this.topServicesTreeOptions.filter((t) => t.label && t.label.toLowerCase().search(search) >= 0);
		if (!tags.length) {
			tags.push({
				label: toCapitalize(event.query),
				key: event.query,
			});
		}
		this.suggestionsTopServices = tags;
	}

	async updateEnterpriseContext() {
		this.industryTreeSelections.map((t) => {
			if (!this.industryTreeOptions.filter((d) => d.key === t.key).length) {
				this.industryTreeOptions.push(t);
			}
		});

		this.topProductsTreeSelections.map((t) => {
			if (!this.topProductsTreeOptions.filter((d) => d.key === t.key).length) {
				this.topProductsTreeOptions.push(t);
			}
		});

		this.topServicesTreeSelections.map((t) => {
			if (!this.topServicesTreeOptions.filter((d) => d.key === t.key).length) {
				this.topServicesTreeOptions.push(t);
			}
		});

		localStorage.setItem('industryTreeSelections', JSON.stringify(this.industryTreeOptions));
		localStorage.setItem('topProductsTreeSelections', JSON.stringify(this.topProductsTreeOptions));
		localStorage.setItem('topServicesTreeSelections', JSON.stringify(this.topServicesTreeOptions));
		localStorage.setItem('platformCategories', JSON.stringify(this.data_landscape));

		if (this.enterpriseContext) {
			this.enterpriseContext.enterpriseContext = {
				industry: this.industryTreeSelections.map((d) => d.key as string),
				revenue: this.revenueTreeSelections.key as string,
				description: this.descriptionSelection as string,
				employee: this.employeesTreeSelections.key as string,
				information: this.additionalInformationTreeSelections.map((d) => d.key as string),
				country: this.countriesTreeSelections.key as string,
				topProducts: this.topProductsTreeSelections.map((d) => d.key as string),
				topServices: this.topServicesTreeSelections.map((d) => d.key as string),
				data_landscape: this.data_landscape,
			};

			(this.enterpriseContext as any).enterprise_context = this.enterpriseContext.enterpriseContext;
			await this.dbService.data_enterprise_context.put(this.enterpriseContext);
			delete (this.enterpriseContext as any).enterprise_context;
			console.log(this.enterpriseContext, 'enterpriseContext');
		}
	}

	technologyTreeOptions: TreeNode[] = [];
	suggestionsTechnology: any[] = [];

	searchTechnology(event: { query: string }) {
		const search = new RegExp(event.query, 'i');
		const tags = this.technologyTreeOptions.filter((t) => t.label && t.label.toLowerCase().search(search) >= 0);
		if (!tags.length) {
			tags.push({
				label: toCapitalize(event.query),
				key: 'custom.' + event.query,
			});
		}
		this.suggestionsTechnology = tags;
	}

	businessValueDriversTreeOptions: TreeNode[] = [
		{
			label: 'Revenue Growth Analysis',
			key: 'revenue_growth',
		},
		{
			label: 'Customer Experience',
			key: 'customer_experience',
		},
		{
			label: 'Process Productivity',
			key: 'process_productivity',
		},
		{
			label: 'Employee Productivity',
			key: 'employee_productivity',
		},
		{
			label: 'Cost Savings',
			key: 'cost_savings',
		},
	];

	scopeTreeOptions: TreeNode[] = [
		{
			label: 'E2E Use Cases',
			key: '1',
		},
		{
			label: 'Process Use Cases',
			key: '2',
		},
		{
			label: 'SubProcess Use Cases',
			key: '3',
		},
	];

	impactOptions: OptionsSlider = {
		floor: 1,
		ceil: 10,
		step: 1,
		showTicks: false,
		showTicksValues: false,
	};

	revenueTree: TreeNode[] = [
		{
			label: '1M USD',
			key: '1',
		},
		{
			label: '10M USD',
			key: '2',
		},
		{
			label: '100M USD',
			key: '3',
		},
		{
			label: '1B USD',
			key: '4',
		},
		{
			label: '10B USD',
			key: '5',
		},
		{
			label: '100B USD',
			key: '6',
		},
	];
	revenueTreeSelections: TreeNode = this.revenueTree[0];
	revenueTreeOptions: TreeNode[] = [...this.revenueTree];

	employeesTree: TreeNode[] = [
		{
			label: '<100',
			key: '1',
		},
		{
			label: '<1000',
			key: '2',
		},
		{
			label: '<10000',
			key: '3',
		},
	];
	employeesTreeSelections: TreeNode = this.employeesTree[0];
	employeesTreeOptions: TreeNode[] = [...this.employeesTree];

	countriesTree: TreeNode[] = [
		{ label: 'None', key: '0' },
		{ label: 'Afghanistan', key: '1' },
		{ label: 'Albania', key: '2' },
		{ label: 'Algeria', key: '3' },
		{ label: 'Andorra', key: '4' },
		{ label: 'Angola', key: '5' },
		{ label: 'Antigua and Barbuda', key: '6' },
		{ label: 'Argentina', key: '7' },
		{ label: 'Armenia', key: '8' },
		{ label: 'Australia', key: '9' },
		{ label: 'Austria', key: '10' },
		{ label: 'Azerbaijan', key: '11' },
		{ label: 'Bahamas', key: '12' },
		{ label: 'Bahrain', key: '13' },
		{ label: 'Bangladesh', key: '14' },
		{ label: 'Barbados', key: '15' },
		{ label: 'Belarus', key: '16' },
		{ label: 'Belgium', key: '17' },
		{ label: 'Belize', key: '18' },
		{ label: 'Benin', key: '19' },
		{ label: 'Bhutan', key: '20' },
		{ label: 'Bolivia', key: '21' },
		{ label: 'Bosnia and Herzegovina', key: '22' },
		{ label: 'Botswana', key: '23' },
		{ label: 'Brazil', key: '24' },
		{ label: 'Brunei', key: '25' },
		{ label: 'Bulgaria', key: '26' },
		{ label: 'Burkina Faso', key: '27' },
		{ label: 'Burundi', key: '28' },
		{ label: 'Cabo Verde', key: '29' },
		{ label: 'Cambodia', key: '30' },
		{ label: 'Cameroon', key: '31' },
		{ label: 'Canada', key: '32' },
		{ label: 'Central African Republic', key: '33' },
		{ label: 'Chad', key: '34' },
		{ label: 'Chile', key: '35' },
		{ label: 'China', key: '36' },
		{ label: 'Colombia', key: '37' },
		{ label: 'Comoros', key: '38' },
		{ label: 'Congo, Democratic Republic of the', key: '39' },
		{ label: 'Congo, Republic of the', key: '40' },
		{ label: 'Costa Rica', key: '41' },
		{ label: 'Croatia', key: '42' },
		{ label: 'Cuba', key: '43' },
		{ label: 'Cyprus', key: '44' },
		{ label: 'Czech Republic', key: '45' },
		{ label: 'Denmark', key: '46' },
		{ label: 'Djibouti', key: '47' },
		{ label: 'Dominica', key: '48' },
		{ label: 'Dominican Republic', key: '49' },
		{ label: 'East Timor', key: '50' },
		{ label: 'Ecuador', key: '51' },
		{ label: 'Egypt', key: '52' },
		{ label: 'El Salvador', key: '53' },
		{ label: 'Equatorial Guinea', key: '54' },
		{ label: 'Eritrea', key: '55' },
		{ label: 'Estonia', key: '56' },
		{ label: 'Eswatini', key: '57' },
		{ label: 'Ethiopia', key: '58' },
		{ label: 'Fiji', key: '59' },
		{ label: 'Finland', key: '60' },
		{ label: 'France', key: '61' },
		{ label: 'Gabon', key: '62' },
		{ label: 'Gambia', key: '63' },
		{ label: 'Georgia', key: '64' },
		{ label: 'Germany', key: '65' },
		{ label: 'Ghana', key: '66' },
		{ label: 'Greece', key: '67' },
		{ label: 'Grenada', key: '68' },
		{ label: 'Guatemala', key: '69' },
		{ label: 'Guinea', key: '70' },
		{ label: 'Guinea-Bissau', key: '71' },
		{ label: 'Guyana', key: '72' },
		{ label: 'Haiti', key: '73' },
		{ label: 'Honduras', key: '74' },
		{ label: 'Hungary', key: '75' },
		{ label: 'Iceland', key: '76' },
		{ label: 'India', key: '77' },
		{ label: 'Indonesia', key: '78' },
		{ label: 'Iran', key: '79' },
		{ label: 'Iraq', key: '80' },
		{ label: 'Ireland', key: '81' },
		{ label: 'Israel', key: '82' },
		{ label: 'Italy', key: '83' },
		{ label: 'Jamaica', key: '84' },
		{ label: 'Japan', key: '85' },
		{ label: 'Jordan', key: '86' },
		{ label: 'Kazakhstan', key: '87' },
		{ label: 'Kenya', key: '88' },
		{ label: 'Kiribati', key: '89' },
		{ label: 'Korea, North', key: '90' },
		{ label: 'Korea, South', key: '91' },
		{ label: 'Kosovo', key: '92' },
		{ label: 'Kuwait', key: '93' },
		{ label: 'Kyrgyzstan', key: '94' },
		{ label: 'Laos', key: '95' },
		{ label: 'Latvia', key: '96' },
		{ label: 'Lebanon', key: '97' },
		{ label: 'Lesotho', key: '98' },
		{ label: 'Liberia', key: '99' },
		{ label: 'Libya', key: '100' },
		{ label: 'Liechtenstein', key: '101' },
		{ label: 'Lithuania', key: '102' },
		{ label: 'Luxembourg', key: '103' },
		{ label: 'Madagascar', key: '104' },
		{ label: 'Malawi', key: '105' },
		{ label: 'Malaysia', key: '106' },
		{ label: 'Maldives', key: '107' },
		{ label: 'Mali', key: '108' },
		{ label: 'Malta', key: '109' },
		{ label: 'Marshall Islands', key: '110' },
		{ label: 'Mauritania', key: '111' },
		{ label: 'Mauritius', key: '112' },
		{ label: 'Mexico', key: '113' },
		{ label: 'Micronesia', key: '114' },
		{ label: 'Moldova', key: '115' },
		{ label: 'Monaco', key: '116' },
		{ label: 'Mongolia', key: '117' },
		{ label: 'Montenegro', key: '118' },
		{ label: 'Morocco', key: '119' },
		{ label: 'Mozambique', key: '120' },
		{ label: 'Myanmar', key: '121' },
		{ label: 'Namibia', key: '122' },
		{ label: 'Nauru', key: '123' },
		{ label: 'Nepal', key: '124' },
		{ label: 'Netherlands', key: '125' },
		{ label: 'New Zealand', key: '126' },
		{ label: 'Nicaragua', key: '127' },
		{ label: 'Niger', key: '128' },
		{ label: 'Nigeria', key: '129' },
		{ label: 'North Macedonia', key: '130' },
		{ label: 'Norway', key: '131' },
		{ label: 'Oman', key: '132' },
		{ label: 'Pakistan', key: '133' },
		{ label: 'Palau', key: '134' },
		{ label: 'Panama', key: '135' },
		{ label: 'Papua New Guinea', key: '136' },
		{ label: 'Paraguay', key: '137' },
		{ label: 'Peru', key: '138' },
		{ label: 'Philippines', key: '139' },
		{ label: 'Poland', key: '140' },
		{ label: 'Portugal', key: '141' },
		{ label: 'Qatar', key: '142' },
		{ label: 'Romania', key: '143' },
		{ label: 'Russia', key: '144' },
		{ label: 'Rwanda', key: '145' },
		{ label: 'Saint Kitts and Nevis', key: '146' },
		{ label: 'Saint Lucia', key: '147' },
		{ label: 'Saint Vincent and the Grenadines', key: '148' },
		{ label: 'Samoa', key: '149' },
		{ label: 'San Marino', key: '150' },
		{ label: 'Sao Tome and Principe', key: '151' },
		{ label: 'Saudi Arabia', key: '152' },
		{ label: 'Senegal', key: '153' },
		{ label: 'Serbia', key: '154' },
		{ label: 'Seychelles', key: '155' },
		{ label: 'Sierra Leone', key: '156' },
		{ label: 'Singapore', key: '157' },
		{ label: 'Slovakia', key: '158' },
		{ label: 'Slovenia', key: '159' },
		{ label: 'Solomon Islands', key: '160' },
		{ label: 'Somalia', key: '161' },
		{ label: 'South Africa', key: '162' },
		{ label: 'South Sudan', key: '163' },
		{ label: 'Spain', key: '164' },
		{ label: 'Sri Lanka', key: '165' },
		{ label: 'Sudan', key: '166' },
		{ label: 'Suriname', key: '167' },
		{ label: 'Sweden', key: '168' },
		{ label: 'Switzerland', key: '169' },
		{ label: 'Syria', key: '170' },
		{ label: 'Taiwan', key: '171' },
		{ label: 'Tajikistan', key: '172' },
		{ label: 'Tanzania', key: '173' },
		{ label: 'Thailand', key: '174' },
		{ label: 'Togo', key: '175' },
		{ label: 'Tonga', key: '176' },
		{ label: 'Trinidad and Tobago', key: '177' },
		{ label: 'Tunisia', key: '178' },
		{ label: 'Turkey', key: '179' },
		{ label: 'Turkmenistan', key: '180' },
		{ label: 'Tuvalu', key: '181' },
		{ label: 'Uganda', key: '182' },
		{ label: 'Ukraine', key: '183' },
		{ label: 'United Arab Emirates', key: '184' },
		{ label: 'United Kingdom', key: '185' },
		{ label: 'United States', key: '186' },
		{ label: 'Uruguay', key: '187' },
		{ label: 'Uzbekistan', key: '188' },
		{ label: 'Vanuatu', key: '189' },
		{ label: 'Vatican City', key: '190' },
		{ label: 'Venezuela', key: '191' },
		{ label: 'Vietnam', key: '192' },
		{ label: 'Yemen', key: '193' },
		{ label: 'Zambia', key: '194' },
		{ label: 'Zimbabwe', key: '195' },
	];

	countriesTreeSelections: TreeNode = this.countriesTree[0];
	countriesTreeOptions: TreeNode[] = [...this.countriesTree];

	additionalInformationTree: TreeNode[] = [
		{
			label: 'SEC Filings',
			key: '1',
		},
		{
			label: 'Annual Revenue',
			key: '2',
		},
		{
			label: 'Latest News',
			key: '3',
		},
		{
			label: 'Industry Trends',
			key: '4',
		},
		{
			label: 'Competitor Analysis',
			key: '5',
		},
		{
			label: 'Regulatory Environment',
			key: '6',
		},
		{
			label: 'Financial Health Indicators',
			key: '7',
		},
		{
			label: 'Customer Insights',
			key: '8',
		},
	];
	additionalInformationTreeSelections: TreeNode[] = [...this.additionalInformationTree];
	additionalInformationTreeOptions: TreeNode[] = [...this.additionalInformationTree];

	descriptionSelection: string = '';

	async getEnterpriseContext() {
		const organizationId: string = await this.authService.getCurrentOrganizationId();
		let context = await this.dbService.data_enterprise_context.get({ organizationId });

		if (!context) {
			context = new EnterpriseContext(
				{
					industry: [],
					revenue: '',
					information: [],
					employee: '',
					country: '',
					topProducts: [],
					topServices: [],
					description: '',
					data_landscape: [],
				},
				organizationId,
			);
			await this.dbService.data_enterprise_context.put(context);
		}

		this.enterpriseContext = context;

		this.industryTreeSelections = this.industryTreeOptions.filter((d) =>
			this.enterpriseContext?.enterpriseContext.industry.includes(d.key as string),
		);
		this.revenueTreeSelections =
			this.revenueTreeOptions.find((d) => this.enterpriseContext?.enterpriseContext.revenue === d.key) ??
			this.revenueTree[0];
		this.employeesTreeSelections =
			this.employeesTreeOptions.find((d) => this.enterpriseContext?.enterpriseContext.employee === d.key) ??
			this.employeesTree[0];
		this.additionalInformationTreeSelections = this.additionalInformationTreeOptions.filter((d) =>
			this.enterpriseContext?.enterpriseContext.information.includes(d.key as string),
		);
		this.countriesTreeSelections =
			this.countriesTreeOptions.find((d) => this.enterpriseContext?.enterpriseContext?.country === d.key) ??
			this.countriesTree[0];

		this.topProductsTreeSelections =
			this.topProductsTreeOptions?.filter((d) =>
				(this.enterpriseContext?.enterpriseContext?.topProducts ?? []).includes(d.key as string),
			) ?? [];

		this.topServicesTreeSelections =
			this.topServicesTreeOptions?.filter((d) =>
				(this.enterpriseContext?.enterpriseContext?.topServices ?? []).includes(d.key as string),
			) ?? [];

		this.descriptionSelection = this.enterpriseContext?.enterpriseContext?.description ?? '';
		this.data_landscape = this.enterpriseContext?.enterpriseContext?.data_landscape ?? [];
	}

	addProcessDialog: boolean = false;

	addProcessTab: number = 0;

	addProcessTaxonomyTreeSelect: TreeNode[] = [];
	addProcessTaxonomyTreeSelected: TreeNode[] = [];

	addProcessTaxonomyScopeOptions: TreeNode[] = [
		{ label: 'E2E', key: '1' },
		{ label: 'Process', key: '2' },
		{ label: 'SubProcess', key: '3' },
	];
	addProcessTaxonomyScope: TreeNode =
		this.addProcessTaxonomyScopeOptions[this.addProcessTaxonomyScopeOptions.length - 1];

	addProcessForm: { name: string; description: string } = { name: '', description: '' };

	blockedAddProcess: boolean = false;
	blockedAddProcessGo: boolean = false;

	async addProcess(go: boolean = false) {
		if (go) {
			this.blockedAddProcessGo = true;
		} else {
			this.blockedAddProcess = true;
		}

		const organizationId: string = await this.authService.getCurrentOrganizationId();

		let simulation: Simulation | undefined = undefined;

		if (this.enterpriseContext) {
			if (this.addProcessTab) {
				simulation = new Simulation(
					this.addProcessForm.name,
					this.addProcessForm.description,
					'',
					true,
					true,
					this.enterpriseContext.enterpriseContext,
					this.defaultSimulationContext,
					organizationId,
				);
				await this.saveSimulation(simulation);
				simulation.label = simulation.name;
				this.setSimulationMenuItem(simulation);
				this.simulations = [simulation, ...this.simulations];
			} else {
				const ids = this.addProcessTaxonomyTreeSelected.map((t) => t.key as string);
				switch (this.addProcessTaxonomyScope.key) {
					case '1':
						for (const fn of this.taxonomy
							.map((cf) => cf.functions)
							.flat()
							.filter((fn) => ids.includes(fn.id as string))) {
							simulation = new Simulation(
								fn.name,
								fn.description,
								'',
								true,
								true,
								this.enterpriseContext.enterpriseContext,
								this.defaultSimulationContext,
								organizationId,
								fn.id as string,
								'1',
							);
							await this.saveSimulation(simulation);
							simulation.label = fn.name;
							simulation.parent_label = fn.category.name;
							this.setSimulationMenuItem(simulation);
							this.simulations = [simulation, ...this.simulations];
						}
						break;
					case '2':
						for (const level1 of this.taxonomy
							.map((cf) => cf.functions)
							.flat()
							.map((f) => f.levels1)
							.flat()
							.filter((l1) => ids.includes(l1.id as string))) {
							simulation = new Simulation(
								level1.name,
								level1.description,
								'',
								true,
								true,
								this.enterpriseContext.enterpriseContext,
								this.defaultSimulationContext,
								organizationId,
								level1.id as string,
								'2',
							);
							await this.saveSimulation(simulation);
							simulation.label = level1.name;
							simulation.parent_label = level1.function.path;
							this.setSimulationMenuItem(simulation);
							this.simulations = [simulation, ...this.simulations];
						}
						break;
					case '3':
						for (const level2 of this.taxonomy
							.map((cf) => cf.functions)
							.flat()
							.map((f) => f.levels1)
							.flat()
							.map((l1) => l1.levels2)
							.flat()
							.filter((l2) => ids.includes(l2.id as string))) {
							simulation = new Simulation(
								level2.name,
								level2.description,
								'',
								true,
								true,
								this.enterpriseContext.enterpriseContext,
								this.defaultSimulationContext,
								organizationId,
								level2.id as string,
								'3',
							);
							await this.saveSimulation(simulation);
							simulation.label = level2.name;
							simulation.parent_label = level2.level1.path;
							this.setSimulationMenuItem(simulation);
							this.simulations = [simulation, ...this.simulations];
						}
						break;
				}
			}
		}

		this.blockedAddProcess = false;
		this.blockedAddProcessGo = false;

		this.addProcessTab = 0;
		this.addProcessForm = { name: '', description: '' };
		this.addProcessTaxonomyTreeSelected = [];
		this.addProcessTaxonomyScope =
			this.addProcessTaxonomyScopeOptions[this.addProcessTaxonomyScopeOptions.length - 1];

		this.addProcessDialog = false;

		if (go && simulation) {
			this.goToSimulation(simulation);
		}
	}

	async saveSimulation(simulation: Simulation) {
		if (simulation.enterpriseContext && !simulation.enterpriseContext.country) {
			simulation.enterpriseContext.country = this.countriesTreeSelections.label as string;
		}
		const obj: any = { ...simulation };
		obj.organization_id = simulation.organizationId;
		if (simulation.taxonomyId) {
			obj.taxonomy_id = simulation.taxonomyId;
		}
		if (simulation.taxonomyRel) {
			obj.taxonomy_rel = simulation.taxonomyRel;
		}
		obj.enterprise_context = simulation.enterpriseContext;
		obj.simulation_context = simulation.simulationContext;
		await this.dbService.data_simulation.put(obj);
	}

	async deleteSimulation(simulation: Simulation) {
		await this.dbService.data_simulation.delete(simulation.id as string);
		this.simulations = this.simulations.filter((c) => c.id !== simulation.id);
	}

	async deleteM1Prep(m1Prep: M1Prep) {
		await this.dbService.data_m1_prep.delete(m1Prep.id as string);
		this.m1Preps = this.m1Preps.filter((c) => c.id !== m1Prep.id);
	}

	goToSimulation(simulation: Simulation) {
		this.router.navigateByUrl('/simulation/detail/' + simulation.id);
	}

	async getSimulation(id: string): Promise<Simulation | undefined> {
		if (!this.loaded) {
			await this.onLoad();
		}
		return this.simulations.find((s) => s.id === id);
	}

	async getUseCaseSimulation(id: string): Promise<UseCaseSimulation | undefined> {
		if (!this.loaded) {
			await this.onLoad();
		}
		return this.simulationsUsesCases.find((s) => s.id === id);
	}

	setSimulationMenuItem(simulation: Simulation) {
		this.simulationsMenu[simulation.id as string] = [
			{
				label: 'Delete',
				icon: 'pi pi-fw pi-trash',
				command: () => {
					this.confirmationService.confirm({
						header: 'Delete the process?',
						message: 'This will delete the process from the simulations.',
						icon: 'pi pi-exclamation-triangle',
						accept: () => {
							this.deleteSimulation(simulation);
						},
					});
				},
			},
		];
	}

	setM1PrepMenuItem(m1Prep: M1Prep) {
		this.m1PrepMenu[m1Prep.id as string] = [
			{
				label: 'Delete',
				icon: 'pi pi-fw pi-trash',
				command: () => {
					this.confirmationService.confirm({
						header: 'Delete the M1 Prep data?',
						message: 'This will delete this M1 Prep data.',
						icon: 'pi pi-exclamation-triangle',
						accept: () => {
							this.deleteM1Prep(m1Prep);
						},
					});
				},
			},
		];
	}

	async saveM1Prep(m1PrepData: M1Prep): Promise<void> {
		console.log('Attempting to save M1Prep data', m1PrepData);
		(m1PrepData as any).company_analysis = m1PrepData.companyAnalysis;
		(m1PrepData as any).competitive_landscape = m1PrepData.competitiveLandscape;
		try {
			const result = await this.dbService.data_m1_prep.put(m1PrepData);
			console.log('M1Prep saved, result ID:', result);
		} catch (error) {
			console.error('Error saving M1Prep:', error);
		}
	}

	async saveUseCase(use_case: UseCaseSimulation) {
		const obj: any = { ...use_case };
		obj.organization_id = use_case.organizationId;
		obj.simulation_id = use_case.simulationId;
		obj.key_features = use_case.keyFeatures;
		if (use_case.deployedId) {
			obj.deployed_id = use_case.deployedId;
		}
		await this.dbService.data_use_case_simulation.put(obj);
	}

	async deleteUseCase(use_case: UseCaseSimulation) {
		await this.dbService.data_use_case_simulation.delete(use_case.id as string);
	}

	async clearUseCaseSimulation(simulation: Simulation) {
		simulation.use_cases.forEach((useCase) => {
			this.deleteUseCase(useCase).then(() => {});
		});

		simulation.use_cases = [];
	}

	selectSimulation(simulation: Simulation) {
		this.selectedSimulationSubject.next(simulation);
	}

	selectSimulationStar(simulation: Simulation) {
		this.selectedSimulationStarSubject.next(simulation);
	}

	selectM1Prep(m1Prep: M1Prep) {
		this.selectedM1PrepSubject.next(m1Prep);
	}
}
