import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { LayoutService } from '../../../layout/service/app.layout.service';
import { SimulationService } from './simulation.service';
import { HttpClient } from '@angular/common/http';
import { UseCasesService } from './use-cases.service';
import { AuthService } from '../../service/auth.service';
import { M1Prep, Simulation } from '../../api/simulation.api';
import { Location } from '@angular/common';

@Component({
	templateUrl: './simulation.component.html',
	styleUrls: ['./simulation.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class SimulationComponent implements OnInit {
	constructor(
		private http: HttpClient,
		private layoutService: LayoutService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public authService: AuthService,
		public simulationService: SimulationService,
		public useCasesService: UseCasesService,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
	) {}

	@Input() selectionView: number = 0;

	navigateToPromptStudio(): void {
		this.selectionView = 3;
	  }
  
	  goToPromptStudio(): void {
		  this.selectionView = 3;
		  this.changeSelectionView();
	  }

	async ngOnInit() {
		this.route.queryParams.subscribe((params) => {
			if (params['NavTab']) {
				this.selectionView = +params['NavTab'];
				this.clearNavTabQueryParam();
			}
		});

		//this.simulationService.simulationsUsesCases
		this.layoutService.updateTitle('Simulation');

		this.simulationService.confirmationService = this.confirmationService;

		if (!this.simulationService.loaded) {
			await this.simulationService.onLoad();
		}

		this.useCasesService.confirmationService = this.confirmationService;
		this.useCasesService.messageService = this.messageService;

		if (!this.useCasesService.init) {
			this.useCasesService.loading = true;
			await this.useCasesService.prepareData();
		}
	}

	clearNavTabQueryParam() {
		const urlTree = this.router.createUrlTree([], {
			relativeTo: this.route,
			queryParams: { NavTab: null },
			queryParamsHandling: 'merge',
		});
		const url = this.router.serializeUrl(urlTree);
		this.location.go(url);
	}

	filterIndex: number = 0;
	useCaseFilter: number = -1;

	getClass(value: string): string {
		switch (value) {
			case 'High':
				return 'background-high';
			case 'Medium':
				return 'background-medium';
			case 'Low':
				return 'background-low';
			case 'None':
				return 'background-none';
			default:
				return '';
		}
	}

	changeFilter(i: number) {
		if (i === this.filterIndex) {
			this.filterIndex = 0;
		} else {
			this.filterIndex = i;
		}
	}

	aiEnablersTreeSelections: TreeNode[] = [];
	aiEnablersTreeOptions: TreeNode[] = [];

	scopeTree: TreeNode[] = [
		{
			label: 'E2E',
			key: '1',
		},
		{
			label: 'Process',
			key: '2',
		},
		{
			label: 'SubProcess',
			key: '3',
		},
	];
	scopeTreeSelections: TreeNode[] = [...this.scopeTree];
	scopeTreeOptions: TreeNode[] = [...this.scopeTree];

	impactTree: TreeNode[] = [
		{
			label: 'Breakthrough',
			key: '1',
		},
		{
			label: 'Transformative',
			key: '2',
		},
	];
	impactTreeSelections: TreeNode[] = [...this.impactTree];
	impactTreeOptions: TreeNode[] = [...this.impactTree];

	technologyTree: TreeNode[] = [
		{
			label: 'OneStream',
			key: '1',
		},
		{
			label: 'SAP',
			key: '2',
		},
	];
	technologyTreeSelections: TreeNode[] = [...this.technologyTree];
	technologyTreeOptions: TreeNode[] = [...this.technologyTree];

	benefitsTree: TreeNode[] = [
		{
			label: 'Savings',
			key: '1',
		},
		{
			label: 'Automation',
			key: '2',
		},
	];
	benefitsTreeSelections: TreeNode[] = [...this.benefitsTree];
	benefitsTreeOptions: TreeNode[] = [...this.benefitsTree];

	addProcessDiagram() {
		this.simulationService.addProcessTab = 0;
		this.simulationService.addProcessForm = { name: '', description: '' };
		this.simulationService.addProcessTaxonomyTreeSelected = [];
		this.simulationService.addProcessTaxonomyScope =
			this.simulationService.addProcessTaxonomyScopeOptions[
				this.simulationService.addProcessTaxonomyScopeOptions.length - 1
			];
		this.simulationService.addProcessDialog = true;
	}

	selectionViewOptions: { value: number }[] = [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }];

	changeSelectionView() {
		this.filterIndex = 0;
		this.useCaseFilter = -1;
	}

	showOpenSimulation: boolean = false;

	openSimulation() {
		this.simulationService._loadSimulations().then(() => {
			this.showOpenSimulation = true;
		});
	}

	selectSimulation(simulation: Simulation) {
		this.showOpenSimulation = false;

		this.simulationService.selectSimulation(simulation);
	}

	showOpenM1Prep: boolean = false;

	openM1Prep() {
		this.simulationService._loadM1Preps().then(() => {
			this.showOpenM1Prep = true;
		});
	}


	selectM1Prep(m1Prep: M1Prep) {
		this.showOpenM1Prep = false;

		this.simulationService.selectM1Prep(m1Prep);
	}
}
