/* eslint-disable @angular-eslint/directive-selector, @angular-eslint/no-input-rename */
import { Directive, Input, OnInit, ElementRef } from '@angular/core';
import tippy, { Props, Content, Placement, Instance } from 'tippy.js';

@Directive({
	selector: '[tippy]',
})
export class TippyDirective implements OnInit {
	@Input('tippyPlacement') public tippyPlacement: Placement;
	@Input('tippyHTML') public tippyHTML: boolean;
	@Input('tippySlider') public tippySlider: boolean;
	@Input('tippyAppendTo') public tippyAppendTo: 'parent' | Element | ((ref: Element) => Element);
	@Input('tippyOptions') public tippyOptions: Partial<Props>;

	public _tippyContent: Content = '';
	public _tippyShow: boolean = true;
	public instance: Instance;

	@Input('tippyContent')
	set tippyContent(value: Content) {
		this._tippyContent = value;
	}

	@Input('tippyShow')
	set tippyShow(value: boolean) {
		if (value !== undefined) {
			if (value !== this._tippyShow) {
				this._tippyShow = value;
			}
		} else {
			this._tippyShow = true;
		}
		if (!this._tippyShow && !!this.instance) {
			this.instance.hide();
		}
	}

	constructor(public el: ElementRef) {
		this.el = el;
	}

	onShow(instance: Instance): void | false {
		if (!this._tippyContent) {
			return false;
		}
		instance.setContent(this._tippyContent);
		if (!this._tippyShow) {
			return false;
		}
		return;
	}

	public ngOnInit() {
		const options = this.tippyOptions || {};
		if (this._tippyContent) {
			options.content = this._tippyContent;
		}
		if (this.tippyPlacement) {
			options.placement = this.tippyPlacement;
		}
		if (this.tippyAppendTo) {
			options.appendTo = this.tippyAppendTo;
		}
		options.allowHTML = this.tippyHTML;
		options.onShow = (instance) => this.onShow(instance);
		let el = this.el.nativeElement;
		if (this.tippySlider) {
			el = this.el.nativeElement.querySelector('.ngx-slider-span.ngx-slider-pointer.ngx-slider-pointer-min');
		}
		this.instance = tippy(el as HTMLElement, options);
	}
}
