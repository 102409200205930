<svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M1.24526 2.93303C1.933 2.93303 2.49053 2.38838 2.49053 1.71652C2.49053 1.04465 1.933 0.5 1.24526 0.5C0.557524 0.5 0 1.04465 0 1.71652C0 2.38838 0.557524 2.93303 1.24526 2.93303Z"
        fill="#1B54F8"
    />
    <path
        d="M17.9989 0.765625H4.9326C4.37965 0.765625 3.9314 1.20353 3.9314 1.74372V1.74439C3.9314 2.28458 4.37965 2.72248 4.9326 2.72248H17.9989C18.5518 2.72248 19.0001 2.28458 19.0001 1.74439V1.74372C19.0001 1.20353 18.5518 0.765625 17.9989 0.765625Z"
        fill="#1B54F8"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.42193 6.98409C6.42193 7.65596 5.8644 8.20061 5.17666 8.20061C4.48892 8.20061 3.9314 7.65596 3.9314 6.98409C3.9314 6.31223 4.48892 5.76758 5.17666 5.76758C5.8644 5.76758 6.42193 6.31223 6.42193 6.98409ZM8.863 6.03254H17.9986C18.5516 6.03254 18.9998 6.47044 18.9998 7.01063V7.0113C18.9998 7.55149 18.5516 7.98939 17.9986 7.98939H8.863C8.31005 7.98939 7.8618 7.55149 7.8618 7.0113V7.01063C7.8618 6.47044 8.31005 6.03254 8.863 6.03254Z"
        fill="#1B54F8"
    />
    <path
        d="M9.10794 13.4994C9.79568 13.4994 10.3532 12.9548 10.3532 12.2829C10.3532 11.6111 9.79568 11.0664 9.10794 11.0664C8.42019 11.0664 7.86267 11.6111 7.86267 12.2829C7.86267 12.9548 8.42019 13.4994 9.10794 13.4994Z"
        fill="#C5C6E3"
    />
    <path
        d="M17.9988 11.332H12.7945C12.2415 11.332 11.7933 11.7699 11.7933 12.3101V12.3108C11.7933 12.851 12.2415 13.2889 12.7945 13.2889H17.9988C18.5517 13.2889 19 12.851 19 12.3108V12.3101C19 11.7699 18.5517 11.332 17.9988 11.332Z"
        fill="#C5C6E3"
    />
</svg>
