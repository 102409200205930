import { Injectable } from '@angular/core';
import {
	CategoryQuestion,
	Flow,
	ISolution,
	Solution,
	Question,
	Tool,
	AiLever,
	SubCategoryQuestion,
	FunctionFilter,
	Skill,
	Icon,
} from '../api/solution.api';
import { v4 as uuid } from 'uuid';
import deepEqual from 'deep-equal';
import { plainToInstance, instanceToInstance } from 'class-transformer';
import { DbService } from './db.service';
import { E2ETaxonomyService } from './e2e-taxonomy.service';
import { createdAt } from './services.data/utils.data';
import { TreeNode } from 'primeng/api';
import { CommonService } from './common.service';
import { DiagramBaseService } from './base.service';
import { DialogService } from 'primeng/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

/**
 * Service class for managing solutions and flows.
 */
@Injectable({
	providedIn: 'root',
})
export class SolutionService extends DiagramBaseService<Flow> {
	constructor(
		public commonService: CommonService,
		public e2ETaxonomyService: E2ETaxonomyService,
		public dbService: DbService,
		dialogService: DialogService,
		http: HttpClient,
	) {
		super(dialogService, http, dbService.get_urls_hook('flow'), dbService.data_flow, Flow);

		this.currentDiagramSource$.subscribe(() => {
			if (this.currentSolution && this.currentDiagram) {
				this.currentSolution.diagram = this.currentDiagram;
			}
		});
	}

	currentSolution: Solution | undefined;
	currentOriginalSolution: Solution | undefined;

	/**
	 * Creates a new instance of Solution.
	 *
	 * @returns {Solution} - The newly created Solution instance.
	 */
	_newSolution(): Solution {
		return plainToInstance(Solution, {
			id: uuid(),
			created_at: createdAt(),
			name: '',
			description: '',
			summary: '',
			keyFeatures: [],
			functionsIds: [],
			technologiesIds: [],
			levels2Ids: [],
			technologiesSolutionIds: [],
			toolsId: [],
			skillsId: [],
		});
	}

	/**
	 * Creates a new Flow object with the specified solution.
	 *
	 * @param {ISolution} solution - The solution object to be associated with the Flow.
	 * @returns {Flow} - The newly created Flow object.
	 */
	_newFlow(solution: ISolution): Flow {
		return plainToInstance(Flow, {
			solution,
			solutionId: solution.id || '',
			...super._newDiagramBase(),
		});
	}

	/**
	 * Retrieves a list of tools as an array of tree nodes.
	 * Each tree node contains a unique identifier (key) and a label representing the tool's name.
	 *
	 * @returns {Promise<TreeNode[]>} A Promise that resolves to an array of tree nodes representing the tools.
	 */
	async getToolAsTreeNode(): Promise<TreeNode[]> {
		return (await this.dbService.data_tool.toArray()).map((t) => ({
			key: t.id,
			label: t.name,
		}));
	}

	/**
	 * Retrieves the skills as tree nodes.
	 *
	 * @return {Promise<TreeNode[]>} A promise that resolves to an array of tree nodes representing the skills.
	 */
	async getSkillAsTreeNode(): Promise<TreeNode[]> {
		return (await this.dbService.data_skill.toArray()).map((s) => ({
			key: s.id,
			label: s.name,
		}));
	}

	/**
	 * Retrieves the AiLever data from the database.
	 *
	 * @returns {Promise<AiLever[]>} A promise that resolves with an array of AiLever objects retrieved from the database.
	 */
	async getAiLever(): Promise<AiLever[]> {
		return this.dbService.data_aiLever.toArray();
	}

	/**
	 * Retrieves AI lever data as tree nodes.
	 *
	 * @returns {Promise<TreeNode[]>} The AI lever data as tree nodes.
	 */
	async getAiLeverAsTreeNode(): Promise<TreeNode[]> {
		return (await this.dbService.data_aiLever.toArray()).map((a) => ({
			key: a.id,
			label: a.name,
		}));
	}

	/**
	 * Retrieves the functions from the database and returns them sorted by name.
	 *
	 * @returns {Promise<FunctionFilter[]>} A promise that resolves to an array of functions.
	 */
	async getFunctions(): Promise<FunctionFilter[]> {
		return this.dbService.data_function_filter.toCollection().sortBy('name');
	}

	/**
	 * Retrieves an array of function filters based on the provided ids.
	 *
	 * @async
	 * @param {string[]} ids - An array containing the ids of the function filters to retrieve.
	 * @return {Promise<FunctionFilter[]>} - A promise that resolves with an array of retrieved function filters. If no ids are provided or if no function filters are found, an empty
	 * array will be returned.
	 */
	async getFunctionsFilterByIds(ids: string[]): Promise<FunctionFilter[]> {
		if (!ids || !ids.length) return [];
		return this.dbService.data_function_filter.toArray().then((data) => {
			return data.filter((d) => ids.includes(d.id || '')).sort((a, b) => a.name.localeCompare(b.name));
		});
	}

	/**
	 * Retrieves tools by their IDs
	 *
	 * @param {string[]} ids - The array of tool IDs to retrieve
	 * @return {Promise<Tool[]>} - A promise that resolves with an array of Tool objects
	 */
	async getToolsByIds(ids: string[]): Promise<Tool[]> {
		if (!ids || !ids.length) return [];
		return this.dbService.data_tool.toArray().then((data) => {
			return data.filter((d) => ids.includes(d.id || '')).sort((a, b) => a.name.localeCompare(b.name));
		});
	}

	/**
	 * Retrieves skills by their ids.
	 *
	 * @async
	 * @param {string[]} ids - An array of skill IDs.
	 * @return {Promise<Skill[]>} A promise that resolves with an array of skills.
	 */
	async getSkillsByIds(ids: string[]): Promise<Skill[]> {
		if (!ids || !ids.length) return [];
		return this.dbService.data_skill.toArray().then((data) => {
			return data.filter((d) => ids.includes(d.id || '')).sort((a, b) => a.name.localeCompare(b.name));
		});
	}

	/**
	 * Retrieves AI Levers by their IDs.
	 *
	 * @param {string[]} ids - An array of IDs of AI Levers.
	 * @return {Promise<AiLever[]>} - A Promise that resolves with an array of AI Levers matching the provided IDs. If no IDs are provided, an empty array is returned.
	 */
	async getAiLeversByIds(ids: string[]): Promise<AiLever[]> {
		if (!ids || !ids.length) return [];
		return this.dbService.data_aiLever.toArray().then((data) => {
			return data.filter((d) => ids.includes(d.id || '')).sort((a, b) => a.name.localeCompare(b.name));
		});
	}

	/**
	 * Retrieves solutions from the database.
	 *
	 * @param {boolean} relations - Indicates whether to include relational data for the solutions.
	 *
	 * @return {Promise<Solution[]>} - A promise that resolves with an array of solutions from the database.
	 */
	async getSolutions(relations: boolean = true): Promise<Solution[]> {
		return this.dbService.data_solution
			.toCollection()
			.reverse()
			.sortBy('created_at')
			.then(async (data) => {
				if (relations) {
					for (const s of data) {
						await this.getRelationSolution(s);
					}
				}
				for (const s of data) {
					if (!s.aiDrivers) {
						s.aiDrivers = {
							benefits: [],
							timeToImplement: '',
							effortToImplement: '',
							technology: [],
						};
					}
				}
				return data;
			});
	}

	/**
	 * Retrieves the categories of questions.
	 *
	 * @return {Promise<CategoryQuestion[]>} A promise that resolves with an array of CategoryQuestion objects.
	 */
	async getCategoriesQuestion(): Promise<CategoryQuestion[]> {
		return this.dbService.data_category_question.toCollection().sortBy('sort');
	}

	/**
	 * Retrieves the subcategory questions for a given category ID.
	 *
	 * @param {string} categoryId - The ID of the category to get subcategory questions for.
	 * @return {Promise<SubCategoryQuestion[]>} A promise that resolves with an array of subcategory questions.
	 */
	async getSubCategoriesQuestion(categoryId: string): Promise<SubCategoryQuestion[]> {
		return this.dbService.data_sub_category_question.where('categoryId').equals(categoryId).sortBy('sort');
	}

	/**
	 * Retrieves a list of questions based on the provided query parameters.
	 * If no query parameters are provided, all questions are returned.
	 *
	 * @param {Object} query - Optional query parameters.
	 * @param {string} query.categoryId - Filter questions by category ID.
	 * @param {string} query.technologyId - Filter questions by technology ID.
	 * @returns {Promise<Array<Question>>} - A Promise that resolves to an array of Question objects.
	 */
	async getQuestions(query?: { categoryId?: string; technologyId?: string }): Promise<Question[]> {
		const data_o = await this.dbService.data_question.toCollection().sortBy('sort');
		let data: Question[] = [];
		if (query) {
			if (query.categoryId) {
				data = data_o.filter((tq) => tq.categoryId === query.categoryId);
			}
			if (query.technologyId) {
				data = data.filter((tq) => tq.technologyId === query.technologyId);
			}
		} else {
			data = data_o;
		}
		return data;
	}

	/**
	 * Retrieves the relation solution for the given solution.
	 *
	 * @async
	 * @param {Solution} solution - The solution object.
	 * @return {Promise<void>} - A promise that resolves when the relation solution is fetched.
	 */
	async getRelationSolution(solution: Solution): Promise<void> {
		const flow = await this.dbService.data_flow.get({ solutionId: solution.id });
		if (flow) {
			solution.diagram = flow;
		} else {
			solution.diagram = this._newFlow(solution);
		}
		solution.functions = await this.getFunctionsFilterByIds(solution.functionsIds);
		if (solution.levels2Ids && solution.levels2Ids.length) {
			solution.levels2 = await this.e2ETaxonomyService.getLevels2({
				ids: solution.levels2Ids,
				recursiveRelation: true,
			});
		} else {
			solution.levels2 = [];
		}
		solution.tools = await this.getToolsByIds(solution.toolsIds);
		solution.skills = await this.getSkillsByIds(solution.skillsIds);
		solution.aiLevers = await this.getAiLeversByIds(solution.aiLeversIds);

		if (solution.iconDefaultId) {
			const iconDefault = await this.getIconDefault(solution.iconDefaultId);
			if (iconDefault) {
				solution.iconDefault = iconDefault;
			}
		}
	}

	/**
	 * Set the current solution.
	 *
	 * @param {string} [id] - The ID of the solution to set. If not provided, a new solution will be created.
	 *
	 * @return {Promise<void>} - A Promise that resolves when the current solution is set.
	 */
	async setCurrentSolution(id?: string): Promise<void> {
		this.currentSolution = id ? (await this.getSolution(id)) ?? this._newSolution() : this._newSolution();
		if (this.currentSolution) {
			await this.getRelationSolution(this.currentSolution);
			this.currentDiagram = this.currentSolution.diagram;
		}
		this.currentOriginalSolution = this.currentSolution ? instanceToInstance(this.currentSolution) : undefined;
	}

	/**
	 * Clears the current solution, current diagram, and current original solution.
	 *
	 * @return {void}
	 */
	clearCurrentSolution(): void {
		this.currentSolution = undefined;
		this.currentDiagram = undefined;
		this.currentOriginalSolution = undefined;
	}

	public updateCurrentSolution = new Subject<string>();
	updateCurrentSolution$ = this.updateCurrentSolution.asObservable();

	/**
	 * Saves the current solution.
	 *
	 * @returns {Promise<string>} - A promise that resolves to a string indicating the result of the save operation.
	 *   - Possible return values:
	 *     - 'no-name' - If the current solution has no name.
	 *     - '' - If the current solution was successfully saved.
	 *     - 'no-updated' - If the current solution was not updated.
	 *     - 'no-data' - If there is no current solution.
	 */
	async saveCurrentSolution(): Promise<string> {
		if (this.currentSolution) {
			if (!this.currentSolution.name) {
				return 'no-name';
			}
			const updated = !deepEqual(this.currentOriginalSolution, this.currentSolution);
			if (updated) {
				await this.saveSolution(this.currentSolution);
				this.updateCurrentSolution.next('');
				this.currentOriginalSolution = instanceToInstance(this.currentSolution);
				return '';
			} else {
				return 'no-updated';
			}
		}
		return 'no-data';
	}

	/**
	 * Retrieves a solution with the specified ID from the database.
	 *
	 * @param {string} id - The ID of the solution to retrieve.
	 * @returns {Promise<Solution | undefined>} A Promise that resolves to the retrieved solution, or undefined if no solution is found.
	 */
	async getSolution(id: string): Promise<Solution | undefined> {
		return this.dbService.data_solution.get(id);
	}

	/**
	 * Retrieves a Flow object from the database based on the provided query.
	 *
	 * @param {string | { solutionId: string }} query - The query used to retrieve the Flow.
	 *        If a string is provided, it will be interpreted as the Flow ID.
	 *        If an object is provided, it should have a 'solutionId' property specifying the Flow ID.
	 *
	 * @return {Promise<Flow | undefined>} - A Promise that resolves with the retrieved Flow object,
	 *         or undefined if no matching Flow is found.
	 */
	async getFlow(query: string | { solutionId: string }): Promise<Flow | undefined> {
		return this.dbService.data_flow.get(query as any);
	}

	/**
	 * Saves a solution to the database.
	 *
	 * @param {Solution} solution - The solution to be saved.
	 * @returns {Promise<Solution>} - The saved solution.
	 */
	async saveSolution(solution: Solution): Promise<Solution> {
		const original_solution = await this.dbService.data_solution.get(solution.id || '');
		const data = plainToInstance(Solution, {
			...(original_solution || {}),
			...solution,
		});
		if (!data.id) {
			data.id = uuid();
		}
		await this.dbService.data_solution.put(data);
		return data;
	}

	/**
	 * Deletes a solution from the database.
	 *
	 * @param {string} id - The ID of the solution to delete.
	 * @returns {Promise<void>} - A promise that resolves when the solution is successfully deleted.
	 */
	async deleteSolution(id: string): Promise<void> {
		await this.dbService.data_solution.delete(id);
	}

	/**
	 * Save tool in the database.
	 *
	 * @param {Tool} tool - The tool object to be saved.
	 * @returns {Promise<Tool>} - A promise that resolves with the saved tool object.
	 */
	async saveTool(tool: Tool): Promise<Tool> {
		const original_tool = await this.dbService.data_tool.get(tool.id || '');
		const data = plainToInstance(Tool, {
			...(original_tool || {}),
			...tool,
		});
		if (!data.id) {
			data.id = uuid();
		}
		await this.dbService.data_tool.put(data);
		return data;
	}

	/**
	 * Performs an action on error image.
	 * This method fetches the flow diagram associated with the current solution's diagram ID using an HTTP GET request.
	 * It then stores the retrieved diagram in the database using the indexedDB `put` method.
	 * If the current solution exists, it updates the `diagram` property of `currentSolution` with the retrieved diagram.
	 * This method does not return any value.
	 */
	onErrorImage(): void {
		if (this.currentSolution?.diagram) {
			this.http.get<Flow>('@api/solution/flow/' + this.currentSolution.diagram.id).subscribe({
				next: (diagram) => {
					this.dbService.data_flow.put(diagram).then(() => {
						if (this.currentSolution) {
							this.currentSolution.diagram = diagram;
						}
					});
				},
			});
		}
	}

	async getIcon(): Promise<Icon[]> {
		return this.dbService.data_icon.toArray();
	}

	async getIconDefault(id: string): Promise<Icon | undefined> {
		return this.dbService.data_icon.get(id);
	}
}
