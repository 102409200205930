<p-toast></p-toast>

<div *ngIf="dbInit" class="layout-wrapper" [ngClass]="containerClass">
    <app-topbar *ngIf="renderLayout"></app-topbar>
    <div *ngIf="renderLayout" class="layout-sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="layout-mask"></div>
</div>

<p-blockUI [blocked]="!dbInit">
    <div class="flex flex-column justify-content-center align-items-center text-center layout-progress gap-3" style="min-height: 100vh; display: flex;">
      <canvas id="dotlottie-canvas" class="h-10rem w-10rem"></canvas>
      <span id="title-loading" class="text-white font-bold" style="font-size: 20px">AI XPLR</span>
      <p-progressBar [value]="progress" class="w-full"></p-progressBar>
    </div>
</p-blockUI>
