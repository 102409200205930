<svg width="100%" height="100%" viewBox="0 0 500 235" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 1">
        <text
            fill="#1532b8"
            xml:space="preserve"
            style="white-space: pre"
            font-family="Open Sans"
            font-size="17"
            font-weight="700"
            letter-spacing="0.16px"
        >
            <tspan x="0.5" y="45">{{ impactArray[2]?.key }}</tspan>
        </text>
        <text
            fill="#1b54f8"
            xml:space="preserve"
            style="white-space: pre"
            font-family="Open Sans"
            font-size="17"
            font-weight="700"
            letter-spacing="0.16px"
        >
            <tspan x="0.5" y="120">{{ impactArray[1]?.key }}</tspan>
        </text>
        <text
            fill="#569bff"
            xml:space="preserve"
            style="white-space: pre"
            font-family="Open Sans"
            font-size="17"
            font-weight="700"
            letter-spacing="0.16px"
        >
            <tspan x="0.5" y="200">{{ impactArray[0]?.key }}</tspan>
        </text>

        <g id="impactPyramid">
            <path
                id="shapeBreakthrough"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M395 77H289L327.4 21.2065L341.998 0L395 77Z"
                fill="url(#paint0_linear_17_95)"
            />
            <path
                id="shapeTransformative"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M447 156H237L289.502 77H394.504L447 156Z"
                fill="url(#paint1_linear_17_95)"
            />
            <path
                id="shapeIncremental"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M500 235H184L236.664 156H447.337L500 235Z"
                fill="url(#paint2_linear_17_95)"
            />
            <text
                fill="#FFF"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Open Sans"
                font-size="24"
                font-weight="700"
                letter-spacing="0.16px"
                text-anchor="middle"
            >
                <!-- <tspan x="342" y="50">{{ impactArray[2]?.value }}</tspan> -->
            </text>
            <text
                fill="#FFF"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Open Sans"
                font-size="24"
                font-weight="700"
                letter-spacing="0.16px"
                text-anchor="middle"
            >
                <!-- <tspan x="342" y="130">{{ impactArray[1]?.value }}</tspan> -->
            </text>
            <text
                fill="#FFF"
                xml:space="preserve"
                style="white-space: pre"
                font-family="Open Sans"
                font-size="24"
                font-weight="700"
                letter-spacing="0.16px"
                text-anchor="middle"
            >
                <!-- <tspan x="342" y="210">{{ impactArray[0]?.value }}</tspan> -->
            </text>

            <line x1="142" y1="195.5" x2="206" y2="195.5" stroke="#b0b0b0" stroke-width="1" stroke-dasharray="2,2" />
            <line x1="142" y1="115.5" x2="259" y2="115.5" stroke="#b0b0b0" stroke-width="1" stroke-dasharray="2,2" />
            <line x1="142" y1="43.5" x2="311" y2="43.5" stroke="#b0b0b0" stroke-width="1" stroke-dasharray="2,2" />
        </g>
    </g>
    <defs>
        <linearGradient id="paint0_linear_17_95" x1="342" y1="0" x2="342" y2="77" gradientUnits="userSpaceOnUse">
            <stop stop-color="#1532b8" />
            <stop offset="1" stop-color="#1532b8" />
        </linearGradient>
        <linearGradient id="paint1_linear_17_95" x1="342" y1="77" x2="342" y2="156" gradientUnits="userSpaceOnUse">
            <stop stop-color="#1b54f8" />
            <stop offset="1" stop-color="#1b54f8" />
        </linearGradient>
        <linearGradient id="paint2_linear_17_95" x1="342" y1="156" x2="342" y2="235" gradientUnits="userSpaceOnUse">
            <stop stop-color="#569bff" />
            <stop offset="1" stop-color="#569bff" />
        </linearGradient>
    </defs>
</svg>
