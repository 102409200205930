<p-toast></p-toast>

<app-blockable-div #content class="card card-content-stepper flex flex-column gap-2 py-0">
	<ng-template [ngIf]="!loading">
		<div class="flex justify-content-between gap-3">
			<p class="text-primary text-2xl mb-0 mt-3">{{ labels[current_index] }}</p>
			<div class="flex justify-content-between gap-3">
				<p-steps
					[model]="items"
					[readonly]="false"
					[activeIndex]="current_index"
					(activeIndexChange)="changeStep($event)"
					styleClass="hidden md:block lg:block xl:block"
				>
				</p-steps>
				<div>
					<p-button
						icon="pi pi-times"
						styleClass="close-button p-button-rounded p-button-outlined p-button-sm"
						(onClick)="close()"
					></p-button>
				</div>
			</div>
		</div>
		<ng-template [ngIf]="!current_index">
			<div class="overflow-scroll scroll-height">
				<div class="grid text-stepper mb-1 text-gray-500">
					<div class="col-3"><label>Components</label></div>
					<div class="col-4"><label>Comments</label></div>
					<div class="col text-center"><label>Current State</label></div>
					<div class="col text-center"><label>Industry Peer Group</label></div>
					<div class="col text-center"><label>Target </label></div>
				</div>
				<ng-template ngFor let-subcategory [ngForOf]="data">
					<p class="text-primary font-bold">{{ subcategory.label }}</p>
					<div
						*ngFor="let question of subcategory.questions; let i = index"
						class="grid text-stepper mb-2"
						[ngClass]="{'mt-2': i}"
					>
						<div class="col-3 flex align-content-end py-0">
							<span class="flex align-items-center justify-content-center"> {{ question.text }} </span>
						</div>
						<div class="col-4 flex align-content-end py-0">
							<div class="flex align-items-center justify-content-center" style="width: 100%">
								<input
									placeholder="add notes"
									class="w-full comments h-2rem"
									[(ngModel)]="question.description"
								/>
							</div>
						</div>
						<div class="col flex justify-content-center align-content-end py-0">
							<p-inputNumber inputStyleClass="w-5rem h-2rem" [(ngModel)]="question.res1"></p-inputNumber>
						</div>
						<div class="col flex justify-content-center align-content-end py-0">
							<div class="box-process-data w-5rem h-2rem">0</div>
						</div>
						<div class="col flex justify-content-center align-content-end py-0">
							<p-inputNumber inputStyleClass="w-5rem h-2rem" [(ngModel)]="question.res3"></p-inputNumber>
						</div>
					</div>
				</ng-template>
			</div>
		</ng-template>
		<ng-template [ngIf]="current_index">
			<div class="overflow-scroll scroll-height">
				<div class="grid text-stepper mb-1 text-gray-500">
					<div class="col-5"><label>Questions</label></div>
					<div class="col-3"><label>Value</label></div>
					<div class="col-4"><label>Comments</label></div>
				</div>
				<div
					*ngFor="let question of questions; let i = index"
					class="grid text-stepper mb-2"
					[ngClass]="{'mt-2': i}"
				>
					<div class="col-5 flex align-content-end py-0">
						<span class="flex align-items-center justify-content-center"> {{ question.text }} </span>
					</div>
					<div class="col-3 py-0">
						<ngx-slider [options]="getFormSliderOptions(question)" [value]="question.value"> </ngx-slider>
					</div>
					<div class="col-4 flex align-content-end py-0">
						<div class="flex align-items-center justify-content-center" style="width: 100%">
							<input
								placeholder="add notes"
								class="w-full comments h-3rem"
								[(ngModel)]="question.description"
							/>
						</div>
					</div>
				</div>
			</div>
		</ng-template>
		<div class="flex justify-content-between px-4 h-4rem">
			<div></div>
			<div class="flex justify-content-end align-items-end gap-3 h-full">
				<p-button
					*ngIf="current_index"
					label="Back"
					icon="pi pi-angle-left"
					styleClass="button-stepper blink-nop"
					(onClick)="previousStep()"
				></p-button>
				<span *ngIf="!current_index" style="width: 5.3rem"></span>
				<p-button
					*ngIf="current_index < max_index"
					label="Next"
					icon="pi pi-angle-right"
					iconPos="right"
					styleClass="button-stepper blink-nop"
					(onClick)="nextStep()"
				></p-button>
				<p-button
					*ngIf="current_index === max_index"
					label="Finish"
					icon="pi pi-times"
					iconPos="right"
					styleClass="button-stepper blink-nop"
					(onClick)="close()"
				></p-button>
			</div>
		</div>
	</ng-template>
</app-blockable-div>

<p-blockUI [target]="content" [blocked]="loading">
	<i class="pi pi-spin pi-spinner" style="font-size: 4rem; color: white"></i>
</p-blockUI>
