import { Injectable } from '@angular/core';
import { MenuItem, Message, TreeNode } from 'primeng/api';
import { CategoryQuestion, Question } from '../../../api/solution.api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SolutionService } from '../../../service/solution.service';
import { UseCaseService } from '../../../service/use-case.service';
import { IOutputChartData } from './category-form/category-form.component';
import { MatStepper } from '@angular/material/stepper';
import { E2ETaxonomyService } from '../../../service/e2e-taxonomy.service';
import { Subject, Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { IAnswerFormData } from '../../../api/use-case.api';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonService } from '../../../service/common.service';
import { Technology } from '../../../api/e2e-taxonomy.api';
import { Options as OptionsSlider } from 'ngx-slider-v2/options';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { DbService } from '../../../service/db.service';

interface BusinessDrivers {
	id: string;
	data: { tag: string; svg: string }[];
}

interface TableDataItem {
	id: string;
	tag: string;
	svg: SafeHtml;
	value: string;
}

@Injectable({
	providedIn: 'root',
})
export class UseCaseReadinessModalService {
	constructor(
		public sanitizer: DomSanitizer,
		public dbService: DbService,
		public commonService: CommonService,
		public solutionService: SolutionService,
		public useCaseService: UseCaseService,
		public e2ETaxonomyService: E2ETaxonomyService,
	) {
		this.solutionService.getCategoriesQuestion().then((data) => {
			this.categoriesQuestion = data;
			this.items = [
				{
					label: 'General',
					tooltipPosition: 'top',
					command: () => {
						this.changeStep(0);
					},
				},
			];
			this.categoriesQuestion.forEach((c, i) => {
				this.items.push({
					label: c.name,
					command: () => {
						this.changeStep(i + 1);
					},
				});
			});
			this.max_index = this.categoriesQuestion.length + 1;
			this.items.push(
				...[
					/*{
						label: 'Work Plan',
						command: () => {
							this.changeStep(this.max_index - 1);
						},
					},*/
					{
						label: 'Result',
						command: () => {
							this.changeStep(this.max_index);
						},
					},
				],
			);
		});

		this.solutionService.getQuestions().then((data) => {
			this.questions = data;
		});

		this.e2ETaxonomyService
			.getCategoriesFunction(
				{
					extend: 'function.level1.level2',
				},
				true,
			)
			.then((data) => {
				this.generalTags = data;
			});

		this.loading_technologies = true;

		this.e2ETaxonomyService
			.getTechnologies()
			.then((tags) => {
				this.technologies = tags.map((t) => {
					t.svg = this.safeHTML(t.svg) as any;
					return t;
				});
			})
			.catch(() => {
				this.technologies = [];
			})
			.finally(() => {
				this.loading_technologies = false;
			});
	}

	public updateChartsSource = new Subject<string>();
	updateChartsSource$ = this.updateChartsSource.asObservable();

	can_edit: boolean = false;
	updatedUseCase: boolean = false;

	updateUseCase() {
		this.updatedUseCase = true;
		if (!this.can_edit && this.messageSocket && this.webSocketError) {
			this.webSocketError(this.messageSocket);
		}
	}

	updateCharts() {
		if (this.current_index > 0 && this.current_index <= this.max_index - 1) {
			this.chartStepData = this.chartStepDataArray[this.current_index - 1];
		}
		this.updateChartsSource.next(uuid());
	}

	safeHTML(html: string) {
		const parser = new DOMParser();
		const document = parser.parseFromString(html, 'text/html');
		return this.sanitizer.bypassSecurityTrustHtml(document.body.outerHTML);
	}

	current_index: number = 0;
	max_index: number = 0;
	items: MenuItem[] = [];

	categoriesQuestion: CategoryQuestion[];
	questions: Question[];

	stepper: MatStepper;
	ref: DynamicDialogRef;
	config: DynamicDialogConfig<{ useCaseId: string; current_index?: number }>;
	webSocketError: (message: Message) => void;
	sub: Subscription;

	public realtimeUpdateSource = new Subject<string>();
	realtimeUpdateSource$ = this.realtimeUpdateSource.asObservable();

	loading: boolean = false;
	loading_technologies: boolean = false;

	technologies: Technology[] = [];
	technologiesIds: string[] = [];

	async onLoad(
		ref: DynamicDialogRef,
		config: DynamicDialogConfig<{
			useCaseId: string;
			current_index?: number;
		}>,
		webSocketError: (message: Message) => void,
	) {
		this.ref = ref;
		this.config = config;
		this.webSocketError = webSocketError;

		this.loading = true;
		this.table_heatmap_running = true;
		this.updatedUseCase = false;

		this.generalTagsSelection = [];
		this.technologiesIds = [];
		this.dataChartsResult = [];
		this.chartStepData = undefined;
		this.chartStepDataArray = [];

		// Stepper
		if (this.config?.data?.current_index !== undefined) {
			this.current_index = this.config.data.current_index;
		}
		if (this.config?.data?.useCaseId) {
			this.webSocket(this.config.data.useCaseId);
			await this.useCaseService.setCurrentUseCase(this.config.data.useCaseId);
		} else {
			this.ref.close();
		}

		this.sub = this.dbService.dbOrganizationUpdateSource$.subscribe(() => {
			setTimeout(() => {
				if (this.config?.data?.useCaseId) {
					this.useCaseService.setCurrentUseCase(this.config.data.useCaseId).then(() => {
						this.realtimeUpdateSource.next(uuid());
					});
				}
			}, 150);
		});

		// General Form
		if (this.useCaseService.currentUseCase) {
			this.useCaseService.currentUseCase.categories = this.categoriesQuestion;
			if (!this.useCaseService.currentUseCase.generalData) {
				this.useCaseService.currentUseCase.generalData = {
					cycle_time: '',
					type_cycle: '',
					fte: '',
					effort: '',
					effort_cycle: '',
					taxonomy: [],
				};
			}

			if (this.useCaseService.currentUseCase.generalData.taxonomy?.length) {
				this.generalTagsSelection = this.useCaseService.currentUseCase.generalData.taxonomy;
			}

			const tags_now = this.useCaseService.currentUseCase.levels2Ids || [];
			for (const categoryFunctionTag of this.generalTags) {
				if (categoryFunctionTag.children?.length) {
					for (const functionTag of categoryFunctionTag.children) {
						if (functionTag.children?.length) {
							for (const level1Tag of functionTag.children) {
								if (level1Tag.children?.length) {
									for (const level2Tag of level1Tag.children) {
										if (tags_now.includes(level2Tag.key || '')) {
											this.generalTagsSelection.push(level2Tag);
											level1Tag.partialSelected = true;
											functionTag.partialSelected = true;
											categoryFunctionTag.partialSelected = true;
										}
									}
								} else {
									level1Tag.selectable = false;
								}
							}
						} else {
							functionTag.selectable = false;
						}
					}
				} else {
					categoryFunctionTag.selectable = false;
				}
			}
		}

		// Result
		this.loading_technologies = true;

		if (this.useCaseService.currentUseCase) {
			this.technologiesIds = this.useCaseService.currentUseCase.getTechnologiesIds();
			this.loading_technologies = false;
		}

		// await this.getTableHeatMap();

		this.dataChartsResult = [...Array(this.categoriesQuestion.length)].map((d, i) => ({
			value: 0,
			label: this.categoriesQuestion[i]?.name || '',
			data: this.getDataChartValue(0),
			options: this.optionsChartsResult,
		}));

		this.updateChartsSource$.subscribe(() => {
			if (this.chartStepDataArray.length === this.categoriesQuestion.length) {
				const values: number[] = [];
				this.chartStepDataArray.forEach((d, i) => {
					if (d.value !== this.dataChartsResult[i].value) {
						this.dataChartsResult[i] = {
							value: d.value,
							label: this.categoriesQuestion[i]?.name || '',
							data: d.data,
							options: this.optionsChartsResult,
						};
					}
					values.push(d.value);
				});
				if (this.useCaseService.currentUseCase?.calculatedData) {
					const pValues = values.filter((v) => !!v);
					let readiness = pValues.reduce((a, b) => a + b, 0) / pValues.length;
					if (isNaN(readiness)) {
						readiness = 0;
					}
					this.useCaseService.currentUseCase.calculatedData.matrixReadiness = {
						value: readiness,
						color: this.useCaseService.useCaseGetPercentBackgroundColor(readiness),
						label_value: this.useCaseService.useCaseGetPercentLabel(readiness),
						class_value: this.useCaseService.useCaseGetPercentBackground(readiness),
					};
				}
			}
		});

		this.loading = false;
	}

	socket: WebSocket;
	messageSocket: Message;
	messages: Message[];

	webSocket(useCaseId: string) {
		const url = environment.url.replace('http', 'ws');
		this.socket = new WebSocket(
			url + '/user-case/' + useCaseId + '/' + '?token=' + localStorage.getItem('auth-token'),
		);

		this.socket.addEventListener('message', (event) => {
			const data: { id: string; username: string } = JSON.parse(event.data);
			if (data.id) {
				this.can_edit = false;
				this.messageSocket = {
					severity: 'warn',
					summary: 'Use Case in use',
					detail: `The user '${data.username}' is currently using this use case, so you cannot save any changes while that user is connected.`,
					life: 5000,
				};
				this.messages = [{ ...this.messageSocket, life: 8000 }];
			} else {
				this.can_edit = true;
			}
		});

		this.ref.onClose.subscribe({
			next: () => {
				if (this.socket) {
					this.socket.close();
					this.messages = [];
				}
				if (this.sub) {
					this.sub.unsubscribe();
				}
			},
		});
	}

	//Stepper
	changeStep(index: number) {
		this.stepper.selectedIndex = index;
		this.saveUseCase();
		this.current_index = this.stepper.selectedIndex;
		if (this.current_index > 0) {
			this.chartStepData = this.chartStepDataArray[this.current_index - 1];
		} else {
			this.chartStepData = undefined;
		}
	}

	nextStep() {
		if (this.current_index !== this.max_index) {
			this.stepper.next();
		}
		this.saveUseCase();
		this.current_index = this.stepper.selectedIndex;
		if (this.current_index > 0) {
			this.chartStepData = this.chartStepDataArray[this.current_index - 1];
		} else {
			this.chartStepData = undefined;
		}
	}

	previousStep() {
		if (this.current_index !== 0) {
			this.stepper.previous();
		}
		this.saveUseCase();
		this.current_index = this.stepper.selectedIndex;
		if (this.current_index > 0) {
			this.chartStepData = this.chartStepDataArray[this.current_index - 1];
		} else {
			this.chartStepData = undefined;
		}
	}

	async saveUseCase() {
		if (this.updatedUseCase && this.can_edit) {
			this.updatedUseCase = false;
			if (this.generalTagsSelection.length && this.useCaseService.currentUseCase) {
				const getChildren = (nodes: TreeNode[]) => {
					return nodes.map((node) => {
						const nNode: TreeNode = { key: node.key, label: node.label };
						if (node.children?.length) {
							nNode.children = getChildren(node.children);
						}
						return nNode;
					});
				};

				this.useCaseService.currentUseCase.generalData.taxonomy = getChildren(this.generalTagsSelection);
			}
			if (this.current_index <= this.max_index - 1) {
				await this.useCaseService.saveCurrentUseCase().then((e) => {
					e;
				});
			}
		}
	}

	chartStepData: IOutputChartData | undefined = undefined;

	chartStepDataArray: IOutputChartData[] = [];

	chartStepDataOptions = {
		cutout: '60%',
		rotation: 270,
		circumference: 180,
		responsive: true,
		maintainAspectRatio: true,
		aspectRatio: 2,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
			datalabels: {
				display: false,
			},
		},
	};

	async goToDiagram() {
		if (this.useCaseService.currentUseCase && this.useCaseService.currentUseCase.id) {
			if (this.socket) {
				this.socket.close();
			}
			this.messages = [];
			this.ref.close();
			await this.useCaseService.goToUseCase(this.useCaseService.currentUseCase.id, false, true);
		}
	}

	// General form

	generalTags: TreeNode[] = [];
	generalTagsSelection: TreeNode[] = [];

	generalTypeCycleOptions = [
		{ label: 'Days', value: '1' },
		{ label: 'Weeks', value: '2' },
		{ label: 'Months', value: '3' },
	];

	generalEffortCycleOptions = [
		{ label: 'Hours/Day', value: '1' },
		{ label: 'Hours/Week', value: '2' },
		{ label: 'Hours/Month', value: '3' },
	];

	// Category Form

	getFormSliderOptions(id: string, form: FormGroup): OptionsSlider {
		const getTextClass = (value: number): string => {
			if (value === 3) {
				return 'text-high';
			} else if (value === 2) {
				return 'text-medium';
			} else if (value === 1) {
				return 'text-low';
			} else {
				return 'text-na';
			}
		};
		return {
			floor: 0,
			ceil: 3,
			step: 1,
			showTicks: true,
			showTicksValues: true,
			getPointerColor: (value: number): string => {
				if (value === 3) {
					return '#7FAA3A';
				} else if (value === 2) {
					return '#FF9900';
				} else if (value === 1) {
					return '#CC3333';
				} else {
					return '#C1C6C8';
				}
			},
			translate: (value: number): string => {
				return '';
			},
			getLegend: (value: number): string => {
				let label = '';
				if (value === 3) {
					label = 'HIGH';
				} else if (value === 2) {
					label = 'MEDIUM';
				} else if (value === 1) {
					label = 'LOW';
				} else {
					label = 'N/A';
				}
				return `<span class="${
					form.value[id] === value ? getTextClass(form.value[id]) : 'text-na'
				}">${label}</span>`;
			},
		};
	}

	// Results
	optionsChartsResult = {
		cutout: '60%',
		rotation: 270,
		circumference: 180,
		responsive: true,
		maintainAspectRatio: true,
		aspectRatio: 2,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
			datalabels: {
				display: false,
			},
		},
	};

	dataChartsResult: {
		value: number;
		label: string;
		data: any;
		options: any;
	}[];

	getDataChartValue(value: number) {
		const documentStyle = getComputedStyle(document.documentElement);

		let color = '#A52819';
		if (value > 75) {
			color = '#7FAA3A';
		} else if (value > 55) {
			color = '#FFC000';
		}

		return {
			labels: ['', ''],
			datasets: [
				{
					label: '',
					data: [value, 100 - value],
					backgroundColor: [color, documentStyle.getPropertyValue('--surface-300')],
					borderColor: ['rgba(255, 255, 255 ,1)', 'rgba(255, 255, 255 ,1)'],
					borderWidth: 5,
				},
			],
		};
	}

	table_heatmap_running = true;
	business_drivers: BusinessDrivers | undefined = undefined;
	business_drivers_data: TableDataItem[] = [];

	async getTableHeatMap() {
		this.table_heatmap_running = true;
		this.business_drivers_data = [];

		if (!this.business_drivers) {
			const res = await this.commonService.getCommonData<BusinessDrivers>('business_drivers');
			if (res) {
				this.business_drivers = res.data;
			}
		}
		let useCasesAnswerBenefits: { [key: string]: IAnswerFormData } = {};
		if (this.useCaseService.currentUseCase?.id && this.business_drivers) {
			useCasesAnswerBenefits = this.useCaseService.currentUseCase.getValue(this.business_drivers?.id)?.answers;
		}
		if (this.business_drivers) {
			for (const bd of this.business_drivers.data) {
				this.business_drivers_data = this.business_drivers_data.filter((n) => n.tag !== bd.tag);
				const answers = this.questions
					.filter((q) => q.tag === bd.tag)
					.map((q) => useCasesAnswerBenefits[q.id as any])
					.filter((a) => !!a);
				let value = '0';
				const max = Math.max(...answers.map((a) => parseFloat(a.value || '0')));
				if (!isNaN(max) && max !== Infinity && max !== -Infinity) {
					value = max.toFixed(0).toString();
				}
				this.business_drivers_data.push({
					id: '',
					tag: bd.tag,
					svg: this.safeHTML(bd.svg),
					value,
				});
			}
		}

		this.table_heatmap_running = false;
	}

	dataValues = [0, 0, 0, 0];
}
