<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M8.33591 16.9906L8.29761 17.0323L8.33591 16.9996V16.9906Z" class="icon"/>
	<path d="M7.56758 6.22121V4.21582L1.47144 9.72049H4.44684L7.56758 6.22121Z" class="icon"/>
	<path d="M7.56857 2.25553V2.17554H0.0224609V9.7239H1.42961L7.56857 2.25553Z" class="icon"/>
	<path d="M7.56753 7.83789L5.35034 9.7216H7.56753V7.83789Z" class="icon"/>
	<path d="M7.54836 17.9618V14.5729L5.27371 16.5445L7.54836 13.9927V11.0522L2.88302 15.1621L7.11236 10.4156H4.53465L1.62346 12.8942L3.83051 10.4156H0V17.964L7.54836 17.9618Z" class="icon"/>
	<path d="M8.33594 13.8901V16.9917L14.1876 10.4167H12.3399L8.33594 13.8901Z" class="icon"/>
	<path d="M8.33594 13.1083L10.7368 10.4156H8.33594V13.1083Z" class="icon"/>
	<path d="M15.8787 10.5701L8.33594 17.0031V17.9641H15.8787V10.5701Z" class="icon"/>
	<path d="M18.1836 0H9.26868V8.91495H18.1836V0Z" class="icon"/>
	<path d="M8.16916 16.4927L8.13086 16.5344L8.16916 16.5017V16.4927Z" class="icon"/>
</svg>
