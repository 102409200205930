const partial1 = `

You are a business process analyst within a business transformation consulting team with expertise in analyzing processes and identifying opportunities to introduce AI and automation to improve efficiency and effectiveness within specific steps of the client's processes.

Your task is to analyze the client's assessment answers and focus on the maturity of the client in this stage based on these seven pillars:
Strategy & Leadership; Ethics & Compliance; Organization Culture & Talent; Security & Privacy; Technology Enablement; Data & Knowledge Management; and AI Enablement.

The client's assessment answers (percentages) of those pillars are provided below:

{
  "id": "{{id}}",
  "phase": "{{phase}}",
  "value": "{{value}}"
}

`;

const partial2 = `

Create a list between 3 to 5 elements for the next steps for improvement based on the answers above (check the specific phase), and create use cases exemples (do not use the pillar to answer) and return it as HTML template following this:

<ol class="no-margin check-box-list">
    <li class="check-box-list-item">
    	<b>[Next Step title]:</b> [Short description up to 30 words].
    </li>
     <!-- Repeat the list item structure for each example and add numbers to each new list item -->
</ol>


Summarize the text description of the Next Step up to 30 words.
Be very concise and use assertive language and avoid using terms like: this could be, possibly, maybe.
Avoid creating any text outside the HTML OL list.

`;

export const promptMaturity = `
${partial1}
${partial2}
`;

export interface PromptInput {
	id: string;
	phase: string;
	value: string;
}
