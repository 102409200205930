<p-toast></p-toast>
<p-confirmDialog class="use-case-readiness-modal-close" [style]="{ width: '50vw' }"></p-confirmDialog>

<div
	*ngIf="!service.loading"
	class="card card-content-stepper"
	[ngClass]="service.current_index === service.max_index ? 'background' : ''"
>
	<p-steps
		[model]="service.items"
		[readonly]="false"
		[activeIndex]="service.current_index"
		[styleClass]="
			(service.current_index === service.max_index ? 'step-final ' : '') + 'hidden md:block lg:block xl:block'
		"
	>
	</p-steps>
	<p-button
		icon="pi pi-times"
		[styleClass]="
			(service.current_index === service.max_index ? 'white' : '') +
			' stepper-close close-button p-button-rounded p-button-outlined absolute'
		"
		(onClick)="modalClose()"
	></p-button>
	<div class="stepper-title">
		<div class="flex flex-wrap justify-content-start align-items-center mr-4 gap-2">
			<p-button
				icon="pi pi-sitemap"
				[styleClass]="'p-button-text' + (service.current_index === service.max_index ? ' text-white' : '')"
				(onClick)="service.goToDiagram()"
			></p-button>
			<span
				[class]="
					'font-bold m-0 title-use-case' + (service.current_index === service.max_index ? ' text-white' : '')
				"
				>{{ useCaseService.currentUseCase?.name }}</span
			>
		</div>
	</div>

	<p-messages [(value)]="service.messages" [enableService]="false"></p-messages>

	<mat-stepper #stepper [selectedIndex]="service.current_index" [@.disabled]="true">
		<mat-step>
			<app-use-cases-readiness-general-form></app-use-cases-readiness-general-form>
		</mat-step>
		<mat-step *ngFor="let categoryQuestion of service.categoriesQuestion; let index = index">
			<app-use-cases-readiness-category-form *ngIf="categoryQuestion.id" [index]="index">
			</app-use-cases-readiness-category-form>
		</mat-step>
		<mat-step>
			<app-use-cases-readiness-result></app-use-cases-readiness-result>
		</mat-step>
	</mat-stepper>
	<div *ngIf="service.current_index <= service.max_index - 1" class="flex justify-content-between px-4 mt-3 h-4rem">
		<div *ngIf="service.chartStepData && service.current_index > 0" class="chart-data">
			<p-chart
				type="doughnut"
				[data]="service.chartStepData.data"
				[options]="service.chartStepDataOptions"
			></p-chart>
			<p class="percent">{{ service.chartStepData.value }}%</p>
		</div>
		<!--
		<div *ngIf="service.current_index === service.max_index - 1"
			 class="flex justify-content-start align-items-end gap-3 h-full">
			<p-button
				label="Export"
				icon="pi pi-download"
				styleClass="button-stepper"
				[loading]="service.exportWorkPlanLoading"
				(onClick)="service.exportWorkPlan()"
			></p-button>
			<p-button
				*ngIf="service.can_edit"
				label="Import"
				icon="pi pi-upload"
				styleClass="button-stepper"
				[loading]="service.importWorkPlanLoading"
				(onClick)="service.visible_import_workplan = true"
			></p-button>
		</div>
		-->
		<div *ngIf="!(service.chartStepData && service.current_index > 0)" class="chart-data pb-2"></div>
		<div class="flex justify-content-end align-items-end gap-3 h-full">
			<p-button
				*ngIf="service.current_index"
				label="Back"
				icon="pi pi-angle-left"
				[styleClass]="'button-stepper' + (service.updatedUseCase && service.can_edit ? ' blink-nop' : '')"
				tippy
				[tippyContent]="service.updatedUseCase && service.can_edit ? 'Save Changes' : ''"
				tippyAppendTo="parent"
				(onClick)="service.previousStep()"
			></p-button>
			<span *ngIf="!service.current_index"></span>
			<p-button
				label="Next"
				icon="pi pi-angle-right"
				iconPos="right"
				[styleClass]="'button-stepper' + (service.updatedUseCase && service.can_edit ? ' blink-nop' : '')"
				tippy
				[tippyContent]="service.updatedUseCase && service.can_edit ? 'Save Changes' : ''"
				tippyAppendTo="parent"
				(onClick)="service.nextStep()"
			></p-button>
		</div>
	</div>
</div>

<!--
<p-dialog
	header="Import WorkPlan"
	[(visible)]="service.visible_import_workplan"
	[style]="{ width: '50vw' }"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="import-workplan"
>
	<div class="flex flex-column flex-wrap gap-3 px-5 pb-3">
		<div class="flex flex-column gap-2">
			<p-fileUpload
				*ngIf="service.visible_import_workplan"
				mode="basic"
				chooseLabel="File"
				accept=".zip"
				[maxFileSize]="10000000"
				(onSelect)="service.onUploadZipWorkPlan($event)"
			></p-fileUpload>
		</div>
		<div class="text-right">
			<p-button label="Import" (onClick)="service.importWorkPlan()"></p-button>
		</div>
	</div>
</p-dialog>
-->
