<svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M1.24526 2.93303C1.933 2.93303 2.49053 2.38838 2.49053 1.71652C2.49053 1.04465 1.933 0.5 1.24526 0.5C0.557524 0.5 0 1.04465 0 1.71652C0 2.38838 0.557524 2.93303 1.24526 2.93303Z"
        fill="#1B54F8"
    />
    <path
        d="M17.9989 0.765625H4.9326C4.37965 0.765625 3.9314 1.20353 3.9314 1.74372V1.74439C3.9314 2.28458 4.37965 2.72248 4.9326 2.72248H17.9989C18.5518 2.72248 19.0001 2.28458 19.0001 1.74439V1.74372C19.0001 1.20353 18.5518 0.765625 17.9989 0.765625Z"
        fill="#1B54F8"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.42193 6.98409C6.42193 7.65596 5.8644 8.20061 5.17666 8.20061C4.48892 8.20061 3.9314 7.65596 3.9314 6.98409C3.9314 6.31223 4.48892 5.76758 5.17666 5.76758C5.8644 5.76758 6.42193 6.31223 6.42193 6.98409ZM8.863 6.03254H17.9986C18.5516 6.03254 18.9998 6.47044 18.9998 7.01063V7.0113C18.9998 7.55149 18.5516 7.98939 17.9986 7.98939H8.863C8.31005 7.98939 7.8618 7.55149 7.8618 7.0113V7.01063C7.8618 6.47044 8.31005 6.03254 8.863 6.03254Z"
        fill="#C4C4FF"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3531 12.2829C10.3531 12.9548 9.79555 13.4994 9.10781 13.4994C8.42007 13.4994 7.86255 12.9548 7.86255 12.2829C7.86255 11.6111 8.42007 11.0664 9.10781 11.0664C9.79555 11.0664 10.3531 11.6111 10.3531 12.2829ZM12.7944 11.3314H17.9987C18.5517 11.3314 18.9999 11.7693 18.9999 12.3095V12.3101C18.9999 12.8503 18.5517 13.2882 17.9987 13.2882H12.7944C12.2415 13.2882 11.7932 12.8503 11.7932 12.3101V12.3095C11.7932 11.7693 12.2415 11.3314 12.7944 11.3314Z"
        fill="#C4C4FF"
    />
</svg>
