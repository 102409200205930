import { ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { LayoutService } from '../../../layout/service/app.layout.service';
import { AiExplorerService } from './ai-explorer.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
	templateUrl: './ai-explorer.component.html',
	styleUrls: ['./ai-explorer.component.scss'],
})
export class AiExplorerComponent implements OnInit {
	@ViewChild('scrollList') private scrollList: ElementRef;
	@ViewChild('scrollGrid') private scrollGrid: ElementRef;
	@ViewChildren('op') ops: QueryList<OverlayPanel>;
	@ViewChild('opOverallFilter') opOverallFilter: OverlayPanel;

	constructor(
		public cdr: ChangeDetectorRef,
		public layoutService: LayoutService,
		public aiExplorer: AiExplorerService,
	) {}

	// Side Filter

	showSide = true;
	showSideExtend = true;
	containerWidth = 0;
	openMenu = false;

	toggleSide() {
		this.showSide = !this.showSide;
	}

	openSide() {
		if (this.showSideExtend && !this.showSide) {
			this.showSide = true;
		}
	}

	sizeSide() {
		const container: HTMLDivElement | null = document.querySelector('#page-taxonomy');
		if (container) {
			const containerWidth = container.offsetWidth;

			if (containerWidth !== this.containerWidth) {
				this.containerWidth = containerWidth;
				if (containerWidth < 990 && this.layoutService.isDesktop() && this.openMenu) {
					this.layoutService.onMenuClose();
					this.openMenu = true;
				}
				if (containerWidth < 880 && this.showSide) {
					this.showSide = false;
					this.showSideExtend = false;
				}

				/*if (containerWidth >= 990 && this.layoutService.isDesktop() && !this.openMenu && this.showSide) {
					this.layoutService.onMenuOpen();
					this.openMenu = false;
				}*/
				if (containerWidth >= 880 && !this.showSideExtend) {
					this.showSide = true;
					this.showSideExtend = true;
				}
			}
		}
	}

	// Content

	activeFilter: boolean = true;

	async ngOnInit() {
		this.layoutService.updateTitle('Taxonomy Explorer');

		this.aiExplorer.onUpdate = () => {
			this.cdr.detectChanges();
		};


		if (!this.aiExplorer.init) {
			await this.aiExplorer.prepareData(
				() => ({
					scrollList: this.scrollList,
					scrollGrid: this.scrollGrid,
				}),
				() => {},
			);
		}

		if (this.opOverallFilter) {
			this.opOverallFilter.onHide.subscribe(() => {
				this.activeFilter = true;
			});
		}

		window.addEventListener('resize', () => this.sizeSide());

		this.sizeSide();
	}

	tooltipText = '';
	tooltipTitle = '---';

	tooltipTexts = [
		'Provides context-based knowledge that can create content & enable autonomous operations',
		'Processes unstructured, complex information and provides insights, and predictions driven by algorithms',
		'Focuses on linguistic interaction and optimizes conversations using natural language processing (NLP) and natural language generation (NLG)',
		'Captures and extracts information from analog and digital inputs',
		'Includes Process Mining, Business Process Management, Workflow',
		'Automates rules-based task on structured data',
	];

	overlayTechnologyShow(i: number, evt: any) {
		this.overlayTechnologyHide(i);
		const op = this.ops.get(i);
		if (op) {
			op.show(evt);
		}
		this.tooltipText = this.tooltipTexts[i] || '';
		const t = this.aiExplorer.technologies[i];
		if (t) {
			this.tooltipTitle = t.name;
		}
	}

	overlayTechnologyHide(i?: number) {
		this.tooltipText = '';
		this.tooltipTitle = '---';
		this.ops.map((op, j) => {
			if ((i && j !== i) || !i) {
				op.hide();
			}
		});
	}

	overallFilter: number[] = [1, 2, 3];

	opOverallFilterVisible(event: any) {
		if (this.activeFilter) {
			this.opOverallFilter.show(event);
		} else {
			this.opOverallFilter.hide();
		}

		this.activeFilter = !this.activeFilter;
	}

	filterView: number = 3;

	filterViewOptions: { value: number }[] = [{ value: 1 }, { value: 2 }, { value: 3 }];

	filterTab: number = 0;

	filterTabOptions: { value: number }[] = [{ value: 0 }, { value: 1 }];
}
