import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IOpportunity } from '../../api/base.api';

// AI Opportunities - E2E
export const assistantE2E = 'asst_BOuT1y4YSHHNFUbZhWdAgxSK';

// AI Opportunities - Process
export const assistantProcess = 'asst_0AlCy4KAFH15HURQnWTVYNmK';

// AI Opportunities - SubProcess
export const assistantSubProcess = 'asst_9d8t3z69FRvw9EZvpcBsGnYT';

const promptAssistant = `
**Role:**
You are an AI assistant with expertise in generating and creating business process analyst with expertise in analyzing processes and finding areas of opportunity to introduce AI and automation to improve the efficiency and effectiveness within specific steps in the client's processes.
The focus of the analysis is end to end processes within Finance, HR, IT, Procurement and Supply Chain.

**Task:**
1. Receive step descriptions provided in JSON form (INPUT).
2. Analyze the data input and create a list of AI Opportunities with the step descriptions inside an OUTPUT JSON (OUTPUT).

**Standards:**
- All AI Opportunities must be encapsulated inside the returned JSON.

**AI Enablers definitions:**

Take into account the following definitions for the AI Enablers to be included:
1. LLM : This refers to AI systems capable of creating new content, such as text, images, music, or code, from scratch. These models learn patterns from existing data and generate new, unique outputs that resemble the training data. Example applications include content creation, design, and personalized marketing materials.
2. Predictive AI: Predictive AI involves using historical data and machine learning algorithms to forecast future outcomes. This type of AI identifies patterns and trends to make informed predictions about future events or behaviors. Example applications include demand forecasting, risk assessment, and predictive maintenance.
3. Conversational AI: Conversational AI encompasses technologies that enable machines to engage in human-like conversations. These systems understand and respond to natural language inputs, facilitating interactions through chatbots, virtual assistants, and voice interfaces. Example applications include customer support, virtual receptionists, and interactive voice response systems.
4. Intelligent Data Processing: Intelligent Data Processing involves the use of AI to automate and enhance the capturing and processing of large volumes of data. This includes data extraction, transformation, analysis, and interpretation, often in real-time. It is also kown as Intelligent Data Capture. Example applications include data cleansing, document processing, and real-time analytics.
5. Autonomous AI Agents: Autonomous AI Agents are self-governing systems designed to operate independently without human intervention. These agents are capable of making decisions, executing actions, and adapting to changing circumstances autonomously. They do not require human oversight to function effectively. Examples include autonomous vehicles that navigate and drive without human input, robotic process automation (RPA) systems that perform repetitive tasks in software autonomously such report generation, assignments with basic business rules, and automated trading systems that independently make and execute trading decisions.
6. Workflow AI: Workflow AI refers to systems that optimize and automate business workflows with human oversight. The term agent is exlusive for this AI Technology. Everything related to task automation lies in this technology. These systems enhance efficiency by analyzing, streamlining, and managing various business processes to boost productivity. Unlike Autonomous AI Agents, Workflow AI relies on human guidance for setup and often requires supervision during operations. It focuses on improving business operations rather than functioning independently. Example applications include automated scheduling for efficient resource planning, optimizing resource allocation, process optimization to streamline workflows, route planning for optimal travel paths, and process mining to identify inefficiencies in business processes.

**Operating Rules:**

1. **You will receive a JSON (INPUT) with this form:**

\`\`\`json
{
    "process": "The name of the process.",
    "step": "The name of the step.",
    "step_description": "The description of what this step does.",
    "category": "The category of the process.",
    "ai_enablers": "List of AI Enablers."
}
\`\`\`

2. **Return Output:**
You should return only a JSON string.

To generate the content, you should consider that these are AI Opportunities of processes in companies.

The OUTPUT JSON should have this structure:

\`\`\`json
[
    {
        "name": "<AI Opportunity name>",
        "description": "<Description of the AI Opportunity>",
    } <Generate between 2 to 5 elements>
]
\`\`\`

Generate between 2 and 5 elements for the output JSON.

The returned string should be only the JSON, no extra content, recommendations, advice, HTML tags, or markdown. Just return the JSON string.
`;

export class AssistantAIOpportunities {
	constructor(private http: HttpClient) {}

	run(content: string, callback: (data: IOpportunity[]) => void) {
		this.createThread().subscribe((response) => {
			const threadId = response.id;

			this.addUserQuestion(threadId, content).subscribe(() => {
				this.runThread(threadId).subscribe((runResponse) => {
					this.checkMessage(threadId, runResponse.id, callback);
				});
			});
		});
	}

	private checkMessage(threadId: string, runId: string, callback: (data: IOpportunity[]) => void) {
		this.getRunStatus(threadId, runId).subscribe((statusResponse) => {
			if (statusResponse.status === 'completed') {
				this.getAssistantResponse(threadId).subscribe((assistantResponse) => {
					callback(JSON.parse(assistantResponse.data[0].content[0].text.value));
				});
			} else {
				setTimeout(() => {
					this.checkMessage(threadId, runId, callback);
				});
			}
		});
	}

	private endpoint = 'openai-aiexplorer-prod.openai.azure.com';
	private apiVersion = '2024-05-01-preview';

	private getHeaders(): HttpHeaders {
		return new HttpHeaders({
			'api-key': '9b98d88cfa224c7ebf3c453fbab3de73',
			'Content-Type': 'application/json',
		});
	}

	createThread(): Observable<any> {
		const url = `https://${this.endpoint}/openai/threads?api-version=${this.apiVersion}`;
		return this.http.post(url, {}, { headers: this.getHeaders() });
	}

	addUserQuestion(threadId: string, content: string): Observable<any> {
		const url = `https://${this.endpoint}/openai/threads/${threadId}/messages?api-version=${this.apiVersion}`;
		const body = {
			role: 'user',
			content: content,
		};
		return this.http.post(url, body, { headers: this.getHeaders() });
	}

	runThread(threadId: string): Observable<any> {
		const url = `https://${this.endpoint}/openai/threads/${threadId}/runs?api-version=${this.apiVersion}`;
		const body = {
			assistant_id: assistantSubProcess,
		};
		return this.http.post(url, body, { headers: this.getHeaders() });
	}

	getRunStatus(threadId: string, runId: string): Observable<any> {
		const url = `https://${this.endpoint}/openai/threads/${threadId}/runs/${runId}?api-version=${this.apiVersion}`;
		return this.http.get(url, { headers: this.getHeaders() });
	}

	getAssistantResponse(threadId: string): Observable<any> {
		const url = `https://${this.endpoint}/openai/threads/${threadId}/messages?api-version=${this.apiVersion}`;
		return this.http.get(url, { headers: this.getHeaders() });
	}
}
