export class ShapeColors {
    getColorShape = {
        'data-object': 'rgb(0,0,0)',
        task: 'rgb(27,87,248)',
        'receive-task': 'rgb(27,87,248)',
        'message-event-definition': 'rgb(0,0,0)',
        'send-task': 'rgb(27,87,248)',
        'end-event': 'rgb(0,0,0)',
        'time-event-definition': 'rgb(0,0,0)',
        'user-task': 'rgb(127,170,58)',
        'service-task': 'rgb(26,177,191)',
        subprocess: 'rgb(0,0,0)',
        'start-event': 'rgb(0,0,0)',
        participant: 'rgb(0,0,0)',
        'exclusive-gateway': 'rgb(0,0,0)',
        'cognitive-task': 'rgb(102,102,153)',
        'data-capture-task': 'rgb(83,25,80)',
        'nlp-task': 'rgb(69,69,149)',
        'call-activity': 'rgb(255,255,255)',
    };

    getColorDiagramShape: {
        [k: string]: [string, string];
    } = {
        'bpmn:DataObjectReference': [this.getColorShape['data-object'], ''],
        'bpmn:Task': ['white', this.getColorShape['task']],
        'bpmn:ReceiveTask': ['white', this.getColorShape['receive-task']],
        'bpmn:MessageEventDefinition': [this.getColorShape['message-event-definition'], ''],
        'bpmn:SendTask': ['white', this.getColorShape['send-task']],
        'bpmn:EndEvent': [this.getColorShape['end-event'], ''],
        'bpmn:TimerEventDefinition': [this.getColorShape['time-event-definition'], ''],
        'bpmn:UserTask': ['white', this.getColorShape['user-task']],
        'bpmn:ServiceTask': ['white', this.getColorShape['service-task']],
        'bpmn:SubProcess': [this.getColorShape['subprocess'], ''],
        'bpmn:StartEvent': [this.getColorShape['start-event'], ''],
        'bpmn:Participant': [this.getColorShape['participant'], ''],
        'bpmn:ExclusiveGateway': [this.getColorShape['exclusive-gateway'], ''],
        'bpmn:ManualTask': ['white', this.getColorShape['cognitive-task']],
        'bpmn:BusinessRuleTask': ['white', this.getColorShape['data-capture-task']],
        'bpmn:ScriptTask': ['white', this.getColorShape['nlp-task']],
        'bpmn:CallActivity': ['rgb(67,67,67)', this.getColorShape['call-activity']],
    };

    getColorPaletteShape = [
        {
            'class-name': '.bpmn-icon-start-event-none',
            color: this.getColorShape['start-event'],
        },
        {
            'class-name': '.bpmn-icon-end-event-none',
            color: this.getColorShape['end-event'],
        },
        {
            'class-name': '.bpmn-icon-gateway-xor',
            color: this.getColorShape['exclusive-gateway'],
        },
        {
            'class-name': '.bpmn-icon-task',
            color: this.getColorShape['task'],
        },
        {
            'class-name': '.bpmn-icon-data-object',
            color: this.getColorShape['data-object'],
        },
        {
            'class-name': '.bpmn-icon-subprocess-expanded',
            color: this.getColorShape['subprocess'],
        },
        {
            'class-name': '.bpmn-icon-participant',
            color: this.getColorShape['participant'],
        },
    ];

    constructor() {}
}
