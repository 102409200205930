import { HttpLink, HttpLinkHandler } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from '../../../environments/environment';
import { APOLLO_OPTIONS } from 'apollo-angular';

/**
 * GraphqlService object that provides options for Apollo GraphQL configuration.
 * @typedef {Object} GraphqlService
 * @property {string} provide - The provider key for the Apollo options.
 * @property {Function} useFactory - A factory function that creates the Apollo options object.
 * @property {Array} deps - An array of dependencies to be injected into the factory function.
 */
export const GraphqlService = {
	provide: APOLLO_OPTIONS,
	/**
	 * Factory function to create cache and link objects.
	 *
	 * @param {HttpLink} httpLink - The HttpLink instance to use for creating the link object.
	 * @returns {{
	 *   cache: InMemoryCache,
	 *   link: HttpLinkHandler
	 * }} - An object containing the created cache and link objects.
	 */
	useFactory(httpLink: HttpLink): {
		cache: InMemoryCache;
		link: HttpLinkHandler;
	} {
		const link = httpLink.create({
			uri: environment.url + '/graphql',
		});

		const typePolicies: { [key: string]: any } = {};

		for (const typePolicy of [
			'E2ETaxonomyQuery',
			'UseCaseQuery',
			'SolutionQuery',
			'CategoryFunctionModelQuery',
			'DiagramTemplateModelQuery',
			'DigitalWorkerModelQuery',
			'FunctionModelQuery',
			'AiLeverModelQuery',
			'CategoryQuestionModelQuery',
			'FunctionFilterModelQuery',
			'DiagramModelQuery',
			'UseCaseModelQuery',
			'SimulationQuery',
			'EnterpriseContextModelQuery',
			'SimulationModelQuery',
			'UseCaseSimulationModelQuery',
		]) {
			typePolicies[typePolicy] = {
				keyFields: [],
				merge(existing: any, incoming: any) {
					return { ...existing, ...incoming };
				},
			};
		}

		const cache = new InMemoryCache({
			typePolicies,
		});

		return {
			cache,
			link,
		};
	},
	deps: [HttpLink],
};
