import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuChangeEvent } from './api/menuchangeevent';

@Injectable({
	providedIn: 'root',
})
export class MenuService {
	public menuSource = new Subject<MenuChangeEvent>();
	public resetSource = new Subject();

	menuSource$ = this.menuSource.asObservable();
	resetSource$ = this.resetSource.asObservable();

	onMenuStateChange(event: MenuChangeEvent) {
		this.menuSource.next(event);
	}

	reset() {
		this.resetSource.next(true);
	}
}
