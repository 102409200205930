<p-toast></p-toast>

<app-blockable-div #content class="card card-content-stepper flex flex-column gap-2 py-0">
	<ng-template [ngIf]="!loading">
		<div class="flex justify-content-between gap-3">
			<p class="text-primary text-2xl mb-0 mt-3">{{ labels[current_index] }}</p>
			<div class="flex justify-content-between gap-3">
				<p-steps
					[model]="items"
					[readonly]="false"
					[activeIndex]="current_index"
					(activeIndexChange)="changeStep($event)"
					styleClass="hidden md:block lg:block xl:block"
				>
				</p-steps>
				<div>
					<p-button
						icon="pi pi-times"
						styleClass="close-button p-button-rounded p-button-outlined p-button-sm"
						(onClick)="close()"
					></p-button>
				</div>
			</div>
		</div>
		<div class="overflow-scroll scroll-height">
			<ng-template [ngIf]="current_index < data.length">
				<div class="grid text-stepper mb-4 border-none text-color-secondary">
					<div class="col-5"><label>Questions</label></div>
					<div class="col-3"><label>Score</label></div>
					<div class="col-4"><label>Comments</label></div>
				</div>
				<ng-template ngFor let-subcategory [ngForOf]="data[current_index]">
					<p class="mb-0 mt-2 text-primary font-bold">{{ subcategory.label }}</p>
					<div *ngFor="let question of subcategory.questions" class="grid text-stepper mt-1">
						<div class="col-5 flex align-content-end" style="padding-top: 0; padding-bottom: 0">
							<span class="flex align-items-center justify-content-center py-2">
								{{ question.text }}
							</span>
						</div>
						<div class="col-3" style="padding-top: 0; padding-bottom: 0">
							<ngx-slider
								[options]="getFormSliderOptions(question)"
								[value]="question.value"
								(userChangeEnd)="updateForm($event, question)"
							>
							</ngx-slider>
						</div>
						<div
							class="col-4 flex align-content-end"
							style="padding-right: 0; padding-top: 0; padding-bottom: 0"
						>
							<div class="flex align-items-center justify-content-center" style="width: 100%">
								<input
									placeholder="add notes"
									class="w-full comments"
									[value]="question.comment"
									(blur)="updateCommentForm($event, question)"
								/>
							</div>
						</div>
					</div>
				</ng-template>
			</ng-template>
			<ng-template [ngIf]="current_index === data.length">
				<div class="flex flex-column align-items-start gap-2">
					<div class="stepper">
						<ng-template ngFor let-phase let-i="index" [ngForOf]="phases">
							<div class="arrow" [ngClass]="getActiveClass(i)" (click)="changeActiveIndexTab(i)">
								{{ phase }}
							</div>
						</ng-template>
					</div>
					<p-tabView [activeIndex]="activeIndexTab" class="w-full">
						<p-tabPanel *ngFor="let content of contentsHtml; index as i">
							<div class="flex flex-column justify-content-start gap-3">
								<div class="flex justify-content-end gap-3">
									<p-button
										*ngIf="!contentEditable && !loadingEditable"
										icon="pi pi-pencil"
										styleClass="p-button-text"
										(onClick)="editContentEditable(i)"
									></p-button>
									<p-button
										*ngIf="contentEditable"
										icon="pi pi-save"
										styleClass="p-button-text"
										(onClick)="saveContentEditable(i)"
									></p-button>
									<p-button
										*ngIf="contentEditable"
										icon="pi pi-times"
										styleClass="p-button-text"
										(onClick)="closeContentEditable(i)"
									></p-button>
									<p-button
										*ngIf="!contentEditable"
										styleClass="p-button-text btnAI"
										tippy
										tippyContent="Regenerate content"
										[loading]="loadingEditable"
										(onClick)="getContentByAI(i)"
									>
										<ng-template pTemplate="icon">
											<svg
												*ngIf="!loadingEditable"
												width="19"
												height="20"
												viewBox="0 0 19 20"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g clip-path="url(#clip0_3650_2953)">
													<path
														d="M11.4405 5.18509L13.1208 9.4945C13.2 9.69617 13.3582 9.85539 13.5587 9.93499L17.8427 11.6253C18.4969 11.8827 18.4969 12.8141 17.8427 13.0715L13.5587 14.7618C13.3582 14.8415 13.2 15.0007 13.1208 15.2023L11.4405 19.5117C11.1846 20.1698 10.2587 20.1698 10.0029 19.5117L8.32252 15.2023C8.24338 15.0007 8.08511 14.8415 7.88463 14.7618L3.60069 13.0715C2.9465 12.8141 2.9465 11.8827 3.60069 11.6253L7.88463 9.93499C8.08511 9.85539 8.24338 9.69617 8.32252 9.4945L10.0029 5.18509C10.2587 4.52701 11.1846 4.52701 11.4405 5.18509Z"
														class="icon"
													/>
													<path
														d="M3.94102 2.85791L4.73502 4.89851C4.77195 4.99404 4.84581 5.06834 4.94078 5.10549L6.96932 5.90422C7.27795 6.02628 7.27795 6.46677 6.96932 6.58884L4.94078 7.38757C4.84581 7.42472 4.77195 7.49902 4.73502 7.59454L3.94102 9.63514C3.81968 9.94561 3.38179 9.94561 3.26044 9.63514L2.46644 7.59454C2.42951 7.49902 2.35565 7.42472 2.26068 7.38757L0.232146 6.58884C-0.0764869 6.46677 -0.0764869 6.02628 0.232146 5.90422L2.26068 5.10549C2.35565 5.06834 2.42951 4.99404 2.46644 4.89851L3.26044 2.85791C3.38179 2.54744 3.81968 2.54744 3.94102 2.85791Z"
														class="icon"
													/>
													<path
														d="M8.91868 0.12471L9.34338 1.21268C9.36449 1.26309 9.40406 1.3029 9.45418 1.32413L10.5357 1.75135C10.7019 1.81769 10.7019 2.05121 10.5357 2.11755L9.45418 2.54477C9.40406 2.566 9.36449 2.6058 9.34338 2.65622L8.91868 3.74419C8.85274 3.91136 8.6206 3.91136 8.55466 3.74419L8.12995 2.65622C8.10885 2.6058 8.06928 2.566 8.01916 2.54477L6.93763 2.11755C6.77144 2.05121 6.77144 1.81769 6.93763 1.75135L8.01916 1.32413C8.06928 1.3029 8.10885 1.26309 8.12995 1.21268L8.55466 0.12471C8.6206 -0.0424652 8.85274 -0.0424652 8.91868 0.12471Z"
														class="icon"
													/>
												</g>
												<defs>
													<clipPath id="clip0_3650_2953">
														<rect width="18.3333" height="20" fill="white" />
													</clipPath>
												</defs>
											</svg>
											<i *ngIf="loadingEditable" class="pi pi-spin pi-spinner"></i>
										</ng-template>
									</p-button>
									<p-button
										*ngIf="loadingEditable"
										icon="pi pi-times"
										styleClass="p-button-text"
										(onClick)="cancelContentByAI(i)"
									></p-button>
								</div>
								<div class="content">
									<div
										class="content-editable ml-1"
										[attr.id]="'content-' + i"
										[innerHTML]="contentsHtml[i]"
									></div>
								</div>
							</div>
						</p-tabPanel>
					</p-tabView>
				</div>
			</ng-template>
		</div>
		<div class="flex justify-content-between px-4 h-4rem">
			<div *ngIf="current_index < data.length" class="chart-data">
				<p-chart type="doughnut" [data]="chartData[current_index].data" [options]="chartDataOptions"></p-chart>
				<p class="percent">{{ chartData[current_index].value }}%</p>
			</div>
			<div *ngIf="current_index === data.length"></div>
			<div class="flex justify-content-end align-items-end gap-3 h-full">
				<p-button
					*ngIf="current_index"
					label="Back"
					icon="pi pi-angle-left"
					styleClass="button-stepper blink-nop"
					(onClick)="previousStep()"
				></p-button>
				<span *ngIf="!current_index" style="width: 5.3rem"></span>
				<p-button
					*ngIf="current_index < max_index"
					label="Next"
					icon="pi pi-angle-right"
					iconPos="right"
					styleClass="button-stepper blink-nop"
					(onClick)="nextStep()"
				></p-button>
				<p-button
					*ngIf="current_index === max_index"
					label="Finish"
					icon="pi pi-times"
					iconPos="right"
					styleClass="button-stepper blink-nop"
					(onClick)="close()"
				></p-button>
			</div>
		</div>
	</ng-template>
</app-blockable-div>

<p-blockUI [target]="content" [blocked]="loading">
	<i class="pi pi-spin pi-spinner" style="font-size: 4rem; color: white"></i>
</p-blockUI>
