<p-blockUI [blocked]="true">
	<div class="flex flex-column justify-content-between text-center layout-progress gap-4 notfound">
		<img src="assets/rtp/img/404_plug.svg" alt="" class="h-8rem">
		<div class="flex flex-column justify-content-center text-center gap-3">
			<span class="text-primary font-bold" style="font-size: 3rem">Oops!</span>
			<span class="font-bold" style="font-size: 1.5rem">There is nothing here</span>
		</div>
		<p-button
			label="Take me back home"
			styleClass="p-button-sm"
			(onClick)="goToHome()"
		></p-button>
	</div>
</p-blockUI>
