import { Injectable } from '@angular/core';
import Dexie, { Table } from 'dexie';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import type { ApolloQueryResult, DocumentNode, TypedDocumentNode } from '@apollo/client/core';
import { environment } from '../../../environments/environment';

import { CommonData } from '../api/common';
import {
	CategoryFunction,
	Function,
	Level1,
	Level2,
	DiagramE2E,
	DiagramProcess,
	DiagramTemplate,
	Technology,
	DigitalWorker,
} from '../api/e2e-taxonomy.api';
import {
	FunctionFilter,
	Skill,
	Solution,
	Tool,
	Flow,
	CategoryQuestion,
	Question,
	AiLever,
	SubCategoryQuestion,
	Icon,
} from '../api/solution.api';
import { Diagram, UseCase } from '../api/use-case.api';
import {
	AssessmentMaturity,
	CategoryQuestionMaturity,
	SubCategoryQuestionMaturity,
	QuestionMaturity,
} from '../api/maturity.api';
import { EnterpriseContext, Simulation, UseCaseSimulation, UseCaseLibrary, M1Prep } from '../api/simulation.api';
import {
	TypeQueryData,
	queryData1,
	queryData2,
	queryData3,
	queryData4,
	queryData5,
	queryData6,
	queryData7,
	queryData8,
} from './services.data/query.data';
import { plainToInstance } from 'class-transformer';

type TypeHook =
	| 'function'
	| 'level1'
	| 'level2'
	| 'diagram_e2e'
	| 'diagram_process'
	| 'diagram_template'
	| 'tool'
	| 'solution'
	| 'flow'
	| 'use_case'
	| 'diagram'
	| 'category_question_maturity'
	| 'question_maturity'
	| 'sub_category_question_maturity'
	| 'assessment_maturity'
	| 'enterprise_context'
	| 'simulation'
	| 'use_case_simulation'
	| 'use_case_library'
	| 'm1_prep';

interface MessageToast {
	summary: string;
	detail: string;
}

type ModelEvent =
	| 'CommonData'
	| 'CategoryFunction'
	| 'Function'
	| 'Level1'
	| 'Level2'
	| 'DiagramE2E'
	| 'DiagramProcess'
	| 'DiagramTemplate'
	| 'Technology'
	| 'DigitalWorker'
	| 'FunctionFilter'
	| 'Tool'
	| 'Skill'
	| 'AiLever'
	| 'Solution'
	| 'Flow'
	| 'CategoryQuestion'
	| 'SubCategoryQuestion'
	| 'Question'
	| 'Diagram'
	| 'UseCase'
	| 'EnterpriseContext'
	| 'Simulation'
	| 'UseCaseSimulation'
	| 'UseCaseLibrary'
	| 'M1Prep';

interface EventData {
	type: string;
	event: 'create' | 'update' | 'delete';
	object_id: string;
	object_ids: string[];
	model_name: 'user' | 'Organization' | ModelEvent;
	user_id: string;
	module: '0' | '1' | '2' | '3' | '4' | '5';
}

@Injectable({
	providedIn: 'root',
})
export class DbService extends Dexie {
	data_common!: Table<CommonData<any>, string>;

	data_category_function!: Table<CategoryFunction, string>;
	data_function!: Table<Function, string>;
	data_level1!: Table<Level1, string>;
	data_level2!: Table<Level2, string>;
	data_diagram_e2e!: Table<DiagramE2E, string>;
	data_diagram_process!: Table<DiagramProcess, string>;
	data_diagram_template!: Table<DiagramTemplate, string>;
	data_technology!: Table<Technology, string>;
	data_digital_worker!: Table<DigitalWorker, string>;

	data_function_filter!: Table<FunctionFilter, string>;
	data_tool!: Table<Tool, string>;
	data_skill!: Table<Skill, string>;
	data_aiLever!: Table<AiLever, string>;
	data_solution!: Table<Solution, string>;
	data_flow!: Table<Flow, string>;
	data_category_question!: Table<CategoryQuestion, string>;
	data_sub_category_question!: Table<SubCategoryQuestion, string>;
	data_question!: Table<Question, string>;
	data_icon!: Table<Icon, string>;

	data_use_case!: Table<UseCase, string>;
	data_diagram!: Table<Diagram, string>;

	data_category_question_maturity!: Table<CategoryQuestionMaturity, string>;
	data_sub_category_question_maturity!: Table<SubCategoryQuestionMaturity, string>;
	data_question_maturity!: Table<QuestionMaturity, string>;
	data_assessment_maturity!: Table<AssessmentMaturity, string>;

	data_enterprise_context!: Table<EnterpriseContext, string>;
	data_simulation!: Table<Simulation, string>;
	data_use_case_simulation!: Table<UseCaseSimulation, string>;
	data_use_case_library!: Table<UseCaseLibrary, string>;
	data_m1_prep!: Table<M1Prep, string>;

	public dbInitSource = new Subject<string>();
	dbInit = false;
	dbInitSource$ = this.dbInitSource.asObservable();
	public dbErrorSource = new Subject<string>();
	dbError = false;
	dbErrorSource$ = this.dbErrorSource.asObservable();
	hasSetHooks = false;
	canHooks = true;

	public dbOrganizationInitSource = new Subject<string>();
	dbOrganizationInitLoad = false;
	dbOrganizationInit = false;
	dbOrganizationInitSource$ = this.dbOrganizationInitSource.asObservable();

	public dbMessageToast = new Subject<MessageToast>();
	dbMessageToastSource$ = this.dbMessageToast.asObservable();

	constructor(public http: HttpClient, public apollo: Apollo) {
		super('rtp-' + environment.version);

		this.prepare();

		this.webSocket();
	}

	public async prepare() {
		this.version(environment.version).stores({
			data_common: 'key',

			data_category_function: 'id, name, short, sort',
			data_function: 'id, name, short, sort, categoryId, [id+categoryId]',
			data_level1: 'id, name, functionId, [id+functionId]',
			data_level2: 'id, name, level1Id, [id+level1Id]',
			data_diagram_e2e: 'id, functionId',
			data_diagram_process: 'id, level1Id',
			data_diagram_template: 'id, level2Id',
			data_technology: 'id, name, short',
			data_digital_worker: 'id, name, technologyId, [id+technologyId]',

			data_function_filter: 'id, name',
			data_tool: 'id, name',
			data_skill: 'id, name',
			data_aiLever: 'id, name',
			data_solution: 'id, name',
			data_flow: 'id, solutionId',
			data_category_question: 'id, name, sort',
			data_sub_category_question: 'id, name, sort, categoryId',
			data_question: 'id, categoryId, technologyId',
			data_icon: 'id',

			data_use_case: 'id, name, solutionId, organizationId',
			data_diagram: 'id, useCaseId, organizationId',

			data_category_question_maturity: 'id, name, sort',
			data_sub_category_question_maturity: 'id, name, sort, categoryId',
			data_question_maturity: 'id, categoryId',
			data_assessment_maturity: 'id, organizationId',

			data_enterprise_context: 'id, organizationId',
			data_simulation: 'id, organizationId',
			data_use_case_simulation: 'id, simulationId, organizationId, deployedId',
			data_use_case_library: 'id, sourceId, sourceRel',
			data_m1_prep: 'id, organizationId',
		});

		this.data_common.mapToClass(CommonData);

		this.data_category_function.mapToClass(CategoryFunction);
		this.data_function.mapToClass(Function);
		this.data_level1.mapToClass(Level1);
		this.data_level2.mapToClass(Level2);
		this.data_diagram_e2e.mapToClass(DiagramE2E);
		this.data_diagram_process.mapToClass(DiagramProcess);
		this.data_diagram_template.mapToClass(DiagramTemplate);
		this.data_technology.mapToClass(Technology);
		this.data_digital_worker.mapToClass(DigitalWorker);

		this.data_function_filter.mapToClass(FunctionFilter);
		this.data_tool.mapToClass(Tool);
		this.data_skill.mapToClass(Skill);
		this.data_solution.mapToClass(Solution);
		this.data_flow.mapToClass(Flow);
		this.data_aiLever.mapToClass(AiLever);
		this.data_category_question.mapToClass(CategoryQuestion);
		this.data_sub_category_question.mapToClass(SubCategoryQuestion);
		this.data_question.mapToClass(Question);
		this.data_icon.mapToClass(Icon);

		this.data_use_case.mapToClass(UseCase);
		this.data_diagram.mapToClass(Diagram);

		this.data_category_question_maturity.mapToClass(CategoryQuestionMaturity);
		this.data_sub_category_question_maturity.mapToClass(SubCategoryQuestionMaturity);
		this.data_question_maturity.mapToClass(QuestionMaturity);
		this.data_assessment_maturity.mapToClass(AssessmentMaturity);

		this.data_enterprise_context.mapToClass(EnterpriseContext);
		this.data_simulation.mapToClass(Simulation);
		this.data_use_case_simulation.mapToClass(UseCaseSimulation);
		this.data_use_case_library.mapToClass(UseCaseLibrary);
		this.data_m1_prep.mapToClass(M1Prep);

		this.open()
			.then(() => {
				if (!environment.production) {
					console.log('DB Opened');
				}
			})
			.catch((err) => console.log(err.message));
	}

	async prepareSync() {
		const organizationId = localStorage.getItem('current_organization') || '';
		if (organizationId) {
			this.fullSync(organizationId);
		} else {
			this.baseSync();
		}
	}

	currentOrganizationId: string = '';

	baseSync() {
		this.canHooks = false;
		Promise.all([this.queryData1Sync(), this.queryData2Sync(), this.queryData3Sync(), this.queryData6Sync()])
			.then(() => {
				if (!environment.production) {
					console.log('Update primary data');
				}
				this.dbInit = true;
				this.dbInitSource.next(uuid());
				this.canHooks = true;
				if (!this.hasSetHooks) {
					this.setHooks();
					this.hasSetHooks = true;
				}
			})
			.catch((err) => {
				if (!environment.production) {
					console.log(err);
				}
				this.dbError = true;
				this.dbErrorSource.next(uuid());
			});
		this.queryData4Sync();
		this.queryData8Sync();
	}

	fullSync(organizationId: string) {
		this.canHooks = false;
		Promise.all([
			this.queryData1Sync(),
			this.queryData2Sync(),
			this.queryData3Sync(),
			this.queryData5Sync(organizationId),
			this.queryData6Sync(),
			this.queryData7Sync(organizationId),
		])
			.then(() => {
				if (!environment.production) {
					console.log('Update full data');
				}

				this.dbInit = true;

				this.dbOrganizationInit = false;
				this.dbOrganizationInitSource.next(uuid());

				this.dbOrganizationInitLoad = true;
				this.dbInitSource.next(uuid());

				this.dbOrganizationInit = true;
				this.dbOrganizationInitSource.next(uuid());

				this.canHooks = true;
				if (!this.hasSetHooks) {
					this.setHooks();
					this.hasSetHooks = true;
				}
			})
			.catch((err) => {
				if (!environment.production) {
					console.log(err);
				}
				this.dbError = true;
				this.dbErrorSource.next(uuid());
			});
		this.queryData4Sync();
		this.queryData8Sync();
	}

	organizationSync(organizationId: string, force: boolean = true, next?: () => void) {
		this.canHooks = false;
		this.currentOrganizationId = organizationId;
		if (force) {
			this.dbOrganizationInit = false;
			this.dbOrganizationInitSource.next(uuid());
		}
		Promise.all([this.queryData5Sync(organizationId), this.queryData7Sync(organizationId)])
			.then(() => {
				if (!environment.production) {
					console.log('Update organization data');
				}
				if (force) {
					this.dbOrganizationInit = true;
					this.dbOrganizationInitSource.next(uuid());
				}
				this.canHooks = true;
				if (!this.hasSetHooks) {
					this.setHooks();
					this.hasSetHooks = true;
				}
				if (next) {
					next();
				}
			})
			.catch((err) => {
				if (!environment.production) {
					console.log(err);
				}
				this.dbError = true;
				this.dbErrorSource.next(uuid());
			});
	}

	queryData<T = any>(query: DocumentNode | TypedDocumentNode<T>) {
		return new Promise((resolve, reject) => {
			this.apollo
				.watchQuery<T>({
					query,
					fetchPolicy: 'network-only',
				})
				.valueChanges.subscribe({
					next: async (result) => {
						resolve(result);
					},
					error: (err) => {
						reject(err);
					},
				});
		});
	}

	async queryData1Sync() {
		return new Promise((resolve, reject) => {
			this.apollo
				.watchQuery<TypeQueryData>({
					query: queryData1,
					fetchPolicy: 'network-only',
				})
				.valueChanges.subscribe({
					next: async (result) => {
						resolve(this.queryData1SyncData(result));
					},
					error: (err) => {
						reject(err);
					},
				});
		});
	}

	async queryData1SyncData(result: ApolloQueryResult<TypeQueryData>) {
		return Promise.all([
			this.get_data_query(result.data.common.commonData.all, this.data_common, CommonData),
			this.get_data_query(
				result.data.e2eTaxonomy.categoryFunction.all,
				this.data_category_function,
				CategoryFunction,
			),
			this.get_data_query(result.data.e2eTaxonomy.function.all, this.data_function, Function),
			this.get_data_query(result.data.e2eTaxonomy.level1.all, this.data_level1, Level1),
			this.get_data_query(result.data.e2eTaxonomy.level2.all, this.data_level2, Level2),
			this.get_data_query(result.data.e2eTaxonomy.technology.all, this.data_technology, Technology),
			this.get_data_query(result.data.e2eTaxonomy.digitalWorker.all, this.data_digital_worker, DigitalWorker),
		]);
	}

	async queryData2Sync() {
		return new Promise((resolve, reject) => {
			this.apollo
				.watchQuery<TypeQueryData>({
					query: queryData2,
					fetchPolicy: 'network-only',
				})
				.valueChanges.subscribe({
					next: async (result) => {
						resolve(this.queryData2SyncData(result));
					},
					error: (err) => {
						reject(err);
					},
				});
		});
	}

	async queryData2SyncData(result: ApolloQueryResult<TypeQueryData>) {
		return Promise.all([
			this.get_data_query(result.data.e2eTaxonomy.diagramE2e.all, this.data_diagram_e2e, DiagramE2E),
		]);
	}

	async queryData3Sync() {
		return new Promise((resolve, reject) => {
			this.apollo
				.watchQuery<TypeQueryData>({
					query: queryData3,
					fetchPolicy: 'network-only',
				})
				.valueChanges.subscribe({
					next: async (result) => {
						resolve(this.queryData3SyncData(result));
					},
					error: (err) => {
						reject(err);
					},
				});
		});
	}

	async queryData3SyncData(result: ApolloQueryResult<TypeQueryData>) {
		return Promise.all([
			this.get_data_query(result.data.e2eTaxonomy.diagramProcess.all, this.data_diagram_process, DiagramProcess),
		]);
	}

	queryData4SyncSuccessful: boolean = false;
	queryData4SyncSubject: Subject<boolean> = new Subject<boolean>();
	queryData4SyncObservable = this.queryData4SyncSubject.asObservable();

	async queryData4Sync() {
		const res = new Promise((resolve, reject) => {
			this.apollo
				.watchQuery<TypeQueryData>({
					query: queryData4,
					fetchPolicy: 'network-only',
				})
				.valueChanges.subscribe({
					next: async (result) => {
						resolve(this.queryData4SyncData(result));
					},
					error: (err) => {
						reject(err);
					},
				});
		});
		res.then(() => {
			this.queryData4SyncSuccessful = true;
			this.queryData4SyncSubject.next(true);
		});
		return res;
	}

	async queryData4SyncData(result: ApolloQueryResult<TypeQueryData>) {
		return Promise.all([
			this.get_data_query(
				result.data.e2eTaxonomy.diagramTemplate.all,
				this.data_diagram_template,
				DiagramTemplate,
			),
		]);
	}

	async queryData5Sync(organizationId: string) {
		this.currentOrganizationId = organizationId;

		return new Promise((resolve, reject) => {
			this.apollo
				.watchQuery<TypeQueryData>({
					query: queryData5,
					variables: {
						organizationId: organizationId,
					},
					fetchPolicy: 'network-only',
				})
				.valueChanges.subscribe({
					next: async (result) => {
						resolve(this.queryData5SyncData(result));
					},
					error: (err) => {
						reject(err);
					},
				});
		});
	}

	async queryData5SyncData(result: ApolloQueryResult<TypeQueryData>) {
		return Promise.all([
			this.get_data_query(result.data.useCase.useCase.all, this.data_use_case, UseCase),
			this.get_data_query(result.data.useCase.diagram.all, this.data_diagram, Diagram),
			this.get_data_query(result.data.maturity.assessment.all, this.data_assessment_maturity, AssessmentMaturity),
			this.get_data_query(
				result.data.maturity.categoryQuestion.all,
				this.data_category_question_maturity,
				CategoryQuestionMaturity,
			),
			this.get_data_query(result.data.maturity.question.all, this.data_question_maturity, QuestionMaturity),
			this.get_data_query(
				result.data.maturity.subCategoryQuestion.all,
				this.data_sub_category_question_maturity,
				SubCategoryQuestionMaturity,
			),
		]);
	}

	async queryData6Sync() {
		return new Promise((resolve, reject) => {
			this.apollo
				.watchQuery<TypeQueryData>({
					query: queryData6,
					fetchPolicy: 'network-only',
				})
				.valueChanges.subscribe({
					next: async (result) => {
						resolve(this.queryData6SyncData(result));
					},
					error: (err) => {
						reject(err);
					},
				});
		});
	}

	async queryData6SyncData(result: ApolloQueryResult<TypeQueryData>) {
		return Promise.all([
			this.get_data_query(
				result.data.solution.categoryQuestion.all,
				this.data_category_question,
				CategoryQuestion,
			),
			this.get_data_query(
				result.data.solution.subCategoryQuestion.all,
				this.data_sub_category_question,
				SubCategoryQuestion,
			),
			this.get_data_query(result.data.solution.functionFilter.all, this.data_function_filter, FunctionFilter),
			this.get_data_query(result.data.solution.skill.all, this.data_skill, Skill),
			this.get_data_query(result.data.solution.aiLever.all, this.data_aiLever, AiLever),
			this.get_data_query(result.data.solution.question.all, this.data_question, Question),
			this.get_data_query(result.data.solution.tool.all, this.data_tool, Tool),
			this.get_data_query(result.data.solution.solution.all, this.data_solution, Solution),
			this.get_data_query(result.data.solution.flow.all, this.data_flow, Flow),
			this.get_data_query(result.data.solution.icon.all, this.data_icon, Icon),
		]);
	}

	async queryData7Sync(organizationId: string) {
		return new Promise((resolve, reject) => {
			this.apollo
				.watchQuery<TypeQueryData>({
					query: queryData7,
					variables: {
						organizationId: organizationId,
					},
					fetchPolicy: 'network-only',
				})
				.valueChanges.subscribe({
					next: async (result) => {
						resolve(this.queryData7SyncData(result));
					},
					error: (err) => {
						reject(err);
					},
				});
		});
	}

	async queryData7SyncData(result: ApolloQueryResult<TypeQueryData>) {
		return Promise.all([
			this.get_data_query(result.data.simulation.context.all, this.data_enterprise_context, EnterpriseContext),
			this.get_data_query(result.data.simulation.simulation.all, this.data_simulation, Simulation),
			this.get_data_query(
				result.data.simulation.useCaseSimulation.all,
				this.data_use_case_simulation,
				UseCaseSimulation,
			),
			this.get_data_query(result.data.simulation.m1Prep.all, this.data_m1_prep, M1Prep),
		]);
	}

	async queryData8Sync() {
		return new Promise((resolve, reject) => {
			this.apollo
				.watchQuery<TypeQueryData>({
					query: queryData8,

					fetchPolicy: 'network-only',
				})
				.valueChanges.subscribe({
					next: async (result) => {
						resolve(this.queryData8SyncData(result));
					},
					error: (err) => {
						reject(err);
					},
				});
		});
	}

	async queryData8SyncData(result: ApolloQueryResult<TypeQueryData>) {
		return Promise.all([
			this.get_data_query(result.data.simulation.useCaseLibrary.all, this.data_use_case_library, UseCaseLibrary),
		]);
	}

	async get_data_query<T>(data: T[], table: Table<T, string>, entity: any) {
		if (data && data.length) {
			await table.clear();
			const data_put: T[] = data.map((d) => {
				const nd = { ...d };
				(nd as any).created_at = (d as any).createdAt;
				(nd as any).updated_at = (d as any).updatedAt;
				if ((d as any).xmlData) {
					(nd as any).xml_data = (d as any).xmlData;
				}
				if ((d as any).xmlImage) {
					(nd as any).xml_image = (d as any).xmlImage;
				}
				if ((d as any).taxonomyRel) {
					let c = (d as any).taxonomyRel;
					if (c.split('_').length === 2) {
						c = c.split('_')[1];
					}
					(nd as any).taxonomyRel = c;
				}
				if ((d as any).sourceRel) {
					let c = (d as any).sourceRel;
					if (c.split('_').length === 2) {
						c = c.split('_')[1];
					}
					(nd as any).sourceRel = c;
				}
				if ((d as any).type) {
					let c = (d as any).type;
					if (c.split('_').length === 2) {
						c = c.split('_')[1];
					}
					(nd as any).type = c;
				}
				if ((d as any).office) {
					let c = (d as any).office;
					if (c.split('_').length === 2) {
						c = c.split('_')[1];
					}
					(nd as any).office = c;
				}
				return plainToInstance(entity, nd);
			});
			if (data_put.length) {
				await table.bulkPut(data_put);
			}
		}
	}

	sync_solution = true;
	sync_use_case = true;

	setHooks() {
		this.data_function.hook('updating').subscribe((obj, id) => {
			this.sync_solution = false;
			this.updateData('function', id, obj, () => {
				this.sync_solution = true;
			});
		});
		this.data_level1.hook('updating').subscribe((obj, id) => {
			this.sync_solution = false;
			this.updateData('level1', id, obj, () => {
				this.sync_solution = true;
			});
		});
		this.data_level2.hook('updating').subscribe((obj, id) => {
			this.sync_solution = false;
			this.updateData('level2', id, obj, () => {
				this.sync_solution = true;
			});
		});

		this.data_tool.hook('creating').subscribe((id, obj) => {
			this.createData('tool', obj);
		});

		this.data_solution.hook('creating').subscribe((id, obj) => {
			this.sync_solution = false;
			this.createData('solution', obj, () => {
				this.sync_solution = true;
			});
		});
		this.data_solution.hook('updating').subscribe((obj, id) => {
			this.sync_solution = false;
			this.updateData('solution', id, obj, () => {
				this.sync_solution = true;
			});
		});
		this.data_solution.hook('deleting').subscribe((id, obj) => {
			this.deleteData('solution', id);
		});
		this.data_use_case.hook('creating').subscribe((id, obj) => {
			this.sync_use_case = false;
			this.createData('use_case', obj, () => {
				this.sync_use_case = true;
			});
		});
		this.data_use_case.hook('updating').subscribe((obj, id) => {
			this.sync_use_case = false;
			this.updateData('use_case', id, obj, () => {
				this.sync_use_case = true;
			});
		});
		this.data_use_case.hook('deleting').subscribe((id, obj) => {
			this.deleteData('use_case', id);
		});

		this.data_assessment_maturity.hook('creating').subscribe((id, obj) => {
			this.sync_solution = false;
			this.createData('assessment_maturity', obj, () => {
				this.sync_solution = true;
			});
		});
		this.data_assessment_maturity.hook('updating').subscribe((obj, id) => {
			this.sync_solution = false;
			this.updateData('assessment_maturity', id, obj, () => {
				this.sync_solution = true;
			});
		});
		this.data_assessment_maturity.hook('deleting').subscribe((id, obj) => {
			this.deleteData('assessment_maturity', id);
		});

		this.data_enterprise_context.hook('creating').subscribe((id, obj) => {
			this.sync_solution = false;
			this.createData('enterprise_context', obj, () => {
				this.sync_solution = true;
			});
		});
		this.data_enterprise_context.hook('updating').subscribe((obj, id) => {
			this.sync_solution = false;
			this.updateData('enterprise_context', id, obj, () => {
				this.sync_solution = true;
			});
		});
		this.data_enterprise_context.hook('deleting').subscribe((id, obj) => {
			this.deleteData('enterprise_context', id);
		});

		this.data_simulation.hook('creating').subscribe((id, obj) => {
			this.sync_solution = false;
			this.createData('simulation', obj, () => {
				this.sync_solution = true;
			});
		});
		this.data_simulation.hook('updating').subscribe((obj, id) => {
			this.sync_solution = false;
			this.updateData('simulation', id, obj, () => {
				this.sync_solution = true;
			});
		});
		this.data_simulation.hook('deleting').subscribe((id, obj) => {
			this.deleteData('simulation', id);
		});

		this.data_use_case_simulation.hook('creating').subscribe((id, obj) => {
			this.sync_solution = false;
			this.createData('use_case_simulation', obj, () => {
				this.sync_solution = true;
			});
		});
		this.data_use_case_simulation.hook('updating').subscribe((obj, id) => {
			this.sync_solution = false;
			this.updateData('use_case_simulation', id, obj, () => {
				this.sync_solution = true;
			});
		});
		this.data_use_case_simulation.hook('deleting').subscribe((id, obj) => {
			this.deleteData('use_case_simulation', id);
		});
		this.data_use_case_library.hook('updating').subscribe((obj, id) => {
			this.sync_solution = false;
			this.updateData('use_case_library', id, obj, () => {
				this.sync_solution = true;
			});
		});
		this.data_m1_prep.hook('creating').subscribe((id, obj) => {
			this.sync_solution = false;
			this.createData('m1_prep', obj, () => {
				this.sync_solution = true;
			});
		});

		this.data_m1_prep.hook('deleting').subscribe((id, obj) => {
			this.deleteData('m1_prep', id);
		});
	}

	get_urls_hook(type: TypeHook) {
		return {
			function: 'e2e_taxonomy/function/',
			level1: 'e2e_taxonomy/level1/',
			level2: 'e2e_taxonomy/level2/',
			diagram_e2e: 'e2e_taxonomy/diagram_e2e/',
			diagram_process: 'e2e_taxonomy/diagram_process/',
			diagram_template: 'e2e_taxonomy/diagram/',
			tool: 'solution/tool/',
			solution: 'solution/solution/',
			flow: 'solution/flow/',
			use_case: 'use_case/use_case/',
			diagram: 'use_case/diagram/',
			category_question_maturity: 'maturity/category_question/',
			question_maturity: 'maturity/question/',
			sub_category_question_maturity: 'maturity/sub_category_question/',
			assessment_maturity: 'maturity/assessment/',
			enterprise_context: 'simulation/context/',
			simulation: 'simulation/simulation/',
			use_case_simulation: 'simulation/use_case_simulation/',
			use_case_library: 'simulation/use_case_library/',
			m1_prep: 'simulation/m1_prep/',
		}[type];
	}

	get_table(type: TypeHook) {
		return {
			function: this.data_function,
			level1: this.data_level1,
			level2: this.data_level2,
			diagram_e2e: this.data_diagram_e2e,
			diagram_process: this.data_diagram_process,
			diagram_template: this.data_diagram_template,
			tool: this.data_tool,
			solution: this.data_solution,
			flow: this.data_flow,
			use_case: this.data_use_case,
			diagram: this.data_diagram,
			category_question_maturity: this.data_category_question_maturity,
			question_maturity: this.data_question_maturity,
			sub_category_question_maturity: this.data_sub_category_question_maturity,
			assessment_maturity: this.data_assessment_maturity,
			enterprise_context: this.data_enterprise_context,
			simulation: this.data_simulation,
			use_case_simulation: this.data_use_case_simulation,
			use_case_library: this.data_use_case_library,
			m1_prep: this.data_m1_prep,
		}[type];
	}

	base_callback(obj: any | undefined) {
		if (!environment.production && obj) {
			console.log(obj);
		}
	}

	createData(type: TypeHook, obj: any, callback: (obj: any) => void = (obj) => this.base_callback(obj)) {
		if (this.canHooks && !!localStorage.getItem('auth-token')) {
			this.http.post('@api/' + this.get_urls_hook(type), obj).subscribe({
				next: callback,
				error: (err) => {
					const detail = `Error creating an object in the database with id: ${obj.id}. Please reload the page and try saving the data again. If the problem persists, contact technical support.`;
					console.log(detail);
					/* this.dbMessageToast.next({
						summary: 'Error creating an object',
						detail,
					}), */
				},
			});
		}
	}

	updateData(type: TypeHook, id: string, obj: any, callback: (obj: any) => void = (obj) => this.base_callback(obj)) {
		if (this.canHooks && !!localStorage.getItem('auth-token') && id) {
			this.http.patch('@api/' + this.get_urls_hook(type) + id + '/', obj).subscribe({
				next: callback,
				error: (err: HttpErrorResponse) => {
					if (!['use_case_value', 'use_case_answer'].includes(type)) {
						if (err.status === 404) {
							const table: Table = this.get_table(type);
							table.get(id).then((new_obj: any) => {
								this.createData(type, new_obj, callback);
							});
						} else {
							const detail = `Error updating an object in the database with id: ${obj.id}. Please reload the page and try saving the data again. If the problem persists, contact technical support.`;
							console.log(detail);
							// this.dbMessageToast.next({
							// 	summary: 'Error updating an object',
							// 	detail,
							// });
						}
					}
				},
			});
		}
	}

	deleteData(type: TypeHook, id: string, callback: (obj: any) => void = (obj) => this.base_callback(obj)) {
		if (this.canHooks && !!localStorage.getItem('auth-token') && id) {
			this.http.delete('@api/' + this.get_urls_hook(type) + id).subscribe({
				next: callback,
				error: (err) => {
					const detail = `Error deleting an object in the database with id: ${id}. Please reload the page and try saving the data again. If the problem persists, contact technical support.`;
					console.log(detail);
					// this.dbMessageToast.next({
					// 	summary: 'Error deleting an object',
					// 	detail ,
					// }),
				},
			});
		}
	}

	async clear() {
		this.canHooks = false;
		await this.data_use_case.clear();
		await this.data_diagram.clear();
		this.canHooks = true;
	}

	public dbAuthUpdateSource = new Subject<EventData>();
	dbAuthUpdateSource$ = this.dbAuthUpdateSource.asObservable();

	public dbBaseUpdateSource = new Subject<string>();
	dbBaseUpdateSource$ = this.dbBaseUpdateSource.asObservable();

	public dbOrganizationUpdateSource = new Subject<string>();
	dbOrganizationUpdateSource$ = this.dbOrganizationUpdateSource.asObservable();

	webSocket() {
		if (localStorage.getItem('auth-token')) {
			const url = environment.url.replace('http', 'ws');
			const socket = new WebSocket(url + '/updates/' + '?token=' + localStorage.getItem('auth-token'));

			socket.addEventListener('message', (event) => {
				if (this.canHooks && !!localStorage.getItem('auth-token')) {
					const data: EventData = JSON.parse(event.data);
					// console.log('Message from server: ', data);
					if (data.module === '0' && data.model_name !== 'CommonData') {
						this.dbAuthUpdateSource.next(data);
					}
					if (data.module === '3') {
						this.webSocketEvent(data, () => this.dbOrganizationUpdateSource.next(data.object_id));
					}
					if (data.module === '4' || data.module === '5') {
						this.webSocketEvent(data, () => {});
					}
					/*if (
						(data.module === '0' && data.model_name !== 'user' && data.model_name !== 'Organization') ||
						data.module === '1' ||
						data.module === '2' ||
						data.module === '3'
					) {
						console.log('webSocketEvent');
						let callback: () => void = () => {};
						if (data.module === '1') {
							callback = () => this.dbBaseUpdateSource.next(uuid());
						}
						if (data.module === '2') {
							callback = () => this.dbBaseUpdateSource.next(data.object_id);
						}
						if (data.module === '3') {
							callback = () => this.dbOrganizationUpdateSource.next(data.object_id);
						}
						this.webSocketEvent(data, callback);
					}*/
				}
			});

			socket.onerror = () => {
				if (!!localStorage.getItem('auth-token')) {
					this.dbMessageToast.next({
						summary: 'Connection error',
						detail: 'There were connection errors and the session has been closed for security and to maintain data integrity.',
					});
					/*setTimeout(() => {
						this.dbError = true;
						this.dbErrorSource.next('');
						setTimeout(() => {
							window.location.reload();
						}, 200);
					}, 3000);*/
				} else {
					setTimeout(() => this.webSocket(), 5000);
				}
			};
		} else {
			setTimeout(() => this.webSocket(), 5000);
		}
	}

	get_urls_event(key: ModelEvent): string {
		const data = {
			CommonData: 'common/common_data/',
			CategoryFunction: 'e2e_taxonomy/category_function/',
			Function: 'e2e_taxonomy/function/',
			Level1: 'e2e_taxonomy/level1/',
			Level2: 'e2e_taxonomy/level2/',
			DiagramE2E: 'e2e_taxonomy/diagram_e2e/',
			DiagramProcess: 'e2e_taxonomy/diagram_process/',
			DiagramTemplate: 'e2e_taxonomy/diagram/',
			Technology: 'e2e_taxonomy/technology/',
			DigitalWorker: 'e2e_taxonomy/digital_worker/',
			TechnologyByLevel2: 'e2e_taxonomy/technology_by_level2/',
			FunctionFilter: 'solution/function_filter/',
			Tool: 'solution/tool/',
			Skill: 'solution/skill/',
			AiLever: 'solution/ai_lever/',
			Solution: 'solution/solution/',
			Flow: 'solution/flow/',
			CategoryQuestion: 'solution/category_question/',
			SubCategoryQuestion: 'solution/sub_category_question/',
			Question: 'solution/question/',
			Diagram: 'use_case/diagram/',
			UseCase: 'use_case/use_case/',
			EnterpriseContext: 'simulation/context/',
			Simulation: 'simulation/simulation/',
			UseCaseSimulation: 'simulation/use_case_simulation/',
			UseCaseLibrary: 'simulation/use_case_library/',
			M1Prep: 'simulation/m1_prep/',
		};
		return data[key];
	}

	get_table_event(key: ModelEvent): Table {
		const data = {
			CommonData: this.data_common,
			CategoryFunction: this.data_category_function,
			Function: this.data_function,
			Level1: this.data_level1,
			Level2: this.data_level2,
			DiagramE2E: this.data_diagram_e2e,
			DiagramProcess: this.data_diagram_process,
			DiagramTemplate: this.data_diagram_template,
			Technology: this.data_technology,
			DigitalWorker: this.data_digital_worker,
			FunctionFilter: this.data_function_filter,
			Tool: this.data_tool,
			Skill: this.data_skill,
			AiLever: this.data_aiLever,
			Solution: this.data_solution,
			Flow: this.data_flow,
			CategoryQuestion: this.data_category_question,
			SubCategoryQuestion: this.data_sub_category_question,
			Question: this.data_question,
			Diagram: this.data_diagram,
			UseCase: this.data_use_case,
			EnterpriseContext: this.data_enterprise_context,
			Simulation: this.data_simulation,
			UseCaseSimulation: this.data_use_case_simulation,
			UseCaseLibrary: this.data_use_case_library,
			M1Prep: this.data_m1_prep,
		};
		return data[key];
	}

	webSocketSubject: Subject<EventData> = new Subject();
	webSocketObservable = this.webSocketSubject.asObservable();

	webSocketEvent(data: EventData, iCallback: () => void) {
		console.log('webSocketEvent');
		const callback = () => {
			this.webSocketSubject.next(data);
			iCallback();
		};
		const table = this.get_table_event(data.model_name as any);
		const url = this.get_urls_event(data.model_name as any);
		if (data.event === 'create' || data.event === 'update') {
			if (table && url) {
				if (data.object_id) {
					this.webSocketCreateEvent(table, url, data, callback, data.object_id);
				} else if (data.object_ids) {
					for (const id of data.object_ids) {
						this.webSocketCreateEvent(table, url, data, callback, id);
					}
				}
			} else {
				callback();
			}
		} else if (data.event === 'delete') {
			if (table) {
				if (data.object_id) {
					this.canHooks = false;
					table.delete(data.object_id).finally(() => {
						callback();
						this.canHooks = true;
					});
				} else if (data.object_ids) {
					for (const id of data.object_ids) {
						this.canHooks = false;
						table.delete(id).finally(() => {
							callback();
							this.canHooks = true;
						});
					}
				}
			} else {
				callback();
			}
		} else {
			callback();
		}
	}

	webSocketCreateEvent(table: Table, url: string, data: EventData, callback: () => void, object_id: string) {
		this.http.get('@api/' + url + object_id + '/').subscribe({
			next: (obj) => {
				this.canHooks = false;
				if ((obj as any).deleted_at || (obj as any).deletedAt) {
					table.delete(data.object_id).finally(() => {
						this.canHooks = true;
						callback();
					});
				} else {
					table.put(obj).finally(() => {
						this.canHooks = true;
						callback();
					});
				}
			},
			error: (err: HttpErrorResponse) => {
				callback();
				console.log(err);
			},
		});
	}
}
