import { BaseEntity, IBaseEntity } from './base.api';

/**
 * Represents a generic interface for common data.
 *
 * @template T - The type of the data.
 */
export interface ICommonData<T> {
	key: string;
	data: T;
}

/**
 * Represents common data with a key-value pair.
 * @template T The type of the data that is stored.
 * @implements ICommonData<T>
 */
export class CommonData<T> implements ICommonData<T> {
	constructor(public key: string, public data: T) {}
}

/**
 * Represents an organization.
 * @interface
 * @extends IBaseEntity
 */
export interface IOrganization extends IBaseEntity {
	name: string;
	summary: string;
	taxonomy: { value: string[]; options: string[] };
}

/**
 * Represents an Organization.
 * @extends BaseEntity
 * @implements IOrganization
 */
export class Organization extends BaseEntity implements IOrganization {
	constructor(
		public name: string,
		public summary: string,
		public taxonomy: { value: string[]; options: string[] },
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(created_at, updated_at, id);
	}
}
