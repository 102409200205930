import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class AppFilterService {
	showSide = true;
	showSideExtend = true;
	containerWidth = 0;
	openMenu = false;

	toggleSide() {
		this.showSide = !this.showSide;
	}

	openSide() {
		if (this.showSideExtend && !this.showSide) {
			this.showSide = true;
		}
	}
}
