import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../service/auth.service';
import { FormChangePassword, FormSignIn } from '../../api/auth.api';
import { DbService } from '../../service/db.service';

@Component({
	templateUrl: './change-password.component.html',
	providers: [MessageService],
})
export class ChangePasswordComponent implements OnInit {
	blocked = true;

	constructor(
		public authService: AuthService,
		public messageService: MessageService,
		public router: Router,
		public route: ActivatedRoute,
	) {
		this.route.queryParams.subscribe(async (params) => {
			const force = params['force'];
			if (force) {
				this.blocked = false;
			} else {
				this.authService.getUser().then(async (user) => {
					if (user) {
						if (!user.change_password) {
							await this.router.navigateByUrl('/');
						}
					} else {
						await this.router.navigateByUrl('/sign-in');
					}
					this.blocked = false;
				});
			}
		});
	}

	form: FormChangePassword;
	loading: boolean;

	ngOnInit() {
		this.form = this.authService.getChangePasswordForm();
	}

	changePassword() {
		this.loading = true;
		this.authService.changePassword(this.form).then((v) => {
			if (v) {
				this.messageService.add({ severity: 'success', summary: 'Welcome, success change password' });
				setTimeout(async () => {
					this.loading = false;
					await this.router.navigateByUrl('/');
				}, 500);
			} else {
				this.loading = false;
				this.messageService.add({ severity: 'warn', summary: 'Error on change password' });
			}
		});
	}
}
