import { ElementRef, Injectable } from '@angular/core';
import { CommonService } from '../../service/common.service';
import { E2ETaxonomyService } from '../../service/e2e-taxonomy.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TreeNode } from 'primeng/api';
import {
	CategoryFunction,
	DiagramE2E,
	DiagramProcess,
	DiagramTemplate,
	Function,
	Level1,
	Level2,
	Technology,
} from '../../api/e2e-taxonomy.api';
import { AuthService } from '../../service/auth.service';
import { HttpClient } from '@angular/common/http';
import { DbService } from '../../service/db.service';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import { E2ETaxonomyV2Service } from '../../service/e2e-taxonomy_v2.service';

@Injectable({
	providedIn: 'root',
})
export class AiExplorerService {
	constructor(
		public authService: AuthService,
		public commonService: CommonService,
		public e2ETaxonomyService: E2ETaxonomyService,
		public e2ETaxonomyV2Service: E2ETaxonomyV2Service,
		public dbService: DbService,
		public sanitizer: DomSanitizer,
		public http: HttpClient,
	) {}

	init: boolean = false;
	loading: boolean = true;
	loading_all: boolean = true;

	scrollList: ElementRef;
	scrollGrid: ElementRef;
	onLoadRun: () => void;

	allOfficeTreeNode!: TreeNode[];
	frontOfficeTreeNode!: TreeNode[];
	middleOfficeTreeNode!: TreeNode[];
	backOfficeTreeNode!: TreeNode[];

	treeNodeSelected: TreeNode | undefined;

	technologySort: string[] = [];
	technologies: Technology[];

	baseData: CategoryFunction[] = [];
	filterData: (CategoryFunction | Function)[] = [];
	data: Function[] = [];
	searchData: Function[] = [];

	onUpdate: () => void = () => {};

	initSubscription: boolean = false;

	async prepareData(onLoad: () => { scrollList: ElementRef; scrollGrid: ElementRef }, onLoadRun: () => void) {
		this.init = true;
		await this.getData(onLoad, onLoadRun);

		if (!this.initSubscription) {
			this.initSubscription = true;

			this.authService.organizationSource$.subscribe(() => {
				setTimeout(() => {
					this.getData(onLoad, onLoadRun);
					this.onUpdate();
				}, 150);
			});

			this.dbService.dbBaseUpdateSource$.subscribe(() => {
				setTimeout(() => {
					this.getData(onLoad, onLoadRun);
					this.onUpdate();
				}, 150);
			});

			this.e2ETaxonomyService.diagramTemplateObservable.subscribe(async (id: string) => {
				const level2 = await this.e2ETaxonomyService.getLevel2(id);
				if (level2) {
					this.data = this.data.map((fn) => {
						fn.levels1 = fn.levels1.map((l1) => {
							if (l1.levels2.filter((l2) => l2.id === id).length > 0) {
								l1.levels2 = l1.levels2.map((l2) => {
									if (l2.id === id) {
										level2.level1 = l1;
										this.getValueArrays(level2);
										return level2;
									}
									return l2;
								});
							}
							l1.valuesArray = this.getValuesArrayLevel1(l1);
							return l1;
						})
						fn.valuesArray = this.getValuesArrayFunction(fn);
						return fn;
					})
					this.loadTableData();
				}
			});
		}
	}

	async getData(onLoad: () => { scrollList: ElementRef; scrollGrid: ElementRef }, onLoadRun: () => void) {
		// this.e2ETaxonomyV2Service.getTaxonomyTreeNode();
		this.e2ETaxonomyService
			.getCategoriesFunction(
				{
					extend: 'function',
				},
				true,
			)
			.then((categoryFunctionAsTreeNode) => {
				categoryFunctionAsTreeNode.forEach((node) => {
					node.expanded = false;
				});
				this.allOfficeTreeNode = categoryFunctionAsTreeNode.map((node) => node);
				this.frontOfficeTreeNode = categoryFunctionAsTreeNode.filter((node) => node.data.office === '1');
				this.middleOfficeTreeNode = categoryFunctionAsTreeNode.filter((node) => node.data.office === '2');
				this.backOfficeTreeNode = categoryFunctionAsTreeNode.filter((node) => node.data.office === '3');
			});

		this.baseData = await this.e2ETaxonomyService.getCategoriesFunction({
			extend: 'function.level1.level2.*',
		});

		if (this.baseData.length) {
			this.baseData.forEach((cf) => {
				cf.getChildren().forEach((f) => {
					f.category = cf;
					f.getChildren().forEach((l1) => {
						l1.function = f;
						l1.levels2 = l1.levels2.map((l2) => {
							l2.level1 = l1;
							return l2;
						});
					});
				});
			});
		}

		await this.getTechnologies();

		await this.selectionTreeNode(null);

		this.loading = false;

		this.onLoadRun = onLoadRun;

		this.onLoadRun();

		setTimeout(() => {
			const scrolls = onLoad();
			this.scrollList = scrolls.scrollList;
			this.scrollGrid = scrolls.scrollGrid;
		}, 500);

		this.loading_all = false;
	}

	safeHTML(html: string) {
		const parser = new DOMParser();
		const document = parser.parseFromString(html, 'text/html');
		return this.sanitizer.bypassSecurityTrustHtml(document.body.outerHTML);
	}

	async getTechnologies() {
		const technologies_sort = await this.commonService.getCommonData<string[]>('technologies_sort');
		if (technologies_sort && technologies_sort.data.length) {
			this.technologySort = technologies_sort.data;
		}
		this.technologies = await this.e2ETaxonomyService.getTechnologies({
			technologySort: this.technologySort,
			neto: true,
		});
	}

	async clearSelectionTreeNode() {
		if (this.treeNodeSelected) {
			this.treeNodeSelected = undefined;
			await this.selectionTreeNode(null);
		}
	}

	async selectionTreeNode(evt: TreeNode | null) {
		if (evt) {
			this.treeNodeSelected = evt;
		}
		this.searchProcess = '';
		this.searchRegExpProcess = undefined;
		if (this.treeNodeSelected && this.treeNodeSelected.key) {
			const cf = this.baseData.find((d) => d.id === this.treeNodeSelected?.key);
			if (cf) {
				this.filterData = cf.getChildren();
			} else {
				for (const cf of this.baseData) {
					this.filterData = cf.getChildren().filter((d) => {
						return d.id === this.treeNodeSelected?.key;
					});
					if (this.filterData.length) {
						break;
					}
				}
			}
		} else {
			this.filterData = this.baseData;
		}

		if (this.scrollList) {
			this.scrollList.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
		}

		if (this.scrollGrid) {
			this.scrollGrid.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
		}

		if (this.onLoadRun) {
			this.onLoadRun();
		}

		await this.getFilterData();
	}

	filterDataByType(data: (Function | CategoryFunction)[]) {
		if (this.selectionTypeFilter !== '1') {
			return data
				.map((node) => {
					if (node instanceof CategoryFunction) {
						const functions = node.functions;
						node.functions = [];
						const nNode = instanceToInstance(node);
						node.functions = functions
							.map((cn) => cn)
							.filter((cn) => {
								return this.selectionTypeFilter === '2'
									? cn.type === '2' || cn.type === '1'
									: cn.type === this.selectionTypeFilter;
							});
						return nNode;
					}
					return node;
				})
				.filter((node) => {
					if (node instanceof CategoryFunction) {
						return !!node.getChildren().length;
					} else if (node instanceof Function) {
						return this.selectionTypeFilter === '2'
							? node.type === '2' || node.type === '1'
							: node.type === this.selectionTypeFilter;
					} else {
						return true;
					}
				});
		} else {
			return data;
		}
	}

	async getFilterData() {
		this.data = [];

		if (this.filterData && this.filterData.length) {
			this.filterData.forEach((item) => {
				if (item instanceof CategoryFunction) {
					const item_children = item.getChildren();
					if (item_children && item_children.length) {
						this.data.push(...item_children);
					}
				} else {
					this.data.push(item);
				}
			});
		}

		this.getValueArrays();

		this.loadTableData();
	}

	getValueArrays(level2?: Level2) {
		if (this.data && this.data.length) {
			this.data = this.data.map((f) => {
				f.valuesArray = this.getValuesArrayFunction(f);
				f.overallLabel = f.getOverallLabel();
				f.overallClass = f.getOverallClass();
				f.levels1 = f.levels1.map((l1) => {
					l1.valuesArray = this.getValuesArrayLevel1(l1);
					l1.levels2 = l1.levels2.map((l2) => {
						if (level2 && level2.id === l2.id) {
							l2.values = level2.values;
						}
						l2.valuesArray = this.getValuesArray(l2);
						return l2;
					});
					return l1;
				});
				return f;
			});
		}
	}

	getValuesArray(level2: Level2): boolean[] {
		return this.technologySort.slice(0, this.technologySort.length - 1).map((t) => {
			return level2.values.technologies[t];
		});
	}

	getValuesArrayLevel1(level1: Level1) {
		return this.technologySort.slice(0, this.technologySort.length - 1).map((t) => {
			let value = false;
			for (const level2 of level1.levels2) {
				if (level2.values.technologies[t]) {
					value = true;
					break;
				}
			}
			return value;
		});
	}

	getValuesArrayFunction(fn: Function): boolean[] {
		return this.technologySort.slice(0, this.technologySort.length - 1).map((t) => {
			let value = false;
			for (const level1 of fn.levels1) {
				for (const level2 of level1.levels2) {
					if (level2.values.technologies[t]) {
						value = true;
						break;
					}
				}
				if (value) {
					break;
				}
			}
			return value;
		});
	}

	sortField: string = '';
	sortFieldIds: string[] = [];
	sortOrder: number = 0;

	searchProcess: string = '';
	searchRegExpProcess: RegExp | undefined = undefined;

	async changeSearchProcess() {
		this.loading = true;
		if (this.searchProcess) {
			this.searchRegExpProcess = new RegExp(this.searchProcess, 'i');
			this.searchData = [];
			for (const f of this.data) {
				const fn = plainToInstance(Function, {
					...f,
					category: undefined,
					levels1: undefined,
				});
				fn.category = f.category;
				fn.levels1 = [...f.levels1];
				if (fn.name.toLowerCase().search(this.searchRegExpProcess) >= 0) {
					this.searchData.push(fn as any);
				} else {
					const levels1: Level1[] = [];
					for (const l1 of f.levels1) {
						const l1n = plainToInstance(Level1, {
							...l1,
							function: undefined,
							levels2: undefined,
						});
						l1n.function = l1.function;
						l1n.levels2 = [...l1.levels2];
						if (l1n.name.toLowerCase().search(this.searchRegExpProcess) >= 0) {
							levels1.push(l1n as any);
						} else {
							const levels2: Level2[] = [];
							for (const l2 of l1.levels2) {
								if (
									l2.name.toLowerCase().search(this.searchRegExpProcess) >= 0 ||
									l2.description.toLowerCase().search(this.searchRegExpProcess) >= 0
								) {
									levels2.push(l2);
								}
							}
							if (levels2.length) {
								l1n.levels2 = [...levels2];
								levels1.push(l1n as any);
							}
						}
					}
					if (levels1.length) {
						fn.levels1 = [...levels1];
						this.searchData.push(fn as any);
					}
				}
			}
		} else {
			this.searchRegExpProcess = undefined;
			this.searchData = [];
		}
		this.loadTableData();
		this.loading = false;
	}

	onErrorImageE2E(fn: Function) {
		if (fn.diagram) {
			this.http.get<DiagramE2E>('@api/e2e_taxonomy/diagram_e2e/' + fn.diagram.id).subscribe({
				next: (diagram) => {
					this.dbService.data_diagram_e2e.put(diagram).then(() => {
						fn.diagram = diagram;
					});
				},
			});
		}
	}

	onErrorImageProcess(level1: Level1) {
		if (level1.diagram) {
			this.http.get<DiagramProcess>('@api/e2e_taxonomy/diagram_process/' + level1.diagram.id).subscribe({
				next: (diagram) => {
					this.dbService.data_diagram_process.put(diagram).then(() => {
						level1.diagram = diagram;
					});
				},
			});
		}
	}

	onErrorImage(level2: Level2) {
		if (level2.diagram) {
			this.http.get<DiagramTemplate>('@api/e2e_taxonomy/diagram/' + level2.diagram.id).subscribe({
				next: (diagram) => {
					this.dbService.data_diagram_template.put(diagram).then(() => {
						level2.diagram = diagram;
					});
				},
			});
		}
	}

	selectionTypeFilter: string = '1';

	selectionTypeFilterOptions: { value: string; label: string }[] = [
		{
			value: '1',
			label: 'All',
		},
		{
			value: '2',
			label: 'Core',
		},
		{
			value: '3',
			label: 'Industry',
		},
	];

	async selectionType() {
		let allOfficeTreeNode =
			this.selectionTypeFilter === '1'
				? this.allOfficeTreeNode.map((node) => node)
				: this.allOfficeTreeNode
						.map((node) => {
							const nNode = { ...node };
							if (nNode.children?.length) {
								nNode.children = nNode.children
									.map((cn) => cn)
									.filter((cn) => {
										return this.selectionTypeFilter === '2'
											? cn.data.type === '2' || cn.data.type === '1'
											: cn.data.type === this.selectionTypeFilter;
									});
							}
							return nNode;
						})
						.filter((node) => {
							return (
								node.data.type === this.selectionTypeFilter ||
								(node.data.type !== this.selectionTypeFilter && node.children?.length)
							);
						});

		this.frontOfficeTreeNode = allOfficeTreeNode.filter((node) => node.data.office === '1');
		this.middleOfficeTreeNode = allOfficeTreeNode.filter((node) => node.data.office === '2');
		this.backOfficeTreeNode = allOfficeTreeNode.filter((node) => node.data.office === '3');

		await this.clearSelectionTreeNode();
		this.loadTableData();
	}

	tableData: Function[] = [];

	loadTableData() {
		this.tableData = this.filterDataByType(this.searchRegExpProcess ? this.searchData : this.data) as Function[];
	}
}
