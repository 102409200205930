import { HttpClient, HttpHeaders } from '@angular/common/http';

export class RelevanceAssistant {
	constructor(private http: HttpClient) {}

	run(agent_id: string, content: string, callback: (data: string) => void) {
		this.createTrigger(agent_id, content).subscribe((response) => {
			this.checkMessage(response.job_info.studio_id, response.job_info.job_id, callback);
		});
	}

	private checkMessage(studioId: string, jobId: string, callback: (data: string) => void) {
		this.getRunStatus(studioId, jobId).subscribe((statusResponse) => {
			if (statusResponse.type === 'complete') {
				callback(statusResponse.updates[0].output.output.answer);
			} else if (statusResponse.type === 'failed') {
				return;
			} else {
				setTimeout(() => {
					this.checkMessage(studioId, jobId, callback);
				});
			}
		});
	}

	private endpoint = 'api-bcbe5a.stack.tryrelevance.com';

	private getHeaders(): HttpHeaders {
		return new HttpHeaders({
			Authorization: '9dcf07a700d6-4f9a-b99b-d08e3f02169d:sk-NTc1MDFiZjgtZTAzZS00NmIyLTkxM2MtMjZjNGZkZmNhMTVj',
			'Content-Type': 'application/json',
		});
	}

	createTrigger(agent_id: string, content: string) {
		const url = `https://${this.endpoint}/latest/agents/trigger/`;
		return this.http.post<{ conversation_id: string; job_info: { studio_id: string; job_id: string } }>(
			url,
			{
				message: {
					role: 'user',
					content: content,
				},
				agent_id,
			},
			{ headers: this.getHeaders() },
		);
	}

	getRunStatus(studioId: string, jobId: string) {
		const url = `https://${this.endpoint}/latest/studios/${studioId}/async_poll/${jobId}`;
		return this.http.get<{
			updates: { type: string; output: { output: { answer: string } } }[];
			type: string;
		}>(url, { headers: this.getHeaders() });
	}
}
