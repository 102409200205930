<div class="layout-grid flex justify-content-between flex-column m-0 p-0">
	<ul class="layout-menu">
		<ng-template ngFor let-item [ngForOf]="items" let-i="index">
			<li
				app-menuitem
				[item]="item"
				[index]="i"
				[root]="true"
				tippy
				[tippyContent]="item.label || ''"
				tippyPlacement="right"
			></li>
		</ng-template>
	</ul>
</div>
