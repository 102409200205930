import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, ConfirmEventType, Message, MessageService, TreeNode } from 'primeng/api';
import { E2ETaxonomyService } from '../../service/e2e-taxonomy.service';
import { UseCaseService } from '../../service/use-case.service';
import { UseCase, Diagram } from '../../api/use-case.api';
import { SolutionService } from '../../service/solution.service';
import { CategoryQuestion, Question } from '../../api/solution.api';
import { ActivatedRoute } from '@angular/router';
import { LayoutService } from '../../../layout/service/app.layout.service';
import { AuthService } from '../../service/auth.service';
import { DbService } from '../../service/db.service';
import { HttpClient } from '@angular/common/http';
import { RelevanceAssistant } from '../../service/RelevanceAssistant';
import { v4 as uuid } from 'uuid';
import type { Api } from 'datatables.net';
import { instanceToInstance } from 'class-transformer';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UseCaseUIService } from '../../service/use-case_ui.service';

@Component({
	templateUrl: './design-nexus.component.html',
	styleUrls: ['./design-nexus.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class DesignNexusComponent implements OnInit {
	@ViewChild('tableInput') tableInput: ElementRef<HTMLTableElement>;
	@ViewChild('tableOutput') tableOutput: ElementRef<HTMLTableElement>;

	layoutDataView: 'list' | 'grid' = 'list';

	categoriesQuestion: CategoryQuestion[] = [];
	questions: Question[] = [];
	taxonomy: TreeNode[] = [];
	taxonomySelected: TreeNode[] = [];
	technologies: TreeNode[] = [];
	technologiesSelected: TreeNode[] = [];
	impacts: TreeNode[] = [
		{ label: 'Minimal', key: '1' },
		{ label: 'Moderate', key: '2' },
		{ label: 'Strong', key: '3' },
	];
	impactsSelected: TreeNode[] = [];
	efforts: TreeNode[] = [
		{ label: '0-3 m', key: '1' },
		{ label: '3-12 m', key: '2' },
		{ label: '+12 m', key: '3' },
	];
	effortsSelected: TreeNode[] = [];

	constructor(
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public layoutService: LayoutService,
		public authService: AuthService,
		public e2ETaxonomyService: E2ETaxonomyService,
		public solutionService: SolutionService,
		public useCaseService: UseCaseService,
		public useCaseUIService: UseCaseUIService,
		public route: ActivatedRoute,
		public dbService: DbService,
		public http: HttpClient,
		private formBuilder: FormBuilder,
	) {}

	loading: boolean = true;

	formFunctionalQuestions: FormGroup;
	functionalQuestions: any[] = [];
	formNonFunctionalQuestions: FormGroup;
	nonFunctionalQuestions: any[] = [];

	async ngOnInit() {
		this.functionalQuestions = [
			{
				id: 'use-case',
				text: 'Please describe the specific use case you are targeting with this AI solution. Provide a detailed description of the particular problem or opportunity you aim to address with the AI solution.',
			},
			{
				id: 'primary-goal',
				text: 'What is the primary goal of this AI project? Are you looking to enhance decision-making, automate repetitive tasks, improve customer experience, increase operational efficiency, reduce costs, or achieve another specific goal?',
			},
			{
				id: 'current-process',
				text: 'How do you currently execute the current process? Please describe the current process or methods used. What tools or software are involved in the current process?',
			},
			{
				id: 'challenges',
				text: 'What challenges or pain points are you facing with the current process? Mention any challenges such as manual effort, errors, delays, or high costs.',
			},
			{
				id: 'decision-making',
				text: 'What are the key decision-making criteria, or business rules, that guide your current process? Please describe any specific conditions, exceptions, or calculations that are critical to the operation of the solution.',
			},
			{
				id: 'accuracy-validation',
				text: 'How do you validate the accuracy or correctness of the current solution? What are the criteria or parameters to judge the accuracy? Are there specific thresholds or benchmarks that must be met? Who is responsible for validating the outputs, and what tools or methods do they use?',
			},
			{
				id: 'error-handling',
				text: 'How do you handle errors, discrepancies, or unexpected results in the current process? What steps are taken to identify and rectify issues? Is there a feedback loop or reporting mechanism for continuous improvement?',
			},
			{
				id: 'user-roles',
				text: 'Describe the different types of users who will interact with the AI solution. Include details such as their roles, responsibilities, goals, and challenges.',
			},
			{
				id: 'features',
				text: 'Do you have a list of features and functionalities for the solution? Please upload a file or share the URL.',
			},
			{
				id: 'human-intervention',
				text: 'At what steps do you think human intervention will be required, to check the accuracy of output before sending it as input to the next step?',
			},
		];

		this.nonFunctionalQuestions = [
			{
				id: 'ui-expectations',
				text: 'What type of UI do you expect for this solution? (For example: A web application, iOS application, Android application, API, SDK, or another type.)',
			},
			{
				id: 'third-party-integrations',
				text: 'What third-party integrations will be required to develop the solution? For each integration, provide the name, purpose, and relevant documentation (e.g., API documentation, credentials).',
			},
			{
				id: 'existing-platforms',
				text: 'Does this solution need to integrate with any existing platforms or systems within your organization? If yes, provide details about the platforms.',
			},
			{
				id: 'success-criteria',
				text: 'What are the success criteria based on which AI’s performance will be evaluated? Are there specific metrics or KPIs that we should focus on?',
			},
			{
				id: 'technology-stack',
				text: 'What is your current technology stack (e.g., cloud service provider, frontend, backend, AI model)? Are there any constraints or preferences for technology that we should be aware of?',
			},
		];

		this.layoutService.updateTitle('Hubble Design');

		await this.prepareData();

		await this.getData();

		this.loading = false;

		window.addEventListener('resize', () => this.sizeTable());

		this.authService.organizationSource$.subscribe(() => {
			setTimeout(() => {
				this.prepareData().then(() => {
					this.getData();
				});
			}, 150);
		});

		this.dbService.dbOrganizationUpdateSource$.subscribe(() => {
			setTimeout(() => {
				this.prepareData().then(() => {
					this.getData();
				});
			}, 150);
		});
	}

	sizeTable(container?: HTMLDivElement | null) {
		if (!container) {
			container = document.querySelector<HTMLDivElement>('p-dataView .p-grid');
		}
		if (container) {
			const containerWidth = container.offsetWidth;

			if (containerWidth < 750) {
				container.classList.add('expanded');
			} else {
				container.classList.remove('expanded');
			}

			const header = document.querySelector('.p-dataview-header');
			if (header) {
				if (containerWidth < 750) {
					header.classList.add('expanded');
				} else {
					header.classList.remove('expanded');
				}
			}
		}
	}

	baseData: UseCase[] = [];
	filterBaseData: UseCase[] = [];
	data: UseCase[] = [];

	async prepareData() {
		this.categoriesQuestion = await this.solutionService.getCategoriesQuestion();
		this.questions = await this.solutionService.getQuestions();
		this.taxonomy = await this.e2ETaxonomyService.getCategoriesFunction({ extend: 'function' }, true);
		this.taxonomy.forEach((t) => {
			t.selectable = true;
			t.children?.forEach((f) => {
				f.selectable = true;
				f.children?.map((l1) => {
					l1.selectable = true;
				});
			});
		});

		this.technologies = await this.e2ETaxonomyService.getTechnologies({}, true);

		this.baseData = await this.useCaseService.getUseCases();
		//console.log(this.baseData);
	}

	loading_data: boolean = false;

	async getData() {
		this.loading_data = true;
		this.data = await this.useCaseService.getUseCases({}, this.baseData);
		let useCases = await this.useCaseService.getUseCases({}, this.baseData);

		this.data = useCases.filter((useCase) => useCase.hubble);

		this.data.forEach((u) => {
			u.categories = this.categoriesQuestion;
		});

		this.loading_data = false;
	}

	favoriteUseCaseModal(id: string, isValid: boolean) {
		this.useCaseService
			.updateUseCaseValidStatus(id, isValid)
			.then(async () => {
				this.baseData = await this.useCaseService.getUseCases();
				this.messageService.add({
					severity: 'info',
					summary: 'Confirmed',
					detail: `Use Case ${isValid ? 'selected' : 'deselected'} from Hubble Design`,
				});
			})
			.catch(() => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: `An error occurred while updating the Use Case status to ${
						isValid ? 'select' : 'deselect'
					} from Hubble Design`,
				});
			});
	}

	deleteUseCaseModal(id: string) {
		this.confirmationService.confirm({
			message: 'Do you want to delete this Use Case?',
			header: 'Delete Confirmation',
			icon: 'pi pi-info-circle',
			accept: () => {
				this.data = [];
				this.useCaseService.deleteUseCase(id).then(async () => {
					this.baseData = await this.useCaseService.getUseCases();
					await this.getData();
					this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Use Case deleted' });
				});
			},
			reject: (type: ConfirmEventType) => {
				switch (type) {
					case ConfirmEventType.REJECT:
						this.messageService.add({
							severity: 'error',
							summary: 'Rejected',
							detail: 'You have rejected',
						});
						break;
					case ConfirmEventType.CANCEL:
						this.messageService.add({
							severity: 'warn',
							summary: 'Cancelled',
							detail: 'You have cancelled',
						});
						break;
				}
			},
		});
	}

	getImpactClass(v: string) {
		if (v === '3') {
			return 'box-select-high';
		} else if (v === '2') {
			return 'box-select-medium';
		} else if (v === '1') {
			return 'box-select-low';
		} else {
			return '';
		}
	}

	async changeSelection(reload: boolean = false) {
		this.loading = true;
		this.loading_data = true;
		const technologiesSelected = this.technologiesSelected.map((t) => t.key);
		const vs = this.impactsSelected.map((t) => t.key);
		const impactsSelected = [
			{ key: '1', min: 0, max: 10 },
			{ key: '2', min: 10, max: 30 },
			{
				key: '3',
				min: 30,
				max: 100,
			},
		].filter((vi) => vs.includes(vi.key));

		const ve = this.effortsSelected.map((t) => t.key);
		const effortsSelected = [
			{ key: '1', min: 0, max: 10 },
			{ key: '2', min: 10, max: 50 },
			{
				key: '3',
				min: 50,
				max: 100,
			},
		].filter((vi) => ve.includes(vi.key));

		let functions: string[] = this.taxonomySelected.filter((t) => !t.children?.length).map((t) => t.key as string);

		this.taxonomySelected.forEach((t) => {
			if (t.children && t.children.length) {
				functions.push(...t.children.map((td) => td.key as string));
			}
		});

		functions = Array.from(new Set(functions));

		this.filterBaseData = this.baseData.filter((u) => {
			let valid_1: boolean;
			if (functions.length) {
				valid_1 = !!u.levels2.filter((l2) => functions.includes(l2.level1.functionId)).length;
			} else {
				valid_1 = true;
			}
			let valid_2: boolean;
			if (technologiesSelected.length) {
				valid_2 = !!u.getTechnologiesIds().filter((t) => technologiesSelected.includes(t)).length;
			} else {
				valid_2 = true;
			}
			let valid_3: boolean;
			if (impactsSelected.length) {
				valid_3 = !!impactsSelected.filter(
					(vi) =>
						u.calculatedData.matrixReadiness.value > vi.min &&
						u.calculatedData.matrixReadiness.value <= vi.max,
				).length;
			} else {
				valid_3 = true;
			}
			let valid_4: boolean;
			if (effortsSelected.length) {
				valid_4 = !!effortsSelected.filter(
					(vi) => u.calculatedData.matrixValue.value > vi.min && u.calculatedData.matrixValue.value <= vi.max,
				).length;
			} else {
				valid_4 = true;
			}
			return valid_1 && valid_2 && valid_3 && valid_4;
		});

		if (reload) {
			this.data = await this.useCaseService.getUseCases({}, this.filterBaseData);
		} else {
			this.data = [...this.filterBaseData];
		}

		this.data.forEach((u) => {
			u.categories = this.categoriesQuestion;
		});

		this.loading = false;
		this.loading_data = false;
	}

	visible_modal: boolean = false;
	current_use_case: UseCase | undefined = undefined;

	openUseCaseModal(useCase: UseCase) {
		this.current_use_case = instanceToInstance(useCase);
		this.webSocket(useCase.id as string);
		this.dTables = {};
		this.dTUseCase = {};
		this.dtSelection = 0;
		this.activeStep = 0;
		this.eventStep[2] = () => {
			this.renderTable(
				() => this.tableInput,
				() => ({
					fields: [
						{
							label: 'Name',
							name: 'name',
						},
						{
							label: 'Description',
							name: 'description',
						},
						{
							label: 'Source',
							name: 'source',
						},
						{
							label: 'Format',
							name: 'format',
							type: 'select',
							options: [
								{ label: 'Excel spreadsheet', value: 'Excel spreadsheet' },
								{ label: 'Email', value: 'Email' },
								{ label: 'Word Document', value: 'Word Document' },
								{ label: 'PDF', value: 'PDF' },
								{ label: 'SQL DB', value: 'SQL DB' },
							],
						},
						{
							label: 'Type',
							name: 'type',
							type: 'select',
							options: [
								{ label: 'Numerical', value: 'Numerical' },
								{ label: 'Text', value: 'Text' },
							],
						},
						{
							label: 'Freq Updates',
							name: 'freq_updates',
							type: 'select',
							options: [
								{ label: 'Monthly', value: 'Monthly' },
								{ label: 'Daily', value: 'Daily' },
								{ label: 'Weekly', value: 'Weekly' },
								{ label: 'Quarterly', value: 'Quarterly' },
							],
						},
						{
							label: 'Tools',
							name: 'tools',
						},
						{
							label: 'Attachments',
							name: 'files',
							type: 'uploadMany',
							display: (file: string) => {
								const fileName = file.split('_')[1].split('%20').join(' ');
								return `<a href="${file}" target="_blank">${fileName}</a>`;
							},
							noFileText: 'No files',
						},
					],
					columns: [
						{
							title: 'Name',
							data: 'name',
						},
						{
							title: 'Description',
							data: 'description',
						},
						{
							title: 'Source',
							data: 'source',
						},
						{
							title: 'Format',
							data: 'format',
						},
						{
							title: 'Type',
							data: 'type',
						},
						{
							title: 'Freq Updates',
							data: 'freq_updates',
						},
						{
							title: 'Tools',
							data: 'tools',
						},
					],
				}),
				() =>
					(this.current_use_case?.nexus.inputs_and_data_sources?.inputs_sources || []).map((d) => {
						if (!d.files) {
							d.files = [];
						}
						return d;
					}),
				(data: any[]) => {
					if (this.current_use_case) {
						if (!this.current_use_case.nexus.inputs_and_data_sources) {
							this.current_use_case.nexus.inputs_and_data_sources = {
								inputs_sources: [],
							} as any;
						}
						this.current_use_case.nexus.inputs_and_data_sources.inputs_sources = data;
						if (this.current_use_case.nexus.status.inputs_and_data_sources === '0') {
							this.current_use_case.nexus.status.inputs_and_data_sources = '1';
						}
					}
				},
			);
		};

		if (!this.current_use_case.nexus?.form) {
			this.current_use_case.nexus.form = {};
		}

		this.formFunctionalQuestions = this.formBuilder.group({
			'use-case-note': this.current_use_case.nexus.form['use-case-note'] || '',
			'primary-goal-note': this.current_use_case.nexus.form['primary-goal-note'] || '',
			'current-process-note': this.current_use_case.nexus.form['current-process-note'] || '',
			'challenges-note': this.current_use_case.nexus.form['challenges-note'] || '',
			'decision-making-note': this.current_use_case.nexus.form['decision-making-note'] || '',
			'accuracy-validation-note': this.current_use_case.nexus.form['accuracy-validation-note'] || '',
			'error-handling-note': this.current_use_case.nexus.form['error-handling-note'] || '',
			'user-roles-note': this.current_use_case.nexus.form['user-roles-note'] || '',
			'features-note': this.current_use_case.nexus.form['features-note'] || '',
			'human-intervention-note': this.current_use_case.nexus.form['human-intervention-note'] || '',
		});

		this.formFunctionalQuestions.valueChanges.subscribe((value) => {
			if (this.current_use_case) {
				this.current_use_case.nexus.form = { ...this.current_use_case.nexus.form, ...value };
				this.updateCurrentUseCase = true;
				if (this.current_use_case.nexus.status.functional_requirements === '0') {
					this.current_use_case.nexus.status.functional_requirements = '1';
				}
			}
		});

		this.formNonFunctionalQuestions = this.formBuilder.group({
			'ui-expectations-note': this.current_use_case.nexus.form['ui-expectations-note'] || '',
			'third-party-integrations-note': this.current_use_case.nexus.form['third-party-integrations-note'] || '',
			'existing-platforms-note': this.current_use_case.nexus.form['existing-platforms-note'] || '',
			'success-criteria-note': this.current_use_case.nexus.form['success-criteria-note'] || '',
			'technology-stack-note': this.current_use_case.nexus.form['technology-stack-note'] || '',
		});

		this.formNonFunctionalQuestions.valueChanges.subscribe((value) => {
			if (this.current_use_case) {
				this.current_use_case.nexus.form = { ...this.current_use_case.nexus.form, ...value };
				this.updateCurrentUseCase = true;
				if (this.current_use_case.nexus.status.non_functional_req === '0') {
					this.current_use_case.nexus.status.non_functional_req = '1';
				}
			}
		});

		this.visible_modal = true;
		this.updateCurrentUseCase = false;
		if (this.loadingDesignNexusContent) {
			this.cancelGenerateContent();
		}
		setTimeout(() => {
			this.eventStep[this.activeStep]();
		}, 50);
	}

	loadingDesignNexusContent: boolean = false;
	activeStep: number = 0;

	phasesName: string[] = [
		'Functional Requirements',
		'Non-Functional Requirements',
		'Inputs and Data Sources',
		// 'Output and Reports',
		// 'Design Diagram',
		'GenAI Checklist',
		'Deployment Handshake',
	];

	eventStep: (() => void)[] = [() => {}, () => {}, () => {}, () => {}, () => {}, () => {}, () => {}];

	async changeActiveStep(i: number) {
		if (!this.loadingDesignNexusContent) {
			if (i < 0) {
				i = 0;
			}
			if (i > this.phasesName.length - 1) {
				i = this.phasesName.length - 1;
			}
			if (this.activeStep !== i) {
				this.activeStep = i;
				this.dtSelection = 0;
				this.eventStep[this.activeStep]();
			}
		}
	}

	currentUseCaseId: string = '';

	async editDiagram(useCase: UseCase) {
		this.useCaseService.currentUseCase = useCase;
		this.currentUseCaseId = '';
		if (useCase?.diagram?.id) {
			const ref = this.useCaseUIService.updateDiagramModal({ diagramId: useCase.diagram.id });

			if (ref) {
				ref.onClose.subscribe(() => {
					this.getData();
				});
			}
		}
	}

	getActiveClass(i: number) {
		let cls = this.activeStep === i ? 'active' : '';
		if (this.loadingDesignNexusContent) {
			cls = [cls, 'cursor-wait'].join(' ');
		} else {
			cls = [cls, 'hoverable'].join(' ');
		}
		return cls;
	}

	jsonNexus_all_outpus: any;

	generateContent() {
		const agentIds = [
			'144ccda6-de6d-462c-9664-faa95e13e0e4', // functional req
			'c02a1dd1-f8b0-4b0c-b9bf-1ee0a2502bd5', // non-funcitonal req
			'3aafacf1-8c4b-4912-9270-de660b1ee384', // inputs
			// 'a31ba7dd-e87c-4e8c-ba4b-982b090dc169', // outputs and reports
			// 'a2d701b1-919c-4767-9ef4-6bf43c33b59a', // diagram
			'7d74f69b-8c9e-4a1b-8406-84de754aab5d', // checklist promt
			'a2d701b1-919c-4767-9ef4-6bf43c33b59a', // HandShake
		];
		this.loadingDesignNexusContent = true;

		let content_prompt: string = '';

		if (this.activeStep == 5) {
			if (this.current_use_case) {
				content_prompt = JSON.stringify(this.current_use_case.nexus);
			}
			//console.log(content_prompt);
		} else {
			content_prompt = JSON.stringify(this.promptUseCase());
			//console.log(this.promptUseCase());
		}

		new RelevanceAssistant(this.http).run(agentIds[this.activeStep], content_prompt, (data_string) => {
			try {
				if (data_string.search('```json') >= 0) {
					data_string = data_string.split('```json')[1].split('```')[0];
				}

				const data = JSON.parse(data_string);
				console.log(data);
				if (this.current_use_case && this.loadingDesignNexusContent) {
					switch (this.activeStep) {
						case 0:
							this.current_use_case.nexus.functional_requirements = data.functional_requirements;
							this.current_use_case.nexus.status.functional_requirements = '1';
							break;
						case 1:
							this.current_use_case.nexus.non_functional_req = data.non_functional_requirements;
							this.current_use_case.nexus.status.non_functional_req = '1';
							break;
						case 2:
							this.current_use_case.nexus.inputs_and_data_sources = data.inputs_and_data_sources;
							this.current_use_case.nexus.status.inputs_and_data_sources = '1';
							this.current_use_case.nexus.inputs_and_data_sources.inputs_sources =
								this.current_use_case.nexus.inputs_and_data_sources.inputs_sources.map((d) => ({
									...d,
									DT_RowId: uuid(),
									tools: d.tools ?? 'Oracle; Script',
								}));
							console.log(this.current_use_case.nexus.inputs_and_data_sources.inputs_sources);
							break;
						case 3:
							this.current_use_case.nexus.genAICheckList = data.AI_checklist;
							this.current_use_case.nexus.status.genAICheckList = '1';
							console.log(this.current_use_case.nexus.genAICheckList);
							break;
						default:
							break;
					}
				}
				this.updateCurrentUseCase = true;
				this.eventStep[this.activeStep]();
			} catch (e) {
				console.log(data_string);
			}
			this.loadingDesignNexusContent = false;
		});
	}

	cancelGenerateContent() {
		this.loadingDesignNexusContent = false;
	}

	renderTable(
		element: () => ElementRef<HTMLTableElement>,
		getOptions: () => { fields: { label: string; name: string }[]; columns: { title: string; data: string }[] },
		getData: () => { DT_RowId: string; files: string[] }[],
		updateData: (
			data: {
				DT_RowId: string;
			}[],
		) => void,
	) {
		setTimeout(() => {
			const el = element();
			if (el && el.nativeElement) {
				this._renderTable(el.nativeElement, getOptions, getData, updateData);
			} else {
				this.renderTable(element, getOptions, getData, updateData);
			}
		});
	}

	dTables: { [key: string]: Api } = {};
	dTUseCase: { [key: number]: string } = {};
	dtSelection: number = 0;
	dtEditData: { DT_RowId: string; files: string[] } | undefined = undefined;
	dtFiles: string[] = [];
	dtDeletesFiles: string[] = [];

	_renderTable(
		table: HTMLTableElement,
		getOptions: () => { fields: { label: string; name: string }[]; columns: { title: string; data: string }[] },
		getData: () => { DT_RowId: string; files: string[] }[],
		updateData: (
			data: {
				DT_RowId: string;
			}[],
		) => void,
	) {
		const data = getData();
		this.dtDeletesFiles = [];
		if (this.dTables[table.id]) {
			const dt = this.dTables[table.id];
			dt.destroy();
		}
		const options = getOptions();
		const editor = new DataTable.Editor({
			table,
			ajax: environment.url + '/api/nexus/files',
			fields: options.fields,
		});
		const dt = new DataTable(table, {
			data,
			columns: options.columns,
			layout: {
				topStart: {
					buttons: [
						{ extend: 'create', editor: editor },
						{ extend: 'edit', editor: editor },
						{ extend: 'remove', editor: editor },
					],
				},
			},
			select: true,
			initComplete: () => {
				if (
					table.parentElement &&
					table.parentElement.parentElement &&
					table.parentElement.parentElement.parentElement
				) {
					table.parentElement.parentElement.parentElement.classList.add('table-input-output-datatable');
				}
			},
		});

		this.dTables[table.id] = dt;
		this.dTUseCase[this.activeStep] = table.id;

		editor.on('open', (a: any, b: any) => {
			const dataRow = dt.rows({ selected: true }).data();
			if (dataRow.length) {
				this.dtEditData = JSON.parse(JSON.stringify(dataRow[0]));
			}
			this.dtFiles = [];
		});

		const updateDataItem = (dataItem: { DT_RowId: string; files: string[] }) => {
			if (dataItem) {
				if (this.dtFiles && this.dtFiles.length) {
					dataItem.files.push(...this.dtFiles);
				}

				const deletes_files: string[] = [];
				let updated = false;
				if (this.dtEditData && this.dtEditData.files.length) {
					this.dtEditData.files.map((l) => {
						if (!dataItem.files.includes(l)) {
							deletes_files.push(l);
							updated = true;
						}
					});
				}

				if (dataItem.files.length) {
					dataItem.files.map((l) => {
						if (this.dtEditData && !this.dtEditData.files.includes(l)) {
							updated = true;
						}
					});
				}

				if (this.dtEditData && dataItem.files.length !== this.dtEditData.files.length) {
					updated = true;
				}

				const data = getData().map((d) => {
					if (d.DT_RowId === dataItem.DT_RowId) {
						d.files = dataItem.files;
					}
					return d;
				});

				if (updated) {
					updateData(data);
					this.updateCurrentUseCase = true;
					if (deletes_files.length) {
						this.dtDeletesFiles.push(...deletes_files);
					}
				}
			}
		};

		editor.on('close', (a: any, b: any) => {
			const dataRow = dt.rows({ selected: true }).data();

			if (dataRow.length) {
				updateDataItem(dataRow[0]);
			}
		});

		editor.on(
			'preSubmit',
			(
				_: any,
				dataEditor: {
					action: 'create' | 'edit';
					data: { [key: string | number]: { DT_RowId: string; files: string[] } };
				},
			) => {
				console.log(dataEditor, dataEditor.data);

				if (dataEditor.action === 'create') {
					const dataItem = { ...dataEditor.data[0], DT_RowId: uuid() };
					updateDataItem(dataItem);
					dt.row.add(dataItem).draw();
					updateData([dataItem, ...getData()]);
					this.updateCurrentUseCase = true;
				} else if (dataEditor.action === 'edit') {
					const id = Object.keys(dataEditor.data)[0];
					const row = dt.row((idx: any, data: any, node: any) => {
						return data.DT_RowId === id;
					});
					row.data(dataEditor.data[id]).draw();
					const data = getData().map((d) => {
						return dataEditor.data[id].DT_RowId === d.DT_RowId ? dataEditor.data[id] : d;
					});
					updateData(data);
					this.updateCurrentUseCase = true;
				}
			},
		);

		editor.on('postCreate', (_: any, dataCreate: { data: { DT_RowId: string; files: string[] }[] }) => {
			updateData([...dataCreate.data, ...getData()]);
			this.updateCurrentUseCase = true;
		});

		editor.on('postEdit', (_: any, dataEdit: { data: { DT_RowId: string; files: string[] }[] }) => {
			const data = getData().map((d) => {
				const b = dataEdit.data.find((a) => a.DT_RowId === d.DT_RowId);
				return b ?? d;
			});
			updateData(data);
			this.updateCurrentUseCase = true;
		});

		editor.on('postRemove', (a: any, b: any, dataRemove: string[]) => {
			const data = getData().filter((d) => !dataRemove.includes(d.DT_RowId));
			updateData(data);
			this.updateCurrentUseCase = true;
		});

		editor.on('uploadXhrSuccess', (a: any, b: any, data: { files: string[] }) => {
			if (data && data.files && data.files.length) {
				this.dtFiles.push(...data.files);
			}
		});

		dt.select().on('select', (a: any, dataSelect: string[]) => {
			this.dtSelection += 1;
		});

		dt.select().on('deselect', (a: any, dataSelect: string[]) => {
			this.dtSelection -= 1;
		});
	}

	addItemTable() {
		if (this.current_use_case) {
			const tableId = this.dTUseCase[this.activeStep];
			const btn = document.querySelector<HTMLButtonElement>(`#${tableId}_wrapper .buttons-create`);
			if (btn) {
				btn.click();
			}
		}
	}

	editItemTable() {
		if (this.current_use_case && this.dtSelection) {
			const tableId = this.dTUseCase[this.activeStep];
			const btn = document.querySelector<HTMLButtonElement>(`#${tableId}_wrapper .buttons-edit`);
			if (btn) {
				btn.click();
			}
		}
	}

	removeItemTable() {
		if (this.current_use_case && this.dtSelection) {
			const tableId = this.dTUseCase[this.activeStep];
			const btn = document.querySelector<HTMLButtonElement>(`#${tableId}_wrapper .buttons-remove`);
			if (btn) {
				btn.click();
			}
		}
	}

	updateCurrentUseCase: boolean = false;

	saveUseCase() {
		if (!this.can_edit) {
			return;
		}
		this.confirmationService.confirm({
			header: 'Save use Case?',
			message: 'Confirm to save.',
			icon: 'pi pi-save',
			accept: () => {
				this._saveUseCase();
			},
		});
	}

	_saveUseCase() {
		if (this.current_use_case) {
			if (this.dtDeletesFiles.length) {
				this.http
					.delete(environment.url + '/api/nexus/files', { body: { files: this.dtDeletesFiles } })
					.subscribe({
						next: () => {
							this.dtDeletesFiles = [];
						},
					});
			}
			this.useCaseService.saveUseCase(this.current_use_case).then((useCase) => {
				this.current_use_case = useCase;
				this.data = this.data.map((d) => {
					if (d.id === this.current_use_case?.id) {
						return this.current_use_case as UseCase;
					} else {
						return d;
					}
				});
				this.updateCurrentUseCase = false;
				this.messageService.add({
					severity: 'success',
					summary: 'Confirmed',
					detail: 'Use Case saved',
				});
			});
		}
	}

	updateGenAIStatus() {
		if (this.current_use_case?.nexus.status.genAICheckList === '0') {
			this.current_use_case.nexus.status.genAICheckList = '1';
		}
	}

	hasComplete() {
		if (this.current_use_case) {
			switch (this.activeStep) {
				case 0:
					return ['1', '2', '3'].includes(this.current_use_case.nexus.status.functional_requirements);
				case 1:
					return ['1', '2', '3'].includes(this.current_use_case.nexus.status.non_functional_req);
				case 2:
					return ['1', '2', '3'].includes(this.current_use_case.nexus.status.inputs_and_data_sources);
				case 3:
					return ['1', '2', '3'].includes(this.current_use_case.nexus.status.genAICheckList);
				default:
					return false;
			}
		}
		return false;
	}

	getComplete() {
		if (this.current_use_case) {
			switch (this.activeStep) {
				case 0:
					return this.current_use_case.nexus.status.functional_requirements;
				case 1:
					return this.current_use_case.nexus.status.non_functional_req;
				case 2:
					return this.current_use_case.nexus.status.inputs_and_data_sources;
				case 3:
					return this.current_use_case.nexus.status.output_and_reports;
				case 4:
					return this.current_use_case.nexus.status.designDiagram;
				case 5:
					return this.current_use_case.nexus.status.genAICheckList;
				case 6:
					return '2';
				default:
					return '2';
			}
		}
		return '2';
	}

	toggleComplete(evt: { checked: '2' | '3' }) {
		if (this.current_use_case) {
			switch (this.activeStep) {
				case 0:
					this.current_use_case.nexus.status.functional_requirements = evt.checked;
					break;
				case 1:
					this.current_use_case.nexus.status.non_functional_req = evt.checked;
					break;
				case 2:
					this.current_use_case.nexus.status.inputs_and_data_sources = evt.checked;
					break;
				case 3:
					this.current_use_case.nexus.status.output_and_reports = evt.checked;
					break;
				case 4:
					this.current_use_case.nexus.status.designDiagram = evt.checked;
					break;
				case 5:
					this.current_use_case.nexus.status.genAICheckList = evt.checked;
					break;
				case 6:
					break;
				default:
					break;
			}
		}
		this.updateCurrentUseCase = true;
	}

	promptUseCase() {
		if (this.current_use_case) {
			return {
				use_case_name: this.current_use_case.name,
				description: this.current_use_case.description,
				AI_enablers: this.current_use_case
					.getTechnologiesIds()
					.map((d) => {
						return this.technologies.find((t) => t.key === d)?.label;
					})
					.filter((s) => !!s) as string[],
				impact: this.current_use_case.simulationData?.context?.impact || '',
				business_value_drivers: {
					cost_savings: this.current_use_case.simulationData?.data?.valuesDriversScores?.cost_savings || '',
					customer_experience:
						this.current_use_case.simulationData?.data?.valuesDriversScores?.customer_experience || '',
					employee_productivity:
						this.current_use_case.simulationData?.data?.valuesDriversScores?.employee_productivity || '',
					process_productivity:
						this.current_use_case.simulationData?.data?.valuesDriversScores?.process_productivity || '',
					revenue_growth:
						this.current_use_case.simulationData?.data?.valuesDriversScores?.revenue_growth || '',
				},
				process: this.current_use_case.simulationData?.data?.process || 'Process',
				subprocess: this.current_use_case.simulationData?.data?.subprocess || 'SubProcess',
				data_requirements:
					this.current_use_case.simulationData?.data?.data_requirements || 'Normal data requirements',
			};
		} else {
			return {};
		}
	}

	onErrorImageDiagram(useCase: UseCase) {
		if (useCase.diagram) {
			this.http.get<Diagram>('@api/use_case/diagram/' + useCase.diagram.id).subscribe({
				next: (diagram) => {
					this.dbService.data_diagram.put(diagram).then(() => {
						useCase.diagram = diagram;
					});
				},
			});
		}
	}

	getNexusClass(status: '0' | '1' | '2' | '3') {
		if (status === '0') {
			return 'background-not-started';
		} else if (status === '1') {
			return 'background-in-progress'; // It was 'background-generated'
		} else if (status === '2') {
			return 'background-in-progress';
		} else {
			return 'background-completed';
		}
	}

	getNexusLabel(status: '0' | '1' | '2' | '3') {
		if (status === '0') {
			return 'Not started';
		} else if (status === '1') {
			return 'In Progress'; // It was 'Generated'
		} else if (status === '2') {
			return 'In Progress';
		} else {
			return 'Completed';
		}
	}

	hideDialogNexus() {
		this.cancelGenerateContent();
		if (this.updateCurrentUseCase && this.can_edit) {
			this._saveUseCase();
		}
		if (this.socket) {
			this.socket.close();
			this.messages = [];
		}
	}

	can_edit: boolean = false;
	socket: WebSocket;
	messageSocket: Message;
	messages: Message[];

	webSocket(useCaseId: string) {
		const url = environment.url.replace('http', 'ws');
		this.socket = new WebSocket(
			url + '/user-case/' + useCaseId + '/' + '?token=' + localStorage.getItem('auth-token'),
		);

		this.socket.addEventListener('message', (event) => {
			const data: { id: string; username: string } = JSON.parse(event.data);
			if (data.id) {
				this.can_edit = false;
				this.messageSocket = {
					severity: 'warn',
					summary: 'Use Case in use',
					detail: `The user '${data.username}' is currently using this use case, so you cannot save any changes while that user is connected.`,
					life: 5000,
				};
				this.messages = [{ ...this.messageSocket, life: 8000 }];
			} else {
				this.can_edit = true;
			}
		});
	}
}

function prompt_checklist2() {
	var json3 = {};
}

function prompt_checklist() {
	var json2 = {
		FunctionalDesignSpecification: {
			Introduction: {
				Purpose:
					'The purpose of this document is to outline the functional design specifications for the Automated Supply Chain Coordination system. This system leverages AI agents to streamline the coordination between suppliers, manufacturing units, and logistics providers, ensuring optimal resource allocation and effective lead time management.',
				Scope: 'This specification covers the functional requirements, key features, AI enablers, and business value drivers of the Automated Supply Chain Coordination system. It is intended for stakeholders including supply chain managers, IT professionals, and AI developers.',
			},
			SystemOverview: {
				Description:
					'The Automated Supply Chain Coordination system utilizes AI to automate the interactions between suppliers, manufacturers, and logistics providers. The system ensures the timely availability of resources and finished goods by optimizing resource allocation and managing lead times.',
				KeyFeatures: [
					'AI-driven Supplier Coordination',
					'Production Scheduling Optimization',
					'Logistics Management',
				],
				AIEnablers: ['Large Language Models (LLM)', 'Predictive AI', 'Workflow AI'],
				Impact: 'Transformative impact on supply chain efficiency and effectiveness.',
				BusinessValueDrivers: ['Financial Performance', 'Organizational Efficiency'],
			},
			FunctionalRequirements: {
				AIDrivenSupplierCoordination: {
					FunctionalDescription:
						'AI agents autonomously communicate with suppliers to place orders, track shipments, and manage inventory levels.',
					Features: [
						'Automated order placement based on inventory levels and demand forecasts.',
						'Real-time shipment tracking and updates.',
						'Inventory level management to ensure raw materials availability.',
					],
					UserInterfaceRequirements: [
						'Dashboard for monitoring supplier interactions and inventory levels.',
						'Notifications and alerts for shipment delays or inventory shortages.',
					],
				},
				ProductionSchedulingOptimization: {
					FunctionalDescription:
						'AI optimizes production schedules by aligning schedules with resource availability and forecasting demand to minimize downtime and maximize output.',
					Features: [
						'Dynamic scheduling based on real-time data.',
						'Predictive analysis to adjust production plans.',
						'Resource utilization tracking.',
					],
					UserInterfaceRequirements: [
						'Scheduling dashboard displaying current and forecasted production plans.',
						'Alerts for schedule adjustments and resource conflicts.',
					],
				},
				LogisticsManagement: {
					FunctionalDescription:
						'AI manages logistics operations including route planning, shipment tracking, and delivery scheduling.',
					Features: [
						'Optimized route planning to reduce delivery times.',
						'Real-time shipment tracking and updates.',
						'Automated delivery scheduling based on production output.',
					],
					UserInterfaceRequirements: [
						'Logistics dashboard with route plans, shipment status, and delivery schedules.',
						'Notifications for shipment status changes and delivery issues.',
					],
				},
			},
			NonFunctionalRequirements: {
				Performance: [
					'Real-time data processing for supplier coordination, production scheduling, and logistics management.',
					'High availability and reliability to ensure continuous operation.',
				],
				Security: [
					'Secure communication between AI agents and external systems (e.g., supplier systems).',
					'Data encryption for sensitive information such as inventory levels and shipment details.',
				],
				Usability: [
					'Intuitive user interfaces for monitoring and managing supply chain operations.',
					'User-friendly dashboards with customizable views and alerts.',
				],
			},
			TechnicalRequirements: {
				Integration: [
					'Integration with existing ERP and supply chain management systems.',
					'APIs for communication with supplier systems, manufacturing units, and logistics providers.',
				],
				DataManagement: [
					'Centralized data repository for storing supply chain data.',
					'Data analytics for generating insights and forecasts.',
				],
				AIModelManagement: [
					'Deployment and maintenance of AI models for supplier coordination, production scheduling, and logistics management.',
					'Continuous model training and updates based on new data.',
				],
			},
			ImplementationPlan: {
				Phases: [
					'Requirement Gathering and Analysis',
					'System Design',
					'Development',
					'Testing',
					'Deployment',
					'Maintenance and Updates',
				],
				Timeline: {
					Phase1: '1 month',
					Phase2: '1 month',
					Phase3: '3 months',
					Phase4: '2 months',
					Phase5: '1 month',
					Phase6: 'Ongoing',
				},
				Resources: [
					'Project Manager',
					'AI Developers',
					'Supply Chain Experts',
					'UI/UX Designers',
					'QA Testers',
				],
			},
			RisksAndMitigations: {
				Risks: [
					'Data integration challenges with existing systems.',
					'Model accuracy and reliability issues.',
					'Resistance to change from users.',
				],
				Mitigations: [
					'Thorough integration testing and validation.',
					'Regular model performance reviews and updates.',
					'Comprehensive user training and support.',
				],
			},
			Conclusion:
				'The Automated Supply Chain Coordination system will significantly enhance supply chain efficiency by leveraging AI to automate coordination tasks. By optimizing resource allocation and managing lead times, the system will drive substantial improvements in financial performance and organizational efficiency.',
		},
	};

	return json2;
}
