import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
	import('./monitor');
}

Chart.register(...registerables);
Chart.register(ChartDataLabels);
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
