import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthService } from '../../../service/auth.service';
import { SolutionService } from '../../../service/solution.service';
import { E2ETaxonomyService } from '../../../service/e2e-taxonomy.service';
import { UseCaseService } from '../../../service/use-case.service';
import { AiLever, Icon } from '../../../api/solution.api';
import { LayoutService } from '../../../../layout/service/app.layout.service';
import { Technology } from '../../../api/e2e-taxonomy.api';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { DbService } from '../../../service/db.service';
import { UseCaseLibrary } from '../../../api/simulation.api';

@Component({
	templateUrl: './use-case-detail.component.html',
	styleUrls: ['./use-case-detail.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class UseCaseDetailComponent implements OnInit, OnDestroy {
	public sub: any;
	public subDb: any;

	valuesDriversScoresLabel(value: number = 0) {
		let label = '';
		if (value === 0) {
			label = '---';
		} else if (value < 4) {
			label = 'Low';
		} else if (value >= 4 && value <= 7) {
			label = 'Medium';
		} else {
			label = 'High';
		}
		return label;
	}

	valuesDriversScoresClassName(value: number = 0) {
		let className = '';
		if (value === 0) {
			className = 'background-none';
		} else if (value < 4) {
			className = 'background-low';
		} else if (value >= 4 && value <= 7) {
			className = 'background-medium';
		} else {
			className = 'background-high';
		}
		return className;
	}

	valuesDriversScores: { title: string; label: string; className: string }[] = [];

	taxonomyList: { name: string; path: string }[] = [];

	constructor(
		public sanitizer: DomSanitizer,
		public route: ActivatedRoute,
		public router: Router,
		public messageService: MessageService,
		public confirmationService: ConfirmationService,
		public layoutService: LayoutService,
		public authService: AuthService,
		public e2ETaxonomyService: E2ETaxonomyService,
		public solutionService: SolutionService,
		public useCaseService: UseCaseService,
		public dbService: DbService,
		public http: HttpClient,
	) {}

	technologies: Technology[] = [];
	useCaseId: string = '';
	useCase: UseCaseLibrary;

	icons: Icon[] = [];

	async ngOnInit() {
		this.setCurrentUseCase();
		this.layoutService.updateTitle('Use Case Library');
		this.sub = this.route.params.subscribe((params) => {
			this.useCaseId = params['id'];
			this.setCurrentUseCase();
		});

		this.subDb = this.dbService.dbBaseUpdateSource$.subscribe((id) => {
			if (id === this.useCaseId) {
				this.setCurrentUseCase();
			}
		});

		this.icons = await this.solutionService.getIcon();

		this.e2ETaxonomyService.getTechnologies().then((technologies) => {
			this.technologies = technologies;
			this.technologies = technologies.map((t) => {
				t.svg = this.safeHTML(t.svg) as any;
				return t;
			});
		});
	}

	async setCurrentUseCase() {
		const useCase = await this.dbService.data_use_case_library.get({ id: this.useCaseId });

		if (useCase) {
			this.useCase = useCase;

			if (useCase.sourceRel === '2') {
				const level1 = await this.e2ETaxonomyService.getLevel1(useCase.sourceId, true);
				if (level1) {
					this.taxonomyList.push({
						name: level1.name,
						path: level1.path,
					});
				}

				const levels2 = await this.e2ETaxonomyService.getLevels2({
					ids: useCase.data.levels2,
					recursiveRelation: true,
				});
				for (const level2 of levels2) {
					this.taxonomyList.push({
						name: level2.name,
						path: level2.path,
					});
				}
			}

			if (useCase.sourceRel === '3') {
				const level2 = await this.e2ETaxonomyService.getLevel2(useCase.sourceId, true);
				if (level2) {
					this.taxonomyList.push({
						name: level2.name,
						path: level2.path,
					});
				}
			}

			this.valuesDriversScores = [
				{
					title: 'Revenue Growth',
					label: this.valuesDriversScoresLabel(this.useCase.data.valuesDriversScores?.revenue_growth),
					className: this.valuesDriversScoresClassName(this.useCase.data.valuesDriversScores?.revenue_growth),
				},
				{
					title: 'Customer Experience',
					label: this.valuesDriversScoresLabel(this.useCase.data.valuesDriversScores?.customer_experience),
					className: this.valuesDriversScoresClassName(
						this.useCase.data.valuesDriversScores?.customer_experience,
					),
				},
				{
					title: 'Process Productivity',
					label: this.valuesDriversScoresLabel(this.useCase.data.valuesDriversScores?.process_productivity),
					className: this.valuesDriversScoresClassName(
						this.useCase.data.valuesDriversScores?.process_productivity,
					),
				},
				{
					title: 'Employee Productivity',
					label: this.valuesDriversScoresLabel(this.useCase.data.valuesDriversScores?.employee_productivity),
					className: this.valuesDriversScoresClassName(
						this.useCase.data.valuesDriversScores?.employee_productivity,
					),
				},
				{
					title: 'Cost Savings',
					label: this.valuesDriversScoresLabel(this.useCase.data.valuesDriversScores?.cost_savings),
					className: this.valuesDriversScoresClassName(this.useCase.data.valuesDriversScores?.cost_savings),
				},
			];
		}
		console.log('useCase', useCase);
		console.log('taxonomy', this.taxonomyList);
	}

	ngOnDestroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
		if (this.subDb) {
			this.subDb.unsubscribe();
		}
	}

	safeHTML(html: string) {
		const parser = new DOMParser();
		const document = parser.parseFromString(html, 'text/html');
		return this.sanitizer.bypassSecurityTrustHtml(document.body.outerHTML);
	}

	async goToBack() {
		await this.router.navigate(['/'], { queryParams: { NavTab: 2 } });
	}
}
