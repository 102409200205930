<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33591 17.0086L8.29761 17.0503L8.33591 17.0176V17.0086Z" fill="#6390B1"/>
    <path d="M7.56758 6.2392V4.23381L1.47144 9.73848H4.44684L7.56758 6.2392Z" fill="#6390B1"/>
    <path d="M7.56857 2.27352V2.19353H0.0224609V9.74189H1.42961L7.56857 2.27352Z" fill="#6390B1"/>
    <path d="M7.56753 7.85588L5.35034 9.73959H7.56753V7.85588Z" fill="#6390B1"/>
    <path d="M7.54836 17.9797V14.5909L5.27371 16.5625L7.54836 14.0107V11.0702L2.88302 15.1801L7.11236 10.4336H4.53465L1.62346 12.9122L3.83051 10.4336H0V17.982L7.54836 17.9797Z" fill="#6390B1"/>
    <path d="M8.33594 13.9081V17.0097L14.1876 10.4347H12.3399L8.33594 13.9081Z" fill="#6390B1"/>
    <path d="M8.33594 13.1263L10.7368 10.4336H8.33594V13.1263Z" fill="#6390B1"/>
    <path d="M15.8787 10.588L8.33594 17.021V17.982H15.8787V10.588Z" fill="#6390B1"/>
    <path d="M18.1836 0.0179901H9.26868V8.93294H18.1836V0.0179901Z" fill="#002F5F"/>
    <path d="M8.16916 16.5106L8.13086 16.5523L8.16916 16.5196V16.5106Z" fill="#6390B1"/>
</svg>
    