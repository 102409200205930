import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WorkSiteComponent } from './components/work-site/work-site.component';

import { AppLayoutComponent } from '../layout/app.layout.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { LoadTokenComponent } from './components/load-token/load-token.component';
import { AiExplorerComponent } from './components/ai-explorer/ai-explorer.component';
import { MaturityFrameworkComponent } from './components/maturity-framework/maturity-framework';
import { SimulationComponent } from './components/simulation/simulation.component';
import { SimulationItemComponent } from './components/simulation/simulation-item/simulation-item.component';
import { UseCaseDetailComponent } from './components/simulation/use-case-detail/use-case-detail.component';
import { DesignNexusComponent } from './components/design-nexus/design-nexus.component';
import { UseCasesComponent } from './components/use-cases/use-cases.component';

@NgModule({
	imports: [
		RouterModule.forChild([
			// { path: '**', component: WorkSiteComponent },
			{ path: 'sign-in', component: SignInComponent },
			{ path: 'change-password', component: ChangePasswordComponent },
			{ path: 'load-token', component: LoadTokenComponent },
			{
				path: '',
				component: AppLayoutComponent,
				children: [
					{ path: 'taxonomy', component: AiExplorerComponent },
					{ path: 'use-cases', component: UseCasesComponent },
					{ path: 'maturity-framework', component: MaturityFrameworkComponent },
					{ path: '', component: SimulationComponent },
					{ path: 'simulation/detail/:id', component: SimulationItemComponent },
					{ path: 'simulation/use-cases/detail', component: UseCaseDetailComponent },
					{ path: 'simulation/use-cases/detail/:id', component: UseCaseDetailComponent },
					{ path: 'design-nexus', component: DesignNexusComponent },
					{ path: '**', redirectTo: '/notfound' },
				],
			},
			{ path: 'notfound', component: NotfoundComponent },
		]),
	],
	exports: [RouterModule],
	declarations: [],
})
export class RtpRoutingModule {}
