import html2canvas from 'html2canvas';
import { Canvg } from 'canvg';
import { IAnswerFormData } from '../../../api/use-case.api';
import { Context, DataContent, BusinessDrivers } from './types';
import PptxGenJS from 'pptxgenjs';
import { Cursor, CursorInput, CursorOutput } from './cursor';
import Slide = PptxGenJS.Slide;
import TextPropsOptions = PptxGenJS.TextPropsOptions;
import TableRow = PptxGenJS.TableRow;
import TextProps = PptxGenJS.TextProps;
import TextBaseProps = PptxGenJS.TextBaseProps;
import BackgroundProps = PptxGenJS.BackgroundProps;
import { Style } from './style';

interface TextLargeOptions extends CursorInput {
	defaultFontSize: number;
	defaultFontFace: string;
	defaultLineSpacing: number;
	maxY: number;
	initY?: number;
}

export class Content {
	/**
	 * Class representing a Constructor.
	 *
	 * @constructor
	 */
	constructor(public cursor: Cursor, public style: Style) {}

	async getContent(context: Context): Promise<DataContent> {
		const data: DataContent = {
			useCases: context.useCases.map((u, i) => ({
				business_drivers: [],
			})),
		};

		let business_drivers: BusinessDrivers | undefined;
		const res = await context.commonService.getCommonData<BusinessDrivers>('business_drivers');
		if (res) {
			business_drivers = res.data;
		}

		if (business_drivers) {
			business_drivers.data = business_drivers.data.map((d) => ({
				...d,
				svg: this.svgToPng(d.svg.replace(/fill='none'/g, "fill='#7c8ea7'")),
			}));
		}

		const get_label = (value_string: string): '---' | 'High' | 'Medium' | 'Low' => {
			const value = parseFloat(value_string);
			if (value > 2) {
				return 'High';
			} else if (value > 1) {
				return 'Medium';
			} else if (value > 0) {
				return 'Low';
			} else {
				return '---';
			}
		};

		for (let i = 0; i < context.useCases.length; i++) {
			const useCase = context.useCases[i];

			let useCasesAnswerBenefits: { [key: string]: IAnswerFormData } = {};
			if (useCase?.id && business_drivers) {
				useCasesAnswerBenefits = useCase.getValue(business_drivers?.id)?.answers;
			}

			if (business_drivers) {
				for (const bd of business_drivers.data) {
					data.useCases[i].business_drivers = data.useCases[i].business_drivers.filter(
						(n) => n.tag !== bd.tag,
					);
					const answers = context.questions
						.filter((q) => q.tag === bd.tag)
						.map((q) => useCasesAnswerBenefits[q.id as any])
						.filter((a) => !!a);
					let value = '0';
					const max = Math.max(...answers.map((a) => parseFloat(a.value || '0')));
					if (!isNaN(max) && max !== Infinity && max !== -Infinity) {
						value = max.toFixed(0).toString();
					}

					data.useCases[i].business_drivers.push({
						tag: bd.tag,
						svg: bd.svg,
						value: get_label(value),
					});
				}
			}
		}

		return data;
	}

	/**
	 * Capture the screen of a given HTML element and return it as a data URL.
	 *
	 * @param {string} id - The ID of the HTML element to capture.
	 * @return {Promise<string>} A promise that resolves to the captured screen as a data URL,
	 *                           or an empty string if the element could not be found.
	 */
	async captureScreen(id: string): Promise<string> {
		const data = document.getElementById(id); // use your div id
		if (data) {
			return await html2canvas(data).then((canvas: HTMLCanvasElement) => {
				return canvas.toDataURL('image/png');
			});
		}
		return '';
	}

	/**
	 * This method captures the content of a canvas element and returns it as a data URL string.
	 *
	 * @param {string} id - The ID of the canvas element or its parent container.
	 * @return {string} - The data URL string representing the captured canvas content, or an empty string if the canvas was not found.
	 */
	captureCanvas(id: string): string {
		let canvas: HTMLCanvasElement | null;
		const data = document.getElementById(id); // use your div id
		if (data) {
			if (data.tagName === 'CANVAS') {
				canvas = data as any;
			} else {
				canvas = data.querySelector('canvas');
			}
			if (canvas) {
				return canvas.toDataURL('image/png');
			}
		}
		return '';
	}

	/**
	 * Converts SVG to PNG.
	 *
	 * @param {string} svg - The SVG code.
	 * @return {string} - The base64 encoded PNG data URL.
	 */
	svgToPng(svg: string): string {
		const canvas = document.createElement('canvas');
		Canvg.fromString(canvas.getContext('2d') as any, svg).start();
		return canvas.toDataURL('image/png');
	}

	/**
	 * Retrieves the dimensions of an image based on the image source.
	 *
	 * @param {string} src - The source URL of the image.
	 * @returns {Promise<{ w: number; h: number }>} - A Promise that resolves with an object containing the width and height of the image.
	 */
	async getImageDimensions(src: string): Promise<{ w: number; h: number }> {
		return new Promise(function (resolved, rejected) {
			let i = new Image();
			i.onload = function () {
				resolved({ w: i.width, h: i.height });
			};
			i.src = src;
		});
	}

	/**
	 * Draws a custom chart on a given slide.
	 *
	 * @param {CursorInput} opt - The cursor output object containing the chart's position and size.
	 * @param {number} value - The value to be displayed on the chart. If 0 or falsy, no chart will be drawn.
	 */
	chartCustom(opt: CursorInput, value: number) {
		this.cursor.newPosition(opt, (p, slide) => {
			p.h = p.w;
			slide.addShape('blockArc', {
				fill: { color: '#dee2e6' },
				angleRange: [180, 0],
				arcThicknessRatio: 0.5,
				...p,
			});
			if (value) {
				let color = '#A52819';
				if (value > 75) {
					color = '#7FAA3A';
				} else if (value > 55) {
					color = '#FFC000';
				}
				slide.addShape('blockArc', {
					fill: { color },
					angleRange: [180, 180 * (1 + value / 100)],
					arcThicknessRatio: 0.5,
					...p,
				});
			}
		});
	}

	/**
	 * Renders large text on a slide.
	 *
	 * @param {string | TextProps[]} input - The text to be rendered or an array of TextProps objects.
	 * @param {CursorInput} options - The cursor options for positioning the text.
	 * @param {TextPropsOptions} [textPropsOptions={}] - Additional options for customizing the text properties.
	 * @param slideOptions
	 * @param callbacks
	 * @returns {void}
	 */
	textLarge(
		input: string | TextProps[],
		options: CursorInput & { initY?: number },
		textPropsOptions: TextPropsOptions = {},
		slideOptions?: { footer: boolean; background?: BackgroundProps | null },
		callbacks?: {
			beforeAdded?: (position: CursorOutput, slide: Slide) => void;
			afterAdded?: (position: CursorOutput, slide: Slide) => void;
		},
	): void {
		const pages: [TextProps[], CursorInput][] = [];

		const textLargeOptions: TextLargeOptions = {
			defaultFontSize: textPropsOptions.fontSize ?? 12,
			defaultFontFace: 'Calibri',
			defaultLineSpacing: 1.2,
			maxY: 0,
			...options,
		};

		textLargeOptions.maxX = options.maxX || this.cursor.maxX;
		textLargeOptions.maxY = options.maxY || this.cursor.maxY;
		textLargeOptions.y =
			options.initY ||
			(options.y ? this.cursor.parseValue(options.y, textLargeOptions.maxY) : this.cursor.getPosition().y) +
				(options.yS === undefined ? this.cursor.getPosition().yS : options.yS);
		textLargeOptions.x =
			(options.x ? this.cursor.parseValue(options.x, textLargeOptions.maxX) : this.cursor.getPosition().x) +
			(options.xS === undefined ? this.cursor.getPosition().xS : options.xS);

		if (options.h) {
			textLargeOptions.h = this.cursor.parseValue(options.h, textLargeOptions.maxY - textLargeOptions.y);
		} else {
			textLargeOptions.h = textLargeOptions.maxY - textLargeOptions.y;
		}

		if (options.align) {
			if (options.align === 'center') {
				textLargeOptions.x = textLargeOptions.x + (textLargeOptions.maxX - textLargeOptions.x) / 2;
			}
		}

		if (options.w) {
			textLargeOptions.w = this.cursor.parseValue(options.w, textLargeOptions.maxX - textLargeOptions.x);
			if (options.align) {
				if (options.align === 'center') {
					textLargeOptions.w = this.cursor.parseValue(
						options.w,
						(textLargeOptions.maxX - textLargeOptions.x) * 2,
					);
					textLargeOptions.x =
						textLargeOptions.x -
						textLargeOptions.w / 2 -
						(options.xS === undefined ? this.cursor.getPosition().xS : options.xS) * 2;
				}
			}
		} else {
			textLargeOptions.w = this.cursor.parseValue('90%', textLargeOptions.maxX - textLargeOptions.x);
		}

		if (Array.isArray(input)) {
			this.getTextLargeByArrayTextProps(input, 0.8, pages, textLargeOptions, textPropsOptions);
		} else {
			this.getTextLargeByArrayTextProps([{ text: input }], 1.005, pages, textLargeOptions, textPropsOptions);
		}
		let i = 0;
		let f = pages.length;
		pages.map(([text, opt]) => {
			this.cursor.newPosition(opt, (p, slide) => {
				if (callbacks?.beforeAdded) {
					callbacks.beforeAdded(p, slide);
				}
				slide.addText(text, p);
				if (callbacks?.afterAdded) {
					callbacks?.afterAdded(p, slide);
				}
			});
			if (f > 1 && i + 1 < f) {
				this.cursor.newSlide(slideOptions);
			}
			i++;
		});
	}

	public getTextLargeByArrayTextProps(
		texts: TextProps[],
		mLH: number,
		pages: [TextProps[], CursorInput][],
		textLargeOptions: TextLargeOptions,
		textPropsOptions: TextPropsOptions,
	) {
		let lw = 0;
		let l = 1;
		let currentText = '';

		const nC =
			typeof textLargeOptions.w === 'number'
				? Math.ceil((textLargeOptions.w / (textLargeOptions.defaultFontSize / 72)) * 2 + 1)
				: 102;
		let yText = textLargeOptions.y as number;
		let lineHeight = ((textLargeOptions.defaultFontSize * textLargeOptions.defaultLineSpacing) / 72) * mLH;

		let page: TextProps[] = [];

		let nextPage = false;

		let bulletLineHeightUpdated = false;
		let bulletAdd = false;
		let bulletLineHeight = 0;
		let bulletMaxY = 0;

		const addTextProps = (currentText: string | undefined, textProps: TextProps) => {
			if (bulletAdd) {
				currentText = '- ' + currentText;
				bulletAdd = false;
			}
			page.push({
				text: currentText,
				options: {
					...textProps.options,
					...textPropsOptions,
					fontFace: textLargeOptions.defaultFontFace,
					fontSize: textLargeOptions.defaultFontSize,
					lineSpacing: textLargeOptions.defaultFontSize * textLargeOptions.defaultLineSpacing,
				},
			});
			currentText = '';
		};

		const addPage = (options: CursorInput) => {
			currentText = '';
			let h = l * lineHeight;
			if (mLH > 1) {
				h = (h / mLH) * 0.5;
			}
			if (yText + h > textLargeOptions.maxY) {
				h = textLargeOptions.maxY - yText - 0.15;
			}
			pages.push([page, { ...options, y: yText, h }]);
			page = [];
			lw = 0;
			l = 1;
			textLargeOptions.h = 4;
			yText = textLargeOptions.initY || 0.5;
			nextPage = true;
			if (bulletLineHeight) {
				lineHeight = bulletLineHeight;
				bulletLineHeight = 0;
			}
			if (bulletMaxY) {
				textLargeOptions.maxY = bulletMaxY;
				bulletMaxY = 0;
			}
		};

		const newPage = (currentText: string, options: CursorInput, textProps: TextProps) => {
			addTextProps(currentText, textProps);
			addPage(options);
		};

		let addedTextProps = false;

		let lastTextProps: TextProps | undefined;

		texts.forEach((textProps, j) => {
			lastTextProps = textProps;
			if (nextPage && !bulletLineHeight && !bulletMaxY) {
				bulletLineHeight = lineHeight;
				lineHeight = lineHeight * 1.15;
				bulletMaxY = textLargeOptions.maxY;
				textLargeOptions.maxY = textLargeOptions.maxY * 1.015;
			}
			if (textProps.text?.startsWith('Key Tasks')) {
				l++;
				lw = 0;
				addTextProps('', { options: { breakLine: true } });
			}

			if (textProps.text && textProps.text !== ' ') {
				const words = textProps.text.split(' ');
				currentText = '';
				addedTextProps = false;
				words.forEach((w) => {
					let mC = 1;
					if (addedTextProps) {
						addedTextProps = false;
					}
					if (w.includes('\t')) {
						w.split('\t').join('aaaaaaaaaaaaaaaaaaaa');
					}
					if (textProps.options?.bold) {
						mC = 1.25;
					} else if (textProps.options?.italic) {
						mC = 1.25;
					} else if (textProps.options?.bold && textProps.options?.italic) {
						mC = 1.3;
					}
					if (lw + w.length * mC > nC) {
						l++;
						if (w.length * mC > nC) {
							l++;
						}
						lw = 0;
					} else if (w.length * mC > nC) {
						l++;
						lw = 0;
					}
					if (w.includes('\n')) {
						const wn = w.split('\n');
						l = l + wn.length - 1;
						if (wn[wn.length - 1].length * mC > nC || !wn[wn.length - 1]) {
							l++;
							lw = 0;
						}
					} else {
						lw = lw ? lw + w.length * mC + 1 : w.length * mC;
					}
					currentText = currentText ? currentText + ' ' + w : w;
					if (yText + (l + 1) * lineHeight * 0.95 >= textLargeOptions.maxY) {
						newPage(currentText, textLargeOptions, textProps);
						addedTextProps = true;
					}
				});
				if (!addedTextProps) {
					addTextProps(currentText, textProps);
					addedTextProps = true;
				}
				if (lw > nC) {
					l++;
					lw = 0;
				}
				if (yText + (l + 1) * lineHeight > textLargeOptions.maxY) {
					if (!addedTextProps) {
						addTextProps(currentText, textProps);
					}
					addPage(textLargeOptions);
				}
			} else if (textProps.options?.breakLine) {
				l++;
				lw = 0;
				addTextProps(textProps.text, textProps);
				if (yText + (l + 1) * lineHeight > textLargeOptions.maxY) {
					addPage(textLargeOptions);
				}
			} else if (textProps.options?.bullet) {
				l++;
				lw = 0;
				if (!bulletLineHeightUpdated) {
					if (!bulletLineHeight) {
						bulletLineHeight = lineHeight;
					}
					lineHeight = lineHeight * 1.18;
					bulletLineHeightUpdated = true;
					if (!bulletMaxY) {
						bulletMaxY = textLargeOptions.maxY;
					}
					if ((textLargeOptions.y as number) > 1) {
						textLargeOptions.maxY = textLargeOptions.maxY * 1.05;
					} else {
						textLargeOptions.maxY = textLargeOptions.maxY * 1.035;
					}
				}
				addTextProps('', { options: { breakLine: true } });
				bulletAdd = true;
				if (yText + (l + 1) * lineHeight > textLargeOptions.maxY) {
					addPage(textLargeOptions);
				}
			} else if (textProps.text === ' ') {
				lw = 1;
				addTextProps(textProps.text, textProps);
				if (yText + (l + 1) * lineHeight > textLargeOptions.maxY) {
					addPage(textLargeOptions);
				}
			}
		});

		if (lastTextProps && !addedTextProps) {
			newPage(lastTextProps.text || '', textLargeOptions, lastTextProps);
		}

		if (!pages.length && page.length) {
			addPage(textLargeOptions);
		}

		if (pages[pages.length - 1] !== page && page.length) {
			addPage(textLargeOptions);
		}

		return pages;
	}

	/**
	 * Create a large table by dividing the given table rows into multiple smaller tables.
	 * The height of each table is adjusted based on the provided parameters.
	 *
	 * @param {TableRow[]} tableRows - An array of table rows.
	 * @param {object} options - The options of the table.
	 * @param {number} options.h - The height of the table.
	 * @param {number} options.rowH - The row height of the table.
	 *
	 * @return {Array<Array<TableRow>>} - An array of arrays sub-tables, where each sub-table is represented by an array of table rows.
	 */
	tableLarge(tableRows: TableRow[], options: { h: number; rowH: number }): TableRow[][] {
		const tables: TableRow[][] = [];
		let currenTable: TableRow[] = [];

		let currentHeight = 0;
		const maxCharByLine = 25;

		tableRows.forEach((tableRow, index) => {
			let lines = 1;
			let currentChars = 0;
			if (tableRow[1] && tableRow[1].text && typeof tableRow[1].text === 'string') {
				tableRow[1].text.split(' ').filter((word) => {
					if (currentChars + word.length >= maxCharByLine) {
						lines++;
						if (word.length >= maxCharByLine) {
							lines++;
						}
						currentChars = 0;
					} else if (word.length >= maxCharByLine) {
						lines++;
						currentChars = 0;
					} else {
						currentChars += word.length;
					}
					currentChars++;
				});
				if (currentChars - 1 >= maxCharByLine) {
					lines++;
				}
			}
			if (lines > 1) {
				currentHeight += lines * options.rowH * 0.5;
			} else {
				currentHeight += options.rowH;
			}
			if (currentHeight < options.h) {
				currenTable.push(tableRow);
			} else {
				if (currenTable.length) {
					tables.push(currenTable);
				}
				currenTable = [tableRow];
				currentHeight = 0;
			}
		});
		if (currenTable.length) {
			tables.push(currenTable);
		}
		return tables;
	}

	progressBar(opt: CursorInput, label: string, value: number, textProps: TextBaseProps) {
		this.cursor.newPosition(opt, (p, slide) => {
			slide.addText(label, {
				...p,
				...textProps,
				valign: 'middle',
			});

			p.x = p.x + (p.w || 1);

			slide.addShape('roundRect', {
				fill: { color: '#dee2e6' },
				...p,
			});
			if (value) {
				let color = '#A52819';
				if (value > 75) {
					color = '#7FAA3A';
				} else if (value > 55) {
					color = '#FFC000';
				}
				slide.addShape('roundRect', {
					fill: { color },
					...p,
					w: (p.w || 1) * (value / 100),
				});
				slide.addText(value + '%', {
					...p,
					w: (p.w || 1) * (value / 100),
					color: '#ffffff',
					fontSize: 10,
					align: 'center',
				});
			}
		});
	}

	title(
		text: string,
		options?: {
			slide?: Slide;
			p?: CursorOutput;
			inverted?: boolean;
			afterAdded?: (p: CursorOutput, slide: Slide) => void;
		},
	) {
		const callback = (p: CursorOutput, slide: Slide) => {
			const slideText = options?.slide || slide;
			slideText.addImage({
				path: '../../../../../assets/rtp/img/iso_aiExplorer_color.png',
				...p,
				y: p.y + 0.06,
				w: 0.364,
				h: 0.364,
			});
			slideText.addText(text, {
				...p,
				x: p.x + 0.364 + 0.2,
				...this.style.getTitle('h2', options?.inverted),
			});
			if (options?.afterAdded) {
				options.afterAdded(p, slide);
			}
		};
		if (options?.p) {
			callback(options.p, options?.slide || this.cursor.slide);
		} else {
			this.cursor.newPosition({ h: 0.5 }, callback);
		}
	}
}
