import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../service/auth.service';
import { FormSignIn } from '../../api/auth.api';
import { DbService } from '../../service/db.service';

@Component({
	templateUrl: './sign-in.component.html',
	providers: [MessageService],
})
export class SignInComponent implements OnInit {
	constructor(
		public dbService: DbService,
		public authService: AuthService,
		public messageService: MessageService,
		public router: Router,
	) {
		this.authService.getUser().then(async (user) => {
			if (user) {
				if (user.change_password) {
					await this.router.navigateByUrl('/change-password');
				} else {
					await this.router.navigateByUrl('/');
				}
			}
		});
	}

	form: FormSignIn;
	loading: boolean;

	ngOnInit() {
		this.form = this.authService.getSignInForm();
	}

	signIn() {
		this.loading = true;
		this.authService.signIn(this.form).then((v) => {
			if (v) {
				this.dbService.dbInit = false;
				this.authService.getUser().then(async (user) => {
					if (user) {
						if (user.change_password) {
							await this.router.navigateByUrl('/change-password');
						} else {
							this.messageService.add({ severity: 'success', summary: 'Welcome' });
							setTimeout(async () => {
								this.loading = false;
								await this.router.navigateByUrl('/');
							}, 500);
						}
					}
				});
			} else {
				this.loading = false;
				this.messageService.add({ severity: 'warn', summary: 'Access denied' });
			}
		});
	}

	reset_password_visible: boolean = false;
	reset_password_loading: boolean = false;
	username_email = '';

	showResetPassword() {
		this.username_email = '';
		this.reset_password_visible = true;
		this.reset_password_loading = false;
	}

	resetPassword() {
		this.reset_password_loading = true;
		this.authService.resetPassword(this.username_email).subscribe({
			next: (response) => {
				if (response.valid) {
					this.messageService.add({
						severity: 'success',
						summary: 'Recovery Email',
						detail: 'An email has been sent to your address to reset your password.',
					});
				} else {
					this.messageService.add({ severity: 'warn', summary: 'Failed to send email' });
				}
			},
			error: () => {
				this.messageService.add({ severity: 'warn', summary: 'Failed to send email' });
			},
			complete: () => {
				this.reset_password_loading = false;
				this.reset_password_visible = false;
			},
		});
	}
}
