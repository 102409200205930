<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>

<div class="card card-content-maturity-framework">
    <div class="flex flex-column w-full gap-5">
        <div class="grid grid-main overflow-auto">
            <div class="col-12">
                <div class="flex justify-content-between gap-3">
                    <div class="flex justify-content-start align-items-center gap-3">
                        <p class="text-lg md:text-xl lg:text-2xl xl:text-2xl text-primary m-0">Assessment Overview</p>
                        <div class="flex justify-content-start gap-4 align-items-center">
                            <div class="border-1 border-round-xl" style="border-color: #eef5ff; padding: 0.5rem">
                                <span class="text-primary">{{ stageValue }}</span>
                            </div>
                        </div>
                    </div>
                    <p-button label="Export PDF" icon="pi pi-file-pdf" (onClick)="exportPDF()"></p-button>
                </div>
            </div>
            <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                <div class="flex flex-column justify-content-start gap-2">
                    <p class="text-lg font-semibold m-0 white-space-nowrap overflow-hidden text-overflow-ellipsis">
                        Hackett’s AI Framework is based on seven Foundational Pillars
                    </p>
                    <div class="overflow-scroll card-pillars scroll-height">
                        <div class="maturity-chart">
                            <svg
                                id="Layer_2"
                                data-name="Layer 2"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 501.69 498.25"
                            >
                                <defs>
                                    <style>
                                        .cls-1 {
                                            fill: url(#radial-gradient);
                                        }

                                        .cls-1,
                                        .cls-2,
                                        .cls-3 {
                                            stroke-width: 0px;
                                        }

                                        .cls-4 {
                                            fill: #1b54f8;
                                            font-family: OpenSans-Semibold, 'Open Sans';
                                            font-size: 16px;
                                            font-weight: 600;
                                        }

                                        .cls-5 {
                                            fill: #f5f5ff;
                                            stroke: #1b54f8;
                                            stroke-width: 0.83px;
                                        }

                                        .cls-5,
                                        .cls-6 {
                                            stroke-miterlimit: 10;
                                        }

                                        .cls-6 {
                                            fill: none;
                                            stroke: #fff;
                                            stroke-width: 3px;
                                        }

                                        .cls-2,
                                        .cls-7 {
                                            fill: #fff;
                                        }

                                        .cls-3 {
                                            fill: #f8f8ff;
                                        }

                                        .cls-7 {
                                            font-family: OpenSans-Regular, 'Open Sans';
                                            font-size: 14px;
                                            font-variation-settings: 'wght' 400, 'wdth' 100;
                                        }
                                    </style>
                                    <radialGradient
                                        id="radial-gradient"
                                        cx="22638.91"
                                        cy="1836.74"
                                        fx="22638.91"
                                        fy="1836.74"
                                        r="5822.58"
                                        gradientTransform="translate(-690.19 338.53) rotate(-10.18) scale(.04)"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop offset="0" stop-color="#6a4595" />
                                        <stop offset="1" stop-color="#1B54F8" />
                                    </radialGradient>
                                </defs>
                                <g id="CircleBackground">
                                    <g id="_CircleBackgroundShape_" data-name="&amp;lt;CircleBackgroundShape&amp;gt;">
                                        <circle class="cls-1" cx="250.58" cy="247.25" r="242.26" />
                                    </g>
                                </g>
                                <g id="Dividers">
                                    <line class="cls-6" x1="250.58" y1="247.25" x2="439.98" y2="96.21" />
                                    <line class="cls-6" x1="250.58" y1="247.25" x2="250.58" y2="5" />
                                    <line class="cls-6" x1="250.58" y1="247.25" x2="61.17" y2="96.21" />
                                    <line class="cls-6" x1="250.58" y1="247.25" x2="14.39" y2="301.16" />
                                    <line class="cls-6" x1="250.58" y1="247.25" x2="145.47" y2="465.52" />
                                    <line class="cls-6" x1="250.58" y1="247.25" x2="355.69" y2="465.52" />
                                    <line class="cls-6" x1="250.58" y1="247.25" x2="486.76" y2="301.16" />
                                </g>
                                <g id="InnerCircle">
                                    <g>
                                        <path
                                            class="cls-3"
                                            d="M250.85,321.16c-3.2,0-6.43-.21-9.62-.63-40.42-5.26-69.02-42.41-63.76-82.83,4.77-36.71,36.25-64.39,73.2-64.39,3.2,0,6.43.21,9.63.63,40.42,5.26,69.02,42.41,63.76,82.83-4.77,36.71-36.25,64.39-73.2,64.39h0Z"
                                        />
                                        <path
                                            class="cls-2"
                                            d="M250.66,173.79c3.16,0,6.35.2,9.56.62,40.22,5.23,68.59,42.08,63.36,82.31-4.81,37.01-36.39,63.98-72.74,63.98-3.16,0-6.35-.2-9.56-.62-40.22-5.23-68.59-42.08-63.36-82.31,4.81-37.01,36.39-63.98,72.74-63.98M250.66,172.85c-37.19,0-68.86,27.86-73.67,64.8-2.56,19.7,2.7,39.22,14.82,54.97,12.12,15.74,29.64,25.82,49.35,28.39,3.21.42,6.47.63,9.69.63,37.19,0,68.86-27.86,73.67-64.8,2.56-19.7-2.7-39.22-14.82-54.97-12.12-15.74-29.64-25.82-49.35-28.39-3.21-.42-6.47-.63-9.69-.63h0Z"
                                        />
                                    </g>
                                </g>
                                <g id="Text">
                                    <text class="cls-7" transform="translate(287.06 103.39)">
                                        <tspan x="0" y="0">Strategy &amp;</tspan>
                                        <tspan x="-2.49" y="16.8">Leadership</tspan>
                                    </text>
                                    <text class="cls-7" transform="translate(383.75 212.19)">
                                        <tspan x="0" y="0">Ethics &amp;</tspan>
                                        <tspan x="-12.33" y="16.8">Compliance</tspan>
                                    </text>
                                    <text class="cls-7" transform="translate(337.12 333.55)">
                                        <tspan x="0" y="0">Organization,</tspan>
                                        <tspan x="12.84" y="16.8">Culture &amp;</tspan>
                                        <tspan x="23.57" y="33.6">Talent</tspan>
                                    </text>
                                    <text class="cls-7" transform="translate(224.53 396.55)">
                                        <tspan x="0" y="0">Security</tspan>
                                        <tspan x="-3.96" y="16.8">&amp; Privacy</tspan>
                                    </text>
                                    <text class="cls-7" transform="translate(89.13 341.97)">
                                        <tspan x="0" y="0">Technology</tspan>
                                        <tspan x="-2.03" y="16.8">Enablement</tspan>
                                    </text>
                                    <text class="cls-7" transform="translate(77.74 200.97)">
                                        <tspan x="0" y="0">Data &amp;</tspan>
                                        <tspan x="-13.65" y="16.8">Knowledge</tspan>
                                        <tspan x="-21.03" y="33.6">Management</tspan>
                                    </text>
                                    <text class="cls-7" transform="translate(175.65 99.45)">
                                        <tspan x="0" y="0">AI</tspan>
                                        <tspan x="-32.87" y="16.8">Enablement</tspan>
                                    </text>
                                    <text class="cls-4" transform="translate(243.02 234.85)">
                                        <tspan x="0" y="0">AI</tspan>
                                        <tspan x="-43.41" y="19.2">Foundational</tspan>
                                        <tspan x="-13.64" y="38.4">Pilars</tspan>
                                    </text>
                                </g>
                                <g id="SmallCircles">
                                    <circle class="cls-5" cx="293.4" cy="8.78" r="8.36" />
                                    <circle class="cls-5" cx="353.66" cy="27.94" r="8.36" />
                                    <circle class="cls-5" cx="406.92" cy="62.05" r="8.36" />
                                    <circle class="cls-5" cx="463.78" cy="131.91" r="8.36" />
                                    <circle class="cls-5" cx="486.37" cy="190.97" r="8.36" />
                                    <circle class="cls-5" cx="492.91" cy="253.87" r="8.36" />
                                    <circle class="cls-5" cx="473.74" cy="341.89" r="8.36" />
                                    <circle class="cls-5" cx="441.65" cy="396.38" r="8.36" />
                                    <circle class="cls-5" cx="396.54" cy="440.71" r="8.36" />
                                    <circle class="cls-5" cx="315.78" cy="480.6" r="8.36" />
                                    <circle class="cls-5" cx="253.17" cy="489.48" r="8.36" />
                                    <circle class="cls-5" cx="190.39" cy="481.85" r="8.36" />
                                    <circle class="cls-5" cx="108.85" cy="443.57" r="8.36" />
                                    <circle class="cls-5" cx="62.87" cy="400.16" r="8.36" />
                                    <circle class="cls-5" cx="29.69" cy="346.32" r="8.36" />
                                    <circle class="cls-5" cx="8.78" cy="258.7" r="8.36" />
                                    <circle class="cls-5" cx="14.06" cy="195.68" r="8.36" />
                                    <circle class="cls-5" cx="35.47" cy="136.18" r="8.36" />
                                    <circle class="cls-5" cx="90.93" cy="65.2" r="8.36" />
                                    <circle class="cls-5" cx="143.49" cy="30.04" r="8.36" />
                                    <circle class="cls-5" cx="203.36" cy="9.68" r="8.36" />
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
                <div class="flex flex-column justify-content-start gap-2">
                    <div class="flex justify-content-between">
                        <p class="text-lg font-semibold m-0">AI Foundational Pillars</p>
                        <p-button
                            label="View Recommendations"
                            icon="pi pi-plus-circle"
                            (click)="modal(categories.length)"
                        ></p-button>
                    </div>
                    <div class="overflow-scroll card-pillars scroll-height pt-3">
                        <div class="flex flex-column justify-content-start pr-5">
                            <div *ngFor="let category of categories, let i = index" class="grid">
                                <div class="col-10 py-1">
                                    <div class="flex flex-column justify-content-start my-2 gap-2">
                                        <div class="flex justify-content-between gap-3" (click)="modal(i)">
                                            <p class="text-primary font-semibold m-0 cursor-pointer">
                                                {{ category.name }}
                                            </p>
                                            <div class="flex justify-content-start gap-4 align-items-center">
                                                <div
                                                    class="border-1 border-round-xl"
                                                    style="border-color: #eef5ff; padding: 0.5rem"
                                                >
                                                    <span class="text-primary">{{ getCategoryStage(category) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <p-progressBar
                                            styleClass="maturity-progress"
                                            [value]="getStageValue(category)"
                                        ></p-progressBar>
                                    </div>
                                </div>
                                <div class="col-2 py-1">
                                    <div class="flex flex-column justify-content-end my-2" style="height: 80%">
                                        <span class="text-primary font-bold">{{ getStageValue(category) }} %</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
