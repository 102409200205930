import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CountUp } from 'countup.js';
import { SimulationService } from '../simulation.service';
import { isNumber } from 'mathjs';


@Component({
	selector: 'app-taxonomy-context',
	templateUrl: './taxonomy.component.html',
	styleUrls: ['./taxonomy.component.scss'],
})
export class TaxonomyComponent implements OnInit {
	@ViewChild('all_countUp', {static:true}) all_countUpElement!: ElementRef;
	@Input() counters: any;
	@ViewChild('aiOpportunitiesCountUp', { static: true }) aiOpportunitiesElement!: ElementRef;
	@ViewChild('genAiUseCasesCountUp', { static: true }) genAiUseCasesElement!: ElementRef;
	@ViewChild('e2eCountUp', { static: true }) e2eElement!: ElementRef;
	@ViewChild('processCountUp', { static: true }) processElement!: ElementRef;
	@ViewChild('subProcessCountUp', { static: true }) subProcessElement!: ElementRef;
	@ViewChild('e2eSourceRelCountUp', { static: true }) e2eSourceRelElement!: ElementRef;
	@ViewChild('processSourceRelCountUp', { static: true }) processSourceRelElement!: ElementRef;
	@ViewChild('subProcessSourceRelCountUp', { static: true }) subProcessSourceRelElement!: ElementRef;

	private all_countUp!: CountUp;

	private aiOpportunitiesCountUp!: CountUp;
	private genAiUseCasesCountUp!: CountUp;
	private e2eCountUp!: CountUp;
	private processCountUp!: CountUp;
	private subProcessCountUp!: CountUp;
	private e2eSourceRelCountUp!: CountUp;
	private processSourceRelCountUp!: CountUp;
	private subProcessSourceRelCountUp!: CountUp;
	constructor(
		public simulationService: SimulationService,
	) {
		this.simulationService.counterGenAiObservable.subscribe(() => {
		this.startCountUps(this.simulationService.counters);
		})
	}

	chartData: {
		value: number;
		data: any;
	}[] = [];

	chartDataOptions = {
		cutout: '60%',
		rotation: 270,
		circumference: 180,
		responsive: true,
		maintainAspectRatio: true,
		aspectRatio: 2,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
			datalabels: {
				display: false,
			},
		},
	};

	ngOnInit() {
		this.startCountUps(this.simulationService.counters);
		//this.startCountUp(this.counters.genAiUseCases);

		const genAiUseCases = isNumber(this.counters?.genAiUseCases) ? this.counters?.genAiUseCases : Number(this.counters?.genAiUseCases?.replace(/,/g, '')) || 1;
		//const e2eSourceRel = Number(this.counters?.e2eSourceRel) || 0;
		const processSourceRel = Number(this.counters?.processSourceRel) || 0;
		const subProcessSourceRel = Number(this.counters?.subProcessSourceRel) || 0;

		//const e2ePercentage = (e2eSourceRel / genAiUseCases) * 100 || 0;
		const processPercentage = (processSourceRel / genAiUseCases) * 100 || 0;
		const subProcessPercentage = (subProcessSourceRel / genAiUseCases) * 100 || 0;

		this.chartData = [
			{
				// value: e2ePercentage,
				// data: this.getDataChartValue(e2ePercentage),
				value: 100,
				data: this.getDataChartValue(15),
			},
			{
				value: processPercentage,
				//data: this.getDataChartValue(processPercentage),
				data: this.getDataChartValue(30),
			},
			{
				value: subProcessPercentage,
				data: this.getDataChartValue(subProcessPercentage),
			},
		];

		// console.log('ChartData:', this.chartData);

	}

	startCountUps(counters: any) {
		const options = { startVal: 0, duration: 2 };

		this.aiOpportunitiesCountUp = new CountUp(this.aiOpportunitiesElement.nativeElement, parseInt(this.simulationService.counters?.aiOpportunities.toString().replace(/,/g, '')) || 0, options);
		this.genAiUseCasesCountUp = new CountUp(this.genAiUseCasesElement.nativeElement, parseInt(this.simulationService.counters?.genAiUseCases.toString().replace(/,/g, '')) || 0, options);
		this.e2eCountUp = new CountUp(this.e2eElement.nativeElement, parseInt(this.simulationService.counters?.e2e || '0'), options);
		this.processCountUp = new CountUp(this.processElement.nativeElement, parseInt(this.simulationService.counters?.process || '0'), options);
		this.subProcessCountUp = new CountUp(this.subProcessElement.nativeElement, parseInt(this.simulationService.counters?.subProcess || '0'), options);
		this.e2eSourceRelCountUp = new CountUp(this.e2eSourceRelElement.nativeElement, parseInt(this.simulationService.counters?.e2eSourceRel || '0'), options);
		this.processSourceRelCountUp = new CountUp(this.processSourceRelElement.nativeElement, parseInt(this.simulationService.counters?.processSourceRel || '0'), options);
		this.subProcessSourceRelCountUp = new CountUp(this.subProcessSourceRelElement.nativeElement, parseInt(this.simulationService.counters?.subProcessSourceRel || '0'), options);

		this.aiOpportunitiesCountUp.start();
		this.genAiUseCasesCountUp.start();
		this.e2eCountUp.start();
		this.processCountUp.start();
		this.subProcessCountUp.start();
		this.e2eSourceRelCountUp.start();
		this.processSourceRelCountUp.start();
		this.subProcessSourceRelCountUp.start();
	}

	getDataChartValue(value: number) {
		const documentStyle = getComputedStyle(document.documentElement);
		let color = '#1B54F8';

		return {
			labels: ['', ''],
			datasets: [
				{
					label: '',
					data: [value, 100 - value],
					backgroundColor: [color, documentStyle.getPropertyValue('--surface-300')],
					borderColor: ['rgba(255, 255, 255 ,1)', 'rgba(255, 255, 255 ,1)'],
					borderWidth: 5,
				},
			],
		};
	}
}
