import { BaseEntity, IBaseEntity } from './base.api';
import { IOrganization, Organization } from './common';

export interface ICategoryQuestionMaturity extends IBaseEntity {
	name: string;
	sort: number;
}

export class CategoryQuestionMaturity extends BaseEntity implements ICategoryQuestionMaturity {
	constructor(public name: string, public sort: number, created_at?: number, updated_at?: number, id?: string) {
		super(created_at, updated_at, id);
	}
}

export interface ISubCategoryQuestionMaturity extends IBaseEntity {
	name: string;
	sort: number;

	category: ICategoryQuestionMaturity;
	categoryId: string;
}

export class SubCategoryQuestionMaturity extends BaseEntity implements ISubCategoryQuestionMaturity {
	category: CategoryQuestionMaturity;

	constructor(
		public name: string,
		public sort: number,
		public categoryId: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(created_at, updated_at, id);
		Object.defineProperties(this, {
			category: { value: undefined, enumerable: false, writable: true },
		});
	}
}

export interface IQuestionMaturity extends IBaseEntity {
	text: string;
	sort: number;

	category: ICategoryQuestionMaturity;
	categoryId: string;

	subcategory: ISubCategoryQuestionMaturity;
	subcategoryId: string;
}

export class QuestionMaturity extends BaseEntity implements IQuestionMaturity {
	category: CategoryQuestionMaturity;
	subcategory: SubCategoryQuestionMaturity;

	constructor(
		public text: string,
		public sort: number,
		public categoryId: string,
		public subcategoryId: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(created_at, updated_at, id);
		Object.defineProperties(this, {
			category: { value: undefined, enumerable: false, writable: true },
			subcategory: { value: undefined, enumerable: false, writable: true },
		});
	}
}

export interface IAssessmentMaturity extends IBaseEntity {
	content: string;
	formData: { [key: string]: number };
	commentData: { [key: string]: string };
	recommendations: string[];

	organization: IOrganization;
}

export class AssessmentMaturity extends BaseEntity implements IAssessmentMaturity {
	organization: Organization;

	constructor(
		public content: string,
		public formData: {
			[key: string]: number;
		},
		public commentData: {
			[key: string]: string;
		},
		public recommendations: string[],
		public organizationId: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(created_at, updated_at, id);
	}
}
