export const getRandom = <T>(items: T[]) => items[Math.floor(Math.random() * items.length)];

export const randomBetween = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

export const createdAt = () => new Date().getTime();

export const toCapitalize = (str: string) =>
	str
		.split(' ')
		.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
		.join(' ');
