import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
	selector: 'app-image',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
	load = true;

	@Input() src: string;
	@Input() styleClass: string;

	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onClick = new EventEmitter<Event>();

	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onLoad = new EventEmitter<Event>();

	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onError = new EventEmitter<Event>();

	constructor(public el: ElementRef) {}

	_onLoad(evt: Event): void {
		this.load = false;
		this.onLoad.emit(evt);
	}
}
