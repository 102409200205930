require('jquery');

function ZoomControls(canvas, eventBus) {
	// build controls
	var $canvas = $(canvas.getContainer());
	var $controls = $('<div></div>');
	var $buttonSet = $('<span class="p-buttonset flex flex-row flex-wrap"></span>');
	var $zoomOut = $(`
		<button
			class="p-ripple p-element p-button p-component p-button-icon-only button-zoom bg-white p-button-text p-button-secondary flex align-items-center justify-content-center" type="button">
			<span class="pi pi-search-minus font-bold p-button-icon"></span>
		</button>
	`);
	var $zoomIn = $(`
		<button
			class="p-ripple p-element p-button p-component p-button-icon-only button-zoom bg-white p-button-text p-button-secondary flex align-items-center justify-content-center" type="button">
			<span class="pi pi-search-plus font-bold p-button-icon"></span>
		</button>
	`);
	var $zoomFit = $(`
		<button
			class="p-ripple p-element p-button p-component p-button-icon-only button-zoom bg-white p-button-text p-button-secondary flex align-items-center justify-content-center" type="button">
			<span class="pi pi-arrows-alt font-bold p-button-icon"></span>
		</button>
	`);
	var zlevel = 1;
	var zstep = 0.2;

	$canvas.append($controls);
	$controls.append($buttonSet);
	$buttonSet.append($zoomIn);
	$buttonSet.append($zoomOut);
	$buttonSet.append($zoomFit);

	$controls.addClass('zoom-controls');
	// $zoomOut.addClass('zoom zoom-out');
	// $zoomIn.addClass('zoom zoom-in');
	// $zoomFit.addClass('zoom zoom-fit');

	$zoomOut.attr('title', 'Zoom out');
	$zoomIn.attr('title', 'Zoom in');
	$zoomFit.attr('title', 'Fit to viewport');

	// update our zoom level on viewbox change
	eventBus.on('canvas.viewbox.changed', function (evt) {
		zlevel = evt.viewbox.scale;
	});

	// define click handlers for controls
	$zoomFit.on('click', function () {
		canvas.zoom('fit-viewport');
	});

	$zoomOut.on('click', function () {
		zlevel = Math.max(zlevel - zstep, zstep);
		canvas.zoom(zlevel, 'auto');
	});

	$zoomIn.on('click', function () {
		zlevel = Math.min(zlevel + zstep, 7);
		canvas.zoom(zlevel, 'auto');
	});
}

// inject necessary dependencies
ZoomControls.$inject = ['canvas', 'eventBus'];

module.exports = ZoomControls;
