<div class="pt-1 px-2">
	<form class="card-content-form">
		<div *ngIf="service.useCaseService.currentUseCase" class="grid m-0">
			<div class="col-12">
				<div class="flex flex-column gap-3 h-full">
					<div class="flex flex-column gap-2">
						<label for="name">Name</label>
						<input
							pInputText
							id="name"
							name="name"
							aria-describedby="name-help"
							[(ngModel)]="service.useCaseService.currentUseCase.name"
							(ngModelChange)="service.updateUseCase()"
						/>
					</div>
					<div class="flex flex-column gap-2 w-20rem">
						<label for="levels2">Taxonomy</label>
						<p-treeSelect
							*ngIf="service.generalTags.length"
							class="w-full"
							id="levels2"
							name="levels2"
							containerStyleClass="w-full"
							[(ngModel)]="service.generalTagsSelection"
							(ngModelChange)="service.updateUseCase()"
							[options]="service.generalTags"
							display="chip"
							[metaKeySelection]="false"
							selectionMode="checkbox"
							placeholder="Select Item"
							appendTo="body"
						></p-treeSelect>
						<p-skeleton *ngIf="!service.generalTags.length" styleClass="mb-2"></p-skeleton>
					</div>

					<div class="flex flex-column gap-2 h-full">
						<label for="description">Description</label>
						<p-editor
							id="description"
							name="description"
							aria-describedby="description-help"
							[(ngModel)]="service.useCaseService.currentUseCase.description"
							(ngModelChange)="service.updateUseCase()"
							class="h-full"
							styleClass="h-full"
						></p-editor>
					</div>
				</div>
			</div>
			<!--
			<div class="col-12 lg:col-5 xl:col-5">
				<div class="flex flex-column flex-wrap gap-3">
					
					 <ng-template [ngIf]="service.useCaseService.currentUseCase.generalData">
						<div class="flex flex-column gap-2">
							<label for="cycle_time">Cycle Time</label>
							<div class="flex flex-wrap gap-2">
								<p-inputNumber
									id="cycle_time"
									inputId="cycle_time"
									name="cycle_time"
									aria-describedby="cycle_time-help"
									[(ngModel)]="service.useCaseService.currentUseCase.generalData.cycle_time"
									(onInput)="service.updateUseCase()"
								></p-inputNumber>
								<p-dropdown
									[(ngModel)]="service.useCaseService.currentUseCase.generalData.type_cycle"
									(ngModelChange)="service.updateUseCase()"
									[options]="service.generalTypeCycleOptions"
									id="type_cycle"
									inputId="type_cycle"
									name="type_cycle"
									optionLabel="label"
									optionValue="value"
									styleClass="w-full"
									appendTo="body"
								></p-dropdown>
							</div>
						</div>
						<div class="flex flex-column gap-2">
							<label for="fte">FTEs Involved</label>
							<p-inputNumber
								id="fte"
								inputId="fte"
								name="fte"
								aria-describedby="fte-help"
								[(ngModel)]="service.useCaseService.currentUseCase.generalData.fte"
								(onInput)="service.updateUseCase()"
							></p-inputNumber>
						</div>
						<div class="flex flex-column gap-2">
							<label for="effort">Effort</label>
							<div class="flex flex-wrap gap-2">
								<p-inputNumber
									id="effort"
									inputId="effort"
									name="effort"
									aria-describedby="effort-help"
									[(ngModel)]="service.useCaseService.currentUseCase.generalData.effort"
									(onInput)="service.updateUseCase()"
								></p-inputNumber>
								<p-dropdown
									[(ngModel)]="service.useCaseService.currentUseCase.generalData.effort_cycle"
									(ngModelChange)="service.updateUseCase()"
									[options]="service.generalEffortCycleOptions"
									id="effort_cycle"
									inputId="effort_cycle"
									name="effort_cycle"
									optionLabel="label"
									optionValue="value"
									styleClass="w-full"
									appendTo="body"
								></p-dropdown>
							</div>
						</div>
					</ng-template> 
				</div>
			</div>-->
		</div>
	</form>
</div>
