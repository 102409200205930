<div id="page-taxonomy" class="page p-0">
    <div class="flex gap-0">
        <div
            class="side-filter border-right-1 border-gray-300"
            [ngClass]="{
		'active': showSide,
		'extend': !showSideExtend,
		}"
        >
            <div class="flex flex-column gap-3 page-content justify-content-between">
                <div *ngIf="showSide" class="flex flex-column justify-content-start gap-2">
                    <div class="flex justify-content-between gap-3">
                        <span class="p-input-icon-right w-full">
                            <i class="pi pi-search"></i>
                            <input
                                type="text"
                                pInputText
                                placeholder="Search process"
                                class="search"
                                [(ngModel)]="aiExplorer.searchProcess"
                                (keyup.enter)="aiExplorer.changeSearchProcess()"
                                (blur)="aiExplorer.changeSearchProcess()"
                            />
                        </span>
                        <p-button
                            *ngIf="!showSideExtend"
                            [icon]="showSide ? 'pi pi-angle-up' : 'pi pi-angle-down'"
                            styleClass="button-header"
                            (onClick)="toggleSide()"
                        ></p-button>
                    </div>
                    <p-selectButton
                        [options]="aiExplorer.selectionTypeFilterOptions"
                        [(ngModel)]="aiExplorer.selectionTypeFilter"
                        styleClass="selection-office-filter"
                        (onOptionClick)="aiExplorer.selectionType()"
                    >
                        <ng-template let-item>
                            <div class="flex justify-content-center align-items-center gap-3 w-full">
                                <span>{{ item.label }}</span>
                            </div>
                        </ng-template>
                    </p-selectButton>
                    <div class="p-tree p-component p-tree-selectable">
                        <div class="p-tree-wrapper">
                            <ul class="p-tree-container">
                                <li class="p-treenode" (click)="aiExplorer.clearSelectionTreeNode()">
                                    <div
                                        class="p-treenode-content p-treenode-selectable"
                                        [ngClass]="!aiExplorer.treeNodeSelected ? 'p-highlight' : ''"
                                    >
                                        <button
                                            class="p-ripple p-element p-tree-toggler p-link"
                                            style="width: 0"
                                        ></button>
                                        <div
                                            class="flex justify-content-between align-self-center align-content-center align-items-center w-full p-treenode-label overflow-hidden"
                                        >
                                            <span>All Taxonomy</span>
                                            <i *ngIf="aiExplorer.loading_all" class="pi pi-spin pi-spinner mr-1"></i>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="filter-content">
                        <div class="p-tree p-component p-tree-selectable label-filter">
                            <div class="p-tree-wrapper">
                                <ul class="p-tree-container">
                                    <li class="p-treenode">
                                        <div class="p-treenode-content">
                                            <button
                                                class="p-ripple p-element p-tree-toggler p-link"
                                                style="width: 0"
                                            ></button>
                                            <div
                                                class="flex justify-content-between align-self-center align-content-center align-items-center w-full p-treenode-label overflow-hidden"
                                            >
                                                <span>Front Office</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p-tree
                            [value]="aiExplorer.frontOfficeTreeNode"
                            class="w-full"
                            styleClass=""
                            selectionMode="single"
                            [(selection)]="aiExplorer.treeNodeSelected"
                            (selectionChange)="aiExplorer.selectionTreeNode($event)"
                        ></p-tree>
                        <div class="p-tree p-component p-tree-selectable label-filter">
                            <div class="p-tree-wrapper">
                                <ul class="p-tree-container">
                                    <li class="p-treenode">
                                        <div class="p-treenode-content">
                                            <button
                                                class="p-ripple p-element p-tree-toggler p-link"
                                                style="width: 0"
                                            ></button>
                                            <div
                                                class="flex justify-content-between align-self-center align-content-center align-items-center w-full p-treenode-label overflow-hidden"
                                            >
                                                <span>Middle Office</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p-tree
                            [value]="aiExplorer.middleOfficeTreeNode"
                            class="w-full"
                            styleClass=""
                            selectionMode="single"
                            [(selection)]="aiExplorer.treeNodeSelected"
                            (selectionChange)="aiExplorer.selectionTreeNode($event)"
                        ></p-tree>
                        <div class="p-tree p-component p-tree-selectable label-filter">
                            <div class="p-tree-wrapper">
                                <ul class="p-tree-container">
                                    <li class="p-treenode">
                                        <div class="p-treenode-content">
                                            <button
                                                class="p-ripple p-element p-tree-toggler p-link"
                                                style="width: 0"
                                            ></button>
                                            <div
                                                class="flex justify-content-between align-self-center align-content-center align-items-center w-full p-treenode-label overflow-hidden"
                                            >
                                                <span>Back Office</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p-tree
                            [value]="aiExplorer.backOfficeTreeNode"
                            class="w-full"
                            styleClass=""
                            selectionMode="single"
                            [(selection)]="aiExplorer.treeNodeSelected"
                            (selectionChange)="aiExplorer.selectionTreeNode($event)"
                        ></p-tree>
                    </div>
                </div>
                <div class="flex justify-content-end align-items-center">
                    <p-button
                        *ngIf="showSideExtend"
                        click-stop-propagation
                        class="filter-sidebar-button"
                        styleClass="p-button-text text-primary hover:text-white"
                        [icon]="'pi ' + (showSide ? 'pi-chevron-circle-left' : 'pi-chevron-circle-right')"
                        (onClick)="toggleSide()"
                    ></p-button>
                </div>
            </div>
        </div>
        <div
            *ngIf="showSideExtend || (!showSideExtend && !showSide)"
            class="side-content"
            [ngClass]="{
				'active': showSide,
				'extend': !showSideExtend,
			}"
        >
            <div class="flex flex-column gap-3 page-content">
                <div class="flex flex-column justify-content-start w-full">
                    <div class="flex justify-content-between mb-1">
                        <p id="title-section" class="text-md lg:text-lg xl:text-xl font-bold">
                            Gen AI Opportunities Matrix <sup>TM</sup>
                        </p>
                        <div class="flex gap-2">
                            <p-button
                                *ngIf="!filterTab"
                                [styleClass]="'button-header' + (activeFilter ? ' active' : '')"
                                (onClick)="opOverallFilterVisible($event)"
                                [icon]="'pi ' + (activeFilter ? 'pi-filter-slash' : 'pi-filter')"
                            ></p-button>

                            <p-selectButton
                                *ngIf="!filterTab"
                                [options]="filterViewOptions"
                                [(ngModel)]="filterView"
                                styleClass="filter-view"
                            >
                                <ng-template let-item>
                                    <app-svg-filter1 *ngIf="item.value === 1" />
                                    <app-svg-filter2 *ngIf="item.value === 2" />
                                    <app-svg-filter3 *ngIf="item.value === 3" />
                                </ng-template>
                            </p-selectButton>

                            <p-selectButton
                                [options]="filterTabOptions"
                                [(ngModel)]="filterTab"
                                styleClass="filter-tab"
                            >
                                <ng-template let-item>
                                    <ng-template [ngIf]="!item.value">
                                        <div class="flex justify-content-center align-items-center gap-3">
                                            <span class="pi pi-chart-bar"></span>
                                            <span>Heatmap</span>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngIf]="item.value">
                                        <div class="flex justify-content-center align-items-center gap-3">
                                            <span class="pi pi-sitemap"></span>
                                            <span>Diagram</span>
                                        </div>
                                    </ng-template>
                                </ng-template>
                            </p-selectButton>

                            <p-button
                                *ngIf="!showSideExtend"
                                [icon]="showSide ? 'pi pi-angle-up' : 'pi pi-angle-down'"
                                styleClass="button-header"
                                (onClick)="toggleSide()"
                            ></p-button>
                        </div>
                    </div>

                    <ng-template [ngIf]="!aiExplorer.loading">
                        <p-tabView [(activeIndex)]="filterTab">
                            <p-tabPanel>
                                <div *ngIf="aiExplorer.loading">
                                    <div class="p-dataview p-component p-dataview-list">
                                        <div class="p-dataview-content">
                                            <div class="p-grid p-nogutter grid grid-nogutter">
                                                <div class="col-12 row-table pl-3 py-3 ng-star-inserted">
                                                    <div class="grid m-0 pl-2">
                                                        <div class="cell-data col-name cursor-pointer">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 row-table pl-3 py-2 ng-star-inserted">
                                                    <div class="grid m-0">
                                                        <div class="col-category">
                                                            <p-skeleton width="50%"></p-skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 row-table pl-3 py-2 ng-star-inserted">
                                                    <div class="grid m-0 pl-2">
                                                        <div class="cell-data col-name cursor-pointer">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 row-table pl-3 py-2 ng-star-inserted">
                                                    <div class="grid m-0 pl-2">
                                                        <div class="cell-data col-name cursor-pointer">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 row-table pl-3 py-2 ng-star-inserted">
                                                    <div class="grid m-0 pl-2">
                                                        <div class="cell-data col-name cursor-pointer">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 row-table pl-3 py-2 ng-star-inserted">
                                                    <div class="grid m-0 pl-2">
                                                        <div class="cell-data col-name cursor-pointer">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 row-table pl-3 py-2 ng-star-inserted">
                                                    <div class="grid m-0 pl-2">
                                                        <div class="cell-data col-name cursor-pointer">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-wrap h-content">
                                    <div class="col-12 data-header border-gray-300 border-bottom-1">
                                        <div class="grid grid-header m-0">
                                            <div class="text-left justify-content-start align-self-end col-name">
                                                <p
                                                    class="uppercase text-primary"
                                                    style="
                                                        font-weight: 800;
                                                        font-family: 'Open Sans Variable';
                                                        padding-bottom: 0.15rem;
                                                    "
                                                >
                                                    AI Enablers
                                                </p>
                                            </div>
                                            <div
                                                *ngFor="let technology of aiExplorer.technologies; let i = index"
                                                class="cell-data col-value cursor-default"
                                            >
                                                <button
                                                    pRipple
                                                    [id]="'button-header-' + technology.id"
                                                    class="p-button p-button-text my-0 py-0 px-1 button-header"
                                                >
                                                    <div
                                                        class="flex flex-column justify-content-between align-items-center gap-1 h-full w-full"
                                                        (mouseenter)="overlayTechnologyShow(i, $event)"
                                                        (mouseleave)="overlayTechnologyHide()"
                                                        (click)="i === 6 ? opOverallFilterVisible($event) : null"
                                                    >
                                                        <span class="uppercase font-bold" style="font-size: 0.6rem">{{
                                                            technology.name
                                                        }}</span>
                                                        <div
                                                            class="flex justify-content-center align-items-center text-center"
                                                        >
                                                            <app-svg-gen-ai
                                                                *ngIf="i === 0"
                                                                class="icon-tooltip flex flex-column justify-content-end align-items-center h-full w-full"
                                                            ></app-svg-gen-ai>
                                                            <app-svg-pa
                                                                *ngIf="i === 1"
                                                                class="icon-tooltip flex flex-column justify-content-end align-items-center h-full w-full"
                                                            ></app-svg-pa>
                                                            <app-svg-ca
                                                                *ngIf="i === 2"
                                                                class="icon-tooltip flex flex-column justify-content-end align-items-center h-full w-full"
                                                            ></app-svg-ca>
                                                            <app-svg-idc
                                                                *ngIf="i === 3"
                                                                class="icon-tooltip flex flex-column justify-content-end align-items-center h-full w-full"
                                                            ></app-svg-idc>
                                                            <app-svg-io
                                                                *ngIf="i === 4"
                                                                class="icon-tooltip flex flex-column justify-content-end align-items-center h-full w-full"
                                                            ></app-svg-io>
                                                            <app-svg-ta
                                                                *ngIf="i === 5"
                                                                class="icon-tooltip flex flex-column justify-content-end align-items-center h-full w-full"
                                                            ></app-svg-ta>
                                                            <app-svg-otp
                                                                *ngIf="i === 6"
                                                                class="icon-tooltip flex flex-column justify-content-end align-items-center h-full w-full"
                                                            ></app-svg-otp>
                                                            <span
                                                                *ngIf="
                                                                    aiExplorer.sortOrder &&
                                                                    aiExplorer.sortField === technology.id
                                                                "
                                                                class="pi"
                                                                [ngClass]="
                                                                    aiExplorer.sortOrder === 1
                                                                        ? 'pi-angle-down'
                                                                        : 'pi-angle-up'
                                                                "
                                                            ></span>
                                                        </div>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div
                                            #scrollList
                                            class="grid overflow-scroll scroll-height-0 align-content-start"
                                        >
                                            <ng-template ngFor let-fn [ngForOf]="aiExplorer.tableData" let-i="index">
                                                <div
                                                    *ngIf="fn.path && fn.getOverallFilter(overallFilter)"
                                                    [attr.id]="'sticky-' + fn.id"
                                                    class="col-12 row-table sticky-header"
                                                >
                                                    <div class="grid m-0">
                                                        <div
                                                            class="align-self-center col-name"
                                                            (click)="aiExplorer.e2ETaxonomyService.editDiagramE2E(fn)"
                                                        >
                                                            <p class="font-bold text-primary">{{ fn.path }}</p>
                                                        </div>
                                                        <div
                                                            *ngFor="let active of fn.valuesArray"
                                                            class="cell-data col-value cursor-default"
                                                        >
                                                            <div class="box-1">
                                                                <i
                                                                    *ngIf="active"
                                                                    class="pi pi-check-circle text-primary"
                                                                ></i>
                                                            </div>
                                                        </div>
                                                        <div class="cell-data col-value text-center">
                                                            <p [ngClass]="fn.overallClass">
                                                                {{ fn.overallLabel }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <ng-template [ngIf]="fn.getOverallFilter(overallFilter)">
                                                    <ng-template ngFor let-level1 [ngForOf]="fn.levels1" let-i="index">
                                                        <div
                                                            *ngIf="
                                                                level1.name && (filterView === 2 || filterView === 3)
                                                            "
                                                            class="col-12 row-table"
                                                        >
                                                            <div class="grid m-0">
                                                                <div
                                                                    class="align-self-center col-name"
                                                                    (click)="
                                                                        aiExplorer.e2ETaxonomyService.editDiagramProcess(
                                                                            level1
                                                                        )
                                                                    "
                                                                    tippy
                                                                    [tippyContent]="
                                                                        level1.name.length > 38 ? level1.name : ''
                                                                    "
                                                                >
                                                                    <p class="font-bold">{{ level1.name }}</p>
                                                                </div>
                                                                <div
                                                                    *ngFor="let active of level1.valuesArray"
                                                                    class="cell-data col-value cursor-default"
                                                                >
                                                                    <div class="box-1">
                                                                        <i
                                                                            *ngIf="active"
                                                                            class="pi pi-check-circle text-primary"
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ng-template
                                                            [ngIf]="level1.path && level1.levels2 && filterView === 3"
                                                        >
                                                            <div
                                                                *ngFor="let level2 of level1.levels2"
                                                                class="col-12 row-table"
                                                            >
                                                                <div class="grid m-0">
                                                                    <div
                                                                        class="cell-data col-name cursor-pointer"
                                                                        (click)="
                                                                            aiExplorer.e2ETaxonomyService.editDiagramTemplate(
                                                                                level2
                                                                            )
                                                                        "
                                                                        tippy
                                                                        [tippyContent]="
                                                                            level2.name.length > 40 ? level2.name : ''
                                                                        "
                                                                    >
                                                                        <p>{{ level2.name }}</p>
                                                                    </div>
                                                                    <div
                                                                        *ngFor="let active of level2.valuesArray"
                                                                        class="cell-data col-value cursor-default"
                                                                    >
                                                                        <div class="box-1">
                                                                            <i
                                                                                *ngIf="active"
                                                                                class="pi pi-check-circle text-level2"
                                                                            ></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-12 py-2"></div>
                                                        </ng-template>

                                                        <ng-template [ngIf]="!level1.levels2">
                                                            <div class="col-12">
                                                                <div class="grid m-0 pl-2">
                                                                    <div class="p-dataview-emptymessage">
                                                                        No results found
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </ng-template>
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <ng-template [ngIf]="!aiExplorer.tableData.length">
                                        <ng-template [ngIf]="aiExplorer.loading_all">
                                            <div class="p-grid p-nogutter grid grid-nogutter">
                                                <div class="col-12 row-table pl-3 py-2 ng-star-inserted">
                                                    <div class="grid m-0">
                                                        <div class="col-category">
                                                            <p-skeleton width="50%"></p-skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 row-table pl-3 py-2 ng-star-inserted">
                                                    <div class="grid m-0 pl-2">
                                                        <div class="cell-data col-name cursor-pointer">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 row-table pl-3 py-2 ng-star-inserted">
                                                    <div class="grid m-0 pl-2">
                                                        <div class="cell-data col-name cursor-pointer">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 row-table pl-3 py-2 ng-star-inserted">
                                                    <div class="grid m-0 pl-2">
                                                        <div class="cell-data col-name cursor-pointer">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 row-table pl-3 py-2 ng-star-inserted">
                                                    <div class="grid m-0 pl-2">
                                                        <div class="cell-data col-name cursor-pointer">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 row-table pl-3 py-2 ng-star-inserted">
                                                    <div class="grid m-0 pl-2">
                                                        <div class="cell-data col-name cursor-pointer">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                        <div class="cell-data col-value">
                                                            <p-skeleton></p-skeleton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template [ngIf]="!aiExplorer.loading_all">
                                            <div class="col-12">
                                                <div class="grid m-0 pl-2">
                                                    <div class="p-dataview-emptymessage">No results found</div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </p-tabPanel>
                            <p-tabPanel>
                                <div #scrollGrid class="grid px-3 overflow-scroll scroll-height-1">
                                    <ng-template ngFor let-fn [ngForOf]="aiExplorer.tableData">
                                        <div class="col-12 p-0"></div>
                                        <div *ngIf="fn.path" class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-6 p-2">
                                            <div
                                                class="p-4 border-1 surface-border surface-card border-round card-template grid-data cursor-pointer"
                                                (click)="aiExplorer.e2ETaxonomyService.editDiagramE2E(fn)"
                                            >
                                                <div class="h-full w-full text-center">
                                                    <p class="text-sm font-bold text-primary">{{ fn.path }}</p>
                                                    <app-image
                                                        [src]="fn.capture_url"
                                                        [styleClass]="
                                                            'image-grid' +
                                                            (fn.capture_url.endsWith('thumb_emptyDiagram.svg')
                                                                ? ' image-empty'
                                                                : '')
                                                        "
                                                        (onError)="aiExplorer.onErrorImageE2E(fn)"
                                                    ></app-image>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-template ngFor let-level1 [ngForOf]="fn.levels1">
                                            <div class="col-12 p-0"></div>
                                            <div
                                                *ngIf="level1.name"
                                                class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-3 p-2"
                                            >
                                                <div
                                                    class="p-4 border-1 surface-border surface-card border-round card-template grid-data cursor-pointer"
                                                    (click)="aiExplorer.e2ETaxonomyService.editDiagramProcess(level1)"
                                                >
                                                    <div class="h-full w-full text-center">
                                                        <app-image
                                                            [src]="level1.capture_url"
                                                            [styleClass]="
                                                                'image-grid' +
                                                                (level1.capture_url.endsWith('thumb_emptyDiagram.svg')
                                                                    ? ' image-empty'
                                                                    : '')
                                                            "
                                                            (onError)="aiExplorer.onErrorImageProcess(level1)"
                                                        ></app-image>
                                                        <p class="text-sm font-bold">{{ level1.name }}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <ng-template [ngIf]="level1.levels2">
                                                <div
                                                    *ngFor="let level2 of level1.levels2"
                                                    class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-3 p-2"
                                                >
                                                    <div
                                                        class="p-4 border-1 surface-border surface-card border-round card-template grid-data cursor-pointer"
                                                        (click)="
                                                            aiExplorer.e2ETaxonomyService.editDiagramTemplate(level2)
                                                        "
                                                    >
                                                        <div class="h-full w-full text-center">
                                                            <app-image
                                                                [src]="level2.capture_url"
                                                                [styleClass]="
                                                                    'image-grid' +
                                                                    (level2.capture_url.endsWith(
                                                                        'thumb_emptyDiagram.svg'
                                                                    )
                                                                        ? ' image-empty'
                                                                        : '')
                                                                "
                                                                (onError)="aiExplorer.onErrorImage(level2)"
                                                            ></app-image>
                                                            <p class="text-sm">{{ level2.name }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>

                                            <ng-template [ngIf]="!level1.levels2">
                                                <div class="col-12">
                                                    <div class="grid m-0 pl-2">
                                                        <div class="p-dataview-emptymessage">No results found</div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-template>
                                    </ng-template>
                                </div>
                                <ng-template [ngIf]="!aiExplorer.tableData.length">
                                    <ng-template [ngIf]="aiExplorer.loading_all">
                                        <div class="flex flex-wrap gap-3 ml-3">
                                            <div class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-3 p-2">
                                                <div
                                                    class="p-4 border-1 surface-border surface-card border-round card-template grid-data cursor-pointer"
                                                >
                                                    <div class="flex flex-column align-items-center gap-3">
                                                        <p-skeleton size="10rem" styleClass="mr-2"></p-skeleton>
                                                        <p-skeleton styleClass="mr-2"></p-skeleton>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-3 p-2">
                                                <div
                                                    class="p-4 border-1 surface-border surface-card border-round card-template grid-data cursor-pointer"
                                                >
                                                    <div class="flex flex-column align-items-center gap-3">
                                                        <p-skeleton size="10rem" styleClass="mr-2"></p-skeleton>
                                                        <p-skeleton styleClass="mr-2"></p-skeleton>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-3 p-2">
                                                <div
                                                    class="p-4 border-1 surface-border surface-card border-round card-template grid-data cursor-pointer"
                                                >
                                                    <div class="flex flex-column align-items-center gap-3">
                                                        <p-skeleton size="10rem" styleClass="mr-2"></p-skeleton>
                                                        <p-skeleton styleClass="mr-2"></p-skeleton>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-3 p-2">
                                                <div
                                                    class="p-4 border-1 surface-border surface-card border-round card-template grid-data cursor-pointer"
                                                >
                                                    <div class="flex flex-column align-items-center gap-3">
                                                        <p-skeleton size="10rem" styleClass="mr-2"></p-skeleton>
                                                        <p-skeleton styleClass="mr-2"></p-skeleton>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-3 p-2">
                                                <div
                                                    class="p-4 border-1 surface-border surface-card border-round card-template grid-data cursor-pointer"
                                                >
                                                    <div class="flex flex-column align-items-center gap-3">
                                                        <p-skeleton size="10rem" styleClass="mr-2"></p-skeleton>
                                                        <p-skeleton styleClass="mr-2"></p-skeleton>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 sm:col-6 md:col-4 lg:col-3 xl:col-3 p-2">
                                                <div
                                                    class="p-4 border-1 surface-border surface-card border-round card-template grid-data cursor-pointer"
                                                >
                                                    <div class="flex flex-column align-items-center gap-3">
                                                        <p-skeleton size="10rem" styleClass="mr-2"></p-skeleton>
                                                        <p-skeleton styleClass="mr-2"></p-skeleton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngIf]="!aiExplorer.loading_all">
                                        <div class="col-12">
                                            <div class="grid m-0 pl-2">
                                                <div class="p-dataview-emptymessage">No results found</div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-template>
                            </p-tabPanel>
                        </p-tabView>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template ngFor let-technology let-i="index" [ngForOf]="aiExplorer.technologies">
    <p-overlayPanel #op [styleClass]="'technologies-tooltip' + (i < 6 ? ' move' : '')">
        <span>{{ tooltipTitle }}</span>
        <p>
            {{ tooltipText }}
        </p>
    </p-overlayPanel>
</ng-template>

<p-overlayPanel #opOverallFilter>
    <div class="flex flex-column gap-3 justify-content-start">
        <div class="flex">
            <p-checkbox [value]="1" [(ngModel)]="overallFilter" inputId="op-filter-low" id="op-filter-low"></p-checkbox>
            <label class="pl-2" for="op-filter-low">Incremental</label>
        </div>
        <div class="flex">
            <p-checkbox
                [value]="2"
                [(ngModel)]="overallFilter"
                inputId="oop-filter-medium"
                id="op-filter-medium"
            ></p-checkbox>
            <label class="pl-2" for="op-filter-medium">Transformative</label>
        </div>
        <div class="flex">
            <p-checkbox
                [value]="3"
                [(ngModel)]="overallFilter"
                inputId="op-filter-high"
                id="op-filter-high"
            ></p-checkbox>
            <label class="pl-2" for="op-filter-high">Breakthrough</label>
        </div>
    </div>
</p-overlayPanel>
