import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Apollo, gql } from 'apollo-angular';
import type { ApolloQueryResult } from '@apollo/client/core';
import { ICategoryFunction } from '../api/e2e-taxonomy.api';
import { DbService } from './db.service';

export const queryTaxonomyTreeNode = gql`
	query QueryTaxonomyTreeNode {
		e2eTaxonomy {
			categoryFunction {
				all {
					id
					name
				}
				count
				fetch {
					id
					name
				}
			}
		}
	}
`;

interface TypeTaxonomyTreeNode {
	e2eTaxonomy: {
		categoryFunction: {
			count: number;
			fetch: { id: string }[];
		};
	};
}

/**
 * Service class for interacting with the E2E Taxonomy data.
 */
@Injectable({
	providedIn: 'root',
})
export class E2ETaxonomyV2Service {
	constructor(public dbService: DbService) {}


	getTaxonomyTreeNode() {
		return this.dbService
			.queryData<TypeTaxonomyTreeNode>(queryTaxonomyTreeNode)
			.then((res) => {
				console.log(res);
			})
			.catch((e) => {
				console.log(e);
			});
	}
}
