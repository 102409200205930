import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DbService } from '../../../service/db.service';
import { AssessmentMaturity, CategoryQuestionMaturity, QuestionMaturity } from '../../../api/maturity.api';
import { AuthService } from '../../../service/auth.service';
import { Options as OptionsSlider } from 'ngx-slider-v2/options';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import handlebars from 'handlebars';
import { PromptInput, promptMaturity } from './prompt';
import { CommonService } from '../../../service/common.service';
import type { MediumEditor } from 'medium-editor';

@Component({
	templateUrl: './maturity-framework-assessment.html',
})
export class MaturityFrameworkAssessmentComponent implements OnInit {
	constructor(
		public sanitizer: DomSanitizer,
		public ref: DynamicDialogRef,
		public messageService: MessageService,
		public commonService: CommonService,
		public dbService: DbService,
		public authService: AuthService,
		public config: DynamicDialogConfig<{
			current_index?: number;
		}>,
	) {}

	current_index = 0;

	max_index = 7;

	labels: string[] = [];
	phases: string[] = [];

	items: MenuItem[] = [];

	data: { label: string; questions: { id: string; text: string; value: number; comment: string }[] }[][] = [];

	loading = true;

	categories: CategoryQuestionMaturity[] = [];

	assessment: AssessmentMaturity;

	async ngOnInit() {
		if (this.config?.data?.current_index) {
			this.current_index = this.config.data.current_index;
		}

		this.categories = await this.dbService.data_category_question_maturity.toCollection().sortBy('sort');

		const assessments = await this.dbService.data_assessment_maturity
			.where('organizationId')
			.equals(await this.authService.getCurrentOrganizationId())
			.toArray();

		if (assessments.length) {
			this.assessment = assessments[0];
		} else {
			this.assessment = new AssessmentMaturity('', {}, {}, [], await this.authService.getCurrentOrganizationId());
			await this.dbService.data_assessment_maturity.add(this.assessment);
		}

		this.categories.forEach((c) => {
			this.labels.push(c.name);
			let label = c.name.split(' ')[0];
			if (label.length < 3) {
				label = c.name;
			}
			this.items.push({
				label,
			});
		});

		this.phases = [...this.labels];

		if (!this.assessment.recommendations.length) {
			this.assessment.recommendations = this.phases.map((_) => '');
		}
		this.contentsHtml = this.assessment.recommendations.map((c) => this.safeHTML(c || '<span></span>'));

		const label = 'Recommendations';

		this.labels.push(label);

		this.items.push({
			label,
		});

		this.max_index = this.items.length - 1;

		for (const category of this.categories) {
			const subcategories = await this.dbService.data_sub_category_question_maturity
				.where('categoryId')
				.equals(category.id as string)
				.sortBy('sort');

			const data: { label: string; questions: { id: string; text: string; value: number; comment: string }[] }[] =
				[];
			let value: number = 0;
			let maxValue: number = 0;

			for (const subcategory of subcategories) {
				const questions: QuestionMaturity[] = (
					await this.dbService.data_question_maturity.toCollection().sortBy('sort')
				).filter(
					(q) => q.categoryId === (category.id as string) && q.subcategoryId === (subcategory.id as string),
				);

				data.push({
					label: subcategory.name,
					questions: questions.map((q) => {
						const v = this.assessment.formData[q.id as string] || 0;
						if (v) {
							value = value + v;
							maxValue = maxValue + 4;
						}
						return {
							id: q.id as string,
							text: q.text,
							value: this.assessment.formData[q.id as string] || 0,
							comment: this.assessment.commentData[q.id as string] || '',
						};
					}),
				});
			}

			let v = parseFloat(((value / maxValue) * 100).toFixed(0));

			v = isNaN(v) ? 0 : v;

			this.chartData.push({
				value: v,
				data: this.getDataChartValue(v),
			});

			this.data.push(data);
		}

		this.loading = false;

		this.ref.onClose.subscribe(() => {
			this.dbService.data_assessment_maturity.update(this.assessment.id as string, this.assessment);
		});

		this.promptTemplate = handlebars.compile<PromptInput>(promptMaturity);
	}

	close() {
		this.ref.close();
	}

	previousStep() {
		if (this.current_index > 0) {
			this.current_index--;
		}
	}

	nextStep() {
		if (this.current_index < this.max_index) {
			this.current_index++;
		}
	}

	changeStep(index: number) {
		this.current_index = index;
	}

	chartDataOptions = {
		cutout: '60%',
		rotation: 270,
		circumference: 180,
		responsive: true,
		maintainAspectRatio: true,
		aspectRatio: 2,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false,
			},
			datalabels: {
				display: false,
			},
		},
	};

	chartData: {
		value: number;
		data: any;
	}[] = [];

	getFormSliderOptions(question: { id: string; value: number }): OptionsSlider {
		const getTextClass = (value: number): string => {
			if (value === 4) {
				return 'text-4';
			} else if (value === 3) {
				return 'text-3';
			} else if (value === 2) {
				return 'text-2';
			} else if (value === 1) {
				return 'text-1';
			} else {
				return 'text-na';
			}
		};
		return {
			floor: 0,
			ceil: 4,
			step: 1,
			showTicks: true,
			showTicksValues: true,
			getPointerColor: (value: number): string => {
				if (value === 4) {
					return '#383890';
				} else if (value === 3) {
					return '#6B9E00';
				} else if (value === 2) {
					return '#FF7D00';
				} else if (value === 1) {
					return '#5B6770';
				} else {
					return '#C1C6C8';
				}
			},
			translate: (value: number): string => {
				return '';
			},
			getLegend: (value: number): string => {
				const label = value ? value : 'N/A';
				return `<span class="${getTextClass(value)}">${label}</span>`;
			},
		};
	}

	updateForm(evt: { pointerType: number; value: number }, question: { id: string; text: string; value: number }) {
		question.value = evt.value;

		this.assessment.formData[question.id as string] = evt.value;

		let value: number = 0;
		let maxValue: number = 0;

		this.data[this.current_index].forEach((d) => {
			d.questions.forEach((q) => {
				if (q.value) {
					value = value + q.value;
					maxValue = maxValue + 4;
				}
			});
		});

		let v = parseFloat(((value / maxValue) * 100).toFixed(0));

		v = isNaN(v) ? 0 : v;

		this.assessment.formData[this.categories[this.current_index].id as string] = v;

		this.chartData[this.current_index] = {
			value: v,
			data: this.getDataChartValue(v),
		};

		this.dbService.data_assessment_maturity.update(this.assessment.id as string, this.assessment);
	}

	updateCommentForm(evt: any, question: { id: string; text: string; value: number; comment: string }) {
		const comment: string = evt.target.value;

		if (comment && comment !== question.comment) {
			this.assessment.commentData[question.id as string] = comment;
			question.comment = comment;
			this.dbService.data_assessment_maturity.update(this.assessment.id as string, this.assessment);
		}
	}

	getDataChartValue(value: number) {
		const documentStyle = getComputedStyle(document.documentElement);

		let color = '#A52819';
		if (value > 75) {
			color = '#7FAA3A';
		} else if (value > 55) {
			color = '#FFC000';
		}

		return {
			labels: ['', ''],
			datasets: [
				{
					label: '',
					data: [value, 100 - value],
					backgroundColor: [color, documentStyle.getPropertyValue('--surface-300')],
					borderColor: ['rgba(255, 255, 255 ,1)', 'rgba(255, 255, 255 ,1)'],
					borderWidth: 5,
				},
			],
		};
	}

	activeIndexTab = 0;
	contentsHtml: SafeHtml[] = [];
	initEditable: boolean = false;
	contentEditable: boolean = false;
	loadingEditable: boolean = false;
	promptTemplate: HandlebarsTemplateDelegate<PromptInput>;

	getActiveClass(i: number) {
		let cls = this.activeIndexTab === i ? 'active' : '';
		if (this.loadingEditable || this.contentEditable) {
			cls = [cls, 'cursor-wait'].join(' ');
		} else {
			cls = [cls, 'hoverable'].join(' ');
		}
		return cls;
	}

	async changeActiveIndexTab(i: number) {
		if (!(this.loadingEditable || this.contentEditable)) {
			if (i < 0) {
				i = 0;
			}
			if (i > this.phases.length - 1) {
				i = this.phases.length - 1;
			}
			if (!this.loadingEditable) {
				this.activeIndexTab = i;
			}
		}
	}

	backupText: string = '';

	safeHTML(html: string) {
		const parser = new DOMParser();
		const document = parser.parseFromString(html, 'text/html');
		return this.sanitizer.bypassSecurityTrustHtml(document.body.outerHTML);
	}

	editor: MediumEditor | undefined = undefined;

	editContentEditable(i: number): void {
		this.backupText = this.assessment.recommendations[i];
		this.contentEditable = true;
		this.editor = new MediumEditor('#content-' + i, {
			extensions: {
				autolist: new ((window as any).AutoList as any)(),
			},
			toolbar: {
				buttons: ['bold', 'italic', 'underline', 'h3', 'h4', 'h5', 'unorderedlist', 'orderedlist'],
			},
		});
		console.log('here');
		console.log(this.editor);
	}

	closeContentEditable(i: number) {
		this.contentEditable = false;
		if (this.editor) {
			this.editor.destroy();
			this.editor = undefined;
		}
		this.assessment.recommendations[i] = this.backupText;
		this.contentsHtml[i] = this.safeHTML(this.assessment.recommendations[i] || '<span></span>');
	}

	async saveContentEditable(i: number) {
		this.contentEditable = false;

		if (this.editor) {
			this.assessment.recommendations[i] = this.editor.getContent();
			this.editor.destroy();
			this.editor = undefined;
		}

		this.contentsHtml[i] = this.safeHTML(this.assessment.recommendations[i] || '<span></span>');
		this.dbService.data_assessment_maturity.update(this.assessment.id as string, this.assessment);
	}

	controller: AbortController;

	async getContentByAI(i: number) {
		this.loadingEditable = true;
		this.assessment.recommendations[i] = '';
		this.contentsHtml[i] = this.safeHTML(this.assessment.recommendations[i]);
		this.controller = new AbortController();
		const prompt = this.promptTemplate({
			id: this.assessment.id || '',
			phase: this.categories[i].name,
			value: (this.assessment.formData[this.categories[i].id as string] || 0).toString(),
		});
		const response = await fetch(
			'https://openai-askhackettbot-dev-eastus2.openai.azure.com/openai/deployments/gpt-4-32k/chat/completions?api-version=2023-07-01-preview',
			{
				method: 'POST',
				signal: this.controller.signal,
				headers: {
					'api-key': await this.commonService.chatgptKey(),
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
				body: JSON.stringify({
					messages: [
						{
							role: 'user',
							content: prompt,
						},
					],
					model: 'gpt-4-32k',
					temperature: 0.75,
					top_p: 0.95,
					max_tokens: 15000,
					frequency_penalty: 0,
					presence_penalty: 0,
					stream: true,
					n: 1,
				}),
			},
		);

		if (response && response.body) {
			const reader = response.body.getReader();
			const textDecoder = new TextDecoder();
			let received = '';

			try {
				while (true) {
					const { done, value } = await reader.read();
					if (done) break;
					received += textDecoder.decode(value, { stream: true });

					const segments = received.split('\ndata: ');

					if (segments.length > 1) {
						for (let j = 0; j < segments.length - 1; ++j) {
							const segment = segments[j];
							const jsonPart = segment.startsWith('{') ? segment : segment.slice(segment.indexOf('{'));
							try {
								const json: { choices: { delta: { content: string } }[] } = JSON.parse(jsonPart);
								if (json.choices.length && json.choices[0]?.delta?.content) {
									const text = json.choices[0].delta.content;
									if (text && text.trim() !== 'undefined') {
										this.assessment.recommendations[i] += text;
										console.log(text);
										this.contentsHtml[i] = this.safeHTML(this.assessment.recommendations[i]);
									}
								}
							} catch (e) {
								this.messageService.add({
									severity: 'warn',
									summary: 'Error',
									detail: 'The content could not be generated with AI. Please try again or contact technical support',
								});
							}
						}
						received = segments[segments.length - 1].startsWith('{') ? segments[segments.length - 1] : '';
					}
				}
				if (received.trim()) {
					try {
						const json: { choices: { delta: { content: string } }[] } = JSON.parse(received);
						const text = json.choices[0].delta.content;
						if (text && text !== 'undefined') {
							this.assessment.recommendations[i] += text;
							console.log(text);
							this.contentsHtml[i] = this.safeHTML(this.assessment.recommendations[i]);
						}
					} catch (e) {
						this.messageService.add({
							severity: 'warn',
							summary: 'Error',
							detail: 'The content could not be generated with AI. Please try again or contact technical support',
						});
					}
				}
				// console.log('La transmisión del stream ha finalizado');
			} catch (error) {
				// console.error('Error al leer el stream', error);
			} finally {
				const wrapInParagraphIfNotHtml = (str: string): string => {
					const htmlRegex = /<\/?[a-z][\s\S]*>/i;
					return htmlRegex.test(str) ? str : `<p>${str}</p>`;
				};
				this.assessment.recommendations[i] = wrapInParagraphIfNotHtml(this.assessment.recommendations[i]);
				this.contentsHtml[i] = this.safeHTML(this.assessment.recommendations[i]);
				this.dbService.data_assessment_maturity.update(this.assessment.id as string, this.assessment);
				this.loadingEditable = false;
				reader.releaseLock();
			}
		}
	}

	cancelContentByAI(i: number) {
		this.controller.abort();
		this.assessment.recommendations[i] = '<span></span>';
		this.contentsHtml[i] = this.safeHTML(this.assessment.recommendations[i]);
		this.loadingEditable = false;
	}
}
