function CustomRendererProvider(bpmnRenderer, eventBus) {
	// console.log(bpmnRenderer);

	const handler = bpmnRenderer.handlers['SubProcessMarker'];

	bpmnRenderer.handlers['SubProcessMarker'] = function (parentGFx, element) {
		const shape = handler(parentGFx, element);

		// console.log(parentGFx, element, shape);

		if (element.type === 'bpmn:CallActivity') {
			const btn = parentGFx.querySelector('[data-marker="sub-process"]');

			if (btn) {
				// console.log(btn);
				/*btn.addEventListener('click', () => {
					console.log('Hola');
				});*/

				const previousElement = btn.previousElementSibling;
				if (previousElement) {
					previousElement.remove();
				}

				btn.remove();
			}
		}

		return shape;
	};
}

CustomRendererProvider.$inject = ['bpmnRenderer', 'eventBus'];

export default {
	__init__: ['customRendererProvider'],
	customRendererProvider: ['type', CustomRendererProvider],
};
