import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../service/auth.service';

@Component({
	templateUrl: './load-token.component.html',
	providers: [MessageService],
})
export class LoadTokenComponent implements OnInit {
	constructor(public authService: AuthService, public router: Router, public route: ActivatedRoute) {}

	ngOnInit() {
		this.authService.signOut().then(() => {
			this.route.queryParams.subscribe(async (params) => {
				const jwt = params['jwt'];
				const changePassword = params['change-password'];
				if (jwt) {
					localStorage.setItem('auth-token', jwt);
					if (changePassword) {
						await this.router.navigateByUrl('/change-password?force=1');
					} else {
						await this.router.navigateByUrl('/');
					}
				}
			});
		});
	}
}
