<div class="grid mr-1">
    <div class="col-12 md:col-2 flex flex-column gap-2">
        <div class="card-container pt-2">
            <mat-card class="large-card" title="# E2E">
                <mat-card-header class="text-center justify-content-center">
                    <mat-card-title class="mat-card-title"># AI Opportunities</mat-card-title>
                </mat-card-header>
                <mat-card-content class="mat-card-content">
                    <!-- <span class="number-text">
                        {{ counters?.aiOpportunities }}
                    </span> -->
                    <span #aiOpportunitiesCountUp class="number-text"></span>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div class="col-12 md:col-4 flex flex-column gap-2">
        <div class="taxonomy-title d-flex align-items-center">
            <app-hackett-logo></app-hackett-logo>
            <span class="taxonomy-company ms-2">Hackett Digital World Class®</span>
            <span> Taxonomy</span>
        </div>
        <div class="grid text-center card-grid">
            <div class="card-container">
                <mat-card class="small-card" title="# E2E">
                    <mat-card-header class="text-center justify-content-center">
                        <mat-card-title class="mat-card-title"># <span class="font-bold">E2E</span></mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="mat-card-content">
                        <span #e2eCountUp></span>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="card-container">
                <mat-card class="small-card" title="# Process">
                    <mat-card-header class="text-center justify-content-center">
                        <mat-card-title class="mat-card-title"># <span class="font-bold">Process</span></mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="mat-card-content">
                        <span #processCountUp></span>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="card-container">
                <mat-card class="small-card" title="# Subprocess">
                    <mat-card-header class="text-center justify-content-center">
                        <mat-card-title class="mat-card-title"># <span class="font-bold">Subprocess</span></mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="mat-card-content">
                        <span #subProcessCountUp></span>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-2 flex flex-column gap-2">
        <div class="card-container pt-2">
            <!-- <mat-card class="large-card" title="# Gen AI Use Cases">
                <mat-card-header class="text-center justify-content-center">
                    <mat-card-title class="mat-card-title"># Gen AI Use Cases</mat-card-title>
                </mat-card-header>
                <mat-card-content class="mat-card-content">
                    <span #genAiUseCasesCountUp class="number-text"></span>
                </mat-card-content>
            </mat-card> -->
            <div class="mat-mdc-card mdc-card large-card text-center justify-content-center">
                <p class="mat-mdc-card-title mat-card-title" style="line-height: 20px; margin: 0"><span class="font-bold"># Gen AI Use Cases</span></p>
                <span #genAiUseCasesCountUp class="mat-mdc-card-content mat-card-content"></span>
                <p class="mat-mdc-card-title mat-card-title" style="line-height: 20px; margin: 0"><span class="font-bold"># Embedded AI Use Cases</span></p>
                <span class="mat-mdc-card-content mat-card-content"> 1,748</span>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-4 flex flex-column gap-2">
        <div class="taxonomy-title d-flex align-items-center">
            <span>Use Cases by Process</span>
        </div>
        <div  class="grid text-center card-grid">
            <div class="card-container">
                <div *ngIf="chartData && chartData[0] && chartData[0].data" class="chart-data">
                    <p-chart type="doughnut" [data]="chartData[0].data" [options]="chartDataOptions"></p-chart>
                </div>
                <mat-card class="small-card mt-2" title="# E2E">
                    <mat-card-header class="text-center justify-content-center">
                        <mat-card-title class="mat-card-title"># <span class="font-bold">E2E</span></mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="mat-card-content">
                        <span #e2eSourceRelCountUp></span>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="card-container">
                <div *ngIf="chartData && chartData[1] && chartData[1].data" class="chart-data">
                    <p-chart type="doughnut" [data]="chartData[1].data" [options]="chartDataOptions"></p-chart>
                </div>
                <mat-card class="small-card mt-2" title="# Process">
                    <mat-card-header class="text-center justify-content-center">
                        <mat-card-title class="mat-card-title"># <span class="font-bold">Process</span></mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="mat-card-content">
                        <span #processSourceRelCountUp></span>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="card-container">
                <div *ngIf="chartData && chartData[2] && chartData[2].data" class="chart-data">
                    <p-chart type="doughnut" [data]="chartData[2].data" [options]="chartDataOptions"></p-chart>
                </div>
                <mat-card class="small-card mt-2" title="# Subprocess">
                    <mat-card-header class="text-center justify-content-center">
                        <mat-card-title class="mat-card-title"># <span class="font-bold">Subprocess</span></mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="mat-card-content">
                        <span #subProcessSourceRelCountUp></span>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>
