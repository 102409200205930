import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Question, SubCategoryQuestion } from '../../../../api/solution.api';
import { ICategoryFormData } from '../../../../api/use-case.api';
import { FormGroup } from '@angular/forms';
import { Options as OptionsSlider } from 'ngx-slider-v2/options';
import { UseCaseReadinessModalService } from '../use-case-readiness-modal.service';
import { SolutionService } from '../../../../service/solution.service';
import { first, Subject, Subscription } from 'rxjs';
import { v4 as uuid } from 'uuid';
import tippy, { Instance as InstanceTippy } from 'tippy.js';

export interface IOutputChartData {
	value: number;
	data: any;
}

@Component({
	selector: 'app-use-cases-readiness-category-form',
	templateUrl: './category-form.component.html',
	styleUrls: ['./category-form.component.scss'],
})
export class CategoryFormReadinessComponent implements OnInit, OnDestroy {
	@Input() index: number;

	questions: Question[];
	categoryId: string;
	formData: ICategoryFormData;

	form: FormGroup;

	value = 0;

	formOptions: OptionsSlider = {
		floor: 0,
		ceil: 3,
		step: 1,
		showTicks: true,
		showTicksValues: true,
		getPointerColor: (value: number): string => {
			if (value === 3) {
				return '#7FAA3A';
			} else if (value === 2) {
				return '#FF9900';
			} else if (value === 1) {
				return '#CC3333';
			} else {
				return '#C1C6C8';
			}
		},
		translate: (value: number): string => {
			if (value === 3) {
				return 'Ready';
			} else if (value === 2) {
				return 'Remediate';
			} else if (value === 1) {
				return 'Stop';
			} else {
				return 'N/A';
			}
		},
	};

	subCategoryQuestion: SubCategoryQuestion[];

	public questionRenderSource = new Subject<string>();
	questionRenderSource$ = this.questionRenderSource.asObservable();
	sub: Subscription;

	constructor(public service: UseCaseReadinessModalService, public solutionService: SolutionService) {}

	async ngOnInit() {
		await this.onLoad();

		this.sub = this.service.realtimeUpdateSource$.subscribe(() => {
			setTimeout(() => {
				this.onLoad();
			}, 150);
		});
	}

	ngOnDestroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	async onLoad() {
		const categoryQuestion = this.service.categoriesQuestion[this.index];
		this.categoryId = categoryQuestion.id as any;
		this.subCategoryQuestion = await this.solutionService.getSubCategoriesQuestion(this.categoryId);

		if (
			this.service.useCaseService.currentUseCase &&
			this.service.useCaseService.currentUseCase.id &&
			this.categoryId
		) {
			this.formData =
				this.service.useCaseService.currentUseCase.formData[this.categoryId] ??
				this.service.useCaseService._newUseCaseValue();
			this.questions = (
				await this.service.solutionService.getQuestions({
					categoryId: this.categoryId,
				})
			).filter((q) => {
				if (!q.technologyId) {
					return true;
				} else {
					return this.service.useCaseService.currentUseCase
						? this.service.technologiesIds.includes(q.technologyId || '')
						: false;
				}
			});

			if (this.subCategoryQuestion?.length) {
				const subCategoryQuestionIds = this.subCategoryQuestion.map((scq) => scq.id as string);
				this.questions = this.questions.sort((a, b) => {
					return (
						subCategoryQuestionIds.indexOf(a.subcategoryId) -
						subCategoryQuestionIds.indexOf(b.subcategoryId)
					);
				});
			}

			this.form = await this.service.useCaseService.getFormTechnologiesQuestion(this.formData, this.questions);

			this.form.valueChanges.subscribe((val) => {
				this.service.updateUseCase();
			});

			const responseForm = await this.service.useCaseService.calcValueCategoryQuestion(
				this.form,
				this.categoryId,
				this.formData,
				this.questions,
			);

			this.value = responseForm.value;

			if (!this.index) {
				this.calcData();
			}
		}

		this.questionRenderSource$.pipe(first()).subscribe(() => {
			this.setTooltip();
		});

		await this.getChart();
	}

	async saveNote(questionId: string) {
		if (this.service.useCaseService.currentUseCase) {
			const question = this.questions.find((q) => q.id === questionId);
			if (question) {
				let answer = this.formData.answers[questionId] || this.service.useCaseService._newUseCaseAnswer();
				await this.service.useCaseService.saveAnswerQuestion(
					this.form,
					this.categoryId,
					this.formData,
					questionId,
					answer,
					this.questions,
				);
				this.service.updateUseCase();
			}
		}
	}

	async getFormData() {
		if (this.service.useCaseService.currentUseCase) {
			const responseForm = await this.service.useCaseService.calcValueCategoryQuestion(
				this.form,
				this.categoryId,
				this.formData,
				this.questions,
			);
			this.value = responseForm.value;

			this.service.updateUseCase();

			if (!this.index) {
				this.calcData();
			}
		}

		await this.getChart();
	}

	async getChart() {
		this.service.chartStepDataArray[this.index] = {
			value: parseFloat(this.value.toFixed(0)),
			data: this.service.getDataChartValue(this.value),
		};

		this.service.updateCharts();
		if (this.index === 3) {
			const runBusinessDrivers = () =>
				setTimeout(() => {
					if (this.countRunBusinessDrivers === 10) {
						this.service.getTableHeatMap().then(() => {
							this.countRunBusinessDrivers = 0;
						});
					} else if (this.countRunBusinessDrivers < 10) {
						this.countRunBusinessDrivers++;
						runBusinessDrivers();
					}
				}, 100);

			if (this.countRunBusinessDrivers > 0) {
				this.countRunBusinessDrivers = 0;
			} else {
				this.service.table_heatmap_running = true;
				runBusinessDrivers();
			}
		}
	}

	countRunBusinessDrivers = 0;

	currentSubCategory = '';

	ifSubCategory(question: Question) {
		this.questionRenderSource.next(uuid());
		if (!!this.subCategoryQuestion?.length) {
			if (question.subcategoryId !== this.currentSubCategory) {
				this.currentSubCategory = question.subcategoryId;
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	subCategoryLabel(question: Question) {
		return this.subCategoryQuestion.find((scq) => scq.id === question.subcategoryId)?.name || '';
	}

	setTooltip() {
		for (const question of this.questions) {
			const help = question.answers
				.sort((a, b) => parseInt(a.value) - parseInt(b.value))
				.map((a) => a.help)
				.filter((h) => !!h);
			if (help.length) {
				const findSlider = () => {
					setTimeout(() => {
						const slider = document.getElementById(question.id as string);
						if (slider) {
							this.setTooltipSlider(slider, help);
						} else {
							findSlider();
						}
					}, 5);
				};
				findSlider();
			}
		}
	}

	setTooltipSlider(slider: HTMLElement, help: string[]) {
		const ticks = slider.querySelector('.ngx-slider-ticks');
		if (ticks?.children.length) {
			help.forEach((h, i) => {
				const el = ticks.children.item(i + 1);
				if (el) {
					tippy(el, {
						content: help.join(''),
						allowHTML: true,
						appendTo: slider,
						arrow: false,
						offset: [0, 5],
						placement: 'bottom',
						zIndex: 1000,
						duration: 10,
					});
				}
			});
		}
		const pointer = slider.querySelector('.ngx-slider-span.ngx-slider-pointer.ngx-slider-pointer-min');
		if (pointer) {
			tippy(pointer, {
				content: help.join(''),
				allowHTML: true,
				appendTo: slider,
				arrow: false,
				offset: [0, 5],
				placement: 'bottom',
				zIndex: 1000,
				duration: 10,
			});
		}
	}

	async calcData() {
		const values: number[] = [];

		const subCategories = await this.service.dbService.data_sub_category_question.orderBy('sort').toArray();

		subCategories.forEach((sc) => {
			let current_value = 0;
			let max_value = 0;

			for (const q of this.questions.filter((q) => q.subcategoryId === sc.id)) {
				let answer = this.formData.answers[q.id as any];
				const weight = parseFloat(q.weight);

				if (answer) {
					if (answer.value !== '0') {
						current_value =
							current_value +
							parseFloat(((answer.value !== '0' ? parseFloat(answer.value) : 0) * weight).toFixed(0));
						max_value =
							max_value +
							parseFloat(
								(
									Math.max(...q.answers.map((a) => (a.value !== '0' ? parseFloat(a.value) : 0))) *
									weight
								).toFixed(0),
							);
					}
				}
			}

			values.push(this.service.useCaseService.getValueCalc(current_value, max_value));
		});

		this.service.dataValues = values;
	}
}
