import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RippleModule } from 'primeng/ripple';
import { AppMenuitemComponent } from './app.menuitem.component';
import { RouterModule } from '@angular/router';
import { AppTopBarComponent } from './app.topbar.component';
import { AppConfigModule } from './config/config.module';
import { AppSidebarComponent } from './app.sidebar.component';
import { AppLayoutComponent } from './app.layout.component';
import { AppFilterComponent } from './app.filter.component';
import { BlockUIModule } from 'primeng/blockui';
import { ToastModule } from 'primeng/toast';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ButtonModule } from 'primeng/button';
import { ClickStopPropagationDirective } from './click-propagation.directive';
import { TippyDirective } from './tippy.directive';
import { ProgressBarModule } from 'primeng/progressbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MenuModule } from 'primeng/menu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { TreeSelectModule } from 'primeng/treeselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table'
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
	declarations: [
		AppMenuitemComponent,
		AppTopBarComponent,
		AppSidebarComponent,
		AppLayoutComponent,
		AppFilterComponent,
		ClickStopPropagationDirective,
		TippyDirective,
	],
	imports: [
		BrowserModule,
		FormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		InputTextModule,
		SidebarModule,
		BadgeModule,
		RadioButtonModule,
		InputSwitchModule,
		RippleModule,
		RouterModule,
		AppConfigModule,
		BlockUIModule,
		ToastModule,
		ConfirmPopupModule,
		ButtonModule,
		ProgressBarModule,
		OverlayPanelModule,
		MenuModule,
		ConfirmDialogModule,
		DropdownModule,
		DialogModule,
		TreeSelectModule,
		AutoCompleteModule,
		MultiSelectModule,
		TabViewModule,
		TableModule,
		CheckboxModule,
		ChipsModule,
		InputTextareaModule,
	],
	exports: [AppLayoutComponent, AppFilterComponent, TippyDirective, ClickStopPropagationDirective],
})
export class AppLayoutModule {}
