import { Component } from '@angular/core';
import { UseCaseReadinessModalService } from '../use-case-readiness-modal.service';

@Component({
	selector: 'app-use-cases-readiness-general-form',
	templateUrl: './general-form.component.html',
	styleUrls: ['./general-form.component.scss'],
})
export class GeneralFormReadinessComponent {
	constructor(public service: UseCaseReadinessModalService) {}
}
