<div class="col-12 col-filter">
	<div class="grid p-main">
		<div class="col-1 flex justify-content-center align-items-center">
			<span class="font-bold">Enterprise Context</span>
		</div>
		<div class="col-11">
			<div class="grid">
				<div class="col-4">
					<div class="flex justify-content-start align-items-center gap-3">
						<label for="industry" class="text-primary font-bold uppercase label-filter">Industry</label>
						<p-autoComplete
							class="element-filter"
							styleClass="filter-content min-token"
							panelStyleClass="w-full"
							inputStyleClass="w-full"
							[(ngModel)]="simulationService.industryTreeSelections"
							[suggestions]="simulationService.suggestionsIndustry"
							(completeMethod)="simulationService.searchIndustry($event)"
							field="label"
							placeholder="Select Industry"
							appendTo="body"
							id="industry"
							inputId="industry"
							[multiple]="true"
							(onSelect)="simulationService.updateEnterpriseContext()"
						></p-autoComplete>
					</div>
				</div>
				<div class="col-4">
					<div class="flex justify-content-start align-items-center gap-3">
						<label for="revenue" class="text-primary font-bold uppercase label-filter">Revenue</label>
						<p-treeSelect
							class="element-filter"
							containerStyleClass="filter-content min-token"
							[(ngModel)]="simulationService.revenueTreeSelections"
							[options]="simulationService.revenueTreeOptions"
							id="revenue"
							inputId="revenue"
							placeholder="Select revenue"
							[metaKeySelection]="false"
							appendTo="body"
							(onNodeSelect)="simulationService.updateEnterpriseContext()"
							(onNodeUnselect)="simulationService.updateEnterpriseContext()"
						></p-treeSelect>
					</div>
				</div>
				<div class="col-4">
					<div class="flex justify-content-start align-items-center gap-3">
						<label for="employees" class="text-primary font-bold uppercase label-filter">Employees</label>
						<p-treeSelect
							class="element-filter"
							containerStyleClass="filter-content min-token"
							[(ngModel)]="simulationService.employeesTreeSelections"
							[options]="simulationService.employeesTreeOptions"
							id="employees"
							inputId="employees"
							placeholder="Select employees"
							[metaKeySelection]="false"
							appendTo="body"
							(onNodeSelect)="simulationService.updateEnterpriseContext()"
							(onNodeUnselect)="simulationService.updateEnterpriseContext()"
						></p-treeSelect>
					</div>
				</div>
				<div class="col-12">
					<div class="flex justify-content-start align-items-center gap-3">
						<label for="additionalInformation" class="text-primary font-bold uppercase label-filter search"
							>Additional Information</label
						>
						<p-multiSelect
							class="element-filter search"
							styleClass="filter-content"
							[(ngModel)]="simulationService.additionalInformationTreeSelections"
							[options]="simulationService.additionalInformationTreeOptions"
							display="chip"
							id="additionalInformation"
							inputId="additionalInformation"
							placeholder="Select information"
							appendTo="body"
							[filter]="false"
							[showHeader]="false"
							(onChange)="simulationService.updateEnterpriseContext()"
						></p-multiSelect>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
