import { v4 as uuid } from 'uuid';

/**
 * Represents an abstract entity.
 *
 * @interface
 */
export interface IAbstractEntity {
	id?: string;
}

/**
 * Represents an abstract entity.
 * @implements {IAbstractEntity}
 */
export class AbstractEntity implements IAbstractEntity {
	constructor(public id?: string) {
		id ? (this.id = id) : (this.id = uuid());
	}

	equals(e1: AbstractEntity, e2: AbstractEntity) {
		return e1.id == e2.id;
	}
}

/**
 * Interface representing the base entity.
 * @interface
 * @extends IAbstractEntity
 */
export interface IBaseEntity extends IAbstractEntity {
	created_at?: number;
	updated_at?: number;
}

/**
 * Represents a BaseEntity.
 * @class
 * @extends AbstractEntity
 * @implements IBaseEntity
 */
export class BaseEntity extends AbstractEntity implements IBaseEntity {
	constructor(public created_at?: number, public updated_at?: number, id?: string) {
		super(id);
		created_at ? (this.created_at = created_at) : (this.created_at = new Date().getTime());
		updated_at ? (this.updated_at = updated_at) : (this.updated_at = this.created_at);
	}
}

/**
 * Represents a tag entity.
 * @interface
 * @extends IBaseEntity
 */
export interface ITagEntity extends IBaseEntity {
	name: string;
	short?: string;
}

/**
 * Represents a Tag Entity.
 * @class
 * @extends BaseEntity
 * @implements ITagEntity
 */
export class TagEntity extends BaseEntity implements ITagEntity {
	constructor(public name: string, public short?: string, created_at?: number, updated_at?: number, id?: string) {
		super(created_at, updated_at, id);
	}
}

export interface IOpportunity {
	id: string;
	name: string;
	description: string;
	checked?: boolean;
	shape_id: string;
	data: { [key: string]: any };
	technologies: string[];
}

/**
 * Interface representing a diagram data item.
 * @interface
 */
export interface IDiagramDataItem {
	name: string;
	type: string;
	description: string;
	technologiesIds: string[];
	aiTechnologies: { [key: string]: string };
	taxonomyRel?: string;
}

/**
 * Represents the base interface for a diagram.
 * @interface
 * @extends IBaseEntity
 */
export interface IDiagramBase extends IBaseEntity {
	xml_data: string;
	xml_image?: string;

	data?: { [key: string]: IDiagramDataItem };
}

/**
 * Represents a base class for diagram entities.
 * @extends BaseEntity
 * @implements IBaseEntity
 */
export class DiagramBase extends BaseEntity implements IBaseEntity {
	constructor(
		public xml_data: string,
		public xml_image?: string,
		public data?: { [key: string]: IDiagramDataItem },
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(created_at, updated_at, id);
		if (!this.data) {
			this.data = {};
		}
	}
}

/**
 * Represents a parent entity for a diagram.
 * @interface
 * @template T - The type of diagram.
 */
export interface IDiagramParent<T extends IDiagramBase> extends IBaseEntity {
	name: string;
	description: string;

	diagram: T;
}

/**
 * Represents a parent diagram.
 *
 * @extends BaseEntity
 * @implements IDiagramParent
 * @template T - The type of the diagram.
 */
export class DiagramParent<T extends DiagramBase> extends BaseEntity implements IDiagramParent<T> {
	diagram: T;

	constructor(
		public name: string,
		public description: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(created_at, updated_at, id);
		Object.defineProperties(this, {
			diagram: { value: undefined, enumerable: false, writable: true },
		});
	}

	/**
	 * Retrieves an array of technology IDs from the data of a diagram.
	 *
	 * @returns {string[]} An array of unique technology IDs.
	 */
	getTechnologiesIds(): string[] {
		if (this.diagram && this.diagram.data) {
			const technologiesIds: string[] = [];
			for (const shapeId in this.diagram.data) {
				const shapeData = this.diagram.data[shapeId];
				if (shapeData && shapeData.technologiesIds && shapeData.technologiesIds.length) {
					technologiesIds.push(...shapeData.technologiesIds);
				}
			}
			if (technologiesIds.length) {
				return Array.from(new Set(technologiesIds));
			}
		}
		return [];
	}

	/**
	 * Retrieves the URL for capturing the image of a diagram.
	 *
	 * @returns {string} The URL for capturing the image of the diagram.
	 */
	get capture_url(): string {
		if (this.diagram?.xml_image) {
			return this.diagram.xml_image;
		} else {
			return '../../../../assets/rtp/img/thumb_emptyDiagram.svg';
		}
	}
}
