import { Injectable } from '@angular/core';
import { DbService } from './db.service';
import { CommonData } from '../api/common';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

/**
 * CommonService class handles common functionalities.
 *
 * @class CommonService
 * @constructor
 * @param {DbService} dbService - The instance of the DbService class.
 * @param {HttpClient} http - The instance of the HttpClient class.
 */
@Injectable({
	providedIn: 'root',
})
export class CommonService {
	constructor(public dbService: DbService, public http: HttpClient) {}

	/**
	 * Retrieves common data from the database based on the specified key.
	 *
	 * @async
	 * @template T - The type of the common data.
	 * @param {string} key - The key to identify the common data.
	 * @returns {Promise<CommonData<T> | undefined>} - A promise that resolves to the retrieved common data, or undefined if no data is found.
	 */
	async getCommonData<T>(key: string): Promise<CommonData<T> | undefined> {
		return this.dbService.data_common.get(key);
	}

	/**
	 * Represents the API key for accessing the ChatGPT service.
	 *
	 * @type {string}
	 */
	_chatgptKey: string;

	/**
	 * Retrieves the ChatGPT key from the server.
	 * If the key is not already stored in the instance variable `_chatgptKey`, it makes an API request to the server to fetch the key.
	 *
	 * @returns {Promise<string>} - A promise that resolves to the ChatGPT key.
	 */
	async chatgptKey() {
		if (!this._chatgptKey) {
			const token = localStorage.getItem('auth-token');
			if (token) {
				const res = await lastValueFrom(
					this.http.get<{
						api_key: string;
					}>(`${environment.url}/api/chatgpt/`, {
						headers: { Authorization: 'Bearer ' + token },
					}),
				).catch(() => ({
					api_key: '',
				}));
				this._chatgptKey = res.api_key;
			}
		}
		return this._chatgptKey;
	}
}
