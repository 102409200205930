import { Table } from 'dexie';
// @ts-ignore
import CustomModeler from './modeler';
import Modeler from 'bpmn-js/lib/Modeler';
import { DiagramComponent } from '../components/diagram/diagram.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { v4 as uuid } from 'uuid';
import { DiagramBase, IDiagramBase, IDiagramDataItem } from '../api/base.api';
import { instanceToInstance, plainToInstance } from 'class-transformer';
import { ClassConstructor } from 'class-transformer/types/interfaces';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Canvas from 'diagram-js/lib/core/Canvas';

/**
 * Represents data needed to update a diagram.
 * @interface
 */
export interface DataUpdateDiagram {
	diagramProcessId?: string;
	diagramTemplateId?: string;
	flowId?: string;
	diagramId?: string;
	back?: boolean;
}

/**
 * Represents a service for handling diagram related operations.
 *
 * @typeparam T - The type of the diagram object.
 */
export class DiagramBaseService<T extends DiagramBase> {
	/**
	 * Represents the URL of a diagram.
	 *
	 * @typedef {string} diagramUrl
	 */
	public diagramUrl: string;
	/**
	 * Represents a diagram table.
	 * @template T - The type of the table.
	 */
	public diagramTable: Table<T, string>;
	/**
	 * Represents a diagram class.
	 * @template T - The type of the class to be constructed.
	 * @constructor
	 * @param {Function} ClassConstructor - The constructor function for the class.
	 * @returns {ClassConstructor<T>} - The constructed diagram class.
	 */
	public readonly diagramClass: ClassConstructor<T>;

	constructor(
		public dialogService: DialogService,
		public http: HttpClient,
		diagramUrl: string,
		diagramTable: Table<T, string>,
		diagramClass: ClassConstructor<T>,
	) {
		this.diagramUrl = diagramUrl;
		this.diagramTable = diagramTable;
		this.diagramClass = diagramClass;
	}

	/**
	 * Represents the current modeler.
	 * @type {Modeler | undefined}
	 */
	currentModeler: Modeler | undefined;

	/**
	 * Represents the current diagram.
	 *
	 * @type {T | undefined}
	 */
	currentDiagram: T | undefined;
	/**
	 * Represents the current original diagram.
	 *
	 * @type {T | undefined}
	 */
	currentOriginalDiagram: T | undefined;

	/**
	 * Represents the current diagram source.
	 *
	 * @type {Subject<string>}
	 */
	public currentDiagramSource: Subject<string> = new Subject<string>();
	/**
	 * The variable `currentDiagramSource$` represents the observable stream of the
	 * current diagram source. It is used to observe changes to the current diagram source
	 * and react accordingly.
	 *
	 * @type {Observable<string>}
	 */
	currentDiagramSource$: Observable<string> = this.currentDiagramSource.asObservable();

	/**
	 * Represents an empty BPMN diagram.
	 *
	 * @type {string}
	 */
	emptyDiagram = `<?xml version="1.0" encoding="UTF-8"?>
<bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn">
  <bpmn2:process id="Process_1" isExecutable="false">
    <bpmn2:startEvent id="StartEvent_1"/>
  </bpmn2:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds height="36.0" width="36.0" x="412.0" y="240.0"/>
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn2:definitions>`;

	/**
	 * Creates a new diagram base object.
	 * @param {string} xml - The XML data of the diagram.
	 * @param {string} capture_url - The URL to the screenshot/image of the diagram.
	 * @param {Object} data - Additional diagram data items.
	 * @returns {Object} The newly created diagram base object.
	 */
	_newDiagramBase(xml?: string, capture_url?: string, data?: { [key: string]: IDiagramDataItem }): IDiagramBase {
		return {
			id: uuid(),
			xml_image: capture_url || '',
			xml_data: xml?.startsWith('http') ? xml : '',
			data: data || {},
		};
	}

	/**
	 * Initializes the current modeler with the specified configuration options.
	 *
	 * @returns {CustomModeler} The current modeler instance.
	 */
	setCurrentModeler(): CustomModeler {
		this.currentModeler = new CustomModeler({
			container: '#canvas',
			width: '100%',
			height: '95vh',
			keyboard: {
				bindTo: document,
			},
		});
		return this.currentModeler;
	}

	/**
	 * Clears the current modeler.
	 * Detaches the current modeler, clears its content, destroys it and sets the current modeler to undefined.
	 *
	 * @function
	 * @name clearCurrentModeler
	 *
	 * @returns {void}
	 */
	clearCurrentModeler(): void {
		this.currentModeler?.detach();
		this.currentModeler?.clear();
		this.currentModeler?.destroy();
		this.currentModeler = undefined;
	}

	/**
	 * Imports the XML data for the current diagram and updates the current modeler.
	 * If the XML data is not available for the current diagram, it uses a default empty diagram.
	 *
	 * @returns {Promise<void>} A promise that resolves when the XML import is complete.
	 */
	async importXmlCurrent(): Promise<void> {
		if (this.currentDiagram && this.currentModeler) {
			if (this.currentDiagram.xml_data) {
				const bpmnXML = await firstValueFrom(
					this.http.get(this.currentDiagram.xml_data, { responseType: 'text' }),
				).catch(async () => {
					if (this.currentDiagram) {
						await firstValueFrom(this.http.get<T>('@api/' + this.diagramUrl + this.currentDiagram.id)).then(
							async (diagram) => {
								await this.diagramTable.put(diagram).then(() => {
									this.currentDiagram = diagram;
								});
							},
						);
						return firstValueFrom(this.http.get(this.currentDiagram.xml_data, { responseType: 'text' }));
					} else {
						return this.emptyDiagram;
					}
				});
				this.currentModeler.importXML(bpmnXML).then(() => {
					if (this.currentModeler) {
						const canvas = this.currentModeler.get<Canvas>('canvas');
						const scale = canvas.zoom('fit-viewport', 'auto' as any);
						canvas.zoom(Math.max(scale - 0.2, 0.2), 'auto' as any);
					}
				});
			} else {
				this.currentModeler.importXML(this.emptyDiagram).then(() => {});
			}
		}
	}

	/**
	 * This method retrieves the XML representation of the current modeler.
	 *
	 * @returns A Promise that resolves to an object containing the XML string or an error message.
	 *          - If the XML is successfully retrieved, the object will have a 'xml' property with the XML string.
	 *          - If there is an error retrieving the XML, the object will have an 'error' property with the error message.
	 *          - If there is no current modeler, the object will have an 'error' property with the value 'no-data'.
	 *
	 */
	async getXml(): Promise<{ xml?: string; error?: string }> {
		if (this.currentModeler) {
			try {
				const { xml, error } = await this.currentModeler.saveXML({ format: true });
				if (xml) {
					return { xml } as { xml: string };
				}
				if (error) {
					return {
						error: (error as Error).message,
					};
				}
				return { error: 'no-data' };
			} catch (e) {
				return {
					error: (e as Error).message,
				};
			}
		}
		return { error: 'no-data' };
	}

	hasModalDiagram: boolean = false;

	/**
	 * Updates the diagram modal with the given data.
	 *
	 * @param {DataUpdateDiagram} data - The data to update the diagram modal with.
	 * @returns {DynamicDialogRef | undefined} The reference to the opened diagram modal, or undefined if the modal already exists.
	 */
	updateDiagramModal(data: DataUpdateDiagram): DynamicDialogRef | undefined {
		if (!this.hasModalDiagram) {
			document.body.style.cursor = 'wait';
			this.hasModalDiagram = true;
			const ref: DynamicDialogRef = this.dialogService.open(DiagramComponent, {
				data,
				header: 'Diagram',
				width: '100%',
				height: '100%',
				styleClass: 'diagram-modal',
				contentStyle: { overflow: 'auto' },
				showHeader: false,
				baseZIndex: 100,
				maximizable: false,
				closeOnEscape: false,
			});
			setTimeout(() => {
				document.body.style.cursor = 'default';
			}, 150);
			ref.onClose.subscribe(() => {
				this.hasModalDiagram = false;
			});
			ref.onDestroy.subscribe(() => {
				this.hasModalDiagram = false;
			});
			return ref;
		}
		return undefined;
	}

	/**
	 * Retrieves the SVG representation of the current modeler.
	 *
	 * @returns {Promise<{ svg?: string; error?: string }>}
	 */
	async getSVG(): Promise<{ svg?: string; error?: string }> {
		if (this.currentModeler) {
			try {
				const { svg, error } = await (this.currentModeler as any).saveSVG({ format: true });
				if (svg) {
					return { svg } as { svg: string };
				}
				if (error) {
					return {
						error: (error as Error).message,
					};
				}
				return { error: 'no-data' };
			} catch (e) {
				return {
					error: (e as Error).message,
				};
			}
		}
		return { error: 'no-data' };
	}

	/**
	 * Converts a Blob to a Base64 string asynchronously.
	 *
	 * @param {Blob} blob - The Blob to convert.
	 * @return {Promise<string>} - A Promise that resolves to the Base64 string representation of the Blob.
	 */
	async blobToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {
		return new Promise((resolve, _) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.readAsDataURL(blob);
		});
	}

	/**
	 * Retrieves the URL of an SVG file asynchronously.
	 *
	 * @returns {Promise<{ url?: string; error?: string }>} - A promise that resolves to an object containing either the URL or an error message.
	 */
	async getSVGUrl(): Promise<{ url?: string; error?: string }> {
		const d = await this.getSVG();
		if (d.svg) {
			const svgBlob = new Blob([d.svg], {
				type: 'image/svg+xml;charset=utf-8',
			});

			return {
				url: (await this.blobToBase64(svgBlob)) as any,
			};
		} else if (d.error) {
			return { error: d.error };
		}
		return { error: 'no-data' };
	}

	/**
	 * Retrieves the diagram from the diagram table based on the given query.
	 *
	 * @param {string | { level2Id: string }} query - The query to search for the diagram.
	 * @return {Promise<T | undefined>} - A promise that resolves to the diagram object if found, or undefined if not found.
	 */
	async getDiagram(query: string | { level2Id: string }): Promise<T | undefined> {
		return this.diagramTable.get(query as any);
	}

	/**
	 * Saves a diagram to the database.
	 *
	 * @param {DiagramBase} diagram - The diagram to be saved.
	 *
	 * @return {Promise<DiagramBase>} - A promise that resolves with the saved diagram object.
	 */
	async saveDiagram(diagram: DiagramBase): Promise<DiagramBase> {
		const original_diagram = await this.getDiagram(diagram.id || '');
		const data = plainToInstance(this.diagramClass, {
			...(original_diagram || {}),
			...diagram,
		});
		if (!data.id) {
			data.id = uuid();
		}
		await this.diagramTable.put(data);
		return data;
	}

	/**
	 * Sets the current diagram to the provided diagram.
	 *
	 * @param {string} id - The ID of the diagram to set as the current diagram.
	 * @param {() => T} newDiagram - A function that returns the new diagram to set as the current diagram if the diagram with the given ID does not exist.
	 * @return {Promise<void>} - A promise that resolves once the current diagram has been set.
	 */
	async setCurrentDiagram(id: string, newDiagram: () => T): Promise<void> {
		this.currentDiagram = await this.getDiagram(id);
		if (!this.currentDiagram) {
			this.currentDiagram = newDiagram();
		}
		this.currentOriginalDiagram = this.currentDiagram ? instanceToInstance(this.currentDiagram) : undefined;
	}

	/**
	 * Clears the current diagram.
	 *
	 * @returns {Promise} A promise that resolves when the current diagram is cleared.
	 */
	async clearCurrentDiagram(): Promise<any> {
		this.currentDiagram = undefined;
		this.currentOriginalDiagram = this.currentDiagram ? instanceToInstance(this.currentDiagram) : undefined;
	}

	/**
	 * Saves the files related to a diagram.
	 *
	 * @param {T | undefined} diagram - The diagram object.
	 * @param {string} [xml] - The XML data to be saved.
	 * @param {string} [svg] - The SVG data to be saved.
	 *
	 * @returns {Promise<string>} - A promise that resolves to an empty string if the files are saved successfully,
	 *                             'error' if there is an error during the saving process, or 'no-data' if the
	 *                             diagram object is undefined.
	 */
	async saveFilesDiagram(diagram: T | undefined, xml?: string, svg?: string): Promise<string> {
		if (diagram) {
			const headers = new HttpHeaders({
				enctype: 'multipart/form-data',
			});
			const formData = new FormData();
			if (xml) {
				formData.append('xml_data', new File([xml], 'diagram.xml', { type: 'text/xml' }));
			}
			if (svg) {
				formData.append('xml_image', new File([svg], 'diagram.svg', { type: 'image/svg+xml;charset=utf-8' }));
			}
			formData.append('data', JSON.stringify(diagram.data));
			if (!diagram.xml_data) {
				const obj = await firstValueFrom(
					this.http.get('@api/' + this.diagramUrl + diagram.id, {
						headers: headers,
					}),
				).catch(() => undefined);
				if (!(obj as any)?.id) {
					await firstValueFrom(
						this.http.post('@api/' + this.diagramUrl, diagram, {
							headers: headers,
						}),
					);
				}
			}
			const request = this.http.patch('@api/' + this.diagramUrl + diagram.id + '/', formData, {
				headers: headers,
			});
			return await firstValueFrom(request)
				.then((response) => {
					if (diagram) {
						if ((response as IDiagramBase).xml_data) {
							diagram.xml_data = (response as IDiagramBase).xml_data;
						}
						if ((response as IDiagramBase).xml_image) {
							diagram.xml_image = (response as IDiagramBase).xml_image;
						}
						return '';
					} else {
						return 'error';
					}
				})
				.catch((err) => {
					return 'error';
				});
		}
		return 'no-data';
	}

	/**
	 * Saves the current diagram with optional XML and SVG.
	 *
	 * @param {string} xml - The XML data to save.
	 * @param {string} svg - The SVG data to save.
	 * @returns {Promise<string>} - A promise that resolves with an error message if there is an error, otherwise it resolves with an empty string.
	 */
	async saveCurrentDiagram(xml?: string, svg?: string): Promise<string> {
		const error = await this.saveFilesDiagram(this.currentDiagram, xml, svg);
		if (this.currentDiagram && !error) {
			this.currentDiagramSource.next(uuid());
			await this.saveDiagram(this.currentDiagram);
			this.currentOriginalDiagram = instanceToInstance(this.currentDiagram);
		}
		return error;
	}
}
