export default function CustomContextMenuModule(enabledExpanded) {
	function CustomContextPadProvider(contextPad, eventBus) {
		contextPad.registerProvider(this);

		this.getContextPadEntries = function (element) {
			return function (entries) {
				if (
					enabledExpanded &&
					((element.type.startsWith('bpmn:') && element.type.endsWith('Task')) ||
						element.type === 'bpmn:CallActivity')
				) {
					entries = {
						taxonomy: {
							group: 'model',
							className: 'custom-icon-taxonomy',
							title: 'Taxonomy Relation',
							action: {
								click: (evt) => {
									eventBus.fire('custom.taxonomy-rel', { context: { shape: { id: element.id } } });
								},
							},
						},
						...entries,
					};
				}

				delete entries['append.gateway'];

				if (element.type === 'bpmn:CallActivity') {
					delete entries['replace'];

					entries = {
						'taxonomy-go': {
							group: 'model',
							className: 'custom-icon-taxonomy-go',
							title: 'Go to child taxonomy',
							action: {
								click: (evt) => {
									eventBus.fire('custom.taxonomy-rel-go', { context: { shape: { id: element.id } } });
								},
							},
						},
						...entries,
					};
				}

				return entries;
			};
		};
	}

	CustomContextPadProvider.$inject = ['contextPad', 'eventBus'];

	return {
		__init__: ['customContextPadProvider'],
		customContextPadProvider: ['type', CustomContextPadProvider],
	};
}
