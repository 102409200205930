import { Component } from '@angular/core';
import { UseCaseReadinessModalService } from '../use-case-readiness-modal.service';

@Component({
	selector: 'app-use-cases-readiness-result',
	templateUrl: './result.component.html',
	styleUrls: ['./result.component.scss'],
})
export class ResultReadinessComponent {
	constructor(public service: UseCaseReadinessModalService) {}

	helpAIOverallTransformationPotential =
		'To display this score, you must complete the "General" form data and incorporate initial values for "AI Effectiveness Potential" and "AI Efficiency Potential" into at least one step of the diagram.';
	helpAIAnalysis =
		'To reveal this score, you need to fulfill the "WorkPlan" form data within at least a single phase and insert initial values for "AI Effectiveness Potential" and "AI Efficiency Potential" into at least one step of the diagram.';
}
