import PptxGenJS from 'pptxgenjs';

import TextBaseProps = PptxGenJS.TextBaseProps;

/**
 * Represents a style object that holds color and text properties.
 */
export class Style {
	public colors: { dark: string; primary: string; text: string; textSecondary: string; white: string };

	/**
	 * Create a new instance of the constructor.
	 *
	 * @constructor
	 */
	constructor() {
		const documentStyle = getComputedStyle(document.documentElement);
		this.colors = {
			dark: '#3F2E74',
			primary: documentStyle.getPropertyValue('--primary-color'),
			text: documentStyle.getPropertyValue('--text-color'),
			textSecondary: documentStyle.getPropertyValue('--bluegray-500'),
			white: '#FFFFFF',
		};
	}

	/**
	 * Returns the text properties for a title.
	 *
	 * @param {string} type - The type of title (h1, h2, h3).
	 * @param {boolean} [inverted=false] - Whether the title should be inverted or not.
	 * @returns {TextBaseProps} - The text properties for the title.
	 */
	getTitle(type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' = 'h1', inverted?: boolean): TextBaseProps {
		return {
			color: inverted ? this.colors.white : this.colors.primary,
			fontSize: { h1: 35, h2: 25, h3: 20, h4: 18, h5: 14, h6: 12 }[type],
			bold: true,
		};
	}

	/**
	 * Returns the primary text base props, including the color and font size.
	 *
	 * @param {number} [fontSize=15] - The font size for the text.
	 *
	 * @return {TextBaseProps} - The primary text base props.
	 */
	getTextPrimary(fontSize: number = 15): TextBaseProps {
		return {
			color: this.colors.primary,
			fontSize,
		};
	}

	/**
	 * Returns the text properties based on the provided parameters.
	 *
	 * @param {boolean} [inverted=false] - Specifies whether the text color should be inverted.
	 * @param {number} [fontSize=15] - The font size of the text.
	 * @return {TextBaseProps} The text properties including the color and font size.
	 */
	getText(inverted: boolean = false, fontSize: number = 14): TextBaseProps {
		return {
			color: inverted ? this.colors.white : this.colors.text,
			fontSize,
		};
	}

	/**
	 * Get the secondary text style properties.
	 *
	 * @param {number} [fontSize=15] - The font size for the secondary text.
	 *
	 * @return {TextBaseProps} - The secondary text style properties.
	 */
	getTextSecondary(fontSize: number = 15): TextBaseProps {
		return {
			color: this.colors.textSecondary,
			fontSize,
		};
	}
}
