<p-toast position="top-center"></p-toast>

<div *ngIf="!blocked" class="sign-in grid gap-0 p-0 m-0">
	<div class="col-12 md:col-6 lg:col-5 xl:col-4 h-screen form w-full">
		<div class="flex flex-column justify-content-center align-items-center h-full">
			<div class="flex flex-column justify-content-center flex-wrap gap-3 form-content">
				<img
					ngSrc="../../../../assets/rtp/img/hackett-logo.png"
					alt="The Hackett Group"
					height="35"
					width="260"
					class="ml-3"
				/>
				<div class="grid ml-3 gap-3">
					<form [formGroup]="form" class="form-inputs">
						<div class="col-12">
							<p class="text-color-secondary">Change password</p>
						</div>
						<div class="col-12">
							<p-password
								formControlName="password1"
								placeholder="New password"
								styleClass="w-full"
								inputStyleClass="form-input-text p-3 w-full"
								[feedback]="false"
								[toggleMask]="true"
								(keyup.enter)="changePassword()"
							></p-password>
						</div>
						<div class="col-12">
							<p-password
								formControlName="password2"
								placeholder="Confirm new password"
								styleClass="w-full"
								inputStyleClass="form-input-text p-3 w-full"
								[feedback]="false"
								[toggleMask]="true"
								(keyup.enter)="changePassword()"
							></p-password>
						</div>
					</form>
					<div class="col-12">
						<p-button
							label="Change password"
							styleClass="p-button-lg"
							[loading]="loading"
							(onClick)="changePassword()"
						></p-button>
					</div>
				</div>
				<p class="text-color-secondary ml-5 text-sm">
					Using the AI XPLR Platform, is subject to the end user terms and conditions.
				</p>
			</div>
		</div>
	</div>
</div>

<p-blockUI [blocked]="blocked">
	<div class="flex flex-column justify-content-center text-center layout-progress gap-3">
		<img src="assets/icons/ISO_aiExplorer_color.svg" alt="" class="h-5rem">
		<span class="text-primary font-bold" style="font-size: 20px">AI XPLR</span>
		<p-progressBar mode="indeterminate"></p-progressBar>
	</div>
</p-blockUI>
