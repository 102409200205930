<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<div class="card card-content-solution-detail card-content-simulation">
		<div *ngIf="useCase && useCase.data" class="grid mx-2 my-1">
			<div class="col-12 md:col-9 lg:col-9 xl:col-9 pl-1 pt-3">
				<div class="use-case-simulation-item text-color-base px-3">
					<div class="flex flex-wrap">
						<div class="col-9 p-0 pr-2">
							<div class="flex justify-content-between gap-3">
								<div class="flex justify-content-start gap-1">
									<span class="font-bold text-2xl pl-0 use-case-description-title">
										{{ useCase.name }}
									</span>
								</div>
							</div>
						</div>

						<div class="col-3 p-0 pr-2">
							<div class="flex justify-content-end py-3">
								<ng-template [ngIf]="solutionService.currentSolution?.icon">
									<img [src]="solutionService.currentSolution?.icon" alt="" class="icon-solution" />
								</ng-template>
								<ng-template [ngIf]="solutionService.currentSolution?.iconDefault?.file">
									<img
										[src]="solutionService.currentSolution?.iconDefault?.file"
										alt=""
										class="icon-solution"
									/>
								</ng-template>
								<ng-template
									[ngIf]="
										!(
											solutionService.currentSolution?.icon ||
											solutionService.currentSolution?.iconDefault?.file
										)
									"
								>
									<span class="pi pi-box text-4xl text-orange-500"></span>
								</ng-template>
							</div>
						</div>

						<div class="col-12  md:col-9 lg:col-9  p-0 pr-2">
							<p class="text-subtitle-component">
								{{ useCase.description }}
							</p>
						</div>
						
						<div class="col-12 md:col-3 lg:col-3 p-0 pr-2 justify-content-end align-items-right gap-2">
							<span class="title-impact background-impact py-1 px-2">
								<i class="pi pi-bullseye mr-0"></i>
								Impact
							</span>
							<span class="title-impact background-impact-value py-1 px-2">
								{{ useCase.data.impact_assessment }}
							</span>
						</div>

						<div class="col-12 md:col-6 p-0 pr-2">
							<div class="box-simulation-item p-0">
								<div class="flex flex-column gap-0">
									<div
										class="flex justify-content-left align-items-center h-3rem font-bold header-business px-3 cursor-pointer"
										(click)="accordion1.activeIndex = accordion1.activeIndex ? 0 : -1"
									>
										<p
											class="text-primary"
											style="
												display: flex;
												justify-content: space-between;
												align-items: center;
												width: 100%;
											"
										>
											<span>
												<i class="pi pi-database mr-2"></i>
												Data Requirements</span
											>
											<i
												[ngClass]="
													accordion1.activeIndex ? 'pi pi-angle-down' : 'pi pi-angle-up'
												"
											></i>
										</p>
									</div>
									<p-accordion #accordion1 [activeIndex]="0" class="accordion">
										<p-accordionTab header="">
											<div class="flex justify-content-left h-12rem px-3 pt-2" style="line-height: 1.4;">
												{{ useCase.data.data_requirements }}
											</div>
										</p-accordionTab>
									</p-accordion>
								</div>
							</div>
						</div>

						<div class="col-12 md:col-6 p-0 pr-2">
							<div class="box-simulation-item p-0">
								<div class="flex flex-column gap-0">
									<div
										class="flex justify-content-left align-items-center h-3rem font-bold header-business px-3 cursor-pointer"
										(click)="accordion2.activeIndex = accordion2.activeIndex ? 0 : -1"
									>
										<p
											class="text-primary"
											style="
												display: flex;
												justify-content: space-between;
												align-items: center;
												width: 100%;
											"
										>
											<span>
												<i class="pi pi-database mr-2"></i>
												Implementation Consideration</span
											>
											<i
												[ngClass]="
													accordion2.activeIndex ? 'pi pi-angle-down' : 'pi pi-angle-up'
												"
											></i>
										</p>
									</div>
									<p-accordion #accordion2 [activeIndex]="0" class="accordion">
										<p-accordionTab header="">
											<div class="flex justify-content-left h-12rem px-3 pt-2" style="line-height: 1.4;">
												{{ useCase.data.potential_challenges }}
											</div>
										</p-accordionTab>
									</p-accordion>
								</div>
							</div>

						</div>

						<div class="col-12 px-0 py-3">
							<div class="box-simulation-item p-0">
								<div class="flex flex-column gap-0">
									<div
										class="flex justify-content-left align-items-center h-3rem font-bold header-business px-3 cursor-pointer"
									>
										<p class="text-primary">
											<i class="pi pi-chart-line mr-2"></i>
											Top Benefits
										</p>
									</div>
									<div class="flex justify-content-left align-items-center h-full margin-bottom">
										<div class="col-12 p-0">
											<div class="flex flex-wrap">
												<ng-template ngFor let-value [ngForOf]="valuesDriversScores">
													<div class="col p-0">
														<div class="flex flex-column gap-0">
															<div
																class="header-gray flex justify-content-center align-items-center h-3rem font-bold text-center"
															>
																<p>{{ value.title }}</p>
															</div>
															<div
																class="flex justify-content-center align-items-center h-5rem"
															>
																<p class="w-5rem h-2rem" [ngClass]="value.className">
																	{{ value.label }}
																</p>
															</div>
														</div>
													</div>
												</ng-template>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 md:col-3 lg:col-3 xl:col-3 pt-3">
				<div class="flex flex-column justify-content-start flex-wrap mt-3 gap-4">
					<div class="mb-2">
						<div class="flex justify-content-end">
							<p-button
								styleClass="p-chip p-component p-button-text h-3rem"
								icon="pi pi-angle-left"
								label="Back"
								(onClick)="this.useCaseService.goToSimulationHome()"
							></p-button>
						</div>
					</div>
					<div>
						<div class="flex justify-content-start gap-1">
							<p class="text-gray-900 text-ml font-bold m-0">Taxonomy Tags</p>
						</div>
						<div class="flex gap-2 flex-wrap">
							<ng-template [ngIf]="taxonomyList.length">
								<p-chip
									*ngFor="let taxonomy of taxonomyList"
									[pTooltip]="taxonomy.path"
									tooltipPosition="left"
									[label]="taxonomy.name"
									class="text-sm"
								></p-chip>
							</ng-template>
							<ng-template [ngIf]="!taxonomyList.length"> ---</ng-template>
						</div>
					</div>

					<div>
						<div class="flex justify-content-start gap-1">
							<p class="text-gray-900 text-ml font-bold m-0">Diagram</p>
						</div>
						<div class="flex justify-content-center align-items-center">
							<img src="assets/rtp/img/thumb_emptyDiagram.svg" class="h-6rem w-6rem" />
						</div>
					</div>

					<div class="w-full">
						<div class="grid">
							<div class="col-12">
								<div class="flex justify-content-start gap-1 relative">
									<p class="text-gray-900 text-ml font-bold m-0">AI Enablers</p>
								</div>
								<div class="grid justify-content-start mt-1">
									<ng-template ngFor let-technology [ngForOf]="technologies">
										<div
											class="col-2 justify-content-center text-center"
											tippy
											[tippyContent]="technology.name"
											tippyPlacement="bottom"
										>
											<i
												*ngIf="technology.svg"
												class="icon-tooltip technology"
												style="width: 100%"
												[ngClass]="{
													active: useCase.data.aiEnablers.includes(technology.id || ''),
												}"
												[innerHTML]="technology.svg"
											></i>
											<span class="text-sm">{{ technology.short }}</span>
										</div>
									</ng-template>
									<div *ngIf="!technologies.length">
										<span> --- </span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
</div>
