import {
	BaseEntity,
	DiagramBase,
	DiagramParent,
	IBaseEntity,
	IDiagramBase,
	IDiagramDataItem,
	IDiagramParent,
	ITagEntity,
	TagEntity,
} from './base.api';
import { ILevel2, ITechnology, Level2, Technology } from './e2e-taxonomy.api';

/**
 * Represents a function filter that extends the ITagEntity interface.
 * This interface provides the blueprint for filtering functions based on tags.
 *
 * @interface
 * @extends ITagEntity
 */
export interface IFunctionFilter extends ITagEntity {}

/**
 * Represents a function filter.
 * @class
 * @extends TagEntity
 * @implements IFunctionFilter
 */
export class FunctionFilter extends TagEntity implements IFunctionFilter {
	constructor(name: string, short?: string, created_at?: number, updated_at?: number, id?: string) {
		super(name, short, created_at, updated_at, id);
	}
}

/**
 * Represents an interface for a Tool.
 * This interface extends the ITagEntity interface.
 *
 * @interface ITool
 * @extends ITagEntity
 */
export interface ITool extends ITagEntity {}

/**
 * Represents a Tool.
 * @extends TagEntity
 * @implements ITool
 */
export class Tool extends TagEntity implements ITool {
	constructor(name: string, short?: string, created_at?: number, updated_at?: number, id?: string) {
		super(name, short, created_at, updated_at, id);
	}
}

/**
 * Represents a skill. It extends the ITagEntity interface.
 *
 * @interface
 * @extends {ITagEntity}
 */
export interface ISkill extends ITagEntity {}

/**
 * Class representing a Skill.
 * @extends TagEntity
 * @implements ISkill
 */
export class Skill extends TagEntity implements ISkill {
	constructor(name: string, short?: string, created_at?: number, updated_at?: number, id?: string) {
		super(name, short, created_at, updated_at, id);
	}
}

/**
 * Represents an AI Lever entity.
 * @interface
 * @extends ITagEntity
 */
export interface IAiLever extends ITagEntity {}

/**
 * Class representing an AiLever.
 * @extends TagEntity
 * @implements {IAiLever}
 */
export class AiLever extends TagEntity implements IAiLever {
	constructor(name: string, short?: string, created_at?: number, updated_at?: number, id?: string) {
		super(name, short, created_at, updated_at, id);
	}
}

/**
 * Represents a key feature solution.
 * @interface
 */
export interface IKeyFeatureSolution {
	id: string;
	name: string;
	description: string;
}

export interface IIcon extends IBaseEntity {
	file: string;
}

export class Icon extends BaseEntity implements IIcon {
	constructor(public file: string, created_at?: number, updated_at?: number, id?: string) {
		super(created_at, updated_at, id);
	}
}

/**
 * Represents a solution in a diagram.
 * @interface
 * @extends {IDiagramParent<IFlow>}
 */
export interface ISolution extends IDiagramParent<IFlow> {
	summary: string;
	keyFeatures: IKeyFeatureSolution[];

	data: { [key: string]: any };

	functions: IFunctionFilter[];
	functionsIds: string[];

	technologies: ITechnology[];
	technologiesIds: string[];

	levels2: ILevel2[];
	levels2Ids: string[];

	taxonomy: string[];

	tools: ITool[];
	toolsIds: string[];

	skills: ISkill[];
	skillsIds: string[];

	certified: boolean;
	industries: string[];
	aiDrivers: {
		benefits: string[];
		timeToImplement: string;
		effortToImplement: string;
		technology: string[];
	};

	icon: string;
	iconDefaultId: string;
}

/**
 * Represents a solution.
 *
 * @extends DiagramParent<Flow>
 * @implements ISolution
 */
export class Solution extends DiagramParent<Flow> implements ISolution {
	functions: FunctionFilter[];
	technologies: Technology[];
	levels2: Level2[];
	tools: Tool[];
	skills: Skill[];
	aiLevers: AiLever[];
	iconDefault: Icon;

	constructor(
		public summary: string,
		public keyFeatures: IKeyFeatureSolution[],
		public functionsIds: string[],
		public technologiesIds: string[],
		public levels2Ids: string[],
		public taxonomy: string[],
		public toolsIds: string[],
		public skillsIds: string[],
		public iconDefaultId: string,
		public data: { [key: string]: any },
		public certified: boolean,
		public industries: string[],
		public aiDrivers: {
			benefits: string[];
			timeToImplement: string;
			effortToImplement: string;
			technology: string[];
		},
		public icon: string,
		name: string,
		description: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(name, description, created_at, updated_at, id);
		Object.defineProperties(this, {
			functions: { value: [], enumerable: false, writable: true },
			technologies: { value: [], enumerable: false, writable: true },
			levels2: { value: [], enumerable: false, writable: true },
			tools: { value: [], enumerable: false, writable: true },
			skills: { value: [], enumerable: false, writable: true },
			aiLevers: { value: [], enumerable: false, writable: true },
			iconDefault: { value: undefined, enumerable: false, writable: true },
		});
	}

	/**
	 * Retrieves an array of aiLeversIds.
	 *
	 * @returns {string[]} The array of aiLeversIds.
	 */
	get aiLeversIds(): string[] {
		return this.data
			? (((this.data as any).ailevers as { id: string; value: number }[]) || [])
					.filter((d) => !!d.value)
					.map((d) => d.id)
			: [];
	}
}

/**
 * Represents a flow in a diagram
 * @interface
 * @extends IDiagramBase
 */
export interface IFlow extends IDiagramBase {
	solution: ISolution;
	solutionId: string;
}

/**
 * Flow class represents a flow diagram.
 */
export class Flow extends DiagramBase implements IFlow {
	solution: Solution;

	constructor(
		public solutionId: string,
		xml: string,
		data?: { [key: string]: IDiagramDataItem },
		capture_url?: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(xml, capture_url, data, created_at, updated_at, id);
		Object.defineProperties(this, {
			solution: { value: undefined, enumerable: false, writable: true },
		});
	}
}

/**
 * Represents a category question.
 * @interface
 * @extends IBaseEntity
 */
export interface ICategoryQuestion extends IBaseEntity {
	name: string;
	sort: number;
}

/**
 * Represents a category question.
 *
 * @class
 * @extends BaseEntity
 * @implements ICategoryQuestion
 */
export class CategoryQuestion extends BaseEntity implements ICategoryQuestion {
	constructor(public name: string, public sort: number, created_at?: number, updated_at?: number, id?: string) {
		super(created_at, updated_at, id);
	}
}

/**
 * @interface ISubCategoryQuestion
 * @extends IBaseEntity
 *
 * @property {string} name - The name of the subcategory question.
 * @property {number} sort - The sort order of the subcategory question.
 * @property {ICategoryQuestion} category - The category question associated with the subcategory question.
 * @property {string} categoryId - The ID of the category question associated with the subcategory question.
 */
export interface ISubCategoryQuestion extends IBaseEntity {
	name: string;
	sort: number;

	category: ICategoryQuestion;
	categoryId: string;
}

/**
 * Represents a SubCategoryQuestion.
 *
 * @class SubCategoryQuestion
 * @extends BaseEntity
 * @implements ISubCategoryQuestion
 */
export class SubCategoryQuestion extends BaseEntity implements ISubCategoryQuestion {
	category: CategoryQuestion;

	constructor(
		public name: string,
		public sort: number,
		public categoryId: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(created_at, updated_at, id);
		Object.defineProperties(this, {
			category: { value: undefined, enumerable: false, writable: true },
		});
	}
}

/**
 * Represents an answer to a technology question.
 * @interface
 */
interface IAnswerTechnologyQuestion {
	text: string;
	value: string;
	help: string;
}

interface WeightJson {
	[t: string]: [number, number, number, number, number, number];
}

/**
 * Represents a question entity that extends the base entity.
 * @interface
 */
export interface IQuestion extends IBaseEntity {
	text: string;
	weight: string;
	weightJson: WeightJson;
	sort: number;
	tag: string;
	label: string;

	answers: IAnswerTechnologyQuestion[];

	category: ICategoryQuestion;
	categoryId: string;

	subcategory: ISubCategoryQuestion;
	subcategoryId: string;

	technology: ITechnology;
	technologyId: string;
}

/**
 * Represents a question.
 * @extends BaseEntity
 * @implements IQuestion
 */
export class Question extends BaseEntity implements IQuestion {
	category: CategoryQuestion;
	subcategory: SubCategoryQuestion;
	technology: Technology;

	constructor(
		public text: string,
		public weight: string,
		public weightJson: WeightJson,
		public sort: number,
		public tag: string,
		public label: string,
		public answers: IAnswerTechnologyQuestion[],
		public categoryId: string,
		public subcategoryId: string,
		public technologyId: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(created_at, updated_at, id);
		Object.defineProperties(this, {
			category: { value: undefined, enumerable: false, writable: true },
			technology: { value: undefined, enumerable: false, writable: true },
		});
	}
}
