import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface AppConfig {
	inputStyle: string;
	colorScheme: string;
	theme: string;
	ripple: boolean;
	menuMode: string;
	scale: number;
}

interface LayoutState {
	staticMenuDesktopInactive: boolean;
	overlayMenuActive: boolean;
	profileSidebarVisible: boolean;
	configSidebarVisible: boolean;
	staticMenuMobileActive: boolean;
	menuHoverActive: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class LayoutService {
	warnings: boolean = true;

	config: AppConfig = {
		ripple: false,
		inputStyle: 'outlined',
		menuMode: 'static',
		colorScheme: 'light',
		theme: 'lara-light-indigo',
		scale: 14,
	};

	state: LayoutState = {
		staticMenuDesktopInactive: true,
		overlayMenuActive: false,
		profileSidebarVisible: false,
		configSidebarVisible: false,
		staticMenuMobileActive: false,
		menuHoverActive: false,
	};

	public title: string = '';

	public titleText = new Subject<string>();

	titleText$ = this.titleText.asObservable();

	updateTitle(title: string) {
		this.title = title;
		this.titleText.next(title);
	}

	public configUpdate = new Subject<AppConfig>();

	public overlayOpen = new Subject<any>();

	configUpdate$ = this.configUpdate.asObservable();

	overlayOpen$ = this.overlayOpen.asObservable();

	isOpen() {
		return !this.state.staticMenuDesktopInactive;
	}

	onMenuClose() {
		this.onMenuToggleBase(false, true, true);
	}

	onMenuOpen() {
		this.onMenuToggleBase(true, false, false);
	}

	onMenuToggle() {
		this.onMenuToggleBase(
			!this.state.overlayMenuActive,
			!this.state.staticMenuDesktopInactive,
			!this.state.staticMenuMobileActive,
		);
	}

	onMenuToggleBase(overlayMenuActive: boolean, staticMenuDesktopInactive: boolean, staticMenuMobileActive: boolean) {
		if (this.isOverlay()) {
			this.state.overlayMenuActive = overlayMenuActive;
			if (this.state.overlayMenuActive) {
				this.overlayOpen.next(null);
			}
		}

		if (this.isDesktop()) {
			this.state.staticMenuDesktopInactive = staticMenuDesktopInactive;
		} else {
			this.state.staticMenuMobileActive = staticMenuMobileActive;

			if (this.state.staticMenuMobileActive) {
				this.overlayOpen.next(null);
			}
		}
	}

	showProfileSidebar() {
		this.state.profileSidebarVisible = !this.state.profileSidebarVisible;
		if (this.state.profileSidebarVisible) {
			this.overlayOpen.next(null);
		}
	}

	showConfigSidebar() {
		this.state.configSidebarVisible = true;
	}

	isOverlay() {
		return this.config.menuMode === 'overlay';
	}

	isDesktop() {
		return window.innerWidth > 991;
	}

	isMobile() {
		return !this.isDesktop();
	}

	onConfigUpdate() {
		this.configUpdate.next(this.config);
	}
}
